<template>
    <div class="checkout-page-spacing">
        <div class="my-10 md:mt-20 md:mb-35">
            <Stepper :current="0"/>
        </div>
        <div class="grid grid-cols-12 lg:space-x-20">
            <div class="col-span-12 lg:col-span-7 xl:col-span-8 px-0 sm:px-20 md:px-40 pb-20 bg-white rounded-lg pt-15 md:py-35">
                <div class="flex justify-between items-end mb-0 md:mb-20">
                    <h1 class="px-20 sm:px-0 font-bold text-primary text-20 md:text-40">
                        1. {{ model.headline }}
                    </h1>
                    <div v-if="isTabletOrDesktop && isAuthenticated" class="flex justify-end">
                        <Button class="sm:-mx-20"
                                :label="editing ? $translate('Client.CheckoutPage.CustomerInformation.CancelEdit') : $translate('Client.CheckoutPage.CustomerInformation.Edit')"
                                transparent
                                @click="editing = !editing"/>
                    </div>
                </div>
                <p v-if="!isAuthenticated" class="p-20 sm:px-0 text-14">
                    {{ $translate('Client.CheckoutPage.SignUpDesc') }}
                </p>

                <div v-else-if="basket?.invoiceAddress">
                    <div v-if="!isTabletOrDesktop && isAuthenticated" class="flex justify-end">
                        <Button class="sm:-mx-20"
                                :label="editing ? $translate('Client.CheckoutPage.CustomerInformation.CancelEdit') : $translate('Client.CheckoutPage.CustomerInformation.Edit')"
                                transparent
                                @click="editing = !editing"/>
                    </div>
                    <CustomerInfoForm v-if="editing" :next-step-url="model.deliveryPage.url" @cancel="editing = false"/>
                    <CustomerInfo v-else :next-step-url="model.deliveryPage.url"/>
                </div>
                <div v-if="!isAuthenticated" class="text-image-block">
                    <RenderBlocks v-if="model.content" :model="model.content"/>
                </div>
            </div>
            <div class="col-span-12 lg:col-span-5 xl:col-span-4 mt-20 lg:mt-0">
                <BasketSummary v-if="basket" :basket="basket" :bonus="bonus" checkout-mode/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { CustomerInformationPageModel } from '@/api/cms';
import { getCheckoutBasket, prepareBasket } from '@/project/apis/commerce/checkoutApi';
import Stepper from '@/project/pages/checkout-page/Stepper.vue';
import BasketSummary from '@/project/basket/basket-summary/BasketSummary.vue';
import CustomerInfo from '@/project/pages/checkout-page/customer-info/CustomerInfo.vue';
import { authRequired, isAuthenticated } from '@/project/authentication/authentication';
import Button from '@/project/components/button/Button.vue';
import { ref } from 'vue';
import CustomerInfoForm from './CustomerInfoForm.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import RenderBlocks from '@/project/pages/content-page/RenderBlocks.vue';
import { trackCheckoutStep, trackPage } from '@/project/tracking/tracking.service';

defineProps<{
    model: CustomerInformationPageModel
}>();

trackPage();

const editing = ref(false);

const { greater } = useBreakpoints();
const isTabletOrDesktop = greater('md');

const { basket, bonus } = getCheckoutBasket();

authRequired();

if (isAuthenticated.value) {
    prepareBasket().then(() => {
        trackCheckoutStep(2, basket.value?.lineItems);
    });
}

</script>

<style scoped>
.text-image-block:deep() .text-block{
    @apply !px-0;
}

</style>
