<template>
    <div class="inline-block relative">
        <div
            v-if="quantity"
            class="absolute -top-10 -right-10 py-4 px-6 text-white rounded-full z-1 min-w-[25px] text-12"
            :style="{ 'background': '#004575' }">
            <AnimateValue animation="scroll">
                <div :key="quantity" class="flex justify-center items-center">
                    {{ quantity }}
                </div>
            </AnimateValue>
        </div>
        <Button class="relative"
                :class="subTotal > 100000 ? 'min-w-[145px]' : 'min-w-[135px]'"
                :label="formatPrice(subTotal) || '0'"
                size="medium"
                strong
                primary
                @click="open">
            <div class="absolute left-15">
                <CIcon name="basket" width="16" height="16"/>
            </div>
            <div class="mr-15"></div>
        </Button>
    </div>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { useBasket } from '@/project/apis/commerce/basketApi';
import { formatPrice } from '@/project/products/priceFormatter';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import openDialog from '@/project/dialog/dialog';
import BasketMini from '@/project/basket/basket-mini/BasketMini.vue';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { useRouteQuery } from '@/core/browser/query/useQueryParams';
import { computed } from 'vue';
import { QueryKeys } from '@/project/browser/query';

const { subTotal, quantity } = useBasket();
const { basketUrl } = getPageUrls();

const termQuery = useRouteQuery(QueryKeys.TERM);
const searchActive = computed(() => termQuery.value !== '' || false);

const open = () => {
    if (basketUrl.value === window.location.pathname) {
        if (searchActive.value) {
            termQuery.value = '';
        }
        return false;
    }
    openDialog(BasketMini, {});
};
</script>
