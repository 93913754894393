<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="[]" :current-page-name="$translate('Client.CheckoutPage.Confirmation.PageTitle')"/>
        <div v-if="receipt" class="grid grid-cols-12 pb-20 lg:space-x-20">
            <div class="col-span-12 lg:col-span-9 xl:col-span-9">
                <div>
                    <div class="px-20 pb-20 mb-20 bg-white rounded-lg pt-15">
                        <div>
                            <div class="flex flex-row items-center text-blue text-30">
                                <CIcon v-if="model.icon" :name="model.icon" width="32" height="32"/>
                                <h1 class="mt-10 mb-10 ml-10 font-bold text-30 lg:text-40 leading-[4rem]">
                                    {{ title }}
                                </h1>
                            </div>
                        </div>
                        <p class="pl-2 ml-40 text-20 ">
                            {{ description }}
                        </p>
                    </div>
                </div>
                <div>
                    <ConfirmationAddress :model="receipt"/>
                </div>
                <div>
                    <div class="flex items-center px-20 md:px-0">
                        <h2 class="text-30 text-blue">
                            {{ $translate('Client.CheckoutPage.Confirmation.YourReceipt') }}
                        </h2><span class="mt-10 ml-20 text-16">{{ receipt.lineItems.reduce((acc, cur) => acc + cur.quantity, 0) }} {{ $translate('Client.BasketPage.Products') }}</span>
                    </div>
                    <div class="mt-20 mb-20 bg-white">
                        <div v-for="(category, index) in categories.sort((a, b) => a.categoryName.localeCompare(b.categoryName))" :key="category.categoryName">
                            <OrderCategory :name="category.categoryName" :class="{'rounded-t-lg': index === 0}"/>
                            <div v-for="lineItem in category.lineItems" :key="lineItem.sku" class="border-b border-background">
                                <OrderLineItem :model="lineItem"/>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div class="ml-auto w-full bg-white rounded-lg md:w-410">
                            <div class="flex pb-15 px-30 pt-30">
                                <span class="font-bold">{{ $translate('Client.BasketPage.SubTotal') }}</span>
                                <span class="ml-auto font-bold">{{ formatPrice(receipt.subTotal) }}</span>
                            </div>
                            <div class="flex px-30 pb-15">
                                <span>{{ $translate('Client.BasketPage.Packaging') }}</span>
                                <span class="ml-auto">{{ formatPrice(receipt.packagingTotal) }}</span>
                            </div>
                            <div class="flex border-b-2 pb-15 px-30 border-background">
                                <span>{{ $translate('Client.CheckoutPage.Confirmation.Delivery') }}</span>
                                <span class="ml-auto">{{ formatPrice(receipt.shippingTotal) }}</span>
                            </div>
                            <div class="flex px-30 py-15">
                                <span class="pr-4 font-bold">{{ $translate('Client.BasketPage.Total') }}</span>
                                <span class="ml-auto font-bold">{{ formatPrice(receipt.total) }}</span>
                            </div>
                            <div class="flex border-t-2 px-30 py-15 border-background">
                                <span class="text-fadedText">{{ $translate('Client.CheckoutPage.Confirmation.OfferSavings') }} </span>
                                <span class="ml-auto text-fadedText">{{ formatPrice(receipt.discountTotal) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-span-12 lg:col-span-3 xl:col-span-3 mt-20 lg:mt-0 mb-20 lg:mb-0">
                <div class="space-y-10">
                    <div class="w-full bg-white rounded-lg">
                        <div class="flex items-center p-20 border-b-2 border-background">
                            <CIcon v-if="model.forgetIcon"
                                   :name="model.forgetIcon"
                                   width="32"
                                   height="32"
                                   :original="true"/>
                            <span class="ml-10 font-bold text-18">{{ model.forgetTitle }}</span>
                        </div>
                        <div class="p-20">
                            <p v-for="text in reminderText" :key="text" class="mb-20">
                                {{ text }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RenderBlock v-if="model.bodyContent" :model="model.bodyContent"/>
    </div>
</template>

<script lang="ts" setup>

import CIcon from '@/core/layout/svgicon/CIcon.vue';
import dictionary from '@/core/dictionary/dictionary';
import OrderLineItem from '@/project/pages/order-page/order-line-item/OrderLineItem.vue';
import { ConfirmationPageModel } from '@/api/cms';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import RenderBlock from '@/project/pages/content-page/RenderBlocks.vue';
import ConfirmationAddress from './ConfirmationAddress.vue';
import { formatPrice } from '@/project/products/priceFormatter';

import OrderCategory from '@/project/pages/order-page/order-category/OrderCategory.vue';
import { useOrder } from '@/project/apis/commerce/orderApi';
import { QueryKeys } from '@/project/browser/query';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { trackReceiptPage, trackPage } from '@/project/tracking/tracking.service';
import { watchOnce } from '@vueuse/core';
import { localStorageService } from '@/core/browser/storage';

const props = defineProps<{
   model: ConfirmationPageModel
}>();

trackPage();

const $route = useRoute();
const { categories, order } = useOrder(String($route.query[QueryKeys.ORDER_ID]));

const receipt = computed(() => order.value);

const name = computed(() => receipt.value?.invoiceAddress?.firstName || '');

const title = computed(() => props.model.title ? props.model.title.replace('{CustomerName}', name.value) : dictionary.get('Client.CheckoutPage.Confirmation.ThankYou', name.value));
const description = computed(() => props.model.description ? props.model.description.replace('{CustomerName}', name.value) : dictionary.get('Client.CheckoutPage.Confirmation.ThankYouText', name.value));

const reminderText = props.model.forgetDescription.split('\n\n');

watchOnce(order, (_order) => {
    if (_order) {
        const hasTrackedOrder = localStorageService.getItemAs<boolean>(_order.orderId);
        if (!hasTrackedOrder) {
            trackReceiptPage(_order.orderId, _order.total, _order.vatTotal, _order.shippingTotal, _order.packagingTotal, _order.lineItems);
            localStorageService.setItemAs<boolean>(_order.orderId, true);
        }
    }
});
</script>
