<template>
    <div class="h-300 lg:h-500 block-spacing relative flex items-end overflow-hidden sm:rounded-lg">
        <div class="z-1 w-full pb-[3rem] lg:pb-[6rem]">
            <div class="relative mx-auto mb-0 flex w-full flex-wrap items-end justify-center gap-10 ">
                <Button
                    v-if="model.primaryCta"
                    :to="model.primaryCta.url"
                    :target="model.primaryCta.target"
                    class="ml-20"
                    :label="model.primaryCta.name"
                    :primary="true"
                    @click="onClick(model.primaryCta?.name)">
                    <template #right>
                        <CIcon name="arrow-right" width="12" height="12"/>
                    </template>
                </Button>
                <Button
                    v-if="model.secondaryCta"
                    :label="model.secondaryCta.name"
                    :to="model.secondaryCta.url"
                    :target="model.secondaryCta.target"
                    :primary="false"
                    @click="onClick(model.secondaryCta?.name)">
                    <template #right>
                        <CIcon name="arrow-right" class="ml-20" width="12" height="12"/>
                    </template>
                </Button>
            </div>
        </div>

        <div class="absolute inset-0 h-full w-full overflow-hidden">
            <ResponsiveImage
                class="!h-full"
                :aspect-ratio="16 / 9"
                :image-url="model.image.url"
                :anchor="model.image.focalPoint.left + ',' + model.image.focalPoint.top"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ImageBlockModel } from '@/api/cms';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Button from '@/project/components/button/Button.vue';
import { trackPromotionClick, trackPromotionImpressions } from '@/project/tracking/tracking.service';

const props = defineProps<{
    model: ImageBlockModel;
    columnIndex?: number
    totalColumns?: number
    indexInColumn: number
    compact?: boolean
}>();

let index = 0;
if (props.columnIndex !== undefined && props.totalColumns) {
    index = props.indexInColumn * props.totalColumns + props.columnIndex;
}
trackPromotionImpressions([{
    nameOfModule: 'ImageBlock',
    headlineOrImageName: props.model.image.name,
    creative: (props.model.primaryCta?.name || props.model.secondaryCta?.name) ?? '',
    index,
}]);

function onClick(creative?: string) {
    console.log('click');

    trackPromotionClick([{
        nameOfModule: 'ImageBlock',
        headlineOrImageName: props.model.image.name,
        creative: creative ?? '',
        index,
    }]);
}
</script>
