<template>
    <DrawerDialog
        v-slot="{close}"
        :title="$translate('Client.BasketPage.DeliveryCheckDescription')"
        use-slide-animation
        :position="renderInMobileMenu ? 'left' : 'right'"
        show-backdrop
        :show-back-button="renderInMobileMenu"
        :title-classes="`px-10 lg:px-30 ${drawerWidth}`"
        :drawer-classes="'bg-background'"
        @back="handleBack">
        <div :class="`pt-5 p-30 ${drawerWidth}`">
            <DeliveryTypeChooser v-if="state.step === Steps.DeliveryType"
                                 class="-mx-30"
                                 :items-direction="renderInMobileMenu ? 'col' : 'row'"
                                 @select-home-delivery="selectHomeDelivery"
                                 @select-pick-up="selectPickup"
                                 @on-valid-zip-with-results="(zipCode) => state.zipCode = zipCode"/>
            <PickupPointChooser v-if="state.step === Steps.PickupPoint"
                                :zip-code="state.zipCode"
                                @change-zip="changeZip"
                                @select-store="selectStore"/>
            <TimeSlotChooser v-if="state.step === Steps.TimeSlot"
                             :zip-code="state.zipCode"
                             :time-slot-type="state.timeSlotType"
                             :readonly="true"
                             :small-screen-number="2"
                             :store="state.store"
                             @change-zip="changeZip">
                <div class="flex justify-between">
                    <Button :label="$translate('Client.Common.Back')" :transparent="true" @click="back">
                        <CIcon name="arrow-left" width="10" height="10" class="-mr-10"/>
                    </Button>
                    <DeliveryNextButton :label="$translate('Client.BasketPage.DeliveryCheckerConfirm')"
                                        no-icon
                                        @click="close"/>
                </div>
            </TimeSlotChooser>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import DeliveryTypeChooser from '@/project/pages/checkout-page/delivery/DeliveryTypeChooser.vue';
import { reactive } from 'vue';
import PickupPointChooser from '@/project/pages/checkout-page/delivery/PickupPointChooser.vue';
import TimeSlotChooser from '@/project/pages/checkout-page/delivery/TimeSlotChooser.vue';
import { StoreViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import DeliveryNextButton from '@/project/pages/checkout-page/delivery/DeliveryNextButton.vue';
import Button from '@/project/components/button/Button.vue';
import { useMenus } from '@/project/navigation/menus.composable';
import { trackDeliveryCheck } from '@/project/tracking/tracking.service';
import { useBasket } from '@/project/apis/commerce/basketApi';

const { subTotal, quantity, minimumPurchase } = useBasket();

trackDeliveryCheck(subTotal.value >= minimumPurchase.value, quantity.value);

enum Steps {
    DeliveryType,
    TimeSlot,
    PickupPoint
}

interface State {
    step: Steps
    zipCode: number
    timeSlotType: TimeSlotTypeViewModel
    store: StoreViewModel | null
}

const { openMobileMenu, drawerWidth, renderInMobileMenu } = useMenus();

const state: State = reactive({
    step: Steps.DeliveryType,
    zipCode: 0,
    timeSlotType: TimeSlotTypeViewModel.HomeDelivery,
    store: null,
});

function selectHomeDelivery() {
    state.timeSlotType = TimeSlotTypeViewModel.HomeDelivery;
    state.step = Steps.TimeSlot;
}

function selectPickup() {
    state.timeSlotType = TimeSlotTypeViewModel.PickUp;
    state.step = Steps.PickupPoint;
}

function selectStore(store: StoreViewModel) {
    state.store = store;
    state.step = Steps.TimeSlot;
}

function changeZip() {
    state.step = Steps.DeliveryType;
}

function back() {
    if (state.timeSlotType === TimeSlotTypeViewModel.PickUp && state.step === Steps.TimeSlot) {
        state.step = Steps.PickupPoint;
    } else if (state.timeSlotType === TimeSlotTypeViewModel.HomeDelivery && state.step === Steps.TimeSlot) {
        state.step = Steps.DeliveryType;
    } else if (state.step === Steps.PickupPoint) {
        state.step = Steps.DeliveryType;
    }
}

function handleBack() {
    openMobileMenu();
}
</script>
