<template>
    <div>
        <Disclosure v-slot="{ open }" :default-open="defaultOpen">
            <div
                class="transition-maxHeight overflow-hidden duration-500 ease-in-out"
                :class="open ? 'max-h-2000' : 'max-h-53'">
                <DisclosureButton class="z-2 relative" @click="handleClick(open)">
                    <div class="mb-5 flex w-full items-center duration-100">
                        <span class="text-primary">{{ $translate('Client.CheckoutPage.Delivery.OpeningHours') }}</span>
                        <CIcon
                            name="arrow-down"
                            width="10"
                            height="10"
                            class="text-primary ml-7 transition-transform duration-200 ease-in-out"
                            :class="open ? '-rotate-180': ' rotate-0'"/>
                    </div>
                </DisclosureButton>
                <DisclosurePanel class="z-1 relative overflow-hidden">
                    <div>
                        <div class="flex">
                            <div>
                                <div v-for="item in program" :key="item.day">
                                    {{ item.day }}:
                                </div>
                            </div>
                            <div class="ml-20 text-right">
                                <div v-for="item in program" :key="item.day">
                                    {{ getOpeningHours(item.openingTime, item.closingTime) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </DisclosurePanel>
            </div>
        </Disclosure>
    </div>
</template>

<script setup lang="ts">
import { StoreProgramViewModel } from '@/api/commerce';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/vue';
import { getTime } from '@/project/utils/datetime';
import dictionary from '@/core/dictionary/dictionary';

defineProps<{
    defaultOpen?: boolean,
    program: StoreProgramViewModel[],
}>();

const emit = defineEmits(['expand-opening-hours']);

function getOpeningHours(openingTime: string, closingTime: string) {
    const _openingTime = getTime(openingTime, 'HH:mm');
    const _closingTime = getTime(closingTime, 'HH:mm');
    if (_openingTime === '00' && _closingTime === '00') {
        return dictionary.get('Client.StoreLocater.Open24hoursADay');
    }

    return `${_openingTime} - ${_closingTime}`;
}

function handleClick(open: boolean) {
    if (!open) {
        emit('expand-opening-hours');
    }
}
</script>
