import { pageResolver, blockResolver } from '@/core/content/componentResolver';
import { App } from 'vue';
import CategoryPage from '@/project/pages/category-page/CategoryPage.vue';
import CheckoutPage from '@/project/pages/checkout-page/CheckoutPage.vue';
import ContentPage from '@/project/pages/content-page/ContentPage.vue';
import SiteRoot from '@/project/pages/site-root-page/SiteRoot.vue';
import CustomerInformationPage from '@/project/pages/checkout-page/customer-info/CustomerInformationPage.vue';
import DeliveryPage from '@/project/pages/checkout-page/delivery/DeliveryPage.vue';
import BasketPage from '@/project/pages/basket-page/BasketPage.vue';
import MyAccountPage from '@/project/pages/my-account/MyAccountPage.vue';
import MyAccountOrderHistoryPage from '@/project/pages/my-account/MyAccountOrderHistoryPage.vue';
import ConfirmationPage from '@/project/pages/checkout-page/confirmation/ConfirmationPage.vue';
import Top100Page from '@/project/pages/top-100-page/Top100Page.vue';
import FallbackPage from '@/core/content/FallbackPage.vue';
import Error404Page from '@/project/pages/Error404Page.vue';
import Error500Page from '@/project/pages/Error500Page.vue';

// import SearchResultsPage from '@/pages/SearchResultsPage.vue';
import FallbackBlock from '@/core/content/FallbackBlock.vue';
import CategoryBand from '@/project/pages/category-page/CategoryBand.vue';
import CategoryBannerBlock from '@/project/content/banner/CategoryBannerBlock.vue';
import ProductBand from '@/project/products/product-band/ProductBand.vue';
import ProductBandSku from '@/project/products/product-band/ProductBandSku.vue';
import SectionHeader from '@/project/content/section-header/SectionHeader.vue';
import TextBlock from '@/project/content/text-block/TextBlock.vue';
import ImageBlock from '@/project/content/image/ImageBlock.vue';
import BannerBlock from '@/project/content/banner/BannerBlock.vue';
import TwoColumn from '@/project/content/columns/TwoColumn.vue';
import ThreeColumn from '@/project/content/columns/ThreeColumn.vue';
import ThreeColumnGrid from '@/project/content/columns/ThreeColumnGrid.vue';
import QuoteBlock from '@/project/content/quote-block/QuoteBlock.vue';
import TextImageBlock from '@/project/content/text-image-block/TextImageBlock.vue';
import VideoBlock from '@/project/content/video/VideoBlock.vue';
import WriteUsBlock from '@/project/content/write-us-block/WriteUsBlock.vue';
import GetInContact from '@/project/content/get-in-contact/GetInContact.vue';

export default function config(app: App<Element>): void {
    registerPages();
    registerBlocks();
}

function registerPages(): void {
    pageResolver.register('notFoundPage', Error404Page);
    pageResolver.register('categoryPage', CategoryPage);
    pageResolver.register('basketPage', BasketPage);
    pageResolver.register('checkoutPage', CheckoutPage);
    pageResolver.register('confirmationPage', ConfirmationPage);
    pageResolver.register('customerInformationPage', CustomerInformationPage);
    pageResolver.register('deliveryPage', DeliveryPage);
    pageResolver.register('myAccountPage', MyAccountPage);
    pageResolver.register('myAccountOrderHistoryPage', MyAccountOrderHistoryPage);
    pageResolver.register('contentPage', ContentPage);
    pageResolver.register('top100Page', Top100Page);
    pageResolver.register('siteRoot', SiteRoot);
    pageResolver.register('errorPage', Error500Page);
    pageResolver.register('curatedCategoryPage', ContentPage);
    pageResolver.registerFallback(FallbackPage);
}

function registerBlocks(): void {
    blockResolver.register('categoryBandBlock', CategoryBand);
    blockResolver.register('productBandBlock', ProductBand);
    blockResolver.register('productBandSkuBlock', ProductBandSku);
    blockResolver.register('sectionHeader', SectionHeader);
    blockResolver.register('textBlock', TextBlock);
    blockResolver.register('imageBlock', ImageBlock);
    blockResolver.register('bannerBlock', BannerBlock);
    blockResolver.register('twoColumnContent', TwoColumn);
    blockResolver.register('threeColumnContent', ThreeColumn);
    blockResolver.register('threeColumnGridContent', ThreeColumnGrid);
    blockResolver.register('quoteBlock', QuoteBlock);
    blockResolver.register('textAndImageSideBySideBlock', TextImageBlock);
    blockResolver.register('videoBlock', VideoBlock);
    blockResolver.register('writeUsBlock', WriteUsBlock);
    blockResolver.register('getInContactBlock', GetInContact);
    blockResolver.register('categoryBannerBlock', CategoryBannerBlock);
    blockResolver.registerFallback(FallbackBlock);
}
