import { BasketValidityViewModel, BasketViewModel, CheckoutApiFactory, CompleteBasketResultViewModelClientResponse, DeliveryAddressRequest, LineItemViewModel, OrderViewModel, ProductReplacementOptions, ReserveTimeSlotErrorViewModel, ReserveTimeSlotErrorViewModelClientResponse, TimeSlotTypeViewModel, UpdateOrderInvoiceAddressRequest } from '@/api/commerce';
import HttpStatus from 'http-status-codes';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { clearBasket, fetchBonus } from '@/project/apis/commerce/basketApi';
import { accessToken } from '@/project/authentication/authentication';
import { addMessage, removeAllOfType } from '@/project/components/system-message/system-message.service';
import axios, { AxiosError } from 'axios';
import { computed, DeepReadonly, Ref, ref, readonly, shallowReadonly, ComputedRef } from 'vue';
import dictionary from '@/core/dictionary/dictionary';
import router from '@/router';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { trackCheckoutStep } from '@/project/tracking/tracking.service';
import { CategoryAndLineItems } from '@/project/basket/basket.types';

const checkoutApi = CheckoutApiFactory({ isJsonMime: () => true }, commerceApiHost);

const basket = ref<BasketViewModel | undefined>();
const readonlyBasket: DeepReadonly<Ref<BasketViewModel | undefined>> = readonly(basket);
const bonus = ref(0);
const readonlyBonus = shallowReadonly(bonus);
const status = computed(() => basket.value?.status);

export function getCheckoutBasket(): {
    basket: DeepReadonly<Ref<BasketViewModel | undefined>>,
    bonus: Readonly<Ref<number>>,
    status: ComputedRef<BasketValidityViewModel | undefined>,
    ageLimitedLineItemsByCategory: Ref<CategoryAndLineItems[]>,
    } {
    return {
        basket: readonlyBasket,
        bonus: readonlyBonus,
        status,
        ageLimitedLineItemsByCategory: computed(() => {
            const category:CategoryAndLineItems[] = [];
            basket.value?.lineItems.filter((l: LineItemViewModel) => !!l.ageLimit).forEach((item: LineItemViewModel) => {
                if (!category[item.firstLevelCategorySortOrder]) {
                    category[item.firstLevelCategorySortOrder] = {
                        categoryName: item.firstLevelCategoryName,
                        lineItems: [],
                    };
                }
                category[item.firstLevelCategorySortOrder].lineItems.push(item as LineItemViewModel);
            });

            return category.filter(n => n).sort((a, b) => a.categoryName.localeCompare(b.categoryName));
        }),
    };
}

export const shipmentAsBonusAchieved = computed(() => basket.value?.shipmentAsBonusThreshold && basket.value?.subTotal >= basket.value?.shipmentAsBonusThreshold);

export async function prepareBasket(): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutPreparePost()
        .then(async res => {
            basket.value = res.data.model;

            if (res.data.model.status !== BasketValidityViewModel.Valid && res.data.model.status !== BasketValidityViewModel.MinimumSubTotalNotReachedButCustomerHasOpenOrder) {
                if (res.data.model.status === BasketValidityViewModel.IsEmpty) {
                    const { basketUrl } = getPageUrls();
                    router.replace(basketUrl.value);
                } else {
                    addMessage({
                        type: 'delivery',
                        title: dictionary.get(`Client.DeliveryStatus.${res.data.model.status}Title`),
                        description: dictionary.get(`Client.DeliveryStatus.${res.data.model.status}Description`),
                        severity: 'warning',
                    });
                }
            }

            bonus.value = await fetchBonus();

            return res.data.model;
        });
}

export async function updateInvoiceAddress(request: UpdateOrderInvoiceAddressRequest): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutInvoiceAddressPut(
        undefined,
        request).then(res => {
        basket.value = res.data.model;

        return res.data.model;
    });
}

export async function updateDeliveryAddress(request: DeliveryAddressRequest): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutDeliveryAddressPut(
        undefined,
        request,
    ).then(res => {
        basket.value = res.data.model;

        return res.data.model;
    });
}

export async function reservePickUpTimeSlot(timeSlotId: number, zip: number, storeId: string): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutReservePickUpTimeSlotPost(timeSlotId, zip, storeId)
        .then(async res => {
            removeAllOfType('delivery');
            basket.value = res.data.model;
            bonus.value = await fetchBonus();
            return res.data.model;
        }).catch(error => {
            if (axios.isAxiosError(error)) {
                handleReserveTimeSlotError(error);
            }
            console.error('Could not reserve pick up time slot', error);
            throw error;
        });
}

export async function reserveHomeDeliveryTimeSlot(timeSlotId: number, zip: number): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(timeSlotId, zip)
        .then(async res => {
            basket.value = res.data.model;
            removeAllOfType('delivery');
            bonus.value = await fetchBonus();
            return res.data.model;
        }).catch(error => {
            if (axios.isAxiosError(error)) {
                handleReserveTimeSlotError(error);
            }
            console.error('Could not reserve home delivery time slot', error);
            throw error;
        });
}

function handleReserveTimeSlotError(error: AxiosError) {
    if (error.response) {
        const data = error.response.data as ReserveTimeSlotErrorViewModelClientResponse;

        switch (data.model) {
        case ReserveTimeSlotErrorViewModel.TimeSlotLeadTimeHasPassed:
        case ReserveTimeSlotErrorViewModel.TimeSlotSoldOut:
            addMessage({
                title: dictionary.get(`Client.DeliveryStatus.${data.model}Title`),
                description: dictionary.get(`Client.DeliveryStatus.${data.model}Description`),
                severity: 'warning',
                type: 'delivery',
            });
            break;
        }

        switch (error.response.status) {
        case HttpStatus.INTERNAL_SERVER_ERROR:
            if (data.validationMessages[0].message !== 'No timeslot can be reserved') {
                addMessage({
                    title: dictionary.get('Client.DeliveryStatus.UnexpectedErrorTitle'),
                    description: dictionary.get('Client.DeliveryStatus.UnexpectedErrorDescription'),
                    severity: 'error',
                    type: 'delivery',
                });
            }
            break;
        case HttpStatus.UNAUTHORIZED:
            addMessage({
                title: dictionary.get('Client.DeliveryStatus.NotLoggedInTitle'),
                description: dictionary.get('Client.DeliveryStatus.NotLoggedInDesc'),
                severity: 'error',
                type: 'delivery',
            });
            break;
        }
    }
}

export async function setOrderProcessingInfo(noteToDriver: string | null, noteToPicker: string | null, selectedReplacementOption: ProductReplacementOptions): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutOrderProcessingInfoPost(
        undefined,
        { noteToDriver, noteToPicker, selectedReplacementOption, acceptTermsAndConditions: true },
    ).then(res => {
        if (res.data.model.status !== BasketValidityViewModel.Valid && res.data.model.status !== BasketValidityViewModel.MinimumSubTotalNotReachedButCustomerHasOpenOrder) {
            addMessage({
                type: 'system',
                title: dictionary.get(`Client.DeliveryStatus.${res.data.model.status}Title`),
                description: dictionary.get(`Client.DeliveryStatus.${res.data.model.status}Description`),
                severity: 'error',
            });
        }
        return res.data.model;
    });
}

export async function addToExistingTimeSlot(existingOrderId?: string): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId).then(async res => {
        removeAllOfType('delivery');
        removeAllOfType('system');
        basket.value = res.data.model;
        bonus.value = await fetchBonus();
        return res.data.model;
    }).catch(error => {
        if (axios.isAxiosError(error)) {
            handleReserveTimeSlotError(error);
        }
        console.error('Could not reserve home delivery time slot', error);
        throw error;
    });
}

export async function setDeliveryZipCode(zipCode: number): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutDeliveryZipCodePut(zipCode).then(async res => {
        removeAllOfType('delivery');
        removeAllOfType('system');
        basket.value = res.data.model;
        bonus.value = await fetchBonus();
        return res.data.model;
    }).catch(error => {
        if (axios.isAxiosError(error)) {
            handleReserveTimeSlotError(error);
        }
        throw error;
    });
}

export async function redirectToPayment(): Promise<void> {
    const token = accessToken.value;
    if (!token) {
        throw Error('no access token present');
    }

    try {
        const response = await checkoutApi.apiBasketCheckoutInitiatePaymentPost();

        // Append access token to redirect URL (this might need to be removed in the future)
        trackCheckoutStep(4, basket.value?.lineItems);
        const url = new URL(response.data.model.redirectUrl);
        url.searchParams.append('accessToken', token);

        window.location.href = url.toString();
    } catch {
        await prepareBasket();
    }
}

export async function completePayment(paymentId: string) : Promise<void> {
    const { confirmationUrl, basketUrl } = getPageUrls();

    try {
        const orderConfirmation = await checkoutApi.apiBasketCheckoutCompletePost(undefined, { paymentId });

        clearCheckoutBasket();
        clearBasket();

        router.push({
            path: confirmationUrl.value,
            query: {
                orderId: orderConfirmation.data.model.orderId,
            },
        });
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                const data = error.response.data as CompleteBasketResultViewModelClientResponse;

                addMessage({
                    title: dictionary.get(`Client.BasketStatus.${data.model}Title`),
                    description: dictionary.get(`Client.BasketStatus.${data.model}Description`),
                    severity: 'error',
                    type: 'system',
                });

                router.push({
                    path: basketUrl.value,
                });
            }
        }
    }
}

export function clearCheckoutBasket(): void {
    basket.value = undefined;
}

export function getDeliveryType(o: OrderViewModel): string {
    if (o.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery) {
        return dictionary.get('Client.CheckoutPage.Delivery.HomeDeliveryOption');
    }

    if (o.pickupStoreChainName) {
        return dictionary.get('Client.OrderPage.PickupAt', o.pickupStoreChainName);
    }

    return dictionary.get('Client.OrderPage.Pickup');
}

export async function deleteBasketTimeslot(): Promise<BasketViewModel> {
    return checkoutApi.apiBasketCheckoutTimeslotDelete().then(res => {
        basket.value = res.data.model;

        return res.data.model;
    });
}
