<template>
    <div class="watermark">
        <div class="page-spacing">
            <div class="flex justify-center pb-15 text-primary">
                <div class="flex my-10 mx-20 space-x-10 sm:space-x-40">
                    <div v-for="usp in model.usps" :key="usp.headline" class="flex flex-col sm:flex-row justify-center items-center">
                        <CIcon :name="usp.icon"
                               width="40"
                               height="40"
                               class="mb-4 mr-15"/>
                        <div class="flex flex-col text-center sm:text-left text-11 sm:text-14">
                            <div class="sm:font-bold">
                                {{ usp.headline }}
                            </div>
                            <div class="font-light text-10 sm:text-13">
                                {{ usp.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-start lg:space-x-10 xl:space-x-20">
                <div class="w-full">
                    <RenderBlocks :model="model.bodyContent"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { SiteRootModel } from '@/api/cms';
import CIcon from '@/core/layout/svgicon/CIcon.vue';
import RenderBlocks from '@/project/pages/content-page/RenderBlocks.vue';
import { trackPage } from '@/project/tracking/tracking.service';

defineProps<{
    model: SiteRootModel,
}>();

trackPage();
</script>
