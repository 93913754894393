import { useWindowScroll } from '@vueuse/core';
import { computed, ref, Ref } from 'vue';

export type SystemMessageTypes = 'system' | 'basket' | 'delivery';
export type SystemMessageSeverity = 'success' | 'warning' | 'error' | 'info';

export interface SystemMessage {
    title: string;
    description: string;
    type: SystemMessageTypes;
    severity: SystemMessageSeverity
}

export const messages: Ref<Set<SystemMessage>> = ref(new Set());
export const isShowingSystemMessages = computed(() => messages.value.size > 0);

export function addMessage(message: SystemMessage): void {
    let exists = false;
    messages.value.forEach(v => {
        if (v.title === message.title && v.description === message.description) {
            exists = true;
        }
    });

    // Should only have one of each unique message.
    if (!exists) {
        messages.value.add(message);

        // If user has scrolled down on page, scroll up to make sure they see the message
        const { y } = useWindowScroll();
        if (y.value > 100) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }
}

export function removeMessage(message: SystemMessage): void {
    messages.value.delete(message);
}

export function removeAllOfType(type: SystemMessageTypes): void {
    messages.value.forEach(v => {
        if (v.type === type) {
            messages.value.delete(v);
        }
    });
}
