<template>
    <Swiper v-bind="swiperConfig"
            :class="{'mySwiper': imageUrls.length > 1}">
        <SwiperSlide v-for="imageUrl in imageUrls" :key="imageUrl">
            <ResponsiveImage v-if="imageUrl"
                             :image-url="imageUrl"
                             :aspect-ratio="1/1"
                             :width-on-screen="{
                                 xs: 100,
                                 md: 50,
                                 lg: 33
                             }"
                             mode="pad"/>
            <img v-else
                 class="p-40 opacity-80 aspect-square"
                 :src="fallbackImage"
                 width="360">
        </SwiperSlide>
    </Swiper>
    <div v-if="imageUrls.length > 1 && isMobile"
         class="flex absolute bottom-1.5 justify-center space-x-40 w-full z-2"
         :class="{'space-x-60': imageUrls.length > 2}">
        <button class="py-10 swiper-prev-button px-15">
            <CIcon name="arrow-left-variant"
                   width="12"
                   height="12"
                   color="#000000"/>
        </button>
        <button class="py-10 swiper-next-button px-15">
            <CIcon name="arrow-right-variant"
                   width="12"
                   height="12"
                   color="#000000"/>
        </button>
    </div>
    <Swiper v-if="imageUrls.length > 1"
            :space-between="20"
            :slides-per-view="5"
            :free-mode="true"
            :watch-slides-progress="true"
            class="h-0 sm:h-80 opacity-0 sm:opacity-100 myThumbSwiper"
            @swiper="setThumbSwiper">
        <SwiperSlide v-for="imageUrl in imageUrls" :key="imageUrl">
            <div class="object-contain md:w-80 md:h-80 h-70 w-70">
                <ResponsiveImage
                    v-if="imageUrl"
                    :image-url="imageUrl"
                    :aspect-ratio="1/1"
                    :fixed-width-on-screen="80"
                    mode="pad"/>
                <img v-else
                     class="opacity-80 aspect-square"
                     :src="fallbackImage"
                     width="80">
            </div>
        </SwiperSlide>
    </Swiper>
</template>

<script setup lang="ts">
import { reactive, ref, watch } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import { fallbackImage } from '@/project/pages/product-details/fallback-image';

SwiperCore.use([Pagination, Navigation, Thumbs]);

const props = defineProps<{
    imageUrls: string[],
}>();
const thumbSwiper = ref(null);

const setThumbSwiper = (swiper) => {
    thumbSwiper.value = swiper;
};

const navigationConfig = {
    nextEl: '.swiper-next-button',
    prevEl: '.swiper-prev-button',
    disabledClass: 'opacity-50',
};

const swiperConfig = reactive<any>({
    modules: [Pagination, Thumbs],
    slidesPerView: 1,
    navigation: props.imageUrls.length > 1 ? navigationConfig : false,
    pagination: false,
    speed: 500,
    thumbs: {
        swiper: thumbSwiper,
    },
});

const breakpoint = useBreakpoints();
const isMobile = breakpoint.smaller('sm');
watch(isMobile, () => {
    if (isMobile.value && props.imageUrls.length > 1) {
        swiperConfig.pagination = {
            renderBullet: function(index, className) {
                return '<span class="' + className + '"></span>';
            },
        };
        swiperConfig.navigation = navigationConfig;
    } else {
        swiperConfig.pagination = false;
        swiperConfig.navigation = false;
    }
}, { immediate: true });
</script>
