import { UserManager, WebStorageStateStore, User, UserManagerEvents, UserManagerSettings } from 'oidc-client';
import { lobycoAuthentication } from '@/core/infrastructure/environment';
import { localStorageService } from '@/core/browser/storage';

export { User };

export default class AuthService {
    private userManager: UserManager;
    private fromLobycoApp: boolean | null;

    constructor() {
        const fromLobycoApp =  localStorageService.getItemAs<boolean>('fromLobycoApp');

        const settings: UserManagerSettings = {
            userStore: new WebStorageStateStore({ store: window.localStorage }),
            authority: lobycoAuthentication.authority,
            client_id: lobycoAuthentication.clientId,
            redirect_uri: lobycoAuthentication.redirectUri,
            automaticSilentRenew: true,
            silent_redirect_uri: lobycoAuthentication.redirectUri,
            response_type: 'code',
            scope: fromLobycoApp ? lobycoAuthentication.scope.replace('pin.edit', '').replace('profile.edit', '') : lobycoAuthentication.scope,
            response_mode: 'query',
            filterProtocolClaims: true,
            extraQueryParams: {
                audience: lobycoAuthentication.audience,
                lang: 'is',
                hide_header: true,
                theme: 'ecommerce',
            },
        };

        this.userManager = new UserManager(settings);
        this.fromLobycoApp = fromLobycoApp;

        this.userManager.startSilentRenew();
    }

    public get events(): UserManagerEvents {
        return this.userManager.events;
    }

    public get isFromLobycoApp(): boolean {
        return !!this.fromLobycoApp;
    }

    public getUser(): Promise<User | null> {
        return this.userManager.getUser();
    }

    public login(): Promise<User> {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
        const top = (height - 600) / 2 / systemZoom + dualScreenTop;
        const popupWindowFeatures = `
        scrollbars=yes,
        width=${500 / systemZoom}, 
        height=${600 / systemZoom}, 
        top=${top}, 
        left=${left}
        `;
        return this.userManager.signinPopup({
            popupWindowFeatures,
        });
    }

    public async silentSignIn(): Promise<User> {
        return await this.userManager.signinSilent();
    }

    public async logout(): Promise<void> {
        const user = await this.getUser();

        if (!user) {
            return;
        }

        return await this.userManager.signoutRedirect();
    }

    // Clears local application storage.
    public async localLogout(): Promise<void> {
        await this.userManager.clearStaleState();
        await this.userManager.removeUser();
    }

    public async getAccessToken(): Promise<string | undefined> {
        const data = await this.getUser();
        return data?.access_token;
    }

    public async signinPopupCallback(): Promise<void> {
        await this.userManager.signinPopupCallback();
    }

    public async signinRedirect(): Promise<void> {
        try {
            await this.userManager.signinRedirect();
        } catch (e) {
            console.log(e);
        }
    }

    public async signinRedirectCallback(): Promise<User> {
        return await this.userManager.signinRedirectCallback();
    }
}
