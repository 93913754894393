<template>
    <div>
        <div v-if="renderAsSlider && isMobile" class="relative block-spacing">
            <Swiper v-bind="swiperConfig" class="blockSwiper">
                <SwiperSlide>
                    <div>
                        <RenderBlocks :model="model.column1"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <RenderBlocks :model="model.column2"/>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div>
                        <RenderBlocks :model="model.column3"/>
                    </div>
                </SwiperSlide>
            </Swiper>
            <div class="flex absolute bottom-1.5 justify-center space-x-40 w-full z-2">
                <button class="py-10 px-10 swiper-prev-button">
                    <CIcon name="arrow-left-variant"
                           width="12"
                           height="12"
                           color="#000000"/>
                </button>
                <button class="py-10 px-10 swiper-next-button">
                    <CIcon name="arrow-right-variant"
                           width="12"
                           height="12"
                           color="#000000"/>
                </button>
            </div>
        </div>
        <div v-else class="grid grid-flow-row auto-rows-max gap-0 md:gap-20 grid-cols-24 block-spacing">
            <div class="md:col-span-8 col-span-24">
                <RenderBlocks :model="model.column1" :column-index="0" :total-columns="3"/>
            </div>
            <div class="md:col-span-8 col-span-24">
                <RenderBlocks :model="model.column2" :column-index="1" :total-columns="3"/>
            </div>
            <div class="md:col-span-8 col-span-24">
                <RenderBlocks :model="model.column3" :column-index="2" :total-columns="3"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ThreeColumnContentBlockModel } from '@/api/cms';
import RenderBlocks from '@/project/pages/content-page/RenderBlocks.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { reactive } from 'vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';

SwiperCore.use([Pagination, Navigation]);

const props = defineProps<{
    model: ThreeColumnContentBlockModel;
}>();

const navigationConfig = {
    nextEl: '.swiper-next-button',
    prevEl: '.swiper-prev-button',
    disabledClass: 'opacity-50',
};

const swiperConfig = reactive<any>({
    modules: [Pagination],
    slidesPerView: 1,
    navigation: navigationConfig,
    pagination: true,
    speed: 500,
});

const renderAsSlider = props.model.column1[0] && props.model.column1[0].alias === 'bannerBlock' && props.model.column2[0] && props.model.column2[0].alias === 'bannerBlock' && props.model.column3[0] && props.model.column3[0].alias === 'bannerBlock';
const breakpoint = useBreakpoints();
const isMobile = breakpoint.smaller('md');
</script>

<style scoped>
.blockSwiper {
    @apply pb-30;
}

.blockSwiper .swiper-wrapper {
    height: auto;
}

</style>
