import { computed, ComputedRef, ref } from 'vue';

export function useTransitionSync(name: string): {
    transitionHooks: Record<string, () => void>,
    syncedTransitionClasses: ComputedRef<string>
} {
    const transitionState = ref<'idle' | 'leave' | 'enter'>('idle');

    return {
        transitionHooks: ({
            leave: () => transitionState.value = 'leave',
            enter: () => transitionState.value = 'enter',
            afterEnter: () => transitionState.value = 'idle',
        }),
        syncedTransitionClasses: computed<string>(() => {
            switch (transitionState.value) {
            case 'leave':
                return `${name}-leave-to fade-leave-active`;
            case 'enter':
                return `${name}-enter-active`;
            default:
                return '';
            }
        }),
    };
}
