<template>
    <div v-if="renderAsSlider && isMobile" class="block-spacing">
        <Swiper v-bind="swiperConfig" class="blockSwiper">
            <SwiperSlide>
                <div>
                    <RenderBlocks :model="model.column1"/>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div>
                    <RenderBlocks :model="model.column2"/>
                </div>
            </SwiperSlide>
        </Swiper>
        <div class="flex relative bottom-1.5 justify-center -mt-28 mb-10 space-x-40 w-full z-2">
            <button class="py-10 swiper-prev-button px-15">
                <CIcon name="arrow-left-variant"
                       width="12"
                       height="12"
                       color="#000000"/>
            </button>
            <button class="py-10 swiper-next-button px-15">
                <CIcon name="arrow-right-variant"
                       width="12"
                       height="12"
                       color="#000000"/>
            </button>
        </div>
    </div>
    <div v-else class="grid grid-cols-12 grid-flow-row auto-rows-max gap-20 block-spacing">
        <div class="col-span-12 md:col-span-6">
            <RenderBlocks :model="model.column1" :column-index="0" :total-columns="2"/>
        </div>
        <div class="col-span-12 md:col-span-6">
            <RenderBlocks :model="model.column2" :column-index="1" :total-columns="2"/>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { TwoColumnContentBlockModel } from '@/api/cms';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import RenderBlocks from '@/project/pages/content-page/RenderBlocks.vue';
import { Pagination } from 'swiper';
import { reactive } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

const props = defineProps<{
    model: TwoColumnContentBlockModel;
}>();

const navigationConfig = {
    nextEl: '.swiper-next-button',
    prevEl: '.swiper-prev-button',
    disabledClass: 'opacity-50',
};

const swiperConfig = reactive<any>({
    modules: [Pagination],
    slidesPerView: 1,
    navigation: navigationConfig,
    pagination: true,
    speed: 500,
});

const renderAsSlider = props.model.column1[0] && props.model.column1[0].alias === 'bannerBlock' && props.model.column2[0] && props.model.column2[0].alias === 'bannerBlock';
const breakpoint = useBreakpoints();
const isMobile = breakpoint.smaller('md');
</script>

<style>
.blockSwiper {
    @apply pb-30;
}
.blockSwiper .swiper-wrapper{
    height: auto;
}

</style>
