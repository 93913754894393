<template>
    <ProductBlock :model="selectedProducts" :link="model.link" :hide-products="model.hideProducts"/>
</template>

<script lang="ts" setup>
import { ProductBandSkuBlockModel } from '@/api/cms';
import { ProductTileViewModel } from '@/api/commerce/models/product-tile-view-model';
import ProductBlock from '@/project/products/product-band/ProductBlock.vue';
import { trackProductImpressions } from '@/project/tracking/tracking.service';
import { ref, watch, Ref } from 'vue';
import { useSelectedProducts } from '../../apis/commerce/productsApi';

const props = defineProps<{
    model: ProductBandSkuBlockModel
}>();

const { getSelectedProducts, selectedProductsResult } = useSelectedProducts();
const selectedProducts:Ref<ProductTileViewModel[]> = ref([]);

getSelectedProducts(props.model.skus);

watch(selectedProductsResult, (res) => {
    if (res.dataReady) {
        selectedProducts.value = res.data;
        trackProductImpressions(res.data, { listType: 'Product band', choosingMechanism: 'Editor' });
    }
});

</script>
