import { format } from 'date-fns';
import { is } from 'date-fns/locale';

const locale = { locale: is };

export function getDate(date: string, fullyear?: boolean): string {
    const d = new Date(date);
    const fDate = format(d, fullyear ? 'dd.MM.yyyy' : 'dd.MM.yy', { locale: is });

    return fDate;
}

export function getWeekday(date: string, short?: boolean): string {
    const d = new Date(date);
    const weekday = format(d, short ? 'EEE' : 'EEEE', locale);

    // The check below is a workaronud for a missing dot in date-fns for this specific shortened weekday
    // https://github.com/date-fns/date-fns/pull/2974
    return `${weekday}${weekday === 'lau' ? '.' : ''}`;
}

export function getMonth(date: string, short?: boolean): string {
    const d = new Date(date);
    const month = format(d, short ? 'MMM' : 'MMMM', locale);

    return month;
}

export function getTime(date: string, formatString?: string): string {
    const d = new Date(date);
    const fromTime = format(d, formatString || 'HH:mm', { locale: is });

    return fromTime;
}
