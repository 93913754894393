<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="[]" :current-page-name="$translate('Client.BasketPage.Title')"/>
        <SystemMessage type="basket"/>
        <div class="grid grid-cols-12 lg:space-x-20">
            <div class="col-span-12 lg:col-span-7 xl:col-span-8">
                <div class="p-15 mb-20 rounded-lg bg-white xl:flex xl:items-center xl:justify-between">
                    <div>
                        <div
                            class="justify-left flex items-baseline">
                            <h1 class="text-28 lg:text-40 text-primary text-center font-bold xl:text-left">
                                {{ $translate('Client.BasketPage.Title') }}
                            </h1>
                            <span class="text-18 ml-20">
                                {{ quantity }} {{ $translate('Client.BasketPage.Products') }}
                            </span>
                        </div>
                        <MascotWithBonus/>
                    </div>
                </div>
                <BasketList/>

                <!-- Options beneath line items -->
                <div v-if="quantity > 0" class="mt-15 ml-10 flex items-center sm:ml-0">
                    <RouterLink to="/groceries" class="text-blue block">
                        {{ $translate('Client.BasketPage.ShopMore') }}
                    </RouterLink>
                    <Button :label="$translate('Client.BasketPage.EmptyBasket')" size="small" transparent @click="emptyBasket"/>
                </div>

                <div v-if="quantity === 0" class="flex w-full items-center justify-center rounded-lg bg-white py-80">
                    <div class="flex flex-col items-center">
                        <EmptyBasketIcon class="mb-40"/>
                        <div class="text-30 mb-10">
                            {{ $translate('Client.BasketPage.Empty') }}
                        </div>
                        <div class="flex space-x-40">
                            <RouterLink
                                v-for="item in mainMenuLinks"
                                :key="item.url"
                                :to="item.url"
                                class="text-14 text-link">
                                {{ item.name }}
                            </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-12 mt-20 lg:col-span-5 lg:mt-0 xl:col-span-4">
                <div class="space-y-10" :class="{'sticky top-100': quantity > 10}">
                    <BasketSummary v-if="basket" :basket="basket" :basket-validated="basketValidated" :bonus="bonus"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import BasketList from '@/project/basket/basket-list/BasketList.vue';
import BasketSummary from '@/project/basket/basket-summary/BasketSummary.vue';
import { fetchBasket, getBasket, useBasket, emptyBasket } from '@/project/apis/commerce/basketApi';
import { getLeftMainMenu } from '@/project/apis/cms/contentApi';
import { NameAndUrl } from '@/api/cms';
import { ref } from 'vue';
import SystemMessage from '@/project/components/system-message/SystemMessage.vue';
import EmptyBasketIcon from './EmptyBasketIcon.vue';
import Button from '@/project/components/button/Button.vue';
import { trackCheckoutStep, trackPage } from '@/project/tracking/tracking.service';
import MascotWithBonus from '@/project/pages/basket-page/MascotWithBonus.vue';

trackPage();

const { quantity } = useBasket();

const { basket, basketValidated, bonus } = getBasket();

const mainMenuLinks = ref<NameAndUrl[]>([]);

getLeftMainMenu().then(_leftMainMenuItems => {
    mainMenuLinks.value = _leftMainMenuItems;
});

fetchBasket();

trackCheckoutStep(1, basket.value?.lineItems);

</script>
