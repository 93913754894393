<template>
    <div class="py-40 o-container">
        <article class="flex flex-col justify-center items-center p-40 sm:mx-40 bg-white rounded-lg lg:mx-[150px]">
            <h1 class="mb-20 font-bold text-primary text-40 lg:text-56">
                {{ model.headline }}
            </h1>
            <p v-for="desc in descriptionLines" :key="desc" class="text-center lg:text-left text-14 lg:text-20">
                {{ desc }}
            </p>
            <div class="flex space-x-40 mt-30">
                <Button :label="$translate('Client.Navigation.BackToLastPage')" size="small" transparent @click="router.back()"/>
                <RouterLink :to="'/'" class="py-6 text-link">
                    {{ $translate('Client.Navigation.BackToHome') }}
                </RouterLink>
            </div>
        </article>
    </div>
</template>

<script setup lang="ts">
import { NotFoundPageModel } from '@/api/cms/models/not-found-page-model';
import { computed } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useRouter } from 'vue-router';

const props = defineProps<{model: NotFoundPageModel}>();

const router = useRouter();

const descriptionLines = computed(() => props.model.description.split('\n\n'));
</script>
