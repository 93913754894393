/* tslint:disable */
/* eslint-disable */
/*
    OBS: This file is a fixed copy of @/api/cms/apis/default-api.ts.
    If that file changes, this file should be updated as well.

    The fix is that the path parameter of verticaUmbracoSpaContentApi is no longer run through encodeURIComponent.
    Also, a prefix of '/' is no longer added.
*/

 import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
 import { Configuration } from '@/api/cms/configuration';
 // Some imports not used depending on template conditions
 // @ts-ignore
 import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '@/api/cms/common';
 // @ts-ignore
 import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '@/api/cms/base';
 // @ts-ignore
 import { SitePageData } from '@/api/cms/models';
 /**
  * DefaultApi - axios parameter creator
  * @export
  */
 export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
     return {
         /**
          * 
          * @param {string} path 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         verticaUmbracoSpaContentApi: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
             // verify required parameter 'path' is not null or undefined
             assertParamExists('verticaUmbracoSpaContentApi', 'path', path)

             // Fixed here!
             const localVarPath = path;

             // was:
             // const localVarPath = `/{path}`
                 //.replace(`{${"path"}}`, encodeURIComponent(String(path)));

             // use dummy base URL string because the URL constructor only accepts absolute URLs.
             const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
             let baseOptions;
             if (configuration) {
                 baseOptions = configuration.baseOptions;
             }
 
             const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
             const localVarHeaderParameter = {} as any;
             const localVarQueryParameter = {} as any;
 
 
     
             setSearchParams(localVarUrlObj, localVarQueryParameter);
             let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
             localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
 
             return {
                 url: toPathString(localVarUrlObj),
                 options: localVarRequestOptions,
             };
         },
     }
 };
 
 /**
  * DefaultApi - functional programming interface
  * @export
  */
 export const DefaultApiFp = function(configuration?: Configuration) {
     const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
     return {
         /**
          * 
          * @param {string} path 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         async verticaUmbracoSpaContentApi(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SitePageData>> {
             const localVarAxiosArgs = await localVarAxiosParamCreator.verticaUmbracoSpaContentApi(path, options);
             return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
         },
     }
 };
 
 /**
  * DefaultApi - factory interface
  * @export
  */
 export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
     const localVarFp = DefaultApiFp(configuration)
     return {
         /**
          * 
          * @param {string} path 
          * @param {*} [options] Override http request option.
          * @throws {RequiredError}
          */
         verticaUmbracoSpaContentApi(path: string, options?: any): AxiosPromise<SitePageData> {
             return localVarFp.verticaUmbracoSpaContentApi(path, options).then((request) => request(axios, basePath));
         },
     };
 };
 
 /**
  * DefaultApi - interface
  * @export
  * @interface DefaultApi
  */
 export interface DefaultApiInterface {
     /**
      * 
      * @param {string} path 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApiInterface
      */
     verticaUmbracoSpaContentApi(path: string, options?: AxiosRequestConfig): AxiosPromise<SitePageData>;
 
 }
 
 /**
  * DefaultApi - object-oriented interface
  * @export
  * @class DefaultApi
  * @extends {BaseAPI}
  */
 export class DefaultApi extends BaseAPI implements DefaultApiInterface {
     /**
      * 
      * @param {string} path 
      * @param {*} [options] Override http request option.
      * @throws {RequiredError}
      * @memberof DefaultApi
      */
     public verticaUmbracoSpaContentApi(path: string, options?: AxiosRequestConfig) {
         return DefaultApiFp(this.configuration).verticaUmbracoSpaContentApi(path, options).then((request) => request(this.axios, this.basePath));
     }
 }
 