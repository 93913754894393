<template>
    <div class="flex justify-between items-start px-20 mb-10 rounded-lg py-15"
         :class="getClasses(message.severity)">
        <div class="flex flex-col items-start"
             :class="{'lg:flex-row lg:items-end': !renderedInDrawer}">
            <strong class="mr-10 sm:mb-10 lg:mb-0 sm:text-18 leading-[20px]">{{ message.title }}</strong>
            <span class="lg:-mb-[1.8px] text-13 sm:text-14 leading-[20px]">{{ message.description }}</span>
        </div>
        <Button v-if="renderedInDrawer"
                :label="$translate('Client.Common.Hide')"
                class="-my-5 -mx-15"
                transparent
                size="small"
                @click="removeMessage(message)"/>
        <div v-else class="ml-20 cursor-pointer" @click="removeMessage(message)">
            <CIcon name="close" width="14" height="14" class="text-black"/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { removeMessage, SystemMessage, SystemMessageSeverity } from './system-message.service';
import Button from '@/project/components/button/Button.vue';
import { onUnmounted } from 'vue';

const props = defineProps<{
    message: SystemMessage
    renderedInDrawer?: boolean,
}>();

function getClasses(severity: SystemMessageSeverity): string {
    const classes: string[] = [];
    if (severity === 'success') {
        classes.push('bg-success');
    } else if (severity === 'warning') {
        classes.push('bg-warning');
    } else if (severity === 'error') {
        classes.push('bg-error');
    } else {
        classes.push('bg-info');
    }

    if (!props.renderedInDrawer) {
        classes.push('lg:items-center');
    }

    return classes.join(' ');
}

onUnmounted(() => {
    removeMessage(props.message);
});
</script>
