<template>
    <!-- inline, so the sticky part of Header.vue works -->
    <header class="inline">
        <HiddenSkipTo :id="SKIP_TO_ID" :label="$translate('Client.A11y.SkipToMainContent')" :show-absolute="!funnelView"/>
        <Header v-if="!funnelView"/>
        <CheckoutHeader v-if="funnelView"/>
        <div v-if="isShowingSystemMessages" :class="funnelView ? 'checkout-page-spacing -mb-20' : 'page-spacing -mb-20'">
            <SystemMessage type="system"/>
        </div>
    </header>

    <main :id="SKIP_TO_ID">
        <SearchPage v-if="isSearchActive"/>

        <div v-else
             class="watermark"
             :class="syncedTransitionClasses">
            <RouterView v-slot="{ Component }">
                <!-- Programmatically routing sometimes doesn't work because of animation, so some routes are ommitted here.  -->
                <Transition name="fade"
                            :mode="key === myAccountUrl || key === basketUrl ? 'default' : 'out-in'"
                            v-on="transitionHooks">
                    <component :is="Component" :key="key"/>
                </Transition>
            </RouterView>
        </div>
    </main>

    <footer>
        <Footer v-if="!funnelView && !isFromLobycoApp" :class="syncedTransitionClasses"/>
        <CheckoutFooter v-if="funnelView" :class="syncedTransitionClasses"/>
    </footer>
    <MetaData/>
    <DialogHost/>
</template>

<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router';
import MetaData from '@/core/browser/metadata/MetaData.vue';
import Header from '@/project/navigation/header/Header.vue';
import { computed, ref, watch } from 'vue';
import DialogHost from '@/project/dialog/DialogHost.vue';
import Footer from '@/project/navigation/footer/Footer.vue';
import { useTransitionSync } from '@/project/animation/transitionSyncComposable';
import { useFunnelView } from '@/core/layout/funnel-view-composable';
import CheckoutHeader from '@/project/navigation/checkout-header/CheckoutHeader.vue';
import CheckoutFooter from '@/project/navigation/checkout-footer/CheckoutFooter.vue';
import { signinPopupCallback, signinRedirectCallback } from './project/authentication/authentication';
import SystemMessage from './project/components/system-message/SystemMessage.vue';
import SearchPage from '@/project/search/SearchPage.vue';
import { useRouteQuery } from './core/browser/query/useQueryParams';
import { QueryKeys } from './project/browser/query';
import { isShowingSystemMessages } from './project/components/system-message/system-message.service';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import HiddenSkipTo from '@/project/components/hidden-skip-to/HiddenSkipTo.vue';
import { localStorageService } from '@/core/browser/storage';
import { useLobyco } from '@/project/authentication/useLobyco';

const SKIP_TO_ID = 'main';

const key = ref('');

const route = useRoute();
const termQuery = useRouteQuery(QueryKeys.TERM);
const isSearchActive = computed(() => termQuery.value !== '' || false);

watch(() => route.path, (path) => key.value = path);

const { myAccountUrl, basketUrl } = getPageUrls();

if (route.query.code) {
    if (localStorageService.getItemAs<boolean>('fromLobycoApp')) {
        signinRedirectCallback();
    } else {
        signinPopupCallback();
    }
}

const { funnelView } = useFunnelView();

const { isFromLobycoApp } = useLobyco();

const {
    transitionHooks,
    syncedTransitionClasses,
} = useTransitionSync('fade');
</script>

<style scoped>

.fade-enter-active {
    transition: opacity 0.3s ease-out;
}

.fade-leave-active {
    transition: opacity 0.2s ease-in;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
