<template>
    <div class="p-10 bg-white rounded-lg pt-15">
        <RouterLink :to="model.url" class="flex flex-col h-full">
            <ResponsiveImage
                v-if="!wide"
                :image-url="model.squareImage.url"
                :aspect-ratio="1/1"
                :width-on-screen="{
                    xs: 50,
                    sm: 25,
                    md: 20
                }"
                mode="pad"/>
            <ResponsiveImage
                v-if="wide"
                :image-url="model.rectangleImage.url"
                :aspect-ratio="10/4"
                :width-on-screen="{
                    xs: 100,
                    md: 40
                }"
                mode="pad"/>
            <h3 class="mt-auto mb-0 text-center text-14">
                {{ model.name }}
            </h3>
        </RouterLink>
    </div>
</template>

<script lang="ts">
import { CategoryPageLink } from '@/api/cms';
import { defineComponent, PropType } from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
export default defineComponent({
    name: 'CategoryTile',
    components: {
        ResponsiveImage,
    },
    props: {
        model: {
            type: Object as PropType<CategoryPageLink>,
            required: true,
        },
        wide: {
            type: Boolean,
            required: false,
        },
    },
});

</script>

<style>

.curated-image-holder .responsive-image{
padding-top: 46%;
}
</style>
