<template>
    <div class="flex flex-col justify-center items-center mb-20 w-full bg-white rounded-lg duration-500 ease-in-out transition-maxHeight p-15"
         :class="showAllCategories ? 'max-h-2000' : 'max-h-200'">
        <h1 class="font-bold text-center text-28 ">
            <span v-if="title && title.length > 0" :class="[`${titleColor}`]">{{ title }}</span>
            <span v-else>{{ $translate('Client.Search.Results') }} <span class="text-blue">{{ term }}</span></span>
        </h1>
        <div v-if="categories.length > 0" class="mt-10 space-x-20 text-center" :class="{'overflow-hidden h-40': !showAllCategories}">
            <div v-for="category in categories"
                 :key="category.url"
                 :ref="mobileItemRef"
                 class="inline-block px-10 mb-10 rounded-full border-2 border-white leading-28 text-14 bg-background"
                 :class="{'text-black font-bold': category.categoryId === current && !allowDeselect,
                          'text-link cursor-pointer': category.categoryId !== current,
                          'text-link font-bold cursor-pointer': category.categoryId === current && allowDeselect}"
                 @click="category.categoryId !== current || allowDeselect ? emit('set-category', category.categoryId) : null">
                {{ category.displayName }}
            </div>
        </div>
        <Button v-if="hiddenItems"
                :label="showAllCategories ? $translate('Client.CategoryPage.ShowLessCategories') : $translate('Client.CategoryPage.ShowAllCategories')"
                :primary="false"
                pill
                class="text-black mt-15 bg-background hover:bg-background focus:bg-background focus-within:bg-background"
                @click="showAllCategories = !showAllCategories">
            <template #right>
                <CIcon name="arrow-down"
                       width="10"
                       height="10"
                       class="transition-transform duration-200 ease-in-out transform"
                       :class="showAllCategories ? 'rotate-180': ' rotate-0'"/>
            </template>
        </Button>
    </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUpdate, onMounted, onUnmounted, ref, watch } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { CategoryTreeViewModel } from '@/api/commerce/models';

const emit = defineEmits(['set-category']);

const props = defineProps<{
    current: string,
    term: string,
    title?: string,
    titleColor?: string,
    model: CategoryTreeViewModel[],
    allowDeselect?: boolean,
}>();

const showAllCategories = ref(false);
const categories = computed(() => props.model || []);
let itemRefs:any[] = [];
const hiddenItems = ref(false);

watch(() => categories.value.length, () => {
    setTimeout(checkIndexes);
});

const mobileItemRef = (el) => {
    if (el) {
        itemRefs.push(el);
    }
};

const checkIndexes = () => {
    hiddenItems.value = false;
    for (const i in itemRefs) {
        const el = itemRefs[i];
        const p = el.parentNode.clientHeight + el.parentNode.offsetTop;
        if (el.offsetTop > p && !showAllCategories.value) {
            el.tabIndex = -1;
            hiddenItems.value = true;
        } else {
            if (el.classList.contains('text-black')) {
                el.tabIndex = -1;
            } else {
                el.tabIndex = 0;
            }
        }
    }
};

onBeforeUpdate(() => {
    itemRefs = [];
});

onMounted(() => {
    checkIndexes();
    window.addEventListener('resize', checkIndexes, true);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkIndexes, true);
});

</script>
