<template>
    <DeliveryLayout
        :terms-and-conditions-text="termsAndConditionsText"
        :is-submitting="submitting"
        @continue-to-payment="continueToPayment">
        <!-- Delivery Time -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.PickupTime" icon-name="clock">
            <div v-if="basket?.timeSlot" class="font-bold sm:flex">
                <div>
                    <span class="capitalize">{{ getWeekday(basket?.timeSlot.from) }}</span>, {{ new Date(basket?.timeSlot.from).getDate() }}. {{ getMonth(basket?.timeSlot.from) }}
                </div>
                <div>
                    <span class="hidden sm:inline">&nbsp;</span>{{ $translate('Client.Common.Between') }} {{ getTime(basket.timeSlot.from) }} - {{ getTime(basket.timeSlot.to) }}
                </div>
            </div>
            <Button
                :label="$translate('Client.CheckoutPage.Delivery.ChangeDateOrTime')"
                class="mx-[-24px]"
                transparent
                @click="changeDateOrTime"/>
        </DeliverySection>

        <!-- Address -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.PickUpPoint" icon-name="store">
            <div v-if="basket?.store">
                <ChainLogo :chain="basket?.store.chain"/>
                <div class="mt-10">
                    <div>{{ basket.store.address.street }} {{ basket.store.address.houseNumber }}</div>
                    <div>{{ basket.store.address.zipCode }} {{ basket.store.address.city }}</div>
                </div>
            </div>
            <div class="mt-10 sm:flex sm:items-center">
                <Button
                    :label="$translate('Client.CheckoutPage.Delivery.ChangeStore')"
                    class="-mx-[24px]"
                    transparent
                    @click="changeStore"/>
                <span class="hidden sm:mx-10 sm:block">{{ $translate('Client.Common.Or') }}</span>
                <Button
                    :label="$translate('Client.CheckoutPage.Delivery.ChangeDeliveryMethod')"
                    class="-mx-[24px]"
                    transparent
                    @click="changeDeliveryMethod"/>
            </div>
        </DeliverySection>

        <!-- Replacement products -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.ReplacementProducts" icon-name="basket-replacements">
            <div class="mb-10">
                <div
                    class="mt-10 flex cursor-pointer items-center"
                    @click="toggleNotePicker">
                    <InputCheckbox
                        :label="$translate('Client.CheckoutPage.Delivery.AddNoteToPicker')"
                        name="noteToPicker"
                        class="cursor-pointer"
                        :checked="addNoteToPicker"/>
                </div>
                <Textarea
                    v-if="addNoteToPicker"
                    v-model="noteToPicker"
                    name="noteToPicker"
                    class="mt-10"
                    :placeholder="$translate('Client.CheckoutPage.Delivery.NoteToPickerPlaceholder')"/>
            </div>
            <div>
                {{ $translate('Client.CheckoutPage.Delivery.ReplacementProductsDescription') }}
            </div>
            <div class="mt-15 space-y-10">
                <div
                    @click="selectedOption = ProductReplacementOptions.DoNotReplaceAnyProducts">
                    <InputRadio
                        name="replacementOption"
                        :label="$translate('Client.CheckoutPage.Delivery.DoNotReplaceProducts')"
                        :value="ProductReplacementOptions.DoNotReplaceAnyProducts"
                        :checked="selectedOption === ProductReplacementOptions.DoNotReplaceAnyProducts"/>
                </div>
                <div
                    @click="selectedOption = ProductReplacementOptions.ReplaceWithSimilarProducts">
                    <InputRadio
                        name="replacementOption"
                        :label="$translate('Client.CheckoutPage.Delivery.ReplaceWithSimilarProducts')"
                        :value="ProductReplacementOptions.ReplaceWithSimilarProducts"
                        :checked="selectedOption === ProductReplacementOptions.ReplaceWithSimilarProducts"/>
                </div>
                <div
                    @click="selectedOption = ProductReplacementOptions.CallBeforeReplacingProducts">
                    <InputRadio
                        name="replacementOption"
                        :label="$translate('Client.CheckoutPage.Delivery.CallBeforeReplacingProducts')"
                        :value="ProductReplacementOptions.CallBeforeReplacingProducts"
                        :checked="selectedOption === ProductReplacementOptions.CallBeforeReplacingProducts"/>
                </div>
            </div>
        </DeliverySection>
    </DeliveryLayout>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { getCheckoutBasket, redirectToPayment, setOrderProcessingInfo } from '@/project/apis/commerce/checkoutApi';
import { useDeliveryState, DeliveryPageStates } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { getWeekday, getMonth, getTime, getDate } from '@/project/utils/datetime';
import openDialog from '@/project/dialog/dialog';
import TimeSlotDrawer from '@/project/pages/checkout-page/delivery/TimeSlotDrawer.vue';
import ChainLogo from '@/project/pages/checkout-page/delivery/ChainLogo.vue';
import PickupPointDrawer from '@/project/pages/checkout-page/delivery/PickupPointDrawer.vue';
import { Ref, ref } from 'vue';
import InputRadio from '@/project/forms/InputRadio.vue';
import Textarea from '@/project/forms/Textarea.vue';
import InputCheckbox from '@/project/forms/InputCheckbox.vue';
import { ProductReplacementOptions } from '@/api/commerce';
import { trackCheckoutOption } from '@/project/tracking/tracking.service';
import DeliveryLayout from '@/project/pages/checkout-page/delivery/DeliveryLayout.vue';
import DeliverySection from '@/project/pages/checkout-page/delivery/DeliverySection.vue';

defineProps<{
    termsAndConditionsText: string
}>();

const { basket } = getCheckoutBasket();
const { deliveryState } = useDeliveryState();

const selectedOption: Ref<ProductReplacementOptions> = ref(ProductReplacementOptions.DoNotReplaceAnyProducts);
const addNoteToPicker = ref(false);
const noteToPicker = ref('');
const submitting = ref(false);

function changeDateOrTime() {
    openDialog(TimeSlotDrawer, {});
}

function changeStore() {
    openDialog(PickupPointDrawer, { zipCode: deliveryState.zipCode });
}

function changeDeliveryMethod() {
    if (basket.value?.openOrderIds.length) {
        deliveryState.pageState = DeliveryPageStates.ChooseExistingOrder;
    } else {
        deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
    }
}

function toggleNotePicker() {
    addNoteToPicker.value = !addNoteToPicker.value;
    noteToPicker.value = '';
}

async function continueToPayment() {
    submitting.value = true;
    trackCheckoutOption(3, undefined, `Pickup_${getDate(basket.value?.timeSlot?.from || '')} ${getTime(basket.value?.timeSlot?.from || '')} - ${getTime(basket.value?.timeSlot?.to || '')}_${basket.value?.store?.name}_Note_${basket.value?.selectedProductReplacementOption}`);
    await setOrderProcessingInfo(null, noteToPicker.value || null, selectedOption.value);
    await redirectToPayment();
    submitting.value = false;
}
</script>
