<template>
    <DrawerDialog v-slot="{close}"
                  position="left"
                  use-slide-animation
                  show-backdrop>
        <div class="min-h-screen watermark w-[320px]" @click="close">
            <div class="flex justify-between items-center menu-section">
                <RouterLink to="/" @click="close">
                    <div class="flex items-center cursor-pointer">
                        <CIcon name="home" width="16" height="16" color="#006BB6"/>
                        <strong class="pt-2 pl-10 text-primary text-14">{{ $translate('Client.Navigation.RootName') }}</strong>
                    </div>
                </RouterLink>
                <div class="cursor-pointer" @click="close">
                    <CIcon name="close" width="12" height="12"/>
                </div>
            </div>

            <!-- Main menu (left/right based on desktop) -->
            <div v-if="(!categoriesReady && !cmsCategories) || showMainMenu"
                 class="flex flex-col space-y-20 menu-section">
                <RouterLink v-for="link in leftMenuLinks"
                            :key="link.url"
                            :to="link.url"
                            class="text-primary text-14"
                            @click.stop="onClick">
                    {{ link.name }}
                </RouterLink>
                <RouterLink v-for="link in rightMenuLinks"
                            :key="link.link.url"
                            :to="link.link.url"
                            class="text-primary text-14"
                            @click="close">
                    {{ link.link.name }}
                </RouterLink>
            </div>

            <div v-if="!showMainMenu"
                 class="pb-20 mt-10 border-b border-background">
                <RouterLink to="/"
                            class="inline-block"
                            @click.stop="showMainMenu = true">
                    <div class="flex items-baseline py-10 px-10 space-x-10">
                        <CIcon name="arrow-left" width="10" height="10"/>
                        <span class="text-14 text-primary">
                            {{ $translate('Client.Navigation.MainMenu') }}
                        </span>
                    </div>
                </RouterLink>

                <!-- Search Category tree (shown when on /groceries|offers/*) -->
                <div v-if="categoriesReady">
                    <div v-if="offerMode" class="flex justify-between pt-10 pb-10 px-30 text-14">
                        <span class="font-bold">{{ $translate('Client.Navigation.Offers') }}</span>
                        <span class="cursor-pointer text-primary"
                              @click="close">{{ $translate('Client.Navigation.ViewAll') }}
                        </span>
                    </div>
                    <div v-if="ancestors.length > 0">
                        <div v-for="category in ancestors" :key="category.categoryId" class="flex items-baseline py-10 px-10 space-x-8">
                            <CIcon name="arrow-left" width="10" height="10"/>
                            <RouterLink :to="getUrl(category)"
                                        class="text-14 text-primary"
                                        @click.stop="onClick">
                                {{ category.displayName }}
                            </RouterLink>
                        </div>
                    </div>
                    <div v-if="children.length > 0 && current"
                         class="flex justify-between pt-10 pb-10 px-30 text-14">
                        <span class="font-bold">{{ current?.displayName }}</span>
                        <span class="cursor-pointer text-primary shrink-0"
                              @click="close">{{ $translate('Client.Navigation.ViewAll') }}
                        </span>
                    </div>
                    <div v-if="children.length > 0"
                         class="pt-10 space-y-20 px-30">
                        <div v-for="category in children" :key="category.categoryId">
                            <RouterLink :to="getUrl(category)"
                                        class="text-14 text-link"
                                        @click.stop="onClick()">
                                {{ category.displayName }}
                            </RouterLink>
                        </div>
                    </div>
                    <div v-if="children.length === 0">
                        <div v-for="item in siblings"
                             :key="item.categoryId"
                             class="pt-10 pb-10 space-y-20 px-30 text-14">
                            <span v-if="item.displayName === current?.displayName"
                                  class="font-bold text-14">
                                {{ item.displayName }}
                            </span>
                            <RouterLink v-if="item.displayName !== current?.displayName"
                                        :to="getUrl(item)"
                                        class="text-14 text-link"
                                        @click="close">
                                {{ item.displayName }}
                            </RouterLink>
                        </div>
                    </div>
                </div>

                <!-- CMS Category tree (shown when on /groceries) -->
                <div v-else-if="!categoriesReady && cmsCategories.length && !offerMode">
                    <div class="pt-10 pb-10 font-bold px-30 text-14">
                        {{ headline }}
                    </div>
                    <div class="pt-10 space-y-20 px-30 menu-section">
                        <div v-for="category in cmsCategories" :key="category.name">
                            <RouterLink :to="category.url"
                                        class="text-14 text-link"
                                        @click.stop>
                                {{ category.name }}
                            </RouterLink>
                        </div>
                    </div>

                    <div class="mt-20 font-bold px-30 text-14">
                        {{ $translate('Client.CategoryPage.CuratedTitle') }}
                    </div>
                    <div class="pt-10 space-y-20 px-30">
                        <div v-for="category in curated" :key="category.name">
                            <RouterLink :to="category.url"
                                        class="text-14 text-link"
                                        @click="close">
                                {{ category.name }}
                            </RouterLink>
                        </div>
                    </div>
                </div>

                <div v-else class="p-20 space-y-10">
                    <div v-for="(_, index) in Array.from({length: 10})"
                         :key="index"
                         class="h-20 rounded-lg animate-pulse w-100 bg-background"></div>
                </div>
            </div>

            <!-- Service Menu -->
            <div class="flex flex-col items-start space-y-20 menu-section">
                <button class="flex items-center cursor-pointer" @click="openDeliveryChecker">
                    <CIcon name="delivery-simple" width="16" height="16"/>
                    <span class="ml-7">{{ $translate('Client.BasketPage.DeliveryCheck') }}</span>
                </button>
                <button class="flex items-center cursor-pointer" @click="openStoreLocater">
                    <CIcon name="location" width="16" height="16"/>
                    <span class="ml-7">{{ $translate('Client.Navigation.Stores') }}</span>
                </button>
                <RouterLink v-for="(item, index) in serviceMenuLinks"
                            :key="item.link?.url || index"
                            :to="item.link?.url || ''"
                            :target="item.link?.target ? item.link?.target : '_self'"
                            @click="close">
                    <div class="flex items-center text-14">
                        <CIcon :name="item.iconName" width="16" height="16"/>
                        <span class="ml-10">
                            {{ item.link?.name }}
                        </span>
                    </div>
                </RouterLink>
                <button v-if="!isFromLobycoApp" class="flex items-center cursor-pointer" @click="openLoginOrMyAccount">
                    <CIcon name="user" width="16" height="16"/>
                    <span class="ml-7">{{ isAuthenticated ? $translate('Client.OrderPage.Greeting', customerInfo?.firstName || '') : $translate('Client.LoginDialog.LoginShort') }}</span>
                </button>
            </div>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import { RouterLink, useRoute } from 'vue-router';
import { HeaderLinkSectionModel, NameAndUrl, SiteSettingServiceMenuItemModel } from '@/api/cms';
import { useCategories } from '@/project/pages/category-page/search-category-composable';
import { QueryKeys } from '@/project/browser/query';
import { CategoryTreeViewModel } from '@/api/commerce';
import { useCmsCategories } from '@/project/pages/category-page/cms-category-composable';
import { closeDialog } from '@/project/dialog/dialog';
import { useCustomerInfo } from '@/project/apis/commerce/accountApi';
import { useDeliveryChecker } from '@/project/components/delivery-checker/delivery-checker.composable';
import { useStoreLocater } from '@/project/components/store-locater/store-locater.composable';
import { isAuthenticated, refreshUserState, login } from '@/project/authentication/authentication';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import {useLobyco} from '@/project/authentication/useLobyco';
import router from '@/router';

const props = defineProps<{
    leftMenuLinks: NameAndUrl[],
    rightMenuLinks: HeaderLinkSectionModel[],
    serviceMenuLinks: SiteSettingServiceMenuItemModel[],
}>();

const showMainMenu = ref(true);

const { ancestors, children, current, siblings, offerMode, categoriesReady } = useCategories();
const { cmsCategories, headline, setCmsCategories, curated } = useCmsCategories();

const route = useRoute();

const customerInfo = useCustomerInfo();

const { openDeliveryChecker } = useDeliveryChecker();
const { openStoreLocater } = useStoreLocater();

const {isFromLobycoApp} = useLobyco();

const isOnProductOrCategoryPages = computed(() => {
    const path = route.path.split('/').filter(i => i !== '')[0];
    const urls = props.leftMenuLinks.map(i => i.url);

    if (urls.join().includes(path)) return true;
    return false;
});

if (cmsCategories.value && isOnProductOrCategoryPages.value) {
    showMainMenu.value = false;
}

watch(siblings, (value, old) => {
    // If on last leaf, we would like to close the mobile menu.
    // Some logic to check if we are on the last leaf in the category tree.
    if (value.length > 1 && value.length !== old.length) {
        closeDialog();
    }
});

const getUrl = (category: CategoryTreeViewModel) => {
    if (offerMode.value) {
        return `?${new URLSearchParams(Object.assign({}, route.query as Record<string, string>, { [QueryKeys.CATEGORY]: category.categoryId })).toString()}`;
    } else {
        return category.url;
    }
};

const onClick = () => {
    showMainMenu.value = false;
    setCmsCategories(null, null);
};

const { myAccountUrl } = getPageUrls();

function openLoginOrMyAccount() {
    if (isAuthenticated.value) {
        router.push(myAccountUrl.value);
    } else {
        login().then(async() => {
            await refreshUserState();
        });
    }
}
</script>

<style scoped>
.menu-section {
    @apply pr-20 border-b border-background pl-30 py-15;
}
</style>
