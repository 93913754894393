const dict = {
    'Client.CategoryPage.NavigationTitle': 'Categories',
    'Client.CategoryPage.ProductsFound': 'vara',
    'Client.CategoryPage.ShowAllCategories': 'Show all categories',
    'Client.CategoryPage.ShowingXofYResults': 'Showing {0} of {1} products',
    'Client.CategoryPage.ShowMoreResults': 'Show more',
    'Client.CategoryPage.SortBy': 'Sort by:',
    'Client.CategoryPage.Sorting.AZ': 'A-Z',
    'Client.CategoryPage.Sorting.PriceAsc': 'Price: Low to high',
    'Client.CategoryPage.Sorting.PriceDesc': 'Price: High to low',
    'Client.CategoryPage.Sorting.ZA': 'Z-A',
    'Client.Common.Home': 'Forsíða',
    'Client.Common.Kroner': 'kr.',
    'Client.Common.Or': 'or',
    'Client.Common.Offers': 'Offers',
    'Client.Common.BonusLong': 'Bonus earn {0}',
    'Client.Common.BonusShort': 'Bonus {0}',
    'Client.IconLabels.Icelandic': 'íslensku',
    'Client.IconLabels.Organic': 'lífrænt',
    'Client.Markings.Icelandic': 'Íslenska',
    'Client.Markings.Organic': 'Lífrænt',
    'Client.Navigation.MainMenu': 'Main menu',
    'Client.Navigation.RootName': 'Forsíða',
    'Client.ProductDetailsPage': 'Client.ProductDetailsPage',
    'Client.ProductDetailsPage.Ingredients': 'Innihaldslýsing',
    'Client.ProductDetailsPage.Nutrition': 'Nutrition',
    'Client.ProductDetailsPage.ProductInformation': 'Product Information',
    'Client.ProductDetailsPage.Save': 'Spara',
    'Client.ProductDetailsPage.OutOfStock': 'Out of stock',
    'Client.ProductDetailsPage.AddToBasket': 'Add to basket',
    'Client.ProductDetailsPage.FewLeft': 'Lítið eftir',
    'Client.ProductFilters.Popular': 'Popular choices',
    'Client.ProductFilters.Reset': 'Reset all',
    'Client.ProductFilters.ShowResults': 'Show results',
    'Client.ProductFilters.Title': 'Filters',
    'Client.BasketPage.ProceedToCheckout': 'Proceed to checkout',
    'Client.BasketPage.GoToBasket': 'Go to basket',
    'Client.BasketPage.Basket': 'Basket',
    'Client.BasketPage.Products': 'Products',
    'Client.CheckoutPage.Delivery.ChooseDeliveryOptions': 'Choose delivery options',
    'Client.CheckoutPage.Delivery.EnterZip': 'Enter your zip code:',
    'Client.CheckoutPage.Delivery.Zip': 'Zip code',
    'Client.CheckoutPage.Delivery.PickUpInStoreOption': 'Pick up in store',
    'Client.CheckoutPage.Delivery.HomeDeliveryOption': 'Home delivery',
    'Client.CheckoutPage.Delivery.OpeningHours': 'Opening hours',
    'Client.CheckoutPage.Delivery.PickUpPointChooserAction': 'Choose pick-up point',
    'Client.CheckoutPage.Confirmation.ThankYou': 'Thank you',
    'Client.CheckoutPage.Confirmation.ThankYouText': 'thank you text',
    'Client.CheckoutPage.Confirmation.OrderNumber': 'Order number',
    'Client.CheckoutPage.CustomerInformation.InvoiceAddress': 'Invoice address',
    'Client.CheckoutPage.Confirmation.DeliveryAddress': 'Delivery address',
    'Client.CheckoutPage.Confirmation.Delivery': 'Delivery',
    'Client.CheckoutPage.Confirmation.Comment': 'Comment',
    'Client.CheckoutPage.CustomerInformation.Phone': 'Phone',
    'Client.CheckoutPage.CustomerInformation.Email': 'E-mail',
    'Client.CheckoutPage.Confirmation.Email': 'Confirmation e-mail',
    'Client.CheckoutPage.Confirmation.Reminder': 'Did you forget anything?',
    'Client.CheckoutPage.Confirmation.PageTitle': 'Confirmation',
    'Client.CheckoutPage.Confirmation.YourReceipt': 'Your receipt',
    'Client.CheckoutPage.Confirmation.ExcludingDelivery': 'Excl. delivery',
    'Client.CheckoutPage.Confirmation.OfferSavings': 'Offer savings',
    'Client.CheckoutPage.Confirmation.EstimatedBonus': 'Estimated bonus',
    'Client.CheckoutPage.Confirmation.Payment': 'Payment',
    'Client.CheckoutPage.Confirmation.EstimatedEarnedText': 'EstimatedEarnedText',
    'Client.CheckoutPage.Confirmation.PaidWithBonus': 'PaidWithBonus',
    'Client.CheckoutPage.Confirmation.PaidWithCard': 'PaidWithCard',
    'Client.CheckoutPage.Confirmation': 'Confirmation',
    'Client.CheckoutPage.Confirmation.SameAsInvoice': 'Same as invoice',
    'Client.CheckoutPage.Confirmation.EstimatedEarned': 'Estimated earned bonus',
    'Client.CheckoutPage.Confirmation.Between': 'between',
    'Client.BasketPage.Total': 'Total',
    'Client.BasketPage.SubTotal': 'Sub total',
    'Client.BasketPage.Packaging': 'Packaging',
    'Client.OrderPage.Greeting': 'Hi {0} ',
    'Client.Common.Logout': 'Log out',
    'Client.OrderPage.DeliveryTime': 'Delivery Time',
    'Client.OrderPage.DeliveryType': 'Delivery Type',
    'Client.OrderPage.OrderNumber': 'Order Number',
    'Client.OrderPage.Status': 'Status',
    'Client.Common.Between': 'between',
    'Client.BasketPage.Unit': 'Unit',
    'Client.BasketPage.Quantity': 'Quantity',
    'Client.BasketPage.UnitPrice': 'UnitPrice',
    'Client.BasketPage.TotalDiscount': 'Discount total',
    'Client.BasketStatus.ProductNotFound': 'Product not found',
};

export default dict;
