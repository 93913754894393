<template>
    <div v-if="invoiceAddress">
        <Form
            v-slot="{errors, isSubmitting}"
            key="customerInfoForm"
            :validation-schema="validationSchema"
            :initial-values="initialValues"
            @submit="onSubmit">
            <TextWrapper>
                <h2 class="text-18 mb-10 font-bold">
                    {{ $translate('Client.CheckoutPage.CustomerInformation.EditProfileTitle') }}
                </h2>
                <div class="my-15 bg-info flex items-start space-x-10 rounded-lg p-8">
                    <div class="mt-[3px]">
                        <CIcon name="info" width="16" height="16"/>
                    </div>
                    <div>
                        {{ $translate('Client.CheckoutPage.CustomerInformation.EditInfo') }}
                    </div>
                </div>
                <div class="my-20">
                    <h2 class="text-18 mb-10 font-bold">
                        {{ $translate('Client.CheckoutPage.CustomerInformation.CustomerType') }}
                    </h2>
                    <div class="flex space-x-20">
                        <InputRadio
                            name="customerType"
                            :checked="selectedCustomerTypeOption === 'private'"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.CustomerTypePrivate')"
                            :value="$translate('Client.CheckoutPage.CustomerInformation.CustomerTypePrivate')"
                            @click="onClickPrivate"/>
                        <InputRadio
                            name="customerType"
                            :checked="selectedCustomerTypeOption === 'business'"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.CustomerTypeBusiness')"
                            :value="$translate('Client.CheckoutPage.CustomerInformation.CustomerTypeBusiness')"
                            @click="selectedCustomerTypeOption = 'business'"/>
                    </div>
                </div>
                <div class="grid grid-cols-8 gap-20">
                    <div v-show="selectedCustomerTypeOption === 'business'" class="col-span-8">
                        <InputText
                            v-model.trim="invoiceAddress.companyName"
                            :aria-required="true"
                            type="text"
                            name="companyName"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.CompanyNameLabel')"/>
                    </div>
                    <div v-show="selectedCustomerTypeOption === 'business'" class="col-span-5 flex">
                        <InputText
                            v-model.trim="invoiceAddress.companySSN"
                            :aria-required="true"
                            type="text"
                            name="companySSN"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.CompanySSNLabel')"/>
                        <PopoverIcon class="mt-30 ml-10 inline" icon-name="info" :text="$translate('Client.CheckoutPage.CustomerInformation.CompanySSNLabelInfo')"/>
                    </div>
                    <div class="col-span-8 sm:col-span-4">
                        <InputText
                            v-model.trim="invoiceAddress.firstName"
                            :aria-required="true"
                            type="text"
                            name="firstName"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.FirstName')"/>
                    </div>
                    <div class="col-span-8 sm:col-span-4">
                        <InputText
                            v-model.trim="invoiceAddress.lastName"
                            type="text"
                            :aria-required="true"
                            name="lastName"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.LastName')"/>
                    </div>
                    <div class="col-span-8 sm:col-span-4">
                        <InputText
                            v-model="invoiceAddress.mobile"
                            type="tel"
                            name="mobile"
                            :aria-required="true"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.Phone')"/>
                    </div>
                    <div class="col-span-8 sm:col-span-4">
                        <InputText
                            v-model="invoiceAddress.email"
                            type="email"
                            name="email"
                            :aria-required="true"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.Email')"/>
                    </div>
                </div>
            </TextWrapper>
            <TextWrapper class="mt-10">
                <h2 class="text-18 mb-10 font-bold">
                    {{ $translate('Client.CheckoutPage.CustomerInformation.InvoiceAddress') }}
                </h2>
                <div class="space-y-20">
                    <div class="col-span-8">
                        <InputText
                            v-model.trim="invoiceAddress.addressLine1"
                            type="text"
                            name="addressLine1"
                            :aria-required="true"
                            :placeholder="$translate('Client.CheckoutPage.CustomerInformation.AddressLine1Placeholder')"
                            validation-behaviour="lazy"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.AddressLine1')"/>
                    </div>
                    <div class="col-span-8">
                        <InputText
                            v-model.trim="invoiceAddress.addressLine2"
                            type="text"
                            name="addressLine2"
                            :aria-required="true"
                            validation-behaviour="lazy"
                            :placeholder="$translate('Client.CheckoutPage.CustomerInformation.AddressLine2Placeholder')"
                            :label="$translate('Client.CheckoutPage.CustomerInformation.AddressLine2')"/>
                    </div>
                    <div class="grid grid-cols-8 gap-20">
                        <div class="col-span-2">
                            <InputText
                                v-model="invoiceAddress.zipCode"
                                type="number"
                                name="zipCode"
                                :aria-required="true"
                                validation-behaviour="lazy"
                                :label="$translate('Client.CheckoutPage.CustomerInformation.ZipCode')"/>
                        </div>
                        <div class="col-span-6">
                            <InputText
                                v-model.trim="invoiceAddress.city"
                                type="text"
                                name="city"
                                :aria-required="true"
                                validation-behaviour="lazy"
                                :label="$translate('Client.CheckoutPage.CustomerInformation.City')"/>
                        </div>
                    </div>
                </div>
            </TextWrapper>
            <div class="mt-20 mr-10 flex sm:mr-0" :class="Object.keys(errors).length > 0 ? 'justify-end md:justify-between flex-wrap md:flex-nowrap' : 'justify-end'">
                <div v-if="Object.keys(errors).length > 0" class="text-errorText mr-20 mb-20 rounded-lg py-4 px-8">
                    {{ $translate('Client.CheckoutPage.CustomerInformation.ErrorInForm') }}
                </div>
                <Button
                    :label="$translate('Client.Common.Cancel')"
                    transparent
                    @click="emit('cancel')"/>
                <Button
                    type="submit"
                    size="medium"
                    :label="$translate('Client.CheckoutPage.CustomerInformation.SaveAndContinueToDelivery')"
                    :disabled="Object.keys(errors).length > 0 || isSubmitting">
                    <template #right>
                        <CIcon name="arrow-right" width="8" height="8"/>
                    </template>
                </Button>
            </div>
        </Form>
    </div>
</template>

<script setup lang="ts">
import { computed, Ref, ref } from 'vue';
import { getCheckoutBasket, updateInvoiceAddress } from '@/project/apis/commerce/checkoutApi';
import Button from '@/project/components/button/Button.vue';
import InputText from '@/project/forms/InputText.vue';
import { Form } from 'vee-validate';
import { useRouter } from 'vue-router';
import TextWrapper from '@/project/components/text-wrapper/TextWrapper.vue';
import InputRadio from '@/project/forms/InputRadio.vue';
import PopoverIcon from '@/project/components/popover-icon/PopoverIcon.vue';

const router = useRouter();

const { basket } = getCheckoutBasket();

const props = defineProps<{
    nextStepUrl: string
}>();

const emit = defineEmits(['cancel']);

const selectedCustomerTypeOption = ref('private');

const initialValues = {
    companyName: basket.value!.invoiceAddress!.companyName,
    companySSN: basket.value!.invoiceAddress!.companySocialSecurityNumber,
    firstName: basket.value!.invoiceAddress!.firstName,
    lastName: basket.value!.invoiceAddress!.lastName,
    zipCode: basket.value!.invoiceAddress!.zipCode,
    city: basket.value!.invoiceAddress!.city,
    addressLine1: basket.value!.invoiceAddress!.addressLine1,
    addressLine2: basket.value!.invoiceAddress!.addressLine2,
    email: basket.value!.invoiceAddress!.email,
    mobile: basket.value!.invoiceAddress!.mobile,
};

const invoiceAddress = ref({
    ...initialValues,
});

const onSubmit = (model: any) => {
    updateInvoiceAddress(model).then(() => {
        router.push({ path: props.nextStepUrl, query: { back: 'true' } });
    });
};

const validationSchema: Ref<Record<string, string>> = computed(() => ({
    email: 'email|required|max:80',
    firstName: 'required|max:50',
    lastName: 'required|max:49',
    addressLine1: 'required|max:100',
    zipCode: 'required|max:3',
    city: 'required|max:50',
    mobile: 'required|number|min:7|max:25',
    addressLine2: 'max:50',
    companyName: selectedCustomerTypeOption.value === 'private' ? 'max:0' : 'required|max:50',
    companySSN: selectedCustomerTypeOption.value === 'private' ? 'max:0' : 'required|companySSN',
}));

function onClickPrivate() {
    selectedCustomerTypeOption.value = 'private';
    invoiceAddress.value.companyName = '';
    invoiceAddress.value.companySSN = '';
}

</script>
