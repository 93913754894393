<template>
    <aside class="space-y-10">
        <div class="w-full bg-white rounded-lg">
            <div class="py-20 font-bold border-b px-30 border-background text-18">
                {{ $translate('Client.BasketPage.Total') }}
            </div>

            <!-- Subtotal, delivery and packaging -->
            <div class="py-20 space-y-10 border-b px-30 border-background text-14">
                <div class="flex justify-between">
                    <span class="font-bold text-15">{{ $translate('Client.BasketPage.SubTotal') }}</span><span>{{ formatPrice(props.basket.subTotal) }}</span>
                </div>
                <div v-if="props.basket.packagingTotal && !hideFees" class="flex justify-between">
                    <span>{{ $translate('Client.BasketPage.Packaging') }}</span><span>{{ formatPrice(props.basket.packagingTotal) }}</span>
                </div>
                <div v-if="props.basket.shippingTotal" class="flex justify-between">
                    <span>{{ $translate('Client.BasketPage.Delivery') }}</span>
                    <span>{{ formatPrice(props.basket.shippingTotal) }}</span>
                </div>
            </div>

            <!-- Total -->
            <div class="flex justify-between py-20 border-b px-30 border-background text-14">
                <div>
                    <span class="font-bold text-15">{{ $translate('Client.BasketPage.Total') }}&nbsp;</span>
                </div>
                <span>{{ formatPrice(props.basket.total) }}</span>
            </div>

            <!-- Delivery checker -->
            <div v-if="!checkoutMode" class="flex items-center py-10 border-b px-30 border-background">
                <CIcon name="delivery" width="26" height="26"/>
                <div class="ml-10">
                    <Button :label="$translate('Client.BasketPage.DeliveryCheck')"
                            class="-ml-[20px]"
                            transparent
                            size="small"
                            @click="openDeliveryChecker"/>
                    <div>{{ $translate('Client.BasketPage.DeliveryCheckDescription') }}</div>
                </div>
            </div>

            <!-- Discount -->
            <div v-if="props.basket.discountTotal" class="flex justify-between py-20 border-b text-fadedText px-30 border-background text-14">
                <span>{{ $translate('Client.BasketPage.TotalSavings') }}</span><span>{{ formatPrice(props.basket.discountTotal) }}</span>
            </div>

            <!-- Bonus -->
            <div class="flex justify-between py-20 border-b px-30 border-background text-14">
                <span class="space-x-10">
                    <span class="font-bold text-bonus">{{ $translate('Client.BasketPage.EarnedBonus') }}</span>
                    <PopoverIcon class="inline" icon-name="info" :text="$translate('Client.BasketPage.BonusInfo')"/>
                </span>
                <span v-if="!isAuthenticated">
                    <span class="text-link">Login</span>
                    <span>&nbsp;{{ $translate('Client.Common.Or') }}&nbsp;</span>
                    <span class="text-link">Sign up</span>
                </span>
                <span v-else class="text-save">{{ formatPrice(bonus) }}</span>
            </div>
            <div v-if="!checkoutMode">
                <div class="py-25 px-30">
                    <Button :to="props.basketValidated ? checkoutUrl: ''"
                            :disabled="!props.basketValidated || notAboveMinPurchase"
                            :label="$translate('Client.BasketPage.Proceed')"
                            size="large"
                            strong
                            class="px-40 mx-auto w-full sm:w-auto md:w-auto lg:w-full">
                        <template #right>
                            <CIcon name="arrow-right" width="12" height="12"/>
                        </template>
                    </Button>
                </div>
                <div class="flex justify-center items-center pb-20">
                    <span v-if="notAboveMinPurchase">
                        <span class="font-bold"> {{ formatPrice(remainingMinPurchase) }}</span>&nbsp;{{ $translate('Client.BasketPage.FromMinPurchase') }}
                    </span>
                </div>
            </div>
        </div>
        <div class="bg-white rounded-lg">
            <div class="font-bold border-b py-25 px-30 border-background text-18">
                {{ footer?.customerService.title }}
            </div>
            <div class="flex justify-start py-20 space-x-30 px-30">
                <div v-for="item in footer?.customerService.items" :key="item.title" class="flex items-center space-x-10">
                    <CIcon :name="item.iconName"
                           width="20"
                           height="20"/>
                    <a :href="item.link.url">
                        {{ item.link.name }}
                    </a>
                </div>
            </div>
            <div v-if="footer?.customerService" class="flex justify-start pt-10 pb-25 px-30 text-primary">
                <div v-if="footer.customerService.todayIsClosed">
                    {{ $translate('Client.Common.CustomerServiceIsClosedToday') }}
                </div>
                <div v-else-if="footer.customerService.todaysOpeningTime && footer.customerService.todaysClosingTime">
                    {{ $translate("Client.Common.TodaysOpeningHours") }} {{ footer.customerService.todaysOpeningTime }} - {{ footer.customerService.todaysClosingTime }}
                </div>
            </div>
        </div>
    </aside>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { getFooter, getPageUrls } from '@/project/apis/cms/contentApi';
import { ref, DeepReadonly, computed } from 'vue';
import { SiteSettingFooterModel } from '@/api/cms';
import { isAuthenticated } from '@/project/authentication/authentication';
import { BasketValidityViewModel, BasketViewModel } from '@/api/commerce';
import { useDeliveryChecker } from '@/project/components/delivery-checker/delivery-checker.composable';
import PopoverIcon from '@/project/components/popover-icon/PopoverIcon.vue';

const props = defineProps<{
    basket: DeepReadonly<BasketViewModel>,
    bonus: DeepReadonly<number>,
    basketValidated?: boolean,
    checkoutMode?: boolean,
    hideFees?: boolean,
}>();

const remainingMinPurchase = computed(() => props.basket.minimumPurchase - props.basket.subTotal);
const notAboveMinPurchase = computed(() => props.basket.subTotal < props.basket.minimumPurchase && props.basket.status !== BasketValidityViewModel.MinimumSubTotalNotReachedButCustomerHasOpenOrder);

const { checkoutUrl } = getPageUrls();

const { openDeliveryChecker } = useDeliveryChecker();

const footer = ref<SiteSettingFooterModel>();
getFooter().then(_model => {
    footer.value = _model;
});

</script>

<style scoped>
</style>
