<template>
    <vue-svgicon role="img"
                 :data="data"
                 :fill="true"
                 :title="translate ? $translate(`Client.IconLabels.${screenReaderFriendlyTitle}`) : `${screenReaderFriendlyTitle} ${$translate('Client.Common.Icon')}`"/>
</template>

<script lang="ts">

/*
    See possibilities for attribs here: https://mmf-fe.github.io/svgicon/en/guide/advanced.html#component
 */

import { defineComponent, ref } from 'vue';

const CIcon = defineComponent({
    name: 'CIcon',
    props: {
        name: {
            type: String,
            required: true,
        },
        translate: {
            type: Boolean,
            required: false,
        },
    },
    setup(props) {
        const screenReaderFriendlyTitle = props.name.replace(/-/g, ' ');

        const data = ref<string | undefined>();
        import(`../../../assets/icons/${props.name.toLowerCase()}.svg`)
            .then(svgModule => data.value = svgModule.default)
            .catch(() => {
                console.error(`Could not find icon ${props.name.toLowerCase()}.svg in assets folder`);
                import('../../../assets/icons/apple.svg')
                    .then(svgModule => data.value = svgModule.default);
            });

        return { screenReaderFriendlyTitle, data };
    },
});

declare module '@vue/runtime-core' {
  export interface GlobalComponents {
    CIcon: typeof CIcon;
  }
}

export default CIcon;
</script>
