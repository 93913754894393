import { CategoryPageLink, CategoryPageModel, CuratedCategoryLink, IMetadata } from '@/api/cms';
import { ref } from 'vue';

const categories = ref<CategoryPageLink[]>([]);
const headline = ref('');
const curated = ref<CuratedCategoryLink[]>([]);

function setCmsCategories(model: CategoryPageModel | null, metadata: IMetadata | null) {
    if (model && metadata) {
        categories.value = model.childCategories;
        headline.value = metadata.navigationTitle;
        curated.value = model.curatedCategories;
    } else {
        categories.value = [];
        headline.value = '';
        curated.value = [];
    }
}

export function useCmsCategories(): {
    cmsCategories: typeof categories,
    headline: typeof headline,
    curated: typeof curated,
    setCmsCategories: typeof setCmsCategories
    } {
    return {
        cmsCategories: categories,
        headline,
        curated,
        setCmsCategories,
    };
}
