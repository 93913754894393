<template>
    <DeliverySection v-if="ageLimitedLineItemsByCategory.length > 0" title-label="Client.CheckoutPage.Delivery.AgeVerification" icon-name="eighteenplus" class="bg-backgroundContrastBlue">
        <div class="mb-20 flex">
            {{ $translate('Client.CheckoutPage.Delivery.AgeVerificationDescription') }}
            <Button transparent class="h-20 p-0" @click="open">
                {{ $translate('Client.CheckoutPage.Delivery.AgeVerificationShowItemsButton') }}
            </Button>
        </div>
        <div class="flex items-start">
            <InputCheckbox
                name="agedeclaration"
                :value="ageDeclaration"
                @click="ageDeclaration = !ageDeclaration"/>
            {{ $translate('Client.CheckoutPage.Delivery.AgeVerificationDeclaration') }}
        </div>
    </DeliverySection>
    <AcceptTerms :consent="consent" :terms-and-conditions-text="termsAndConditionsText" @click="consent = !consent"/>
    <slot :consent="consent" :needs-age-declaration="needsAgeDeclaration"></slot>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { openAsyncDialog } from '@/project/dialog/dialog';
import InputCheckbox from '@/project/forms/InputCheckbox.vue';
import { computed, ref } from 'vue';
import AcceptTerms from '@/project/pages/checkout-page/delivery/AcceptTerms.vue';
import DeliverySection from '@/project/pages/checkout-page/delivery/DeliverySection.vue';
import AgeLimitDrawer from '@/project/basket/age-limit/AgeLimitDrawer.vue';
import { getCheckoutBasket, prepareBasket } from '@/project/apis/commerce/checkoutApi';

defineProps<{
    termsAndConditionsText: string
}>();

const { ageLimitedLineItemsByCategory } = getCheckoutBasket();

const consent = ref(false);
const ageDeclaration = ref(false);
const needsAgeDeclaration = computed(() => ageLimitedLineItemsByCategory.value.length > 0 && !ageDeclaration.value);

const open = () => {
    openAsyncDialog(AgeLimitDrawer, {}).then(() => {
        prepareBasket();
    });
};
</script>
