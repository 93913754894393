<!-- Component needed for the route /checkout which just redirects to, e.g., /checkout/customer-information -->

<template>
    <template></template>
</template>
<script setup lang="ts">
import { CheckoutPageModel } from '@/api/cms/';
import router from '@/router';
import { onBeforeMount } from 'vue';

const props = defineProps<{
    model: CheckoutPageModel
}>();

onBeforeMount(() => router.replace({
    path: props.model.customerInformationPage.url,
}));
</script>
