<!-- Scroll only works in the value is wrapped in a div -->

<template>
    <transition mode="out-in"
                v-bind="animationType">
        <slot></slot>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AnimateValue',
    props: {
        animation: {
            type: String,
            required: false,
            default: 'fade',
            validator: (value: string) => ['fade', 'scroll'].includes(value),
        },
        duration: {
            required: false,
            default: [200, 100],
        },
    },
    setup(props) {
        const animationDuration = Array.isArray(props.duration)
            ? {
                start: props.duration[0],
                end: props.duration[1],
            }
            : {
                start: props.duration,
                end: props.duration,
            };
        const getAnimation = () => {
            switch (props.animation) {
            case 'scroll': {
                return {
                    'enter-active-class': `duration-${animationDuration.start} ease-out transform`,
                    'leave-active-class': `duration-${animationDuration.end} ease-in transform`,
                    'enter-from-class': 'opacity-0 translate-y-full',
                    'enter-to-class': 'opacity-100',
                    'leave-from-class': 'opacity-100',
                    'leave-to-class': 'opacity-0 -translate-y-full',
                };
            }
            default: {
                return {
                    'enter-active-class': `duration-${animationDuration.start} ease-out`,
                    'leave-active-class': `duration-${animationDuration.end} ease-in`,
                    'enter-from-class': 'opacity-0',
                    'enter-to-class': 'opacity-100',
                    'leave-from-class': 'opacity-100',
                    'leave-to-class': 'opacity-0',
                };
            }
            }
        };
        const animationType = getAnimation();
        return {
            animationType,
        };
    },
});
</script>

<style scoped>

</style>
