<template>
    <li :class="{'border-b -mx-30 px-30 border-b-background pb-15 mb-15': expand && menuItem.children.length > 0}">
        <Button v-if="menuItem.children.length > 0"
                transparent
                class="-ml-5 transition-all duration-200 ease-in-out h-[19px]"
                :class="{'!font-bold': expand}"
                size="small"
                @click="expand = !expand">
            {{ menuItem.name }}
        </Button>
        <RouterLink v-else :to="menuItem.url" :class="menuItem.selected ? 'text-black font-bold' : 'text-link'" @click="emit('select-leaf')">
            {{ menuItem.name }}
        </RouterLink>
        <ul v-if="menuItem.children.length > 0" class="overflow-hidden ml-20 space-y-20 transition-all duration-200 ease-in-out" :class="expand ? 'max-h-[1000px] mt-15' : 'max-h-0'">
            <ContentNavigationBranchCollapsible v-for="item in menuItem.children"
                                                :key="item.url"
                                                :menu-item="item"
                                                @select-leaf="emit('select-leaf')"/>
            <li>
                <RouterLink :to="menuItem.url" class="font-bold text-link" @click="emit('select-leaf')">
                    {{ $translate('Client.Common.View') }} {{ menuItem.name }}
                </RouterLink>
            </li>
        </ul>
    </li>
</template>

<script lang="ts" setup>
import { SiteMenuItem } from '@/api/cms';
import { ref } from 'vue';
import Button from '@/project/components/button/Button.vue';

const props = defineProps<{
    menuItem: SiteMenuItem,
}>();

const emit = defineEmits(['select-leaf']);

const expand = ref(props.menuItem.selected);

</script>
