<template>
    <SystemMessage type="delivery" :classes="'mb-20'" rendered-in-drawer/>
    <div class="flex">
        <div class="flex items-start">
            <CIcon name="checkcircle" width="20" height="20" class="text-bonus"/>
        </div>
        <div class="ml-10">
            <div class="font-bold">
                {{ $translate('Client.CheckoutPage.Delivery.Zip') }}
            </div>
            <div>
                <span>{{ zipCodeInfo?.zipCode }} {{ zipCodeInfo?.areaName }}</span>
                <span class="ml-10 cursor-pointer text-link"
                      tabindex="0"
                      @click="emit('changeZip')">{{ $translate('Client.CheckoutPage.Delivery.ChangeZip') }}</span>
            </div>
        </div>
    </div>
    <div v-if="props.timeSlotType === TimeSlotTypeViewModel.PickUp && store"
         class="flex mt-20">
        <ChosenPickupPoint :store="store"/>
    </div>
    <div class="mt-20">
        <DateSelecter :dates="dates" :small-screen-number="smallScreenNumber" @on-select="onSelectDate"/>
    </div>
    <div v-if="timeSlots.length > 0" class="mt-20">
        <div class="flex items-start mb-5">
            <CIcon name="clock" width="20" height="20"/>
            <span class="ml-10 font-bold">{{ readonly ? $translate('Client.BasketPage.DeliveryCheckTimesAndPrices') : $translate('Client.CheckoutPage.Delivery.ChooseDeliveryTime') }}</span>
        </div>
        <div class="mb-4">
            <span class="capitalize">{{ getWeekday(selectedDate) }},</span> {{ new Date(selectedDate).getDate() }}. {{ getMonth(selectedDate, true) }}
        </div>
        <div v-if="selectedTimeSlot?.timeSlotStatus === TimeSlotStatusViewModel.SoldOut"
             class="p-8 mb-10 rounded-lg bg-warning">
            {{ $translate('Client.DeliveryStatus.TimeSlotUnavailableTitle') }}
        </div>
        <div v-if="readonly"
             class="gap-10 mt-10 columns-1">
            <TimeSlotReadOnly v-for="(timeSlot) in timeSlots"
                              :key="timeSlot.id"
                              :time-slot="timeSlot"
                              class="mb-10"/>
        </div>
        <div v-else
             class="gap-10 columns-1 md:columns-2">
            <TimeSlot v-for="(timeSlot) in timeSlots"
                      :key="timeSlot.id"
                      :time-slot="timeSlot"
                      :selected-id="selectedTimeSlot?.id"
                      class="mb-10"
                      :readonly="readonly"
                      @on-select="selectTimeSlot"/>
        </div>
    </div>
    <div v-if="readonly" class="p-20 mt-10 mb-20 rounded-lg bg-info">
        <div class="flex items-center font-bold text-18 mb-15">
            <CIcon name="info" width="20" height="20" class="mr-10"/>{{ $translate('Client.BasketPage.DeliveryCheckerDisclaimerTitle') }}
        </div>
        <div>{{ $translate('Client.BasketPage.DeliveryCheckerDisclaimerDescription') }}</div>
    </div>
    <!-- Slot for next/submit button -->
    <slot :disabled="!selectedTimeSlot || selectedTimeSlot.timeSlotStatus === TimeSlotStatusViewModel.SoldOut" :selected-timeslot="selectedTimeSlot"></slot>
</template>

<script setup lang="ts">
import { getDates, getTimeSlots, getZipCodeInfo } from '@/project/apis/commerce/timeSlotsApi';
import { ref, Ref } from 'vue';
import { RefetchTimeSlots } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { TimeSlotViewModel, TimeSlotTypeViewModel, TimeSlotStatusViewModel, TimeSlotDate, ZipCodeViewModel, StoreViewModel } from '@/api/commerce';
import SystemMessage from '@/project/components/system-message/SystemMessage.vue';
import DateSelecter from '@/project/pages/checkout-page/delivery/DateSelecter.vue';
import TimeSlot from '@/project/pages/checkout-page/delivery/TimeSlot.vue';
import ChosenPickupPoint from '@/project/pages/checkout-page/delivery/ChosenPickupPoint.vue';
import { getWeekday, getMonth } from '@/project/utils/datetime';
import bus from '@/core/bus';
import TimeSlotReadOnly from '@/project/pages/checkout-page/delivery/TimeSlotReadOnly.vue';

const props = defineProps<{
    zipCode: number
    timeSlotType: TimeSlotTypeViewModel
    store?: StoreViewModel | null
    readonly?: boolean
    smallScreenNumber: number
}>();
const emit = defineEmits(['changeZip']);

const dates: Ref<TimeSlotDate[]> = ref([]);
const selectedDate = ref('');
const timeSlots: Ref<TimeSlotViewModel[]> = ref([]);
const selectedTimeSlot: Ref<TimeSlotViewModel | null> = ref(null);
const zipCodeInfo: Ref<ZipCodeViewModel | null> = ref(null);

getDates(Number(props.zipCode), props.timeSlotType).then(datesRes => {
    dates.value = datesRes;
    if (datesRes.length > 0) {
        const hasSelectedTimeSlot = datesRes.find(d => d.hasSelectedTimeSlot);
        const firstNotSoldOut = datesRes.find(d => !d.isSoldOut);
        if (hasSelectedTimeSlot) {
            selectedDate.value = hasSelectedTimeSlot.date;
        } else if (firstNotSoldOut) {
            selectedDate.value = firstNotSoldOut.date;
        } else {
            selectedDate.value = datesRes[0].date;
        }
    }

    // Gets time slots for initially selected date
    getTimeSlots(Number(props.zipCode), props.timeSlotType, selectedDate.value).then(res => {
        timeSlots.value = res;
        res.forEach(timeSlot => {
            if (timeSlot.timeSlotStatus === TimeSlotStatusViewModel.Selected) {
                selectedTimeSlot.value = timeSlot;
            }
        });
    });
});

function onSelectDate(date: string) {
    selectedDate.value = date;
    getTimeSlots(Number(props.zipCode), props.timeSlotType, date).then(res => {
        timeSlots.value = res;
    });
}

function selectTimeSlot(timeSlot: TimeSlotViewModel) {
    if (timeSlot.timeSlotStatus !== TimeSlotStatusViewModel.SoldOut) {
        selectedTimeSlot.value = timeSlot;
    }
}

function refetchTimeSlots() {
    getTimeSlots(Number(props.zipCode), props.timeSlotType, selectedDate.value).then(res => {
        timeSlots.value = res;
        // Find previous so that we can show a potential new status after refetch.
        const prevTimeSlot = res.find(v => v.id === selectedTimeSlot.value?.id);
        if (prevTimeSlot) {
            selectedTimeSlot.value = prevTimeSlot;
        } else {
            selectedTimeSlot.value = null;
        }
    });
}

bus.on(RefetchTimeSlots, refetchTimeSlots);

getZipCodeInfo(Number(props.zipCode)).then(res => {
    zipCodeInfo.value = res;
});
</script>
