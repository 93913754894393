/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasketValidityViewModelClientResponse } from '../models';
// @ts-ignore
import { BasketViewModelClientResponse } from '../models';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { CompleteBasketRequest } from '../models';
// @ts-ignore
import { CompleteBasketResultViewModelClientResponse } from '../models';
// @ts-ignore
import { DeliveryAddressRequest } from '../models';
// @ts-ignore
import { OrderProcessingInfoRequest } from '../models';
// @ts-ignore
import { PaymentRedirectViewModelClientResponse } from '../models';
// @ts-ignore
import { ReserveTimeSlotErrorViewModelClientResponse } from '../models';
// @ts-ignore
import { UpdateOrderInvoiceAddressRequest } from '../models';
/**
 * CheckoutApi - axios parameter creator
 * @export
 */
export const CheckoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Attaches the basket to the timeslot of an open order.
         * @param {string} [existingOrderId] Id of the existing order
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutAddToExistingTimeSlotPost: async (existingOrderId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/addToExistingTimeSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (existingOrderId !== undefined) {
                localVarQueryParameter['existingOrderId'] = existingOrderId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Completes the current basket and turns it into an order. The order is sent to ERP, an email is sent to the  customer and an order history entry is created. It can take up to 30 seconds for the order history entry to  be available
         * @param {string} [apiVersion] 
         * @param {CompleteBasketRequest} [completeBasketRequest] Basket completion request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutCompletePost: async (apiVersion?: string, completeBasketRequest?: CompleteBasketRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(completeBasketRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the delivery address on the current basket.
         * @param {string} [apiVersion] 
         * @param {DeliveryAddressRequest} [deliveryAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutDeliveryAddressPut: async (apiVersion?: string, deliveryAddressRequest?: DeliveryAddressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/deliveryAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deliveryAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Sets the delivery zipcode on the current basket.
         * @param {number} [deliveryZipCode] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutDeliveryZipCodePut: async (deliveryZipCode?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/deliveryZipCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (deliveryZipCode !== undefined) {
                localVarQueryParameter['deliveryZipCode'] = deliveryZipCode;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Initiates a payment in Lobyco Payment Provider
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutInitiatePaymentPost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/initiatePayment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set invoice address/customer information on the basket. This will not update the customer\'s information on  their profile.
         * @param {string} [apiVersion] 
         * @param {UpdateOrderInvoiceAddressRequest} [updateOrderInvoiceAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutInvoiceAddressPut: async (apiVersion?: string, updateOrderInvoiceAddressRequest?: UpdateOrderInvoiceAddressRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/invoiceAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrderInvoiceAddressRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set order processing info. This includes the note to the driver and the selected replacement option.
         * @param {string} [apiVersion] 
         * @param {OrderProcessingInfoRequest} [orderProcessingInfoRequest] The request information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutOrderProcessingInfoPost: async (apiVersion?: string, orderProcessingInfoRequest?: OrderProcessingInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/orderProcessingInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderProcessingInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Prepares the basket for checkout by setting information such as default invoice address and customer number  on the basket.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutPreparePost: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/prepare`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reserves a home delivery timeslot for a basket.
         * @param {number} [id] Timeslot id
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutReserveHomeDeliveryTimeSlotPost: async (id?: number, zipCode?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/reserveHomeDeliveryTimeSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reserves a pick up timeslot for a basket.
         * @param {number} [id] Timeslot id
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [storeId] Id of the delivery store if pickup has been selected
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutReservePickUpTimeSlotPost: async (id?: number, zipCode?: number, storeId?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/reservePickUpTimeSlot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete the timeslot for the basket
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutTimeslotDelete: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/checkout/timeslot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutApi - functional programming interface
 * @export
 */
export const CheckoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Attaches the basket to the timeslot of an open order.
         * @param {string} [existingOrderId] Id of the existing order
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Completes the current basket and turns it into an order. The order is sent to ERP, an email is sent to the  customer and an order history entry is created. It can take up to 30 seconds for the order history entry to  be available
         * @param {string} [apiVersion] 
         * @param {CompleteBasketRequest} [completeBasketRequest] Basket completion request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutCompletePost(apiVersion?: string, completeBasketRequest?: CompleteBasketRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteBasketResultViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutCompletePost(apiVersion, completeBasketRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the delivery address on the current basket.
         * @param {string} [apiVersion] 
         * @param {DeliveryAddressRequest} [deliveryAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutDeliveryAddressPut(apiVersion?: string, deliveryAddressRequest?: DeliveryAddressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutDeliveryAddressPut(apiVersion, deliveryAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Sets the delivery zipcode on the current basket.
         * @param {number} [deliveryZipCode] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Initiates a payment in Lobyco Payment Provider
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutInitiatePaymentPost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentRedirectViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutInitiatePaymentPost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set invoice address/customer information on the basket. This will not update the customer\'s information on  their profile.
         * @param {string} [apiVersion] 
         * @param {UpdateOrderInvoiceAddressRequest} [updateOrderInvoiceAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutInvoiceAddressPut(apiVersion?: string, updateOrderInvoiceAddressRequest?: UpdateOrderInvoiceAddressRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutInvoiceAddressPut(apiVersion, updateOrderInvoiceAddressRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set order processing info. This includes the note to the driver and the selected replacement option.
         * @param {string} [apiVersion] 
         * @param {OrderProcessingInfoRequest} [orderProcessingInfoRequest] The request information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutOrderProcessingInfoPost(apiVersion?: string, orderProcessingInfoRequest?: OrderProcessingInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutOrderProcessingInfoPost(apiVersion, orderProcessingInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Prepares the basket for checkout by setting information such as default invoice address and customer number  on the basket.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutPreparePost(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutPreparePost(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reserves a home delivery timeslot for a basket.
         * @param {number} [id] Timeslot id
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id?: number, zipCode?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id, zipCode, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Reserves a pick up timeslot for a basket.
         * @param {number} [id] Timeslot id
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [storeId] Id of the delivery store if pickup has been selected
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutReservePickUpTimeSlotPost(id?: number, zipCode?: number, storeId?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutReservePickUpTimeSlotPost(id, zipCode, storeId, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete the timeslot for the basket
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketCheckoutTimeslotDelete(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketCheckoutTimeslotDelete(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutApi - factory interface
 * @export
 */
export const CheckoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutApiFp(configuration)
    return {
        /**
         * 
         * @summary Attaches the basket to the timeslot of an open order.
         * @param {string} [existingOrderId] Id of the existing order
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId?: string, apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Completes the current basket and turns it into an order. The order is sent to ERP, an email is sent to the  customer and an order history entry is created. It can take up to 30 seconds for the order history entry to  be available
         * @param {string} [apiVersion] 
         * @param {CompleteBasketRequest} [completeBasketRequest] Basket completion request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutCompletePost(apiVersion?: string, completeBasketRequest?: CompleteBasketRequest, options?: any): AxiosPromise<CompleteBasketResultViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutCompletePost(apiVersion, completeBasketRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the delivery address on the current basket.
         * @param {string} [apiVersion] 
         * @param {DeliveryAddressRequest} [deliveryAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutDeliveryAddressPut(apiVersion?: string, deliveryAddressRequest?: DeliveryAddressRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutDeliveryAddressPut(apiVersion, deliveryAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Sets the delivery zipcode on the current basket.
         * @param {number} [deliveryZipCode] 
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode?: number, apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Initiates a payment in Lobyco Payment Provider
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutInitiatePaymentPost(apiVersion?: string, options?: any): AxiosPromise<PaymentRedirectViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutInitiatePaymentPost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set invoice address/customer information on the basket. This will not update the customer\'s information on  their profile.
         * @param {string} [apiVersion] 
         * @param {UpdateOrderInvoiceAddressRequest} [updateOrderInvoiceAddressRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutInvoiceAddressPut(apiVersion?: string, updateOrderInvoiceAddressRequest?: UpdateOrderInvoiceAddressRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutInvoiceAddressPut(apiVersion, updateOrderInvoiceAddressRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set order processing info. This includes the note to the driver and the selected replacement option.
         * @param {string} [apiVersion] 
         * @param {OrderProcessingInfoRequest} [orderProcessingInfoRequest] The request information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutOrderProcessingInfoPost(apiVersion?: string, orderProcessingInfoRequest?: OrderProcessingInfoRequest, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutOrderProcessingInfoPost(apiVersion, orderProcessingInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Prepares the basket for checkout by setting information such as default invoice address and customer number  on the basket.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutPreparePost(apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutPreparePost(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reserves a home delivery timeslot for a basket.
         * @param {number} [id] Timeslot id
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id?: number, zipCode?: number, apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id, zipCode, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Reserves a pick up timeslot for a basket.
         * @param {number} [id] Timeslot id
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [storeId] Id of the delivery store if pickup has been selected
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutReservePickUpTimeSlotPost(id?: number, zipCode?: number, storeId?: string, apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutReservePickUpTimeSlotPost(id, zipCode, storeId, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete the timeslot for the basket
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketCheckoutTimeslotDelete(apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketCheckoutTimeslotDelete(apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckoutApi - interface
 * @export
 * @interface CheckoutApi
 */
export interface CheckoutApiInterface {
    /**
     * 
     * @summary Attaches the basket to the timeslot of an open order.
     * @param {string} [existingOrderId] Id of the existing order
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Completes the current basket and turns it into an order. The order is sent to ERP, an email is sent to the  customer and an order history entry is created. It can take up to 30 seconds for the order history entry to  be available
     * @param {string} [apiVersion] 
     * @param {CompleteBasketRequest} [completeBasketRequest] Basket completion request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutCompletePost(apiVersion?: string, completeBasketRequest?: CompleteBasketRequest, options?: AxiosRequestConfig): AxiosPromise<CompleteBasketResultViewModelClientResponse>;

    /**
     * 
     * @summary Sets the delivery address on the current basket.
     * @param {string} [apiVersion] 
     * @param {DeliveryAddressRequest} [deliveryAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutDeliveryAddressPut(apiVersion?: string, deliveryAddressRequest?: DeliveryAddressRequest, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Sets the delivery zipcode on the current basket.
     * @param {number} [deliveryZipCode] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Initiates a payment in Lobyco Payment Provider
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutInitiatePaymentPost(apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<PaymentRedirectViewModelClientResponse>;

    /**
     * 
     * @summary Set invoice address/customer information on the basket. This will not update the customer\'s information on  their profile.
     * @param {string} [apiVersion] 
     * @param {UpdateOrderInvoiceAddressRequest} [updateOrderInvoiceAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutInvoiceAddressPut(apiVersion?: string, updateOrderInvoiceAddressRequest?: UpdateOrderInvoiceAddressRequest, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Set order processing info. This includes the note to the driver and the selected replacement option.
     * @param {string} [apiVersion] 
     * @param {OrderProcessingInfoRequest} [orderProcessingInfoRequest] The request information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutOrderProcessingInfoPost(apiVersion?: string, orderProcessingInfoRequest?: OrderProcessingInfoRequest, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Prepares the basket for checkout by setting information such as default invoice address and customer number  on the basket.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutPreparePost(apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Reserves a home delivery timeslot for a basket.
     * @param {number} [id] Timeslot id
     * @param {number} [zipCode] A valid zip code.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id?: number, zipCode?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Reserves a pick up timeslot for a basket.
     * @param {number} [id] Timeslot id
     * @param {number} [zipCode] A valid zip code.
     * @param {string} [storeId] Id of the delivery store if pickup has been selected
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutReservePickUpTimeSlotPost(id?: number, zipCode?: number, storeId?: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Delete the timeslot for the basket
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApiInterface
     */
    apiBasketCheckoutTimeslotDelete(apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

}

/**
 * CheckoutApi - object-oriented interface
 * @export
 * @class CheckoutApi
 * @extends {BaseAPI}
 */
export class CheckoutApi extends BaseAPI implements CheckoutApiInterface {
    /**
     * 
     * @summary Attaches the basket to the timeslot of an open order.
     * @param {string} [existingOrderId] Id of the existing order
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutAddToExistingTimeSlotPost(existingOrderId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Completes the current basket and turns it into an order. The order is sent to ERP, an email is sent to the  customer and an order history entry is created. It can take up to 30 seconds for the order history entry to  be available
     * @param {string} [apiVersion] 
     * @param {CompleteBasketRequest} [completeBasketRequest] Basket completion request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutCompletePost(apiVersion?: string, completeBasketRequest?: CompleteBasketRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutCompletePost(apiVersion, completeBasketRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the delivery address on the current basket.
     * @param {string} [apiVersion] 
     * @param {DeliveryAddressRequest} [deliveryAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutDeliveryAddressPut(apiVersion?: string, deliveryAddressRequest?: DeliveryAddressRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutDeliveryAddressPut(apiVersion, deliveryAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Sets the delivery zipcode on the current basket.
     * @param {number} [deliveryZipCode] 
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutDeliveryZipCodePut(deliveryZipCode, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Initiates a payment in Lobyco Payment Provider
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutInitiatePaymentPost(apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutInitiatePaymentPost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set invoice address/customer information on the basket. This will not update the customer\'s information on  their profile.
     * @param {string} [apiVersion] 
     * @param {UpdateOrderInvoiceAddressRequest} [updateOrderInvoiceAddressRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutInvoiceAddressPut(apiVersion?: string, updateOrderInvoiceAddressRequest?: UpdateOrderInvoiceAddressRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutInvoiceAddressPut(apiVersion, updateOrderInvoiceAddressRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set order processing info. This includes the note to the driver and the selected replacement option.
     * @param {string} [apiVersion] 
     * @param {OrderProcessingInfoRequest} [orderProcessingInfoRequest] The request information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutOrderProcessingInfoPost(apiVersion?: string, orderProcessingInfoRequest?: OrderProcessingInfoRequest, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutOrderProcessingInfoPost(apiVersion, orderProcessingInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Prepares the basket for checkout by setting information such as default invoice address and customer number  on the basket.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutPreparePost(apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutPreparePost(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reserves a home delivery timeslot for a basket.
     * @param {number} [id] Timeslot id
     * @param {number} [zipCode] A valid zip code.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id?: number, zipCode?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutReserveHomeDeliveryTimeSlotPost(id, zipCode, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Reserves a pick up timeslot for a basket.
     * @param {number} [id] Timeslot id
     * @param {number} [zipCode] A valid zip code.
     * @param {string} [storeId] Id of the delivery store if pickup has been selected
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutReservePickUpTimeSlotPost(id?: number, zipCode?: number, storeId?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutReservePickUpTimeSlotPost(id, zipCode, storeId, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete the timeslot for the basket
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutApi
     */
    public apiBasketCheckoutTimeslotDelete(apiVersion?: string, options?: AxiosRequestConfig) {
        return CheckoutApiFp(this.configuration).apiBasketCheckoutTimeslotDelete(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
