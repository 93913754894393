<template>
    <div class="flex items-center">
        <input
            :id="fieldId"
            type="checkbox"
            :name="name"
            :value="value"
            :checked="checked"
            v-bind="{ ...$attrs }"
            :class="{ '!border-red-500' : hasError }"
            @input="onInput">
        <label :for="fieldId" class="ml-10">
            <slot>
                <span v-if="label">{{ label }}</span>
                <span v-else-if="htmlLabel" v-html="htmlLabel"/>
            </slot>
        </label>
    </div>
    <InputErrorMessage
        v-if="showErrors"
        class="mt-5"
        :name="name"/>
</template>
<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import { useField, useFieldError } from 'vee-validate';
import InputErrorMessage from '@/project/forms/InputErrorMessage.vue';

export default defineComponent({
    name: 'InputCheckbox',
    components: { InputErrorMessage },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Boolean, Number],
            default: true,
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: null,
        },
        label: {
            type: String,
            default: '',
        },
        htmlLabel: {
            type: String,
            default: '',
        },
        showErrors: {
            type: Boolean,
            default: true,
        },
    },
    emits: {
        'update:modelValue': (value) => true,
    },
    setup(props, { emit }) {
        const fieldId = computed(() => {
            return props.id || props.name + Math.random();
        });

        const { checked, handleChange } = useField(props.name, undefined, {
            type: 'checkbox',
            checkedValue: props.value,
            valueProp: props.value,
            initialValue: props.modelValue,
            uncheckedValue: false,
        });

        watch(() => props.modelValue, (value) => {
            emit('update:modelValue', value);
            handleChange(value);
        });

        const hasError = useFieldError(props.name);

        const onInput = (event: Event) => {
            emit('update:modelValue', (event.target as HTMLInputElement).checked);
            handleChange(event);
        };

        return {
            checked,
            onInput,
            fieldId,
            hasError,
        };
    },
});
</script>
