import { isEqual, omit, pick } from 'lodash-es';
import { LocationQuery, LocationQueryRaw, LocationQueryValue, LocationQueryValueRaw, NavigationFailure, RouteLocationNormalizedLoaded, Router, useRoute, useRouter } from 'vue-router';
import { computed, ComputedRef, nextTick, WritableComputedRef } from 'vue';

let pendingQuery: LocationQueryRaw | null = null;
let _nonFacetKeys: string[] = [];
let _lastFacetKey: string;
let _invalidates: Record<string, string[]> = {};
let _valueFormats: Record<string, (value: any) => any> = {};

export function configure<T extends string>(
    nonFacetKeys: T[],
    invalidates: { [key: string]: T[] } = {},
    valueFormats: { [key: string]: (value: any) => any } = {},
    lastFacetKey: string,
): void {
    _nonFacetKeys = nonFacetKeys;
    _invalidates = invalidates;
    _valueFormats = valueFormats;
    _lastFacetKey = lastFacetKey;
}

export function useRouteQuery<T extends LocationQueryValueRaw | LocationQueryValueRaw[] = string>(name: string, options: {
    replace: boolean,
} = {
    replace: true,
}): WritableComputedRef<T> {
    const $route = useRoute();
    const $router = useRouter();

    return computed<T>({
        get: () => {
            const value = $route.query[name];
            return _valueFormats[name] ? _valueFormats[name](value) : value as T;
        },
        set(value: T) {
            const newQuery = pendingQuery || queryClone($route.query);
            newQuery[name] = value;

            // Remove keys that are invalidated by this query-key
            const shouldBeRemoved = _invalidates[name] || [];
            shouldBeRemoved.forEach(key => delete newQuery[key]);

            // Remove this one if no data
            if (!value) {
                delete newQuery[name];
            }

            batchUpdate($router, $route, newQuery, options.replace);
        },
    });
}

function getReactiveFacets(route: RouteLocationNormalizedLoaded) {
    let currentFacets;
    return computed(() => {
        const { query } = route;
        const possibleNewFacets = omit(queryClone(query), _nonFacetKeys);
        if (!isEqual(currentFacets, possibleNewFacets)) {
            currentFacets = possibleNewFacets;
        }
        return currentFacets;
    });
}

function getLastFacet(route: RouteLocationNormalizedLoaded): ComputedRef<string | undefined> {
    return computed(() => {
        const lastFacet = route.query[_lastFacetKey];
        return (Array.isArray(lastFacet) ? lastFacet[0] : lastFacet) || undefined;
    });
}

function applyFacets(router: Router, route: RouteLocationNormalizedLoaded, facets: Record<string, string | string[]>, resetLastFacet = false): void {
    // Clean up old facets and set new ones
    const { query } = route;
    const cloneWithoutFacets = pick(queryClone(query), _nonFacetKeys);
    const result = { ...cloneWithoutFacets, ...facets };
    if (resetLastFacet) {
        delete result[_lastFacetKey];
    }
    updateQuery(router, route, result, true);
}

function setFacetValue(router: Router, route: RouteLocationNormalizedLoaded, name: string, value: string | string[], set: boolean) {
    const newQuery = pendingQuery || queryClone(route.query);

    if (Array.isArray(value)) {
        // Set or clear all values for facet
        if (value.length) {
            newQuery[name] = value;
        } else {
            delete newQuery[name];
        }
    } else {
        // Set or remove individual value
        let facetValue = safeArrayifyFacetValue(newQuery[name]);
        if (set) {
            facetValue.push(value);
        } else {
            facetValue = facetValue.filter(f => f !== value);
        }
        if (facetValue.length > 0) {
            newQuery[name] = facetValue;
        } else {
            delete newQuery[name];
        }
    }
    newQuery[_lastFacetKey] = name;

    batchUpdate(router, route, newQuery, true);
}

export function useFacets(): {
    facets: ComputedRef<Record<string, string | string[]>>,
    applyFacets: (facets: Record<string, string | string[]>, resetLastFaset? : boolean) => void,
    lastFacet: ComputedRef<string | undefined>,
    setFacetValue: (name: string, value: string, set: boolean) => void
    setFacetValues: (name: string, values: string[]) => void
    } {
    const $router = useRouter();
    const $route = useRoute();

    return {
        facets: getReactiveFacets($route),
        applyFacets: (facets: Record<string, string | string[]>, resetLastFacet = false) => applyFacets($router, $route, facets, resetLastFacet),
        lastFacet: getLastFacet($route),
        setFacetValue: (name: string, value: string, set: boolean) => setFacetValue($router, $route, name, value, set),
        setFacetValues: (name: string, values: string[]) => setFacetValue($router, $route, name, values, true),
    };
}

function updateQuery(router: Router, route: RouteLocationNormalizedLoaded, query: LocationQueryRaw, replace: boolean): Promise<NavigationFailure | void | undefined> {
    if (isEqual(query, route.query)) {
        return Promise.resolve();
    }

    const method = replace ? router.replace : router.push;
    return method({
        path: router.currentRoute.value.path,
        query,
    });
}

function batchUpdate(router: Router, route: RouteLocationNormalizedLoaded, newQuery: LocationQueryRaw | null, replace: boolean) {
    // Queue and update once pr. batch
    if (!pendingQuery) {
        pendingQuery = newQuery;
        nextTick().then(async() => {
            await updateQuery(router, route, pendingQuery!, replace);
            pendingQuery = null;
        });
    }
}

function safeArrayifyFacetValue(facetValue: any | any[]): string[] {
    return !facetValue
        ? []
        : typeof facetValue === 'string'
            ? [facetValue]
            : [...facetValue] as string[];
}

function queryClone(query: LocationQuery): LocationQuery {
    if (!query) throw new Error('Illegal access to query');
    const clone = {
        ...query,
    };
    Object.keys(clone).forEach(key => {
        clone[key] = safeArrayifyFacetValue(clone[key]);
    });
    return clone;
}

export function asString(queryValue: LocationQueryValue | LocationQueryValue[]): string {
    const value = safeArrayifyFacetValue(queryValue)[0];
    return value?.toString() ?? '';
}

export function asBoolean(queryValue: LocationQueryValue | LocationQueryValue[]): boolean {
    const value = asString(queryValue);
    return value === 'true' || value === '1';
}

export function asInt(queryValue: LocationQueryValue | LocationQueryValue[]): number {
    const value = asString(queryValue);
    const parsedValue = parseInt(value, 10);
    return !isNaN(parsedValue) ? parsedValue : 0;
}

export function asNumber(queryValue: LocationQueryValue | LocationQueryValue[]): number {
    const value = asString(queryValue);
    const parsedValue = Number(value);
    return !isNaN(parsedValue) ? parsedValue : 0;
}
