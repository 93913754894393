<template>
    <svg class="w-full h-full transform -rotate-12 bg" aria-hidden="true" :class="props.type ==='hero' ? 'opacity-[0.02] -translate-y-[50%] scale-[3]' : 'opacity-5 scale-125 '">
        <defs>
            <pattern id="Text"
                     x="0"
                     y="0"
                     width="1"
                     height=".2">
                <text x="0" y="10" style="font-weight: bold">
                    {{ offerText }}
                </text>
                <text x="20" y="25" style="font-weight: bold">
                    {{ offerText }}
                </text>
            </pattern>
        </defs>
        <rect fill="url(#Text)" width="100%" height="100%"/>
    </svg>
</template>

<script lang="ts" setup>

const props = defineProps<{
    text: string;
    type?: string;
}>();

const offerText = (`${props.text}  ${props.text}  ${props.text}  ${props.text}  ${props.text}  ${props.text}  ${props.text}  ${props.text}  ${props.text}   ${props.text}  ${props.text}  ${props.text}  ${props.text}  ${props.text} `).toUpperCase();

</script>
