<template>
    <DrawerDialog
        :title="$translate('Client.StoreLocater.Title')"
        use-slide-animation
        :title-classes="'px-10 lg:px-30'"
        :position="renderInMobileMenu ? 'left' : 'right'"
        :show-back-button="renderInMobileMenu"
        show-backdrop
        :drawer-classes="'bg-background'"
        @back="handleBack">
        <div :class="`${drawerWidth}`">
            <TextWrapper class="sticky top-0 z-dialog shadow-[0_2px_4px_0_rgba(0,0,0,0.06)]">
                <div class="flex items-center">
                    <div class="p-8 rounded-full bg-primary">
                        <CIcon name="location" width="24" height="24" class="text-white"/>
                    </div>
                    <h2 class="ml-20 font-bold text-18">
                        {{ $translate('Client.StoreLocater.Instruction') }}
                    </h2>
                    <div class="flex flex-wrap items-center">
                        <InputText name="zip"
                                   class="w-64 ml-15"
                                   autocomplete="off"
                                   @input="debouncedOnChange"/>
                        <span class="mt-10 lg:mt-0 text-black ml-15">{{ zipCodeInfo?.areaName }}</span>
                    </div>
                </div>
            </TextWrapper>
            <div class="my-20 mx-10 lg:mx-30">
                <div v-if="stores.netto.stores.length > 0" class="mt-20">
                    <Store v-for="store in stores.netto.stores.slice(0, sliceNettoStoresBy)"
                           :key="store.id"
                           class="mb-10"
                           :model="store"
                           @expand-opening-hours="trackViewOpeningHours(store.name)">
                        <Button :label="$translate('Client.StoreLocater.ShowOnMapButton')"
                                :to="store.googleMapsLink"
                                target="_blank"
                                :primary="false"
                                @click="trackGoToMap(store.name)"/>
                    </Store>
                    <div>
                        <Button :pill="true"
                                class="mx-auto"
                                :label="showAllNettoStores ? $translate('Client.StoreLocater.ShowLess') : $translate('Client.StoreLocater.ShowAllNettoStores', stores.netto.totalHits)"
                                :transparent="true"
                                @click="toggleAllNettoStores">
                            <template #right>
                                <CIcon name="arrow-down"
                                       width="9"
                                       height="9"
                                       class="-ml-7 transition-transform duration-200 ease-in-out transform text-primary"
                                       :class="showAllNettoStores ? '-rotate-180': ' rotate-0'"/>
                            </template>
                        </Button>
                    </div>
                </div>
                <div v-if="stores.other.stores.length > 0" class="mt-10">
                    <h2 class="font-bold text-18 mb-15">
                        {{ $translate('Client.StoreLocater.OtherStoresTitle') }}
                    </h2>
                    <Store v-for="store in stores.other.stores.slice(0, sliceOtherStoresBy)"
                           :key="store.id"
                           class="mb-10"
                           :model="store"
                           @expand-opening-hours="trackViewOpeningHours(store.name)">
                        <Button :label="$translate('Client.StoreLocater.ShowOnMapButton')"
                                :to="store.googleMapsLink"
                                target="_blank"
                                :primary="false"
                                @click="trackGoToMap(store.name)"/>
                    </Store>
                    <div>
                        <Button :pill="true"
                                class="mx-auto"
                                :label="showAllOtherStores ? $translate('Client.StoreLocater.ShowLess') : $translate('Client.StoreLocater.ShowAllOtherStores', stores.other.totalHits)"
                                :transparent="true"
                                @click="toggleAllOtherStores">
                            <template #right>
                                <CIcon name="arrow-down"
                                       width="9"
                                       height="9"
                                       class="-ml-7 transition-transform duration-200 ease-in-out transform text-primary"
                                       :class="showAllOtherStores ? '-rotate-180': ' rotate-0'"/>
                            </template>
                        </Button>
                    </div>
                </div>
            </div>
            <div v-if="loading" class="flex justify-center items-center py-[80px]">
                <Spinner/>
            </div>
            <div v-else-if="stores?.netto.stores.length === 0 && stores.other.stores.length === 0 && hasTriedValidZip" class="py-4 px-8 rounded-lg bg-info">
                {{ $translate('Client.StoreLocater.NoResults') }}
            </div>
            <div v-if="hasError" class="py-4 px-8 rounded-lg bg-error">
                {{ $translate('Client.StoreLocater.Error') }}
            </div>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import { computed, ref, Ref, watch } from 'vue';
import { getStores, SamkaupStores, getAllNettoStores, getAllOtherStores } from '@/project/apis/commerce/storesApi';
import TextWrapper from '@/project/components/text-wrapper/TextWrapper.vue';
import debounce from 'lodash-es/debounce';
import { getZipCodeInfo } from '@/project/apis/commerce/timeSlotsApi';
import { isValidZip } from '@/core/forms/vee-validate.config';
import { ZipCodeViewModel } from '@/api/commerce';
import { useTimeoutFn } from '@vueuse/core';
import InputText from '@/project/forms/InputText.vue';
import Spinner from '@/project/components/spinner/Spinner.vue';
import Store from '@/project/components/store/Store.vue';
import Button from '@/project/components/button/Button.vue';
import { useMenus } from '@/project/navigation/menus.composable';
import { useCustomerInfo } from '@/project/apis/commerce/accountApi';
import { trackStoreFinding } from '@/project/tracking/tracking.service';

const customerInfo = useCustomerInfo();
const defaultZipForTracking = customerInfo.value?.zipCode ?? 101;
trackStoreFinding(defaultZipForTracking, '');

const NETTO_STORES_LIMIT = 5;
const OTHER_STORES_LIMIT = 3;

const stores: Ref<SamkaupStores> = ref({ netto: { stores: [], totalHits: 0 }, other: { stores: [], totalHits: 0 } });

const zipCode = ref('');
const zipCodeInfo: Ref<ZipCodeViewModel | null> = ref(null);
const loading = ref(false);
const hasError = ref(false);
const hasTriedValidZip = ref(false);
const showAllNettoStores = ref(false);
const showAllOtherStores = ref(false);
const sliceNettoStoresBy = computed(() => {
    if (!showAllNettoStores.value && stores.value.netto.stores.length > NETTO_STORES_LIMIT) {
        return NETTO_STORES_LIMIT;
    } else {
        return undefined;
    }
});
const sliceOtherStoresBy = computed(() => {
    if (!showAllOtherStores.value && stores.value.other.stores.length > OTHER_STORES_LIMIT) {
        return OTHER_STORES_LIMIT;
    } else {
        return undefined;
    }
});

window.scrollTo(0, 0);

const { openMobileMenu, drawerWidth, renderInMobileMenu } = useMenus();

const { stop: stopInitialLoad } = setLoading();

function getDefaultStores() {
    getStores(undefined, NETTO_STORES_LIMIT, OTHER_STORES_LIMIT).then(res => {
        stores.value = res;

        hasError.value = false;
    }).catch(() => {
        hasError.value = true;
    }).finally(() => stopInitialLoad());
}

getDefaultStores();

function onChange(e: any) {
    if (e.target.value === zipCode.value) return;

    if (isValidZip(e.target.value)) {
        hasTriedValidZip.value = true;
    } else {
        hasTriedValidZip.value = false;
        if (e.target.value === '') {
            zipCode.value = '';
            zipCodeInfo.value = { areaName: '', zipCode: 0 };
            getDefaultStores();
        }
    }
    const { stop } = setLoading();
    if (hasTriedValidZip.value) {
        getStores(Number(e.target.value), NETTO_STORES_LIMIT, OTHER_STORES_LIMIT).then(res => {
            showAllNettoStores.value = false;
            showAllOtherStores.value = false;

            stores.value = res;

            hasError.value = false;
            zipCode.value = e.target.value;
            trackStoreFinding(e.target.value, '', false);
        }).catch(() => {
            hasError.value = true;
        }).finally(() => stop());
    } else {
        stop();
    }
}

const debouncedOnChange = debounce(onChange, 300);

watch(zipCode, (zip) => {
    if (zip) {
        getZipCodeInfo(Number(zip)).then(res => {
            zipCodeInfo.value = res;
        });
    }
});

function toggleAllNettoStores() {
    if (showAllNettoStores.value) {
        showAllNettoStores.value = false;
        return;
    } else if (!showAllNettoStores.value && stores.value.netto.stores.length > NETTO_STORES_LIMIT) {
        showAllNettoStores.value = true;
        return;
    }

    const { stop } = setLoading();

    getAllNettoStores(zipCode.value ? Number(zipCode.value) : undefined).then(res => {
        showAllNettoStores.value = true;

        stores.value = { ...stores.value, ...res };

        hasError.value = false;
    }).catch(() => {
        hasError.value = true;
    }).finally(() => stop());
}

function toggleAllOtherStores() {
    if (showAllOtherStores.value) {
        showAllOtherStores.value = false;
        return;
    } else if (!showAllOtherStores.value && stores.value.other.stores.length > OTHER_STORES_LIMIT) {
        showAllOtherStores.value = true;
        return;
    }

    const { stop } = setLoading();

    getAllOtherStores(zipCode.value ? Number(zipCode.value) : undefined).then(res => {
        showAllOtherStores.value = true;

        stores.value = { ...stores.value, ...res };

        hasError.value = false;
    }).catch(() => {
        hasError.value = true;
    }).finally(() => stop());
}

function setLoading() {
    const { stop: stopTimeout } = useTimeoutFn(() => {
        loading.value = true;
    }, 300);

    return {
        stop: () => {
            stopTimeout();
            loading.value = false;
        },
    };
}

function handleBack() {
    openMobileMenu();
}

function trackGoToMap(storeName: string) {
    trackStoreFinding(zipCode.value ? Number(zipCode.value) : defaultZipForTracking, storeName, !zipCode.value, false, true);
}

function trackViewOpeningHours(storeName: string) {
    trackStoreFinding(zipCode.value ? Number(zipCode.value) : defaultZipForTracking, storeName, !zipCode.value, true, false);
}
</script>
