<template>
    <section v-if="invoiceAddress" class="flex flex-col w-full">
        <TextWrapper class="mb-10">
            <h2 class="mb-10 font-bold text-18">
                {{ $translate('Client.CheckoutPage.CustomerInformation.ProfileTitle') }}
            </h2>
            <div class="mb-5">
                <span>{{ invoiceAddress.firstName }} {{ invoiceAddress.lastName }}</span>
            </div>
            <div class="flex">
                <div>
                    <div class="mb-5">
                        <span>{{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}:</span>
                    </div>
                    <div>
                        <span>{{ $translate('Client.CheckoutPage.CustomerInformation.Email') }}:</span>
                    </div>
                </div>
                <div class="ml-10">
                    <div class="mb-5">
                        {{ invoiceAddress.mobile }}&nbsp;
                    </div>
                    <div class="mb-5">
                        {{ invoiceAddress.email }}
                    </div>
                </div>
            </div>
            <div v-if="(invoiceAddress.companyName || invoiceAddress.companySSN)" class="flex">
                <div>
                    <div v-if="invoiceAddress.companyName" class="mb-5">
                        <span>{{ $translate('Client.CheckoutPage.CustomerInformation.CompanyNameLabel') }}:</span>
                    </div>
                    <div v-if="invoiceAddress.companySSN">
                        <span>{{ $translate('Client.CheckoutPage.CustomerInformation.CompanySSNLabel') }}:</span>
                    </div>
                </div>
                <div class="ml-10">
                    <div v-if="invoiceAddress.companyName" class="mb-5">
                        {{ invoiceAddress.companyName }}&nbsp;
                    </div>
                    <div v-if="invoiceAddress.companySSN">
                        {{ invoiceAddress.companySSN }}
                    </div>
                </div>
            </div>
        </TextWrapper>
        <TextWrapper>
            <h2 class="mb-10 font-bold text-18">
                {{ $translate('Client.CheckoutPage.CustomerInformation.InvoiceAddress') }}
            </h2>
            <div class="space-y-[0.5rem]">
                <div>
                    <span>{{ invoiceAddress.firstName }} {{ invoiceAddress.lastName }}</span>
                </div>
                <div>
                    <span>{{ invoiceAddress.addressLine1 }}</span>
                </div>
                <div>
                    <span>{{ invoiceAddress.addressLine2 }}</span>
                </div>
                <div>
                    <span>{{ invoiceAddress.zipCode }} {{ invoiceAddress.city }}</span>
                </div>
            </div>
        </TextWrapper>
        <div class="flex justify-between items-center mx-10 sm:mx-0 mt-20">
            <CheckoutBackButton/>
            <DeliveryNextButton :label="$translate('Client.CheckoutPage.CustomerInformation.ContinueToDelivery')"
                                @click="next"/>
        </div>
    </section>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { getCheckoutBasket } from '@/project/apis/commerce/checkoutApi';
import TextWrapper from '@/project/components/text-wrapper/TextWrapper.vue';
import DeliveryNextButton from '@/project/pages/checkout-page/delivery/DeliveryNextButton.vue';
import { useRouter } from 'vue-router';
import CheckoutBackButton from '@/project/navigation/checkout-header/CheckoutBackButton.vue';

const { basket } = getCheckoutBasket();

const props = defineProps<{
    nextStepUrl: string
}>();

const router = useRouter();

const initialValues = {
    companyName: basket.value!.invoiceAddress!.companyName,
    companySSN: basket.value!.invoiceAddress!.companySocialSecurityNumber,
    firstName: basket.value!.invoiceAddress!.firstName,
    lastName: basket.value!.invoiceAddress!.lastName,
    zipCode: basket.value!.invoiceAddress!.zipCode,
    city: basket.value!.invoiceAddress!.city,
    addressLine1: basket.value!.invoiceAddress!.addressLine1,
    addressLine2: basket.value!.invoiceAddress!.addressLine2,
    email: basket.value!.invoiceAddress!.email,
    mobile: basket.value!.invoiceAddress!.mobile,
};

const invoiceAddress = ref({
    ...initialValues,
});

function next() {
    router.push({ path: props.nextStepUrl, query: { back: 'true' } });
}
</script>
