<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="navigation.breadcrumb.slice(1)" :current-page-name="metadata.navigationTitle"/>

        <div class="flex flex-col lg:flex-row space-y-20 lg:space-y-0 lg:space-x-20">
            <MyAccountNavigation :menu="navigation.menu"
                                 :is-mobile="isMobile"
                                 :user="customerInfo?.firstName"/>

            <div class="w-full">
                <div class="hidden lg:flex relative xl:justify-between xl:items-center mb-20 w-full bg-white rounded-lg p-15">
                    <div class="flex flex-col xl:flex-row justify-center xl:items-baseline xl:justify-left">
                        <h1 class="font-bold text-center lg:text-left xl:text-left text-28 lg:text-40 text-primary">
                            {{ metadata.navigationTitle }}
                        </h1>
                    </div>
                </div>
                <div class="grid md:gap-20 gap-y-20 grid-cols-24">
                    <div v-if="!isFromLobycoApp" class="flex flex-col md:col-span-12 lg:col-span-11 justify-start p-20 bg-white rounded-lg col-span-24">
                        <div>
                            <div class="mb-20">
                                <h2 class="mb-20 font-bold text-18">
                                    {{ $translate('Client.MyAccount.CustomerInformationHeading') }}
                                </h2>
                                <div class="space-y-20">
                                    <div>
                                        <span>{{ customerInfo?.firstName }} {{ customerInfo?.lastName }}</span>
                                    </div>
                                    <div>
                                        <span>{{ customerInfo?.addressLine1 }}</span>
                                    </div>
                                    <div>
                                        <span>{{ customerInfo?.addressLine2 }}</span>
                                    </div>
                                    <div>
                                        <span>{{ customerInfo?.zipCode }} {{ customerInfo?.city }}</span>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h2 class="mb-20 font-bold text-18">
                                    {{ $translate('Client.MyAccount.LoginInformationHeading') }}
                                </h2>
                                <div class="flex">
                                    <div class="space-y-20">
                                        <div>
                                            <span>{{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}:</span>
                                        </div>
                                        <div>
                                            <span>{{ $translate('Client.CheckoutPage.CustomerInformation.Email') }}:</span>
                                        </div>
                                        <div>
                                            <span>{{ $translate('Client.Common.Password') }}:</span>
                                        </div>
                                    </div>
                                    <div class="ml-10 space-y-20">
                                        <div class="">
                                            {{ customerInfo?.mobile }}&nbsp;
                                        </div>
                                        <div>
                                            {{ customerInfo?.email }}
                                        </div>
                                        <div>
                                            **********
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isFromLobycoApp" class="flex justify-between items-center mt-30">
                            <Button :label="$translate('Client.MyAccount.EditAccountInfoButton')" @click="edit"/>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 md:col-span-12 gap-20 col-span-24 lg:col-span-13">
                        <div class="flex flex-col col-span-12 p-20 bg-white rounded-lg">
                            <h2 class="font-bold text-18">
                                {{ $translate('Client.MyAccount.BonusHeading') }}
                            </h2>
                            <div class="font-bold text-bonus">
                                <span>{{ $translate('Client.MyAccount.BonusBalance') }}</span>
                                <span class="ml-60 text-20">{{ formatPrice(bonusBalance?.bonus) }}</span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!isFromLobycoApp" class="grid grid-cols-12 gap-20 col-span-24">
                        <div class="flex flex-col col-span-12 p-20 bg-white rounded-lg">
                            <h2 class="mb-10 font-bold text-18">
                                {{ $translate('Client.MyAccount.PaymentHeading') }}
                            </h2>
                            <iframe class="w-full rounded-lg h-[720px]" :src="lobycoPaymentMethodUrl"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { IMetadata, SiteNavigation } from '@/api/cms/';
import { useCustomerInfo, getCustomerInfo, getCustomerBonusBalance } from '@/project/apis/commerce/accountApi';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import MyAccountNavigation from './MyAccountNavigation.vue';
import { BasketValidityViewModel, CustomerBonusBalanceViewModel } from '@/api/commerce';
import { Ref, ref } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { openAsyncDialog } from '@/project/dialog/dialog';
import EditInfoDrawer from './EditInfoDrawer.vue';
import { trackPage } from '@/project/tracking/tracking.service';
import { authRequired } from '@/project/authentication/authentication';
import { lobycoPaymentMethodUrl } from '@/core/infrastructure/environment';
import { setDeliveryZipCode } from '@/project/apis/commerce/checkoutApi';
import { addMessage } from '@/project/components/system-message/system-message.service';
import dictionary from '@/core/dictionary/dictionary';
import { useLobyco } from '@/project/authentication/useLobyco';

defineProps<{
    navigation: SiteNavigation,
    metadata: IMetadata,
}>();

const { isFromLobycoApp } =  useLobyco();

trackPage();

authRequired();

const { smaller } = useBreakpoints();
const isMobile = smaller('lg');

const customerInfo = useCustomerInfo();

const bonusBalance: Ref<CustomerBonusBalanceViewModel | undefined> = ref(undefined);

getCustomerBonusBalance().then((res) => {
    bonusBalance.value = res;
}).catch(() => {
    bonusBalance.value = undefined;
});

async function edit() {
    await openAsyncDialog(EditInfoDrawer, {});

    customerInfo.value = await getCustomerInfo();
    const basket = await setDeliveryZipCode(customerInfo.value.zipCode);
    if (basket.status !== BasketValidityViewModel.Valid) {
        addMessage({
            title: dictionary.get(`Client.DeliveryStatus.${basket.status}Title`),
            description: dictionary.get(`Client.DeliveryStatus.${basket.status}Description`),
            severity: 'warning',
            type: 'system',
        });
    }
}
</script>
