<template>
    <div v-if="model">
        <section class="flex justify-center items-center h-115 bg-white">
            <CIcon name="netto-logo"
                   width="119"
                   height="37"
                   class="text-primary"/>
        </section>
        <section class="flex justify-center items-center h-80 bg-addressline">
            <div class="grid grid-cols-12">
                <address class="col-span-full lg:col-span-8 lg:col-start-3 not-italic leading-8 text-center text-white text-12 md:text-13">
                    <div class="inline-block">
                        © {{ model.companyname }}
                    </div>&nbsp;
                    <div class="inline-block">
                        {{ model.streetAndNumber }} {{ model.cityAndZipCode }}
                    </div>&nbsp;
                    <div class="inline-block">
                        {{ $translate('Client.Common.Id') }} {{ model.idNumber }}
                    </div>&nbsp;
                    <div class="inline-block">
                        {{ $translate('Client.Common.Vat') }} {{ model.vatNumber }}
                    </div>&nbsp;
                    <div class="inline-block">
                        {{ $translate('Client.Common.Phone') }} {{ model.phoneNumber }}
                    </div>&nbsp;
                    <div class="inline-block">
                        {{ $translate('Client.Common.Email') }} {{ model.email }}
                    </div>
                </address>
            </div>
        </section>
    </div>
</template>

<script setup lang="ts">
import { SiteSettingFooterModel } from '@/api/cms';
import { getFooter } from '@/project/apis/cms/contentApi';
import { ref } from 'vue';

const model = ref<SiteSettingFooterModel>();

getFooter().then(_model => {
    model.value = _model;
});
</script>

<style scoped>
</style>
