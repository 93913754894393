<template>
    <Confirmation v-if="orderId" :model="model"/>
</template>
<script setup lang="ts">
import { ConfirmationPageModel } from '@/api/cms';
import { QueryKeys } from '@/project/browser/query';
import { computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { completePayment } from '../../../apis/commerce/checkoutApi';
import { useDeliveryState } from '../delivery/delivery-state.composeable';
import Confirmation from './Confirmation.vue';

defineProps<{
   model: ConfirmationPageModel
}>();

const $route = useRoute();

const paymentId = computed(() => $route.query[QueryKeys.PAYMENT_ID]);
const orderId = computed(() => $route.query[QueryKeys.ORDER_ID]);

const { reset } = useDeliveryState();
reset();

watch(paymentId, async p => {
    if (p) {
        await completePayment(String(p));
    }
}, {
    immediate: true,
});

</script>
