<template>
    <RouterLink :to="basketUrl">
        <div class="flex items-center space-x-10 cursor-pointer" @click="onClick">
            <div class="inline-block relative">
                <div
                    v-if="quantity"
                    class="flex absolute -top-8 -right-8 justify-center items-center px-3 pt-[1.2px] font-bold text-blue bg-white rounded-full text-12 border border-blue"
                    :class="quantity > 99 ? 'min-w-[30px]' : 'min-w-22'">
                    <AnimateValue animation="scroll">
                        <div :key="quantity" class="flex justify-center items-center">
                            {{ quantity }}
                        </div>
                    </AnimateValue>
                </div>
                <Button size="small" primary class="w-[3.2rem] h-[3.2rem]" :tabindex="-1">
                    <CIcon name="basket" width="16" height="16"/>
                </Button>
            </div>
        </div>
    </RouterLink>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { useBasket } from '@/project/apis/commerce/basketApi';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import openDialog from '@/project/dialog/dialog';
import BasketMini from '@/project/basket/basket-mini/BasketMini.vue';

const emit = defineEmits(['click']);

const breakpoints = useBreakpoints();
const isLargeScreen = breakpoints.greater('lg');
const { quantity } = useBasket();

const onClick = () => isLargeScreen.value ? open() : emit('click');

const { basketUrl } = getPageUrls();

const open = () => {
    openDialog(BasketMini, {});
};
</script>
