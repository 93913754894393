<template>
    <div class="block-spacing @container px-10 sm:px-0">
        <div
            class="@[90rem]:grid-cols-5 @[130rem]:grid-cols-7 grid grid-flow-row auto-rows-max grid-cols-2 gap-10">
            <ProductTile
                v-for="(item, index) in model"
                :key="item.sku"
                :tracking-context="{index, impressionList: {listType: 'Product band', choosingMechanism: 'Editor'}}"
                :model="item"
                class="col-span-1 flex-col"
                :class="{
                    'hidden': index > 1 && !showAllProducts && hideProducts,
                    '@[90rem]:flex': index < 5 && !showAllProducts && hideProducts,
                    '@[130rem]:flex': index < 7 && !showAllProducts && hideProducts,
                }"/>
        </div>
        <div v-if="link && link.name != ''" class="mt-20 flex items-center justify-center">
            <RouterLinkConditional :url="link.url" :target="link.target">
                <Button :label="link.name" :pill="true" :primary="false">
                    <template #right>
                        <CIcon name="arrow-right" width="12" height="12"/>
                    </template>
                </Button>
            </RouterLinkConditional>
        </div>
        <div v-else-if="hideProducts" class="showhide-toggle mt-20 flex items-center justify-center">
            <span class="border-products mr-20 inline-block h-0 w-full"></span>
            <Button
                :label="showAllProducts ? $translate('Client.Common.HideAllProducts') : $translate('Client.Common.ShowAllProducts')"
                :pill="true"
                :primary="false"
                @click="showAllProducts = !showAllProducts">
                <template #right>
                    <CIcon v-if="showAllProducts" name="arrow-up" width="12" height="12"/>
                    <CIcon v-else name="arrow-down" width="12" height="12"/>
                </template>
            </Button>
            <span class="border-products ml-20 inline-block h-0 w-full"></span>
        </div>
    </div>
</template>

<script lang="ts" setup>

import { ProductTileViewModel } from '@/api/commerce/models';
import ProductTile from '@/project/products/product-tile/ProductTile.vue';
import Button from '@/project/components/button/Button.vue';
import { Link } from '@/api/cms/models/link';
import { ref } from 'vue';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';

defineProps<{
    model: ProductTileViewModel[],
    link?: Link,
    hideProducts?: boolean;
}>();

const showAllProducts = ref(false);
</script>

<style scoped>

.border-products{
    border-top: 1px solid #e3e3e3;
}
</style>
