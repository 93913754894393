import { StoreViewModel, TimeSlotDate, TimeSlotsApiFactory, TimeSlotTypeViewModel, TimeSlotViewModel, ZipCodeViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { handleError } from '@/project/apis/commerce/errorHandler';

const timeSlotsApi = TimeSlotsApiFactory({ isJsonMime: () => true }, commerceApiHost);

export async function getAvailableDeliveryTypes(zip: number): Promise<TimeSlotTypeViewModel[]> {
    return timeSlotsApi.apiTimeslotsDeliverytypesGet(zip).then(res => {
        return res.data.model;
    }).catch(error => {
        handleError(error);
        console.error('Could not get delivery options', error);
        return error;
    });
}

export async function getPickUpPoints(zip: number): Promise<StoreViewModel[]> {
    return timeSlotsApi.apiTimeslotsStoresGet(zip).then(res => {
        return res.data.model;
    }).catch(error => {
        handleError(error);
        console.error('Could not get pick-up points', error);
        return error;
    });
}

export async function getDates(zip: number, deliveryType: TimeSlotTypeViewModel): Promise<TimeSlotDate[]> {
    const date = new Date();
    return timeSlotsApi.apiTimeslotsDatesGet(zip, deliveryType, date.toDateString(), 14)
        .then(res => {
            return res.data.model;
        }).catch(error => {
            handleError(error);
            console.error('Could not get dates', error);
            return error;
        });
}

export async function getTimeSlots(zip: number, deliveryType: TimeSlotTypeViewModel, deliveryDate: string): Promise<TimeSlotViewModel[]> {
    return timeSlotsApi.apiTimeslotsTimeslotsGet(zip, deliveryType, deliveryDate)
        .then(res => {
            return res.data.model;
        }).catch(error => {
            handleError(error);
            console.error('Could not get time slots', error);
            return error;
        });
}

export async function getZipCodeInfo(zipCode: number): Promise<ZipCodeViewModel> {
    return timeSlotsApi.apiTimeslotsZipCodeInfoGet(zipCode)
        .then(res => {
            return res.data.model;
        }).catch(error => {
            console.error('Could not get zip code info');
            throw error;
        });
}
