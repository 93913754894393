import { computed, readonly, ref, watch } from 'vue';
import { fetchBasket } from '@/project/apis/commerce/basketApi';

import AuthService, { User } from './auth-service';
import router from '@/router';
import { getPageUrls } from '../apis/cms/contentApi';
import { localStorageService } from '@/core/browser/storage';

declare global {
    interface Window {
        lobycoRegisterOAuth(token: string | undefined): void;
    }
}

const params = new URLSearchParams(location.search);
const fromLobycoApp = params.has('FromLobycoApp');
if (fromLobycoApp) {
    localStorageService.setItemAs<boolean>('fromLobycoApp', true);
}

const service = new AuthService();
const accessTokenRef = ref<string | undefined>();

watch(accessTokenRef, (token) => {
    if (window.lobycoRegisterOAuth) {
        window.lobycoRegisterOAuth(token);
    }
});

export async function initAuth(): Promise<void> {
    service.events.addUserSignedIn(async() => {
        accessTokenRef.value = await service.getAccessToken();
    });
    service.events.addUserSignedOut(() => {
        accessTokenRef.value = undefined;
    });

    service.events.addUserLoaded((user: User) => {
        accessTokenRef.value = user.access_token;
    });

    try {
        let user = await service.getUser();
        if (user) {
            if (user.expired) {
                if (user.refresh_token) {
                    try {
                        user = await service.silentSignIn();
                    } catch {
                        service.localLogout();
                        if (fromLobycoApp) {
                            service.signinRedirect();
                        }
                    }
                } else {
                    await service.logout();
                }
            } else {
                accessTokenRef.value = user.access_token;
            }
        } else if (fromLobycoApp) {
            service.signinRedirect();
        }
    } catch {
        service.localLogout();
        if (fromLobycoApp) {
            service.signinRedirect();
        }
    }
}

export async function refreshUserState(): Promise<void> {
    try {
        const user = await service.getUser();
        accessTokenRef.value = user?.access_token;
        fetchBasket();
    } catch (e) {
        console.log('could not refresh user state', e);
    }
}

export const isAuthenticated = computed(() => !!accessTokenRef.value);
export const accessToken = readonly(accessTokenRef);

export function signinPopupCallback(): Promise<void> {
    return service.signinPopupCallback();
}
export async function silentSignIn(): Promise<void> {
    await service.silentSignIn();
}

export async function logout(): Promise<void> {
    await service.logout();
}

export async function authRequired(): Promise<void> {
    await refreshUserState(); // Refresh here, in case that frontend did not receive logged in event.

    if (!isAuthenticated.value) {
        try {
            await service.login();
        } catch (e) {
            // If we try to login (open pop-up) without a user interaction (hitting an authRequired route directly) opening the popup will fail. In that case we want to just redirect to the frontpage.
            const { frontPageUrl } = getPageUrls();
            router.push(frontPageUrl.value);
        }
        await refreshUserState();
    }
}

export function login(): Promise<User | void> {
    const fromLobycoApp = service.isFromLobycoApp;
    if (fromLobycoApp) {
        return service.signinRedirect();
    } else {
        return service.login();
    }
}

export function getUser(): Promise<User | null> {
    return service.getUser();
}

export function signinRedirectCallback(): Promise<User> {
    return service.signinRedirectCallback();
}
