<template>
    <div v-if="model.displayName" class="o-container md:grid-cols-34 md:p-50 grid grid-cols-8 rounded-lg bg-white p-20 sm:grid-cols-12">
        <div class="md:col-span-14 relative col-span-full sm:col-span-6" :class="{'opacity-60': soldOut}">
            <div
                v-if="model.offer?.description"
                class="z-2 absolute right-0 top-0 flex">
                <Splash :label="model.offer.description" size="lg"/>
            </div>
            <ProductDetailsGallery :image-urls="model.imageUrls"/>
        </div>
        <div class="md:col-start-16 md:col-span-19 col-span-full mt-10 sm:col-span-6 sm:ml-10 sm:mt-0">
            <h1 class=" text-28 lg:text-34 xl:text-40 text-primary mt-20 font-bold leading-tight sm:mt-0">
                {{
                    model.displayName
                }}
            </h1>
            <div class="text-16 md:text-20">
                <span v-if="productTeasers">{{ productTeasers }}</span>
            </div>
            <div class="mt-15 mb-10" :class="{ 'md:mt-30': !model.bonus }">
                <div>
                    <span v-if="model.offer && model.offer.discountAmount" class="text-save text-14 md:text-16 font-bold">{{ $translate('Client.ProductDetailsPage.Save') }} {{ model.offer.discountAmount }}</span>
                </div>
                <div>
                    <span class="text-30 md:text-36 font-bold">
                        {{ formatPrice((model.offer?.discountedSalesPrice) || model.salesPrice, {hideCurrency: true}) }}
                        <span class="text-16 md:text-18 font-bold">{{ $translate('Client.Common.Kroner') }}</span>
                    </span>
                    <span v-if="model.offer?.discountedSalesPrice" class="text-fadedText text-16 ml-10 line-through">{{ formatPrice(model.salesPrice) }}</span>
                </div>
                <div v-if="model.pricePerUnit && model.unitOfMeasure">
                    <span class="text-fadedText text-14">{{ formatPrice(model.pricePerUnit) }} / {{
                        model.unitOfMeasure
                    }}</span>
                </div>
            </div>
            <div v-if="model.loyalty?.amount && !soldOut" class="mb-4 mt-8">
                <span class="text-14 lg:text-16 text-primary font-semibold">
                    {{ $translate('Client.ProductDetailsPage.EarnBonus') }} <span class="whitespace-nowrap">{{ formatPrice(model.loyalty.amount) }}</span>
                </span>
            </div>
            <div v-if="stockExceeded" class="text-errorText mb-10">
                {{ $translate('Client.BasketStatus.NotEnoughInStock') }}
            </div>
            <div v-else-if="fewLeftInStock" class="text-errorText mb-10">
                {{ $translate('Client.ProductDetailsPage.FewLeft') }}
            </div>
            <div class="flex items-center justify-between">
                <transition name="component-fade" mode="out-in">
                    <div v-if="soldOut" class="bg-info rounded-lg p-10">
                        <span>{{ $translate('Client.ProductDetailsPage.OutOfStock') }}</span>
                    </div>
                    <div v-else-if="!canAddToBasket" class="bg-info mr-10 rounded-lg p-10">
                        {{ $translate('Client.ProductDetailsPage.AgeLimitMessage', 18) }}
                    </div>
                    <Button
                        v-else-if="!lineItem || lineItem?.quantity === 0"
                        :label="$translate('Client.ProductDetailsPage.AddToBasket')"
                        size="large"
                        strong
                        @click="onIncrement">
                        <CIcon name="basket" width="24" height="24" class="text-white"/>
                    </Button>
                    <div v-else class="w-170">
                        <AddToBasket
                            :quantity="lineItem?.quantity || 0"
                            :disable-increment="stockLimitHit || stockExceeded"
                            @increment="onIncrement"
                            @decrement="onDecrement"
                            @on-change="onManualChange"/>
                    </div>
                </transition>

                <div class="flex space-x-4">
                    <CIcon
                        v-for="icon in model.hazardLabels"
                        :key="icon"
                        :name="icon"
                        width="33.8"
                        height="32.8"
                        original
                        translate/>
                    <CIcon
                        v-for="icon in model.icons"
                        :key="icon"
                        :name="icon"
                        width="33.8"
                        height="32.8"
                        original
                        translate/>
                </div>
            </div>
            <div class="mt-30 border-lightBorder border-b">
                <Accordion
                    v-if="model.ingredients"
                    :is-html="true"
                    :title="$translate('Client.ProductDetailsPage.Ingredients')"
                    :content="model.ingredients"
                    :default-open="isPrerender"/>
                <Accordion
                    v-if="model.nutrition"
                    :title="$translate('Client.ProductDetailsPage.Nutrition')"
                    :default-open="isPrerender">
                    <NutritionTable :nutrition="model.nutrition"/>
                </Accordion>
                <Accordion
                    v-if="model.productInformation"
                    :is-html="true"
                    :title="$translate('Client.ProductDetailsPage.ProductInformation')"
                    :content="model.productInformation"
                    :default-open="isPrerender"/>
            </div>
        </div>
    </div>
    <div v-else class="h-640 o-container md:grid-cols-34 md:p-50 relative grid grid-cols-8 rounded-lg bg-white p-20 sm:grid-cols-12">
        <div class="absolute inset-0 flex items-center justify-center">
            <Spinner/>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ProductDetailsViewModel } from '@/api/commerce';
import Button from '@/project/components/button/Button.vue';
import Accordion from '@/project/components/accordion/Accordion.vue';
import ProductDetailsGallery from '@/project/pages/product-details/ProductDetailsGallery.vue';
import AddToBasket from '@/project/basket/add-to-basket/AddToBasket.vue';
import Spinner from '@/project/components/spinner/Spinner.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import Splash from '@/project/products/Splash/Splash.vue';
import { setMetaData } from '@/core/browser/metadata/metaData';
import { useLineItem } from '@/project/apis/commerce/basketApi';
import NutritionTable from './NutritionTable.vue';
import { isUserAgentPrerender } from '@/core/browser/bot.service';
import { trackAddToBasket } from '@/project/tracking/tracking.service';
import { useStock } from '@/project/products/useStock';
import { ref, watch } from 'vue';
import { useAgeLimit } from '@/project/products/useAgeLimit';

const props = defineProps<{
    model: ProductDetailsViewModel,
}>();

const isPrerender = isUserAgentPrerender();

setMetaData({
    navigationTitle: props.model.displayName,
    url: props.model.url,
    index: true,
});

const { lineItem, increment, decrement, manualChange } = useLineItem(props.model.sku);

const { fewLeftInStock, soldOut, stockLimitHit } = useStock(props.model, lineItem);

const stockExceeded = ref(false);

const canAddToBasket = useAgeLimit(props.model.ageLimit);

watch(lineItem, (li) => {
    if (!props.model.alwaysInStock && li?.availableInStock) {
        stockExceeded.value = li.availableInStock - li.quantity < 0;
    }
}, { immediate: true });

function onIncrement() {
    increment();
    trackAddToBasket({
        product: props.model,
        increasedQuantity: true,
        lineItemMode: false,
        quantity: 1,
    });
}

function onDecrement() {
    decrement();
    trackAddToBasket({
        product: props.model,
        increasedQuantity: false,
        lineItemMode: false,
        quantity: 1,
    });
}

let prevQuantity = lineItem.value?.quantity ?? 0;
function onManualChange(amount: number) {
    manualChange(amount);

    const isAdding = prevQuantity < amount;
    trackAddToBasket({
        product: props.model,
        increasedQuantity: isAdding,
        lineItemMode: false,
        quantity: isAdding ? amount - prevQuantity : prevQuantity - amount,
    });
    prevQuantity = amount;
}

const productTeasers = [
    props.model.brand,
    props.model.weight,
    props.model.productTeaser,
].filter(elem => elem).join(', ');
</script>

<style scoped>

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.1s ease;
}

.component-fade-enter-from,
.component-fade-leave-to {
  opacity: 0;
}

.nutrition-item {
    @apply flex space-x-40;
}
</style>
