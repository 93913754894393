<template>
    <div>
        <Disclosure v-slot="{ open }" :default-open="defaultOpen">
            <div class="overflow-hidden duration-500 ease-in-out transition-maxHeight"
                 :class="open ? 'max-h-2000' : 'max-h-53'">
                <DisclosureButton class="relative my-2 w-full bg-white z-2" @click="onOpen(!open)">
                    <div
                        class="flex justify-between w-full border-t duration-100 border-lightBorder hover:bg-backgroundContrast"
                        :class="compact ? 'py-15 pl-30 pr-20': 'px-4 py-15'">
                        <span
                            class="font-semibold"
                            :class="compact ? 'text-14' : 'text-16'">{{ title }}</span>
                        <div class="flex justify-center items-center rounded w-22 h-22 bg-background">
                            <CIcon v-if="open"
                                   name="accordion-minus"
                                   width="11"
                                   height="2"
                                   color="#000000"/>
                            <CIcon v-if="!open"
                                   name="accordion-plus"
                                   width="11"
                                   height="11"
                                   color="#000000"/>
                        </div>
                    </div>
                </DisclosureButton>
                <DisclosurePanel class="overflow-hidden relative text-gray-500 z-1">
                    <p v-if="isHtml" class="px-4 pt-10 leading-8 prose text-14" v-html="content"></p>
                    <p v-if="content && !isHtml" class="px-4 leading-8 text-14">
                        {{ content }}
                    </p>
                    <div :class="compact ? 'py-10 pl-30 pr-20': 'px-4 py-15'">
                        <slot/>
                    </div>
                </DisclosurePanel>
            </div>
        </Disclosure>
    </div>
</template>

<script setup lang="ts">
import { trackOverlay } from '@/project/tracking/tracking.service';
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from '@headlessui/vue';

const props = defineProps<{
    title: string,
    content?: string,
    isHtml?: boolean,
    compact?: boolean,
    defaultOpen?: boolean,
}>();

function onOpen(opening: boolean) {
    if (opening) {
        trackOverlay(props.title);
    }
}
</script>
