<template>
    <TextWrapper class="pb-40 -mb-20 sm:mb-0">
        <div class="flex items-center">
            <div class="p-8 rounded-full bg-primary">
                <CIcon name="location" width="24" height="24" class="text-white"/>
            </div>
            <h2 class="ml-20 font-bold text-18">
                {{ title || $translate('Client.CheckoutPage.Delivery.ChooseDeliveryOptions') }}
            </h2>
        </div>
        <div class="px-0 mt-10" :class="{'md:px-60': itemsDirection === 'row'}">
            <div class="flex items-center mb-20 ml-60 md:ml-0">
                <span id="zip-label">{{ $translate('Client.CheckoutPage.Delivery.EnterZip') }}</span>
                <div class="flex flex-wrap items-center">
                    <InputText ref="zipField"
                               name="zip"
                               aria-labelledby="zip-label"
                               class="w-64 ml-15"
                               autofocus
                               autocomplete="off"
                               @input="debouncedOnChange"/>
                    <span class="mt-10 lg:mt-0 text-black ml-15">{{ zipCodeInfo?.areaName }}</span>
                </div>
            </div>
            <div v-if="status === BasketValidityViewModel.HasInvalidLineItem">
                <div class="flex items-start p-8 space-x-10 rounded-lg my-15 bg-info">
                    <div class="mt-[3px]">
                        <CIcon name="info" width="16" height="16"/>
                    </div>
                    <div>
                        {{ $translate('Client.DeliveryStatus.ProductsNotInAssortment') }}
                    </div>
                </div>
                <div class="flex items-center"
                     :class="itemsDirection === 'col' ? 'flex-col' : 'flex-wrap sm:flex-nowrap'">
                    <div class="w-full" :class="{'sm:max-w-[200px]': itemsDirection === 'row'}">
                        <DeliveryTypeButton
                            :label="$translate('Client.CheckoutPage.Delivery.UndoZipCodeChange')"
                            icon="undo"
                            :hide-arrow="true"
                            @click="undoZipCodeChange"/>
                    </div>
                    <div class="mx-auto text-center md:mx-30 my-25">
                        {{ $translate('Client.Common.Or') }}
                    </div>
                    <div class="w-full" :class="{'sm:max-w-[200px]': itemsDirection === 'row'}">
                        <DeliveryTypeButton
                            :label="$translate('Client.CheckoutPage.Delivery.FixBasket')"
                            icon="checkout-viewbasket"
                            :original-icon="true"
                            :hide-arrow="true"
                            @click="goToBasket"/>
                    </div>
                </div>
            </div>
            <div v-else-if="availableOptions"
                 class="flex items-center"
                 :class="itemsDirection === 'col' ? 'flex-col' : 'flex-wrap sm:flex-nowrap'">
                <div v-if="availableOptions.includes(TimeSlotTypeViewModel.PickUp)" class="w-full" :class="{'sm:max-w-[200px]': itemsDirection === 'row'}">
                    <DeliveryTypeButton
                        :label="$translate('Client.CheckoutPage.Delivery.PickUpInStoreOption')"
                        icon="store"
                        :active="active === 'store'"
                        @click="openPickupPointChooser"/>
                </div>
                <div v-if="availableOptions.length > 1" class="mx-auto text-center md:mx-30 my-25">
                    {{ $translate('Client.Common.Or') }}
                </div>
                <div v-if="availableOptions.includes(TimeSlotTypeViewModel.HomeDelivery)" class="w-full" :class="{'sm:max-w-[200px]': itemsDirection === 'row'}">
                    <DeliveryTypeButton
                        :label="$translate('Client.CheckoutPage.Delivery.HomeDeliveryOption')"
                        icon="delivery"
                        :active="active === 'home'"
                        @click="openHomeDeliveryTimeChooser"/>
                </div>
                <div v-if="loading" class="flex justify-center items-center py-[80px]">
                    <Spinner v-if="loading"/>
                </div>
                <div v-if="availableOptions.length === 0 && hasTriedValidZip" class="py-4 px-8 rounded-lg bg-info">
                    {{ $translate('Client.CheckoutPage.Delivery.OptionsNoResults') }}
                </div>
            </div>
            <div v-if="hasError" class="py-4 px-8 rounded-lg bg-error">
                {{ $translate('Client.CheckoutPage.Delivery.OptionsError') }}
            </div>
        </div>
    </TextWrapper>
</template>

<script setup lang="ts">
import { isValidZip } from '@/core/forms/vee-validate.config';
import { DialogClosedKey } from '@/project/dialog/dialog';
import InputText from '@/project/forms/InputText.vue';
import DeliveryTypeButton from '@/project/pages/checkout-page/delivery/DeliveryTypeButton.vue';
import { getAvailableDeliveryTypes, getZipCodeInfo } from '@/project/apis/commerce/timeSlotsApi';
import { debounce } from 'lodash-es';
import { Ref, ref, watch } from 'vue';
import { TimeSlotTypeViewModel, ZipCodeViewModel, BasketValidityViewModel } from '@/api/commerce';
import Spinner from '@/project/components/spinner/Spinner.vue';
import { useTimeoutFn } from '@vueuse/core';
import bus from '@/core/bus';
import TextWrapper from '@/project/components/text-wrapper/TextWrapper.vue';
import { deleteBasketTimeslot, getCheckoutBasket, setDeliveryZipCode } from '@/project/apis/commerce/checkoutApi';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import router from '@/router';

interface Props {
    title?: string
    itemsDirection?: 'row' | 'col'
}

withDefaults(defineProps<Props>(), {
    title: '',
    itemsDirection: 'row',
});

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'onValidZipWithResults', zipCode: number): void
  (e: 'selectPickUp'): void
  (e: 'selectHomeDelivery'): void
}>();

const active = ref('');
const availableOptions = ref<TimeSlotTypeViewModel[] | undefined>(undefined);
const loading = ref(false);
const hasError = ref(false);
const hasTriedValidZip = ref(false);
const zipCode = ref('');
const zipCodeInfo: Ref<ZipCodeViewModel | null> = ref(null);
const zipField = ref<any>(null);

const { status, basket } = getCheckoutBasket();

window.scrollTo(0, 0);

function onChange(e: any) {
    if (isValidZip(e.target.value)) {
        hasTriedValidZip.value = true;
    } else {
        hasTriedValidZip.value = false;
    }
    const { stop } = useTimeoutFn(() => {
        loading.value = true;
    }, 300);
    getAvailableDeliveryTypes(Number(e.target.value)).then(res => {
        stop();
        loading.value = false;
        if (res.length > 0 || (res.length === 0 && hasTriedValidZip.value)) {
            availableOptions.value = res;
        } else {
            availableOptions.value = undefined;
        }
        hasError.value = false;
        zipCode.value = e.target.value;

        if (hasTriedValidZip.value && availableOptions.value?.length) {
            emit('onValidZipWithResults', Number(e.target.value));
        }
    }).catch(() => {
        loading.value = false;
        hasError.value = true;
    });
}

function openPickupPointChooser() {
    active.value = 'store';
    emit('selectPickUp');
}

function openHomeDeliveryTimeChooser() {
    active.value = 'home';
    emit('selectHomeDelivery');
}

bus.on(DialogClosedKey, () => {
    active.value = '';
});

watch(zipCode, (zip) => {
    getZipCodeInfo(Number(zip)).then(res => {
        zipCodeInfo.value = res;
    });
});

function undoZipCodeChange() {
    if (basket.value?.invoiceAddress?.zipCode) {
        if (zipField.value) {
            zipField.value.inputValue = basket.value?.invoiceAddress?.zipCode.toString();
        }
        setDeliveryZipCode(basket.value?.invoiceAddress?.zipCode);
    }
}

function goToBasket() {
    deleteBasketTimeslot();
    const { basketUrl } = getPageUrls();
    router.push(basketUrl.value);
}

const debouncedOnChange = debounce(onChange, 300);
</script>
