declare global {
    interface Window {
        appData: {
            cmsHost: string,
            commerceApiHost: string,
            lobycoAuthentication: {
                authority: string,
                clientId: string,
                redirectUri: string,
                scope: string,
                audience: string,
            },
            lobycoMemberProfileUrl: string,
            lobycoPaymentMethodUrl: string,
            recaptcha: {
                siteKey: string,
            }
        }
    }
}

export function isDevMode(): boolean {
    return !isProdMode();
}

export function isProdMode(): boolean {
    // return process.env.NODE_ENV === 'production';
    return import.meta.env.PROD;
}

export const cmsHost = window.appData.cmsHost;
export const commerceApiHost = window.appData.commerceApiHost;
export const lobycoAuthentication = window.appData.lobycoAuthentication;
export const lobycoMemberProfileUrl = window.appData.lobycoMemberProfileUrl;
export const lobycoPaymentMethodUrl = window.appData.lobycoPaymentMethodUrl;
export const recaptchaSiteKey = window.appData.recaptcha.siteKey;
