import { ref, computed, ComputedRef, readonly, Ref } from 'vue';
import { CategoryTreeViewModel } from '@/api/commerce';
import { QueryKeys } from '@/project/browser/query';
import { useRoute } from 'vue-router';

const categories = ref<CategoryTreeViewModel[]>([]);
const offerMode = ref(false);
const categoriesReady = ref(false);

function setCategories(_categories: CategoryTreeViewModel[]): void {
    categories.value = _categories;
    if (_categories.length > 0) {
        categoriesReady.value = true;
    } else {
        categoriesReady.value = false;
    }
}

function setOfferMode(_offerMode: boolean): void {
    offerMode.value = _offerMode;
}

export function useCategories(): {
    ancestors: ComputedRef<CategoryTreeViewModel[]>,
    current: ComputedRef<CategoryTreeViewModel | undefined>,
    children: ComputedRef<CategoryTreeViewModel[]>,
    siblings: ComputedRef<CategoryTreeViewModel[]>,
    offerMode: Ref<boolean>,
    categoriesReady: Ref<boolean>,
    setCategories: typeof setCategories,
    setOfferMode: typeof setOfferMode,
    getCategoryUrl: typeof getCategoryUrl
    } {
    const current = computed<CategoryTreeViewModel | undefined>(() => {
        let result: CategoryTreeViewModel | undefined;

        let level = categories.value;
        while (!result && level.length) {
            result = level.find(v => v.selected);
            level = level.find(v => v.children)?.children || [];
        }

        return result;
    });

    const ancestors = computed<CategoryTreeViewModel[]>(() => {
        if (!current.value || categories.value.length > 1) {
            return [];
        }

        const result: CategoryTreeViewModel[] = [];
        let level = categories.value;
        while (level.length === 1 && !level[0].selected) {
            result.push(level[0]);
            level = level[0].children;
        }

        return result;
    });

    const children = computed<CategoryTreeViewModel[]>(() => {
        if (!current.value) {
            return categories.value;
        }
        return current.value?.children || [];
    });

    const siblings = computed<CategoryTreeViewModel[]>(() => {
        let result: CategoryTreeViewModel | undefined;

        let level = categories.value;
        while (!result && level.length) {
            result = level.find(v => v.selected);

            if (!result) {
                level = level.find(v => v.children)?.children || [];
            }
        }

        if (!result) {
            return [];
        }

        return level;
    });

    const route = useRoute();

    const getCategoryUrl = (category: CategoryTreeViewModel, isOffersMode: boolean) => {
        if (isOffersMode) {
            const params = new URLSearchParams(route.query as Record<string, string>);

            if (category.categoryId) {
                params.set(QueryKeys.CATEGORY, category.categoryId);
            } else {
                params.delete(QueryKeys.CATEGORY);
            }

            return `?${params.toString()}`;
        } else {
            return category.url;
        }
    };

    return {
        ancestors,
        current,
        children,
        siblings,
        offerMode: readonly(offerMode),
        categoriesReady: readonly(categoriesReady),
        setCategories,
        setOfferMode,
        getCategoryUrl,
    };
}
