<template>
    <transition-group name="list-complete">
        <div v-for="(category, index) in categories" :key="category.categoryName" class="list-complete-item">
            <BasketCategory :name="category.categoryName" :class="{'rounded-t-lg': index === 0}"/>
            <transition-group name="list-complete">
                <div v-for="(lineItem, itemIndex) in category.lineItems" :key="lineItem.sku" class="border-b border-background list-complete-item">
                    <BasketLineItem :model="lineItem" :position="itemIndex"/>
                </div>
            </transition-group>
        </div>
    </transition-group>
</template>

<script setup lang="ts">
import BasketLineItem from '@/project/basket/basket-line-item/BasketLineItem.vue';
import { useBasket } from '@/project/apis/commerce/basketApi';
import BasketCategory from '@/project/basket/basket-category/BasketCategory.vue';

const { categories } = useBasket();

</script>

<style scoped>
.list-complete-move {
  transition: transform 0.8s ease;
}

.list-complete-item {
  transition: all 0.3s ease;
}

.list-complete-enter-from,
.list-complete-leave-to {
  opacity: 0;
  transform: translateX(30%);
}

.list-complete-leave-active {
  position: absolute;
}
</style>
