<template>
    <div class="flex justify-between items-start px-20 rounded-lg bg-backgroundContrastBlue p-15">
        <template v-if="renderInMobileMenu">
            <div class="flex flex-col justify-start w-full">
                <div class="flex justify-between">
                    <div>
                        <div>
                            <ChainLogo :chain="model.chain"/>
                        </div>
                        <div class="mt-10">
                            <div>
                                <div v-if="model.name">
                                    {{ model.name }}
                                </div>
                                <div>
                                    {{ model.address.street }} {{ model.address.houseNumber }}
                                </div>
                                <div>
                                    {{ model.address.zipCode }} {{ model.address.city }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <slot></slot>
                    </div>
                </div>
                <OpeningHoursAccordion :program="model.program"/>
            </div>
        </template>
        <template v-else>
            <div class="flex flex-row justify-start items-start">
                <div class="flex flex-col items-center sm:mt-4">
                    <ChainLogo :chain="model.chain"/>
                </div>
                <div class="mt-0 mr-20 ml-20">
                    <div class="flex flex-wrap">
                        <div v-if="model.name">
                            {{ model.name }}<span>,&nbsp;</span>
                        </div>
                        <div>
                            {{ model.address.street }} {{ model.address.houseNumber }}<span>,&nbsp;</span>
                        </div>
                        <div>
                            {{ model.address.zipCode }} {{ model.address.city }}
                        </div>
                    </div>
                    <OpeningHoursAccordion :program="model.program" @expand-opening-hours="emit('expand-opening-hours')"/>
                </div>
            </div>
            <div>
                <slot></slot>
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { StoreViewModel } from '@/api/commerce';
import OpeningHoursAccordion from '../../pages/checkout-page/delivery/OpeningHoursAccordion.vue';
import ChainLogo from '@/project/pages/checkout-page/delivery/ChainLogo.vue';
import { useMenus } from '@/project/navigation/menus.composable';

defineProps<{
    model: StoreViewModel
}>();

const emit = defineEmits(['expand-opening-hours']);

const { renderInMobileMenu } = useMenus();
</script>
