<template>
    <div class="flex">
        <div class="flex items-start">
            <CIcon name="checkcircle" width="20" height="20" class="text-bonus"/>
        </div>
        <div class="ml-10">
            <div class="font-bold">
                {{ $translate('Client.CheckoutPage.Delivery.Zip') }}
            </div>
            <div>
                <span>{{ zipCodeInfo?.zipCode }} {{ zipCodeInfo?.areaName }}</span>
                <span class="ml-10 cursor-pointer text-link" tabindex="0" @click="emit('changeZip')">
                    {{ $translate('Client.CheckoutPage.Delivery.ChangeZip') }}
                </span>
            </div>
        </div>
    </div>
    <div class="mt-20 mb-15">
        <div class="flex">
            <CIcon name="store" width="20" height="20"/>
            <span class="ml-10 font-bold">{{ $translate('Client.CheckoutPage.Delivery.PickUpPointChooserAction') }}</span>
        </div>
    </div>
    <div class="overflow-y-auto pb-10 space-y-10">
        <PickupPoint v-for="item in stores"
                     :key="item.id"
                     :model="item">
            <Button label="Choose"
                    :primary="false"
                    size="small"
                    @click="chooseStore(item)"/>
        </PickupPoint>
    </div>
</template>

<script setup lang="ts">
import { StoreViewModel, ZipCodeViewModel } from '@/api/commerce';
import PickupPoint from '@/project/components/store/Store.vue';
import { getPickUpPoints, getZipCodeInfo } from '@/project/apis/commerce/timeSlotsApi';
import { ref, Ref } from 'vue';
import Button from '@/project/components/button/Button.vue';

const props = defineProps<{
    zipCode: number
}>();

// eslint-disable-next-line func-call-spacing
const emit = defineEmits<{
  (e: 'changeZip'): void
  (e: 'selectStore', store: StoreViewModel): void
}>();

const stores: Ref<StoreViewModel[]> = ref([]);
const zipCodeInfo: Ref<ZipCodeViewModel | null> = ref(null);

getPickUpPoints(props.zipCode).then(res => {
    stores.value = res;
});

function chooseStore(store: StoreViewModel) {
    emit('selectStore', store);
}

getZipCodeInfo(props.zipCode).then(res => {
    zipCodeInfo.value = res;
});
</script>
