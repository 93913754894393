/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { ProductSearchResultViewModelClientResponse } from '../models';
// @ts-ignore
import { SearchRequest } from '../models';
// @ts-ignore
import { SuggestViewModelClientResponse } from '../models';
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Endpoint for searches
         * @param {string} [apiVersion] 
         * @param {SearchRequest} [searchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsSearchPost: async (apiVersion?: string, searchRequest?: SearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/products/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint for getting search suggestion keywords for free-text searches.
         * @param {string} [term] The search term.
         * @param {number} [size] Number of suggestions to return.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsSearchSuggestGet: async (term?: string, size?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/products/search/suggest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (term !== undefined) {
                localVarQueryParameter['term'] = term;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SearchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Endpoint for searches
         * @param {string} [apiVersion] 
         * @param {SearchRequest} [searchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCatalogProductsSearchPost(apiVersion?: string, searchRequest?: SearchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductSearchResultViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCatalogProductsSearchPost(apiVersion, searchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint for getting search suggestion keywords for free-text searches.
         * @param {string} [term] The search term.
         * @param {number} [size] Number of suggestions to return.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCatalogProductsSearchSuggestGet(term?: string, size?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SuggestViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCatalogProductsSearchSuggestGet(term, size, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SearchApiFp(configuration)
    return {
        /**
         * 
         * @summary Endpoint for searches
         * @param {string} [apiVersion] 
         * @param {SearchRequest} [searchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsSearchPost(apiVersion?: string, searchRequest?: SearchRequest, options?: any): AxiosPromise<ProductSearchResultViewModelClientResponse> {
            return localVarFp.apiCatalogProductsSearchPost(apiVersion, searchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint for getting search suggestion keywords for free-text searches.
         * @param {string} [term] The search term.
         * @param {number} [size] Number of suggestions to return.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsSearchSuggestGet(term?: string, size?: number, apiVersion?: string, options?: any): AxiosPromise<SuggestViewModelClientResponse> {
            return localVarFp.apiCatalogProductsSearchSuggestGet(term, size, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SearchApi - interface
 * @export
 * @interface SearchApi
 */
export interface SearchApiInterface {
    /**
     * 
     * @summary Endpoint for searches
     * @param {string} [apiVersion] 
     * @param {SearchRequest} [searchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    apiCatalogProductsSearchPost(apiVersion?: string, searchRequest?: SearchRequest, options?: AxiosRequestConfig): AxiosPromise<ProductSearchResultViewModelClientResponse>;

    /**
     * 
     * @summary Endpoint for getting search suggestion keywords for free-text searches.
     * @param {string} [term] The search term.
     * @param {number} [size] Number of suggestions to return.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApiInterface
     */
    apiCatalogProductsSearchSuggestGet(term?: string, size?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<SuggestViewModelClientResponse>;

}

/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI implements SearchApiInterface {
    /**
     * 
     * @summary Endpoint for searches
     * @param {string} [apiVersion] 
     * @param {SearchRequest} [searchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiCatalogProductsSearchPost(apiVersion?: string, searchRequest?: SearchRequest, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiCatalogProductsSearchPost(apiVersion, searchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint for getting search suggestion keywords for free-text searches.
     * @param {string} [term] The search term.
     * @param {number} [size] Number of suggestions to return.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    public apiCatalogProductsSearchSuggestGet(term?: string, size?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return SearchApiFp(this.configuration).apiCatalogProductsSearchSuggestGet(term, size, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
