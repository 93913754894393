<template>
    <div class="bg-white">
        <div class="o-container flex items-center justify-between overflow-x-hidden px-20 py-10">
            <div class="flex items-center space-x-20">
                <div class="cursor-pointer" @click="emit('open')">
                    <CIcon name="burger-menu" width="22" height="22"/>
                </div>
            </div>
            <div class="flex items-center justify-center space-x-40">
                <RouterLink to="/">
                    <CIcon name="netto-logo" width="64" height="22" color="#006BB6"/>
                </RouterLink>
            </div>
            <div class="flex w-auto items-center space-x-20">
                <!-- <div class="hidden md:inline xl:hidden">
                    <button class="flex cursor-pointer items-center" @click="openLoginOrMyAccount">
                        <CIcon name="myaccount" width="20" height="20"/>
                    </button>
                </div> -->
                <MascotWithBonus/>
                <div class="h-30 items-center space-x-40">
                    <BasketButtonMobile/>
                </div>
            </div>
        </div>
        <SearchFieldMobile @open-search="toggleSearch"/>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import BasketButtonMobile from '@/project/basket/basket-button-mobile/BasketButtonMobile.vue';
import SearchFieldMobile from '@/project/search/SearchFieldMobile.vue';
import { isAuthenticated, refreshUserState, login } from '@/project/authentication/authentication';
import { useTimeoutFn } from '@vueuse/core';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import router from '@/router';
import MascotWithBonus from '@/project/navigation/main-menu/MascotWithBonus.vue';

const emit = defineEmits(['open']);

const openSearch = ref(false);

const show = ref(true);

// We need a small timeout before show basket button again, to avoid some unwanted height adjustment when opening and closing search.
// This is not the best solution, but we need more orchestration to make a good solution.
watch(openSearch, open => {
    if (open) {
        show.value = false;
    } else {
        useTimeoutFn(() => {
            show.value = true;
        }, 300);
    }
});

const toggleSearch = (open:boolean) => {
    openSearch.value = open;
};

const { myAccountUrl } = getPageUrls();

function openLoginOrMyAccount() {
    if (isAuthenticated.value) {
        router.push(myAccountUrl.value);
    } else {
        login().then(async() => {
            await refreshUserState();
        });
    }
}

</script>
