<template>
    <div class="overflow-hidden relative p-20 sm:p-40 md:p-20 lg:p-40 mx-10 sm:mx-0 bg-white rounded-lg block-spacing transition-background-color" :class="{'bg-black': started}">
        <div class="absolute inset-0 transition-opacity duration-500 cursor-pointer z-1" :class="{'opacity-0 pointer-events-none': started}" @click="playVideo()">
            <CIcon name="play"
                   width="64"
                   height="64"
                   class="absolute left-[50%] top-[50%] z-1 -translate-x-[50%] -translate-y-[50%]"
                   original="true"/>
            <h4 v-if="model.headline" class="absolute bottom-0 px-20 pb-20 w-full font-bold text-center text-white text-22 sm:text-40 md:text-30 lg:text-34 leading-[3rem] lg:leading-36 z-2">
                {{ model.headline }}
            </h4>
            <div v-if="model.headline" class="absolute bottom-0 left-0 w-full bg-gradient-to-b from-transparent to-black opacity-[0.25] h-[50%] z-1"></div>
            <ResponsiveImage :aspect-ratio="16 / 9" :image-url="model.video.url" class="opacity-0 !h-full" :mode="'crop'"/>
        </div>

        <VimeoPlayer v-if="started"
                     class="inset-0 z-0 rounded-lg !absolute"
                     :auto-play="true"
                     :video-url="model.vimeoUrl"
                     :loop="false"
                     :do-not-track="true"
                     :controls="true"
                     :background="false"
                     :aspect-ratio="16 / 9"/>
        <div class="pt-[53%] transition-background-color" :class="{'bg-black': started}"></div>
    </div>
</template>

<script lang="ts" setup>
import { VideoBlockModel } from '@/api/cms';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import VimeoPlayer from '@/core/media/VimeoPlayer.vue';
import { ref } from 'vue';

defineProps<{
    model: VideoBlockModel
}>();

const started = ref(false);

const playVideo = () => {
    started.value = true;
};

</script>
