<template>
    <DrawerDialog v-slot="{close}"
                  use-slide-animation>
        <div class="relative sm:h-full md:h-screen bg-white product-filters-dialog-watermark">
            <Button class="absolute top-2 right-12 bg-transparent hover:bg-transparent cursor-pointer" transparent @click="close">
                <CIcon name="close" width="12" height="12" class="text-black"/>
            </Button>
            <ProductFilters :model="model" :bonus="bonus" @toggle-bonus="onlyBonusRequest = onlyBonusRequest === 'true' ? 'false' : 'true'"/>
            <div class="flex sticky sm:relative bottom-0 justify-center p-10 bg-white z-2">
                <Button :label="`${$translate('Client.ProductFilters.ShowResults')} (${numberOfResults})`" size="medium" @click="close"/>
            </div>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import { FacetValueViewModel, FacetViewModel } from '@/api/commerce';
import Button from '@/project/components/button/Button.vue';
import ProductFilters from '@/project/products/product-filters/ProductFilters.vue';
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import { trackOverlay } from '@/project/tracking/tracking.service';
import { useRouteQuery } from '@/core/browser/query/useQueryParams';
import { QueryKeys } from '@/project/browser/query';

defineProps<{
    model: FacetViewModel[],
    bonus: FacetValueViewModel | undefined,
    numberOfResults: number,
}>();

trackOverlay('Product filters');
const onlyBonusRequest = useRouteQuery(QueryKeys.BONUS);

</script>

<style scoped>
.product-filters-dialog-watermark {
    @apply bg-no-repeat bg-80% sm:bg-40% md:bg-35% bg-watermark min-h-full bg-left-bottom;
}
</style>
