<template>
    <DeliveryLayout
        :terms-and-conditions-text="termsAndConditionsText"
        :is-submitting="submitting"
        @continue-to-payment="continueToPayment">
        <!-- Delivery Time -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.DeliveryTime" icon-name="clock">
            <div v-if="basket?.timeSlot" class="font-bold sm:flex">
                <div>
                    <span class="capitalize">{{ getWeekday(basket?.timeSlot.from) }}</span>, {{ new Date(basket?.timeSlot.from).getDate() }}. {{ getMonth(basket?.timeSlot.from) }}
                </div>
                <div>
                    <span class="hidden sm:inline">&nbsp;</span>{{ $translate('Client.Common.Between') }} {{ getTime(basket.timeSlot.from) }} - {{ getTime(basket.timeSlot.to) }}
                </div>
            </div>
            <Button
                :label="$translate('Client.CheckoutPage.Delivery.ChangeDateOrTime')"
                class="mx-[-24px]"
                transparent
                @click="changeDateOrTime"/>
            <div v-if="statusMessage === BasketValidityViewModel.TimeSlotLeadTimePassed" class="bg-warning rounded-lg p-8" @click="changeDateOrTime">
                {{ $translate('Client.CheckoutPage.Delivery.ChangeDateOrTimeLabel') }}
            </div>
        </DeliverySection>

        <!-- Address -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.HomeDeliveryOption" icon-name="delivery">
            <div v-if="!editDeliveryAddress && validDeliveryAddress">
                <div v-if="address">
                    <div class="space-y-4">
                        <div class="font-bold">
                            {{ address.firstName }} {{ address.lastName }}
                        </div>
                        <div>{{ address.addressLine1 }}</div>
                        <div>{{ address.addressLine2 }}</div>
                        <div>{{ address.zipCode }} {{ address.city }}</div>
                        <div v-if="address.mobile">
                            {{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}: {{ address.mobile }}
                        </div>
                    </div>
                </div>
                <div>
                    <div
                        class="mt-10 flex cursor-pointer items-center"
                        @click="toggleNoteDriver">
                        <InputCheckbox
                            :label="$translate('Client.CheckoutPage.Delivery.AddNoteToDriver')"
                            name="noteToDriver"
                            class="cursor-pointer"
                            :checked="addNoteDriver"/>
                    </div>
                    <div v-if="addNoteDriver" style="position: relative">
                        <Textarea
                            v-model="noteToDriver"
                            name="noteToDriver"
                            class="mt-10 resize-none"
                            maxlength="50"
                            :placeholder="$translate('Client.CheckoutPage.Delivery.NoteToDriverPlaceholder')"/>
                        <div style="position: absolute; bottom: 0.15rem; right: 0.15rem;">
                            {{ noteToDriver.length }}/50
                        </div>
                    </div>
                </div>
                <div class="mt-10 sm:flex sm:items-center">
                    <Button
                        :label="$translate('Client.CheckoutPage.Delivery.ChangeDeliveryAddress')"
                        class="mx-[-24px]"
                        transparent
                        @click="changeDeliveryAddress"/>
                    <span class="block sm:mx-10">{{ $translate('Client.Common.Or') }}</span>
                    <Button
                        :label="$translate('Client.CheckoutPage.Delivery.ChangeDeliveryMethod')"
                        class="mx-[-24px]"
                        transparent
                        @click="changeDeliveryMethod"/>
                </div>
            </div>
            <div v-else>
                <DeliveryAddressForm :valid-delivery-address="validDeliveryAddress" @cancel="editDeliveryAddress = false"/>
            </div>
        </DeliverySection>

        <!-- Replacement products -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.ReplacementProducts" icon-name="basket-replacements">
            <div class="mb-10">
                <div
                    class="mt-10 flex cursor-pointer items-center"
                    @click="toggleNotePicker">
                    <InputCheckbox
                        :label="$translate('Client.CheckoutPage.Delivery.AddNoteToPicker')"
                        name="noteToPicker"
                        class="cursor-pointer"
                        :checked="addNoteToPicker"/>
                </div>
                <div v-if="addNoteToPicker" style="position: relative">
                    <Textarea
                        v-model="noteToPicker"
                        name="noteToPicker"
                        class="mt-10"
                        maxlength="50"
                        :placeholder="$translate('Client.CheckoutPage.Delivery.NoteToPickerPlaceholder')"/>
                    <div style="position: absolute; bottom: 0.15rem; right: 0.15rem;">
                        {{ noteToPicker.length }}/50
                    </div>
                </div>
            </div>
            <div>
                {{ $translate('Client.CheckoutPage.Delivery.ReplacementProductsDescription') }}
            </div>
            <div class="mt-15 space-y-10">
                <div
                    @click="selectedOption = ProductReplacementOptions.DoNotReplaceAnyProducts">
                    <InputRadio
                        name="replacementOption"
                        :label="$translate('Client.CheckoutPage.Delivery.DoNotReplaceProducts')"
                        :value="ProductReplacementOptions.DoNotReplaceAnyProducts"
                        :checked="selectedOption === ProductReplacementOptions.DoNotReplaceAnyProducts"/>
                </div>
                <div
                    @click="selectedOption = ProductReplacementOptions.ReplaceWithSimilarProducts">
                    <InputRadio
                        name="replacementOption"
                        :label="$translate('Client.CheckoutPage.Delivery.ReplaceWithSimilarProducts')"
                        :value="ProductReplacementOptions.ReplaceWithSimilarProducts"
                        :checked="selectedOption === ProductReplacementOptions.ReplaceWithSimilarProducts"/>
                </div>
                <div
                    @click="selectedOption = ProductReplacementOptions.CallBeforeReplacingProducts">
                    <InputRadio
                        name="replacementOption"
                        :label="$translate('Client.CheckoutPage.Delivery.CallBeforeReplacingProducts')"
                        :value="ProductReplacementOptions.CallBeforeReplacingProducts"
                        :checked="selectedOption === ProductReplacementOptions.CallBeforeReplacingProducts"/>
                </div>
            </div>
        </DeliverySection>
    </DeliveryLayout>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { getCheckoutBasket, redirectToPayment, setOrderProcessingInfo } from '@/project/apis/commerce/checkoutApi';
import { useDeliveryState, DeliveryPageStates } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { getWeekday, getMonth, getTime, getDate } from '@/project/utils/datetime';
import openDialog from '@/project/dialog/dialog';
import TimeSlotDrawer from '@/project/pages/checkout-page/delivery/TimeSlotDrawer.vue';
import InputCheckbox from '@/project/forms/InputCheckbox.vue';
import { computed, Ref, ref } from 'vue';
import Textarea from '@/project/forms/Textarea.vue';
import InputRadio from '@/project/forms/InputRadio.vue';
import DeliveryAddressForm from './DeliveryAddressForm.vue';
import { ProductReplacementOptions, BasketValidityViewModel } from '@/api/commerce';
import DeliverySection from '@/project/pages/checkout-page/delivery/DeliverySection.vue';
import DeliveryLayout from '@/project/pages/checkout-page/delivery/DeliveryLayout.vue';
import { trackCheckoutOption } from '@/project/tracking/tracking.service';

defineProps<{
    termsAndConditionsText: string
}>();

const { basket } = getCheckoutBasket();
const { deliveryState } = useDeliveryState();

const addNoteDriver = ref(false);
const noteToDriver = ref('');
const addNoteToPicker = ref(false);
const noteToPicker = ref('');
const selectedOption: Ref<ProductReplacementOptions> = ref(ProductReplacementOptions.DoNotReplaceAnyProducts);
const editDeliveryAddress = ref(false);
const submitting = ref(false);
const statusMessage = computed(() => basket.value?.status);
const address = computed(() => (basket.value?.deliveryAddress || basket.value?.invoiceAddress));
const validDeliveryAddress = computed(() => {
    return statusMessage.value !== BasketValidityViewModel.DeliveryAddressCityMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressFirstNameMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressHouseNumberMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressLastNameMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressStreetMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressZipCodeMissing &&
    statusMessage.value !== BasketValidityViewModel.DeliveryAddressDoesNotMatchSelectedTimeSlot;
});

function changeDateOrTime() {
    openDialog(TimeSlotDrawer, {});
}

function changeDeliveryAddress() {
    editDeliveryAddress.value = true;
}

function changeDeliveryMethod() {
    if (basket.value?.openOrderIds.length) {
        deliveryState.pageState = DeliveryPageStates.ChooseExistingOrder;
    } else {
        deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
    }
}

function toggleNoteDriver() {
    addNoteDriver.value = !addNoteDriver.value;
    noteToDriver.value = '';
}

function toggleNotePicker() {
    addNoteToPicker.value = !addNoteToPicker.value;
    noteToPicker.value = '';
}

async function continueToPayment() {
    submitting.value = true;
    trackCheckoutOption(3, undefined, `Home delivery_${getDate(basket.value?.timeSlot?.from || '')} ${getTime(basket.value?.timeSlot?.from || '')} - ${getTime(basket.value?.timeSlot?.to || '')}_Note_${basket.value?.selectedProductReplacementOption}`);
    await setOrderProcessingInfo(noteToDriver.value || null, noteToPicker.value || null, selectedOption.value);
    await redirectToPayment();
    submitting.value = false;
}
</script>

<style scoped>

:deep(textarea) {
    resize: none;
}

</style>
