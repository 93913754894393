<!-- You might be looking for CategoryListPage.vue or ProductListPage.vue -->
<template>
    <component :is="page" :model="model" :metadata="metadata"/>
</template>
<!-- You might be looking for CategoryListPage.vue or ProductListPage.vue -->

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import CategoryListPage from './CategoryListPage.vue';
import ProductListPage from './ProductListPage.vue';
import { CategoryPageModel, IMetadata } from '@/api/cms';

export default defineComponent({
    name: 'CategoryPage',
    components: {
        ProductListPage,
        CategoryListPage,
    },
    props: {
        model: {
            required: true,
            type: Object as PropType<CategoryPageModel>,
        },
        metadata: {
            required: true,
            type: Object as PropType<IMetadata>,
        },
    },
    setup(props) {
        return {
            page: props.model.showProducts ? ProductListPage : CategoryListPage,
        };
    },

});
</script>
