<template>
    <DrawerDialog
        v-slot="{close}"
        :title="$translate('Client.CheckoutPage.Delivery.PickUpInStoreOption')"
        use-slide-animation
        :drawer-classes="'bg-background'">
        <div class="px-20 w-full sm:w-[564px]">
            <PickupPointChooser :zip-code="deliveryState.zipCode!"
                                @change-zip="changeZip(close)"
                                @select-store="chooseStore"/>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import PickupPointChooser from '@/project/pages/checkout-page/delivery/PickupPointChooser.vue';
import { DeliveryPageStates, useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { StoreViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import openDialog from '@/project/dialog/dialog';
import TimeSlotDrawer from '@/project/pages/checkout-page/delivery/TimeSlotDrawer.vue';

const { deliveryState } = useDeliveryState();

function changeZip(close: () => void) {
    close();
    deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
}

function chooseStore(store: StoreViewModel) {
    deliveryState.store = store;
    deliveryState.type = TimeSlotTypeViewModel.PickUp;
    openDialog(TimeSlotDrawer, {});
}
</script>
