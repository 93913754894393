import bus from '@/core/bus';
import Deferred from '@/core/async/Deferred';
import { Component } from 'vue';
export const OpenDialogKey = 'OpenDialog';
export const CloseDialogKey = 'CloseDialog';
export const DialogClosedKey = 'DialogClosed';

export interface OpenDialogData {
    dialog: Component,
    props: Record<string, any>,
}

export default function openDialog(dialog: Component, props: Record<string, any>): void {
    bus.emit(OpenDialogKey, { dialog, props } as OpenDialogData);
}

export function closeDialog(): void {
    bus.emit(CloseDialogKey);
}

export function openAsyncDialog<T>(dialog: Component, props: Record<string, any>): Promise<T> {
    bus.emit(OpenDialogKey, { dialog, props } as OpenDialogData);
    const deferredResult = new Deferred<T>();
    bus.on(DialogClosedKey, (result: T) => deferredResult.resolve(result));
    return deferredResult.promise;
}
