<template>
    <div class="sticky top-0 bg-white md:h-[116px] shadow-[0_2px_7px_0_rgba(0,0,0,0.06)] h-50 z-menu">
        <div class="grid grid-cols-8 h-full o-container-checkout">
            <div class="flex col-span-3 items-center pl-10 md:pl-0">
                <CheckoutBackButton/>
            </div>
            <div class="flex col-span-2 justify-center items-center">
                <CIcon v-if="isMobile"
                       name="netto-logo"
                       width="64"
                       height="20"
                       color="#006BB6"/>
                <CIcon v-else
                       name="netto-logo"
                       width="103"
                       height="32"
                       color="#006BB6"/>
            </div>
            <div class="flex col-span-3 justify-end items-center pl-30">
                <CIcon name="secure" width="24" height="24" class="mr-10"/>
                <span class="leading-7">{{ $translate('Client.CheckoutPage.SecureCheckout') }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import CheckoutBackButton from './CheckoutBackButton.vue';

const { smaller } = useBreakpoints();

const isMobile = smaller('md');

</script>
