<template>
    <div class="relative">
        <div v-if="removing" class="absolute inset-0 m-10 z-[3] fade-300">
            <div class="flex absolute inset-0 flex-col justify-center text-center z-[4]"
                 @click.prevent="stopRemoval()">
                {{ $translate('Client.Top100.ProductRemoved') }}
                <Button :label="$translate('Client.Common.Undo')"
                        :transparent="true"
                        class="text-blue"/>
            </div>
            <div class="absolute bg-white rounded-lg opacity-95 -inset-[1rem] z-[3]"></div>
        </div>
        <div v-else
             class="absolute -right-10 p-2 rounded-full opacity-80 cursor-pointer -top-[1rem] w-[2.8rem] h-[2.8rem] z-[4] fade-300"
             :class="{'opacity-20 pointer-events-none': removing}"
             @click.prevent="removeProduct()">
            <CIcon name="search-clear"
                   width="20"
                   height="20"
                   :original="true"
                   :title="$translate('Client.Top100.RemoveProduct')"/>
        </div>
        <!-- Slot for removable tile -->
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { removeFromTop100 } from '@/project/apis/commerce/top100Api';
import { useTimeoutFn } from '@vueuse/core';
import Button from '@/project/components/button/Button.vue';

const props = defineProps<{
    sku: string;
}>();

const emit = defineEmits<{(e: 'onRemoved', sku: string): void}>();

const { start: removeProduct, stop: stopRemoval, isPending: removing } = useTimeoutFn(() => {
    removeFromTop100(props.sku).then(() => emit('onRemoved', props.sku));
}, 3000, { immediate: false });
</script>
