<template>
    <DeliveryLayout
        :terms-and-conditions-text="termsAndConditionsText"
        :is-submitting="submitting"
        :is-existing-order="true"
        tracking-prefix="Existing home delivery"
        @continue-to-payment="continueToPayment">
        <!-- Delivery Time -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.DeliveryTime" icon-name="clock">
            <div v-if="deliveryState.existingOrder?.timeSlot" class="font-bold sm:flex">
                <div>
                    <span class="capitalize">{{ getWeekday(deliveryState.existingOrder.timeSlot.from) }}</span>, {{ new Date(deliveryState.existingOrder.timeSlot.from).getDate() }}. {{ getMonth(deliveryState.existingOrder.timeSlot.from) }}
                </div>
                <div>
                    <span class="hidden sm:inline">&nbsp;</span>{{ $translate('Client.Common.Between') }} {{ getTime(deliveryState.existingOrder.timeSlot.from) }} - {{ getTime(deliveryState.existingOrder.timeSlot.to) }}
                </div>
            </div>
        </DeliverySection>

        <!-- Address -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.HomeDeliveryOption" icon-name="delivery">
            <div>
                <div v-if="address">
                    <div class="space-y-4">
                        <div class="font-bold">
                            {{ address.firstName }} {{ address.lastName }}
                        </div>
                        <div>{{ address.addressLine1 }}</div>
                        <div>{{ address.addressLine2 }}</div>
                        <div>{{ address.zipCode }} {{ address.city }}</div>
                        <div v-if="address.mobile">
                            {{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}: {{ address.mobile }}
                        </div>
                    </div>
                </div>
                <div v-if="deliveryState.existingOrder?.noteToDriver">
                    <div class="mt-10 flex cursor-pointer items-center">
                        <InputCheckbox
                            :label="$translate('Client.CheckoutPage.Delivery.AddNoteToDriver')"
                            name="noteToDriver"
                            :checked="!!deliveryState.existingOrder?.noteToDriver"/>
                    </div>
                    <Textarea
                        v-model="deliveryState.existingOrder.noteToDriver"
                        name="noteToDriver"
                        class="mt-10"
                        :disabled="true"/>
                </div>
            </div>
        </DeliverySection>

        <!-- Replacement products -->
        <DeliverySection title-label="Client.CheckoutPage.Delivery.ReplacementProducts" icon-name="basket">
            <div>
                {{ $translate('Client.CheckoutPage.Delivery.ReplacementProductsDescription') }}
            </div>
            <div class="mt-15 space-y-10">
                <div>
                    <InputRadio
                        name="replacementOption"
                        :disabled="true"
                        :label="$translate('Client.CheckoutPage.Delivery.DoNotReplaceProducts')"
                        :value="ProductReplacementOptions.DoNotReplaceAnyProducts"
                        :checked="selectedOption === ProductReplacementOptions.DoNotReplaceAnyProducts"/>
                </div>
                <div>
                    <InputRadio
                        name="replacementOption"
                        :disabled="true"
                        :label="$translate('Client.CheckoutPage.Delivery.ReplaceWithSimilarProducts')"
                        :value="ProductReplacementOptions.ReplaceWithSimilarProducts"
                        :checked="selectedOption === ProductReplacementOptions.ReplaceWithSimilarProducts"/>
                </div>
                <div>
                    <InputRadio
                        name="replacementOption"
                        :disabled="true"
                        :label="$translate('Client.CheckoutPage.Delivery.CallBeforeReplacingProducts')"
                        :value="ProductReplacementOptions.CallBeforeReplacingProducts"
                        :checked="selectedOption === ProductReplacementOptions.CallBeforeReplacingProducts"/>
                </div>
            </div>
        </DeliverySection>
    </DeliveryLayout>
</template>

<script setup lang="ts">
import { getCheckoutBasket, redirectToPayment, setOrderProcessingInfo } from '@/project/apis/commerce/checkoutApi';
import { DeliveryPageStates, useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { getWeekday, getMonth, getTime, getDate } from '@/project/utils/datetime';
import InputCheckbox from '@/project/forms/InputCheckbox.vue';
import { computed, Ref, ref, watch } from 'vue';
import Textarea from '@/project/forms/Textarea.vue';
import InputRadio from '@/project/forms/InputRadio.vue';
import { BasketValidityViewModel, ProductReplacementOptions } from '@/api/commerce';
import { trackCheckoutOption } from '@/project/tracking/tracking.service';
import DeliveryLayout from '@/project/pages/checkout-page/delivery/DeliveryLayout.vue';
import DeliverySection from '@/project/pages/checkout-page/delivery/DeliverySection.vue';

defineProps<{
    termsAndConditionsText: string
}>();

const { deliveryState } = useDeliveryState();
const { status } = getCheckoutBasket();

watch(status, (_status) => {
    if (_status === BasketValidityViewModel.TimeSlotLeadTimePassed) {
        deliveryState.pageState = DeliveryPageStates.ChooseExistingOrder;
    }
}, { immediate: true });

const selectedOption: Ref<ProductReplacementOptions> = ref(deliveryState.existingOrder?.selectedProductReplacementOption || ProductReplacementOptions.DoNotReplaceAnyProducts);
const submitting = ref(false);
const address = computed(() => (deliveryState.existingOrder?.deliveryAddress || deliveryState.existingOrder?.invoiceAddress));

async function continueToPayment() {
    if (deliveryState.existingOrder) {
        submitting.value = true;
        trackCheckoutOption(3, undefined, `Existing home delivery_${getDate(deliveryState.existingOrder.timeSlot?.from || '')} ${getTime(deliveryState.existingOrder.timeSlot?.from || '')} - ${getTime(deliveryState.existingOrder.timeSlot?.to || '')}_Note_${deliveryState.existingOrder.selectedProductReplacementOption}`);
        await setOrderProcessingInfo(null, null, selectedOption.value);
        await redirectToPayment();
        submitting.value = false;
    }
}
</script>
