<template>
    <DeliveryTypeChooser @select-pick-up="openPickupPointChooser"
                         @select-home-delivery="openHomeDeliveryTimeChooser"
                         @on-valid-zip-with-results="changeZip"/>
    <TextWrapper v-if="deliveryState.timeSlotId" class="flex items-center sm:mt-40">
        <Button :label="$translate('Client.CheckoutPage.Delivery.Undo')"
                size="small"
                transparent
                @click="undo"/>
        <span class="ml-10">{{ $translate('Client.CheckoutPage.Delivery.UndoDescription') }}</span>
    </TextWrapper>
</template>

<script setup lang="ts">
import DeliveryTypeChooser from '@/project/pages/checkout-page/delivery/DeliveryTypeChooser.vue';
import TextWrapper from '@/project/components/text-wrapper/TextWrapper.vue';
import { useDeliveryState, DeliveryPageStates } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { ref } from 'vue';
import { TimeSlotTypeViewModel } from '@/api/commerce';
import Button from '@/project/components/button/Button.vue';
import openDialog from '@/project/dialog/dialog';
import PickupPointDrawer from '@/project/pages/checkout-page/delivery/PickupPointDrawer.vue';
import TimeSlotDrawer from '@/project/pages/checkout-page/delivery/TimeSlotDrawer.vue';
import { setDeliveryZipCode } from '@/project/apis/commerce/checkoutApi';
import { isValidZip } from '@/core/forms/vee-validate.config';

const { deliveryState } = useDeliveryState();

const prevState = ref({ ...deliveryState });

function openPickupPointChooser() {
    deliveryState.type = TimeSlotTypeViewModel.PickUp;
    openDialog(PickupPointDrawer, {});
}

function openHomeDeliveryTimeChooser() {
    deliveryState.type = TimeSlotTypeViewModel.HomeDelivery;
    openDialog(TimeSlotDrawer, {});
}

function changeZip(zipCode: number) {
    deliveryState.zipCode = zipCode;

    if (isValidZip(zipCode.toString())) {
        setDeliveryZipCode(zipCode);
    }
}

function undo() {
    deliveryState.zipCode = prevState.value.zipCode;
    deliveryState.store = prevState.value.store;
    deliveryState.timeSlotId = prevState.value.timeSlotId;
    deliveryState.type = prevState.value.type;

    if (prevState.value.type === TimeSlotTypeViewModel.HomeDelivery) {
        deliveryState.pageState = DeliveryPageStates.HomeDeliverySelected;
    } else if (prevState.value.type === TimeSlotTypeViewModel.PickUp) {
        deliveryState.pageState = DeliveryPageStates.PickupSelected;
    }
}
</script>
