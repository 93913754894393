import { DictionaryApiFactory } from '@/api/cms';
import { cmsHost } from '@/core/infrastructure/environment';
import dictionary from '@/core/dictionary/dictionary';

const labelsApi = DictionaryApiFactory({
    basePath: cmsHost,
    isJsonMime: () => true,
});

export default function loadDictionary(): Promise<void> {
    return labelsApi.apiDictionaryGet('is')
        .then(labels => {
            dictionary.init(labels.data.model);
        })
        .catch(e => {
            dictionary.init({});
            // Probably wrong market - will end up in 404
        });
}
