<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="navigation.breadcrumb.slice(1)" :current-page-name="metadata.navigationTitle"/>
        <div class="flex " :class="{'flex-col ': !showDesktopView,'space-x-20': showDesktopView}">
            <MyAccountNavigation :menu="navigation.menu"
                                 :is-mobile="!showDesktopView"
                                 :class="{'bg-white rounded-lg': showDesktopView}"
                                 :user="customerInfo?.firstName"/>
            <template v-if="orders.length === 0" class="page-spacing">
                <div class="mt-10 lg:mt-0 w-full">
                    <div class="xl:flex relative xl:justify-between xl:items-center mb-10 w-full bg-white rounded-lg p-15">
                        <div class="flex flex-col xl:flex-row justify-center xl:items-baseline xl:justify-left">
                            <h1 class="font-bold text-center xl:text-left text-28 lg:text-40 text-primary">
                                {{ metadata.navigationTitle }}
                            </h1>
                        </div>
                    </div>
                    <EmptyResults :title="$translate('Client.OrderPage.NoOrders')">
                        <EmptyOrderHistoryIcon/>
                    </EmptyResults>
                </div>
            </template>
            <div v-else class="flex flex-col">
                <!-- mobile view of order history -->
                <div v-if="!showDesktopView && !selectedOrder?.orderId" class="mt-20 mb-20 w-full">
                    <div v-if="processingOrders.length > 0" class="py-20 bg-white rounded-lg ">
                        <h3 class="px-20 pb-20 mb-20 font-bold border-b text-20 border-b-lightBorder">
                            {{ $translate('Client.OrderPage.ProcessingOrders') }}
                        </h3>
                        <div v-for="(order, index) in processingOrders" :key="order.orderId">
                            <OrderHeaderMobile :model="order" :class="{'border-t border-lightBorder pt-20': index !== 0}" class=" px-20" @click="(order) => onClick(order)"/>
                        </div>
                    </div>
                    <div v-if="previousOrders.length > 0" class="py-20 mt-20 bg-white rounded-lg">
                        <h3 class="px-20 pb-20 mb-20 font-bold border-b text-20 border-b-lightBorder">
                            {{ $translate('Client.OrderPage.PreviousOrders') }}
                        </h3>
                        <div v-for="(order, index) in previousOrders" :key="order.orderId">
                            <OrderHeaderMobile :model="order" :class="{'border-t border-lightBorder pt-20': index !== 0}" class=" px-20" @click="(order) => onClick(order)"/>
                        </div>
                    </div>
                </div>
                <div v-if="!showDesktopView && selectedOrder?.orderId"
                     :ref="el => { if(el){ (el as HTMLDivElement).scrollIntoView({behavior: 'smooth'})}}">
                    <div class="py-20 mt-20 mb-20 bg-white rounded-lg">
                        <h3 class="px-20 pb-10 mb-10 font-bold border-b text-20 border-lightBorder">
                            {{ $translate('Client.OrderPage.OrderDetailsTitle') }}
                        </h3>
                        <OrderHeaderMobile :model="orders.filter(x => x.orderId === selectedOrder?.orderId)[0]" :minimal="true" class="px-20"/>
                        <OrderAddress :model="selectedOrder" :minimal="!showDesktopView" class="px-20"/>
                    </div>
                    <div class="mb-20 bg-white">
                        <div class="flex flex-row col-span-12 items-center py-10 pl-20 border-b bg-backgroundContrast border-b-lightBorder">
                            <span class="mr-10 font-bold text-20">
                                {{ $translate('Client.OrderPage.ItemOverview') }}
                            </span>
                            <span class="mt-[.2rem]">
                                {{ orderCount }} {{ orderCount && orderCount > 1 ? $translate('Client.CategoryPage.ProductsFound') : $translate('Client.CategoryPage.SingleProductFound') }}
                            </span>
                        </div>

                        <div v-for="(category, index) in selectedOrderCategories" :key="category.categoryName">
                            <OrderDetailedCategory :name="category.categoryName" :wide-layout="isMediumScreen" :class="{'rounded-t-lg ': index === 0}"/>
                            <div v-for="lineItem in category.lineItems" :key="lineItem.sku" class="border-b border-background">
                                <OrderDetailedLineItem :model="lineItem" :wide-layout="isMediumScreen"/>
                            </div>
                        </div>
                    </div>
                    <div class="pt-20 pb-10 bg-white rounded-lg">
                        <h3 class="px-20 pb-10 font-bold border-b text-20 border-lightBorder">
                            {{ $translate('Client.CheckoutPage.Confirmation.Payment') }}
                        </h3>
                        <div class="px-20">
                            <OrderSummary :model="selectedOrder" :is-mobile="true"/>
                        </div>
                    </div>
                    <div class="py-20">
                        <Button :primary="true" class="mx-auto" :label="$translate('Client.OrderPage.CloseOrder')" @click="closeOrder"/>
                    </div>
                </div>

                <!-- desktop and tablet view of order history -->
                <div v-if="showDesktopView" class="w-full">
                    <div class="xl:flex relative xl:justify-between xl:items-center mb-10 w-full bg-white rounded-lg p-15">
                        <div class="flex flex-col xl:flex-row justify-center xl:items-baseline xl:justify-left">
                            <h1 class="font-bold text-center xl:text-left text-28 lg:text-40 text-primary">
                                {{ metadata.navigationTitle }}
                            </h1>
                        </div>
                    </div>
                    <div class="grid grid-cols-12 font-bold border-b px-30 py-15 bg-backgroundContrast border-b-lightBorder">
                        <span class="col-span-4">{{ $translate('Client.OrderPage.DeliveryTime') }}</span>
                        <span class="col-span-2">{{ $translate('Client.OrderPage.DeliveryType') }}</span>
                        <span class="col-span-2">{{ $translate('Client.OrderPage.OrderNumber') }}</span>
                        <span class="col-span-2">{{ $translate('Client.OrderPage.Status') }}</span>
                        <span class="col-span-1">{{ $translate('Client.BasketPage.Total') }}</span>
                        <span class="col-span-1"></span>
                    </div>
                    <div class="bg-white min-h-half">
                        <div v-for="order in orders" :key="order.orderId">
                            <div class="grid grid-cols-12 bg-white border-b transition-all cursor-pointer px-30 py-15 border-b-lightBorder hover:bg-backgroundContrastBlue" :class="{'font-bold bg-backgroundContrastBlue': order.orderId === selectedOrder?.orderId}" @click="() => onClick(order)">
                                <span class="col-span-4 px-10">
                                    <span class="capitalize mr-[2px]">
                                        {{ getWeekday(order.timeSlot?.from!, true) }}
                                    </span>
                                    {{ getDate(order.timeSlot?.from!, true) }}
                                    {{ $translate('Client.Common.Between') }}
                                    {{ getTime(order.timeSlot?.from!) }}-{{ getTime(order.timeSlot?.to!) }}
                                </span>
                                <span class="col-span-2">
                                    {{ getDeliveryType(order) }}
                                </span>
                                <span class="col-span-2">
                                    {{ order.orderId }}
                                </span>
                                <span class="col-span-2 px-10">
                                    {{ $translate(`Client.OrderPage.Status.${order.status}`) }}
                                </span>
                                <span class="col-span-1">
                                    {{ formatPrice(order.total) }}
                                </span>
                                <span class="flex col-span-1 justify-end items-center text-right">
                                    <CIcon v-if="order.orderId === selectedOrder?.orderId" name="arrow-up" width="12" height="12"/>
                                    <CIcon v-else name="arrow-down" width="12" height="12"/>
                                </span>
                            </div>
                            <div v-if="selectedOrder?.orderId == order.orderId"
                                 :ref="el => { if(el){ (el as HTMLDivElement).previousElementSibling?.scrollIntoView({behavior: 'smooth'})}}"
                                 class="overflow-hidden max-h-[0rem] "
                                 :class="{'animate-show-vertical': selectedOrder?.orderId == order.orderId}">
                                <OrderAddress :model="selectedOrder"/>
                                <div class="grid grid-cols-12 col-span-12 border-b-2 bg-backgroundContrast border-lightBorder">
                                    <div class="flex flex-row col-span-12 items-center py-10 border-b pl-30 bg-backgroundContrast border-b-lightBorder">
                                        <span class="mr-10 font-bold text-20">
                                            {{ $translate('Client.OrderPage.ItemOverview') }}
                                        </span>
                                        <span class="mt-[.2rem]">
                                            {{ orderCount }} {{ orderCount && orderCount > 1 ? $translate('Client.CategoryPage.ProductsFound') : $translate('Client.CategoryPage.SingleProductFound') }}
                                        </span>
                                    </div>
                                    <div class="col-span-12 mb-20 bg-white">
                                        <div v-for="(category, index) in selectedOrderCategories" :key="category.categoryName">
                                            <OrderDetailedCategory :name="category.categoryName" :wide-layout="isMediumScreen" :class="{'rounded-t-lg ': index === 0}"/>
                                            <div v-for="lineItem in category.lineItems" :key="lineItem.sku" class="flex items-center border-b border-background min-h-[6rem]">
                                                <OrderDetailedLineItem :model="lineItem" :wide-layout="isMediumScreen" class="w-full"/>
                                            </div>
                                        </div>
                                    </div>
                                    <OrderSummary :model="selectedOrder"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ShowMoreButton v-if="orders.length > 0"
                                :max-page-size="300"
                                :page-size="30"
                                :total="totalOrders"
                                :number-of-results="orders.length"/>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { OrderDetailsViewModel, OrderStatusViewModel, OrderViewModel } from '@/api/commerce';
import { ref, computed, watch } from 'vue';
import { getOrder, getOrders } from '../../apis/commerce/orderApi';
import { formatPrice } from '@/project/products/priceFormatter';
import OrderDetailedLineItem from '../order-page/order-line-item/OrderDetailedLineItem.vue';
import OrderDetailedCategory from '../order-page/order-category/OrderDetailedCategory.vue';
import OrderSummary from './OrderSummary.vue';
import OrderAddress from './OrderAddress.vue';
import { CategoryAndLineItems } from '@/project/basket/basket.types';
import MyAccountNavigation from './MyAccountNavigation.vue';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import { getWeekday, getDate, getTime } from '@/project/utils/datetime';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import OrderHeaderMobile from '@/project/pages/my-account/OrderHeaderMobile.vue';
import Button from '@/project/components/button/Button.vue';
import EmptyResults from '@/project/components/empty-results/EmptyResults.vue';
import { IMetadata, SiteNavigation } from '@/api/cms';
import { getDeliveryType } from '@/project/apis/commerce/checkoutApi';
import { useCustomerInfo } from '@/project/apis/commerce/accountApi';
import EmptyOrderHistoryIcon from './EmptyOrderHistoryIcon.vue';
import { useRouteQuery } from '@/core/browser/query/useQueryParams';
import { QueryKeys } from '@/project/browser/query';
import ShowMoreButton from '@/project/search/show-more-button/ShowMoreButton.vue';
import { trackPage } from '@/project/tracking/tracking.service';
import { authRequired } from '@/project/authentication/authentication';

defineProps<{
    metadata:IMetadata,
    navigation: SiteNavigation,
}>();

trackPage();

authRequired();

const { greater } = useBreakpoints();
const showDesktopView = greater('lg');
const isMediumScreen = greater('sm');

const orders = ref<Array<OrderViewModel>>([]);
const totalOrders = ref(0);
const selectedOrderCategories = ref<CategoryAndLineItems[]>([]);
const selectedOrder = ref<OrderDetailsViewModel>();

const lastY = ref(0);

const customerInfo = useCustomerInfo();

const pageSize = useRouteQuery(QueryKeys.PAGE_SIZE);

async function onClick(o: OrderViewModel) {
    if (o.orderId === selectedOrder.value?.orderId) {
        selectedOrder.value = undefined;
        selectedOrderCategories.value = [];
        return;
    }
    lastY.value = window.scrollY;
    const { order, categories } = await retrieveOrder(o.orderId);
    selectedOrder.value = order;
    selectedOrderCategories.value = categories;
}

const closeOrder = () => {
    window.scrollTo(0, lastY.value);
    selectedOrder.value = undefined;
    selectedOrderCategories.value = [];
};

watch([pageSize], ([pageSizeValue]) => {
    getOrders(1, Number(pageSizeValue))
        .then(os => {
            orders.value = os.orders;
            totalOrders.value = os.totalOrders;
        });
}, { immediate: true });

const orderCount = computed(() => selectedOrder.value?.lineItems.map(l => l.quantity).reduce((a, b) => a + b));
const processingOrders = computed(() => orders.value.filter(x => isOrderProcessing(x.status)));
const previousOrders = computed(() => orders.value.filter(x => !isOrderProcessing(x.status)));

function isOrderProcessing(status: OrderStatusViewModel) {
    return status === OrderStatusViewModel.Processing || status === OrderStatusViewModel.Created || status === OrderStatusViewModel.OpenOrder;
}

async function retrieveOrder(id: string): Promise<{ order: OrderDetailsViewModel, categories: CategoryAndLineItems[] }> {
    return getOrder(id)
        .then(order => {
            let categories:CategoryAndLineItems[] = [];
            order.lineItems.forEach(item => {
                if (!categories[item.firstLevelCategorySortOrder]) {
                    categories[item.firstLevelCategorySortOrder] = {
                        categoryName: item.firstLevelCategoryName,
                        lineItems: [],
                    };
                }
                categories[item.firstLevelCategorySortOrder].lineItems.push({ ...item, alwaysInStock: true, availableInStock: 0 });
            });

            categories = categories.filter(n => n).sort((a, b) => a.categoryName.localeCompare(b.categoryName));

            return {
                order,
                categories,
            };
        });
}

</script>
