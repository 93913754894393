/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasketViewModelClientResponse } from '../models';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { OrderDetailsViewModelClientResponse } from '../models';
// @ts-ignore
import { OrderListViewModelClientResponse } from '../models';
// @ts-ignore
import { TopProductsRequest } from '../models';
// @ts-ignore
import { TopProductsResultViewModelClientResponse } from '../models';
/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Returns the customers active and invoiced orders.
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderGet: async (page?: number, pageSize?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Given an order id, return the products and their quantity.
         * @param {string} id The order ID.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderIdGet: async (id: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAccountOrderIdGet', 'id', id)
            const localVarPath = `/api/account/order/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Adds the products from the given order to the current basket.
         * @param {string} [id] The order ID.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderReorderPost: async (id?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/order/reorder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the top products in active and invoiced orders from the customer.
         * @param {string} [apiVersion] 
         * @param {TopProductsRequest} [topProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderTopPost: async (apiVersion?: string, topProductsRequest?: TopProductsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/account/order/top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(topProductsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove a product from the list of top products.                Note that the product will be added to the list again if purchased at a later time.
         * @param {string} sku The SKU of the product to remove from the list of top products.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderTopSkuDelete: async (sku: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('apiAccountOrderTopSkuDelete', 'sku', sku)
            const localVarPath = `/api/account/order/top/{sku}`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Returns the customers active and invoiced orders.
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOrderGet(page?: number, pageSize?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderListViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOrderGet(page, pageSize, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Given an order id, return the products and their quantity.
         * @param {string} id The order ID.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOrderIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderDetailsViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOrderIdGet(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Adds the products from the given order to the current basket.
         * @param {string} [id] The order ID.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOrderReorderPost(id?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOrderReorderPost(id, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the top products in active and invoiced orders from the customer.
         * @param {string} [apiVersion] 
         * @param {TopProductsRequest} [topProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOrderTopPost(apiVersion?: string, topProductsRequest?: TopProductsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopProductsResultViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOrderTopPost(apiVersion, topProductsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove a product from the list of top products.                Note that the product will be added to the list again if purchased at a later time.
         * @param {string} sku The SKU of the product to remove from the list of top products.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAccountOrderTopSkuDelete(sku: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAccountOrderTopSkuDelete(sku, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @summary Returns the customers active and invoiced orders.
         * @param {number} [page] Page number.
         * @param {number} [pageSize] Page size.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderGet(page?: number, pageSize?: number, apiVersion?: string, options?: any): AxiosPromise<OrderListViewModelClientResponse> {
            return localVarFp.apiAccountOrderGet(page, pageSize, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Given an order id, return the products and their quantity.
         * @param {string} id The order ID.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderIdGet(id: string, apiVersion?: string, options?: any): AxiosPromise<OrderDetailsViewModelClientResponse> {
            return localVarFp.apiAccountOrderIdGet(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Adds the products from the given order to the current basket.
         * @param {string} [id] The order ID.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderReorderPost(id?: string, apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiAccountOrderReorderPost(id, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the top products in active and invoiced orders from the customer.
         * @param {string} [apiVersion] 
         * @param {TopProductsRequest} [topProductsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderTopPost(apiVersion?: string, topProductsRequest?: TopProductsRequest, options?: any): AxiosPromise<TopProductsResultViewModelClientResponse> {
            return localVarFp.apiAccountOrderTopPost(apiVersion, topProductsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove a product from the list of top products.                Note that the product will be added to the list again if purchased at a later time.
         * @param {string} sku The SKU of the product to remove from the list of top products.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAccountOrderTopSkuDelete(sku: string, apiVersion?: string, options?: any): AxiosPromise<ClientResponse> {
            return localVarFp.apiAccountOrderTopSkuDelete(sku, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - interface
 * @export
 * @interface OrderApi
 */
export interface OrderApiInterface {
    /**
     * 
     * @summary Returns the customers active and invoiced orders.
     * @param {number} [page] Page number.
     * @param {number} [pageSize] Page size.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApiInterface
     */
    apiAccountOrderGet(page?: number, pageSize?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<OrderListViewModelClientResponse>;

    /**
     * 
     * @summary Given an order id, return the products and their quantity.
     * @param {string} id The order ID.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApiInterface
     */
    apiAccountOrderIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<OrderDetailsViewModelClientResponse>;

    /**
     * 
     * @summary Adds the products from the given order to the current basket.
     * @param {string} [id] The order ID.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApiInterface
     */
    apiAccountOrderReorderPost(id?: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Returns the top products in active and invoiced orders from the customer.
     * @param {string} [apiVersion] 
     * @param {TopProductsRequest} [topProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApiInterface
     */
    apiAccountOrderTopPost(apiVersion?: string, topProductsRequest?: TopProductsRequest, options?: AxiosRequestConfig): AxiosPromise<TopProductsResultViewModelClientResponse>;

    /**
     * 
     * @summary Remove a product from the list of top products.                Note that the product will be added to the list again if purchased at a later time.
     * @param {string} sku The SKU of the product to remove from the list of top products.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApiInterface
     */
    apiAccountOrderTopSkuDelete(sku: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<ClientResponse>;

}

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI implements OrderApiInterface {
    /**
     * 
     * @summary Returns the customers active and invoiced orders.
     * @param {number} [page] Page number.
     * @param {number} [pageSize] Page size.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiAccountOrderGet(page?: number, pageSize?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiAccountOrderGet(page, pageSize, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Given an order id, return the products and their quantity.
     * @param {string} id The order ID.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiAccountOrderIdGet(id: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiAccountOrderIdGet(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Adds the products from the given order to the current basket.
     * @param {string} [id] The order ID.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiAccountOrderReorderPost(id?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiAccountOrderReorderPost(id, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the top products in active and invoiced orders from the customer.
     * @param {string} [apiVersion] 
     * @param {TopProductsRequest} [topProductsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiAccountOrderTopPost(apiVersion?: string, topProductsRequest?: TopProductsRequest, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiAccountOrderTopPost(apiVersion, topProductsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove a product from the list of top products.                Note that the product will be added to the list again if purchased at a later time.
     * @param {string} sku The SKU of the product to remove from the list of top products.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiAccountOrderTopSkuDelete(sku: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return OrderApiFp(this.configuration).apiAccountOrderTopSkuDelete(sku, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
