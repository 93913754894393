<template>
    <div></div>
</template>

<script lang="ts" setup>
import { login, getUser } from '@/project/authentication/authentication';

getUser().then(a => {
    if (a) {
        window.parent.postMessage('login-frame-reload', '*');
    } else {
        login();
    }
});

</script>
