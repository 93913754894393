<template>
    <div class="py-40 o-container">
        <article class="flex flex-col justify-center items-center p-40 sm:mx-40 bg-white rounded-lg lg:mx-[150px]">
            <h1 class="mb-20 font-bold text-primary text-40 lg:text-56">
                {{ model.headline }}
            </h1>
            <p v-for="desc in descriptionLines" :key="desc" class="text-center lg:text-left text-14 lg:text-20">
                {{ desc }}
            </p>
            <div class="flex space-x-40 mt-30">
                <RouterLink :to="frontPageUrl" class="text-link">
                    {{ $translate('Client.Navigation.BackToHome') }}
                </RouterLink>
                <RouterLink v-for="item in mainMenuLinks"
                            :key="item.url"
                            :to="item.url"
                            class="text-14 text-link">
                    {{ item.name }}
                </RouterLink>
            </div>
        </article>
    </div>
</template>

<script setup lang="ts">
import { NotFoundPageModel } from '@/api/cms/models/not-found-page-model';
import { computed, ref } from 'vue';
import { getLeftMainMenu, getPageUrls } from '@/project/apis/cms/contentApi';
import { NameAndUrl } from '@/api/cms';

const props = defineProps<{model: NotFoundPageModel}>();

const descriptionLines = computed(() => props.model.description.split('\n\n'));

const mainMenuLinks = ref<NameAndUrl[]>([]);

getLeftMainMenu().then(_leftMainMenuItems => {
    mainMenuLinks.value = _leftMainMenuItems;
});

const { frontPageUrl } = getPageUrls();
</script>
