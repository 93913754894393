import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ProductDetailsPage from '@/project/pages/product-details/ProductDetailsPage.vue';
import CmsPageProxy, { pageCache } from '@/core/content/CmsPageProxy.vue';
import Login from '@/project/pages/checkout-page/Login.vue';
import bus from '@/core/bus';
import { NEW_PAGE_SET } from '@/core/content/constants';
import { nextTick } from 'vue';
import { useFunnelView } from '@/core/layout/funnel-view-composable';
import { getPageUrls, rootPage } from '@/project/apis/cms/contentApi';

export const PRODUCT_INFO_SPLITTER = '-p-';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/login-iframe',
        component: Login,
    },
    {
        path: '/:pathMatch(.*)*',
        component: CmsPageProxy,
        meta: {
            isServerPage: true,
        },
    },
    {
        path: '/:urlSegment(.*-p-.*)',
        component: ProductDetailsPage,
        props: route => {
            const productIdInfo = route.params.urlSegment.toString().split(PRODUCT_INFO_SPLITTER);

            return { productId: productIdInfo[productIdInfo.length - 1] };
        },
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.path === from.path) return undefined; // Don't scroll on only-query change
        if (!to.meta.isServerPage) {
            return {
                top: savedPosition?.top ?? 0,
            };
        }
        return new Promise((resolve) => {
            bus.once(NEW_PAGE_SET, () => {
                // Wait for new page data to be set and wait a bit for rendering
                nextTick(() => {
                    resolve({
                        top: savedPosition?.top ?? 0,
                    });
                });
            });
        });
    },
});

const { setFunnelView } = useFunnelView();

const { checkoutUrl, confirmationUrl } = getPageUrls();

router.beforeEach(async(route) => {
    await rootPage;
    if (checkoutUrl.value && route.path.includes(checkoutUrl.value) && !route.path.includes(confirmationUrl.value)) {
        setFunnelView(true);
    } else {
        setFunnelView(false);
    }
});

export function getPageName(): string | undefined {
    const pageName = pageCache.get(router.currentRoute.value.path)?.jsonContent?.alias || router.currentRoute.value?.name;
    return pageName ? (pageName as string).substring(0, 1).toLocaleUpperCase() + (pageName as string).substring(1) : undefined;
}

export default router;
