<template>
    <DrawerDialog
        v-slot="{close}"
        use-slide-animation>
        <div class="overflow-x-hidden overflow-y-auto w-full h-full md:w-[510px] bg-background">
            <div class="sticky top-0 z-2">
                <div class="flex items-center py-10 px-20 w-full bg-white justity-center justity-between">
                    <div class="p-8 mr-10 w-40 h-40 rounded-full bg-blue">
                        <CIcon name="eighteenplus" width="24" height="24" class="text-white"/>
                    </div>
                    <div>
                        <h1 class="font-bold text-18">
                            {{ $translate('Client.CheckoutPage.Delivery.AgeVerificationPopupTitle') }}
                        </h1>
                    </div>
                    <button class="ml-auto cursor-pointer" @click="close">
                        <CIcon name="closecircle" width="24" height="24"/>
                    </button>
                </div>
                <div class="py-10 px-20 bg-backgroundContrast">
                    {{ $translate('Client.CheckoutPage.Delivery.AgeVerificationPopupDescription') }}
                </div>
            </div>

            <TransitionGroup name="list-complete">
                <div v-for="(category, categoryIndex) in ageLimitedLineItemsByCategory" :key="'category' + categoryIndex" class="list-complete-item">
                    <div class="py-5 px-20 font-bold bg-backgroundContrastBlue">
                        {{ category.categoryName }}
                    </div>
                    <TransitionGroup name="list-complete">
                        <AgeLimitLineItem v-for="(item, itemIndex) in category.lineItems.filter(i => !removed.includes(i.sku))"
                                          :key="item.sku"
                                          :position="itemIndex"
                                          class="list-complete-item"
                                          :model="item"
                                          @remove="remove(item.sku)"/>
                    </TransitionGroup>
                </div>
            </TransitionGroup>
            <div class="flex justify-center mt-10 w-full">
                <Button transparent @click="removeAll">
                    {{ $translate('Client.CheckoutPage.Delivery.RemoveAllAgeRestrictedItemsFromBasket') }}
                </Button>
            </div>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import { fetchBasket, removeListOfLineItems } from '@/project/apis/commerce/basketApi';
import { getCheckoutBasket } from '@/project/apis/commerce/checkoutApi';
import AgeLimitLineItem from '@/project/basket/age-limit/AgeLimitLineItem.vue';
import { ref, Ref } from 'vue';
import Button from '@/project/components/button/Button.vue';

fetchBasket();

const { ageLimitedLineItemsByCategory } = getCheckoutBasket();

const removed: Ref<string[]> = ref([]);

function remove(sku: string) {
    removed.value.push(sku);
}

function removeAll() {
    let listOfSkus: string[] = [];
    for (const category of ageLimitedLineItemsByCategory.value) {
        const skus = category.lineItems.map(l => l.sku);
        listOfSkus = [...listOfSkus, ...skus];
    }
    removeListOfLineItems(listOfSkus);
    removed.value = [...listOfSkus];
}

</script>

<style scoped>
.list-complete-move {
  transition: all 0.8s ease;
}

.list-complete-item {
  transition: all 0.3s ease;
  transform-origin: top left;
  overflow: hidden;
  max-height: 3000px;
}

.list-complete-leave-from {
    max-height: 3000px;
    overflow-y: hidden;
    transform: scaleY(1);
}

.list-complete-leave-to {
    max-height: 0;
    overflow-y: hidden;
    transform: scaleY(0);
}
</style>
