import { LineItemViewModel, ProductDetailsViewModel, ProductTileViewModel } from '@/api/commerce';
import { computed, ComputedRef, Ref } from 'vue';

export function useStock(model: ProductDetailsViewModel | LineItemViewModel | ProductTileViewModel, lineItem: Ref<LineItemViewModel | undefined>): {
    fewLeftInStock: ComputedRef<boolean>
    soldOut: ComputedRef<boolean>
    stockLimitHit: ComputedRef<boolean>
} {
    const soldOut = computed(() => !model.alwaysInStock && model.availableInStock <= 0);
    const stockLimitHit = computed(() => !model.alwaysInStock && model.availableInStock - (lineItem.value?.quantity ?? 0) === 0);
    const fewLeftInStock = computed(() => !soldOut.value && !model.alwaysInStock && model.availableInStock - (lineItem.value?.quantity ?? 0) <= 3);

    return { fewLeftInStock, soldOut, stockLimitHit };
}
