<template>
    <div class="flex relative items-center px-10 h-[3.2rem] xl:h-40 rounded-lg fade-300 w-300 xl:w-410 bg-background">
        <InputSearch v-model="termQuery"
        name="search"
        class="flex items-center w-full bg-transparent border-none shadow-none transition-all outline-none search-input"
        :class="isSearchActive ? 'translate-x-0 -ml-0' : 'translate-x-[25%] lg:-ml-20 xl:-ml-0'"
        :placeholder="$translate('Client.Search.Teaser')"
        type="search"
        autocomplete="off"
        :search-active="isSearchActive"
        :large-screen="isLargeScreen"
        @click="trackSearchIntent"
        @focus="onFocus"
        @blur="checkFocus"
        @keyup="keyup"/>
        <Transition name="fade">
            <div v-if="spinnerActive && termQuery" class="absolute right-[.9rem] -mt-[.2rem] ">
                <div class="rounded-full border-4 opacity-50 animate-spin w-[2.5rem] h-[2.5rem] border-r-lightBorder border-fadedText"></div>
            </div>
        </Transition>
        <HiddenSkipTo v-if="termQuery" id="search-results" :label="$translate('Client.A11y.SkipToProductList')"/>
        <div class="absolute top-0 right-0 bottom-0 border-opacity-10 transition translate-x-full opacity-1 "
        :class="{'opacity-0 pointer-events-none': !isSearchActive}">
        <Button :label="$translate('Client.Search.Close')"
        class="px-0 ml-4"
        :size="!props.isMobile && isLargerScreen ? 'medium' : 'small'"
        :primary="false"
        :search="true"
        @click="onUnfocus"/>
    </div>
    </div>
</template>

<script setup lang="ts">
import { useFacets, useRouteQuery } from '@/core/browser/query/useQueryParams';
import debounce from 'lodash-es/debounce';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { QueryKeys } from '@/project/browser/query';
import InputSearch from '@/project/forms/InputSearch.vue';
import { useSearchToggle } from '../apis/commerce/searchApi';
import Button from '@/project/components/button/Button.vue';
import { useTimeoutFn } from '@vueuse/core';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { trackSearchIntent } from '../tracking/tracking.service';
import HiddenSkipTo from '../components/hidden-skip-to/HiddenSkipTo.vue';

const props = defineProps<{
    isMobile?: boolean;
}>();

const emit = defineEmits(['openSearch']);

const { applyFacets } = useFacets();

const termQuery = useRouteQuery(QueryKeys.TERM);
const categoryId = useRouteQuery(QueryKeys.CATEGORY);
const spinnerActive = ref(false);

const { isSearchActive, setSearch, isSearching } = useSearchToggle();
const breakpoints = useBreakpoints();

const isLargeScreen = breakpoints.greater('lg').value;
const isLargerScreen = breakpoints.greater('xl');

// checks if search response is taking too long and shows spinner in search field. It's removed when search is done and if spinner is active.
const { start, stop } = useTimeoutFn(() => {
    if (isSearching.value) {
        spinnerActive.value = true;
    }
}, 300);

watch(() => isSearching.value, (searching) => {
    if (!searching && spinnerActive.value) {
        stop();
        spinnerActive.value = false;
    }
});

const onFocus = () => {
    setSearch(true);
    emit('openSearch', true);
};

onMounted(() => {
    if (termQuery.value !== '') {
        setSearch(true);
        emit('openSearch', true);
    }
});

onUnmounted(() => {
    stop();
});

const checkFocus = (e:FocusEvent) => {
    if ((e.target as HTMLInputElement).value.length === 0) {
        onUnfocus();
    }
};

const onUnfocus = () => {
    setSearch(false);
    termQuery.value = '';
    categoryId.value = '';
    applyFacets({}, true);
    emit('openSearch', false);
    debouncedSearch.cancel();
};

const updateSearch = () => {
    categoryId.value = '';
    if (termQuery.value) {
        start();
    }
};

const debouncedSearch = debounce(updateSearch, 200);

const keyup = (e:KeyboardEvent) => {
    applyFacets({}, true);
    if (e.key === 'Escape') {
        (e.target as HTMLInputElement).blur();
        onUnfocus();
    }
    debouncedSearch.cancel();
    debouncedSearch();
};

</script>

<style scoped>
.fade-300{
    @apply transition-opacity duration-300 ease-linear;
}
.search-input:deep() input[type=search]::placeholder{
    @apply text-fadedText;
}

.search-input:deep() input[type=search]::-webkit-search-cancel-button{
    height: 1em;
    width: 1em;
    cursor: pointer;
    -webkit-appearance: none;
    background-image: url('@/assets/icons/search-clear.svg');
    background-repeat: no-repeat;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,.fade-leave-to, .fade-enter-from {
  opacity: 0;
}

</style>
