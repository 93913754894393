<template>
    <div :class="[$attrs.class]">
        <label class="font-bold truncate text-14 leading-20" :for="fieldId">{{ label }}</label>
        <label v-if="isSearchType" :for="fieldId" class="absolute cursor-pointer" :class="{'m-0': searchActive}">
            <CIcon name="search-bold"
                   :width="searchActive ? '16' : (!largeScreen ? '22' : '16')"
                   :height="searchActive ? '16' : (!largeScreen ? '22' : '16')"/>
        </label>
        <input
            :id="fieldId"
            v-bind="fieldAttrs"
            :ref="(el) => { searchInputElement = el }"
            v-autofocus="autofocus"
            v-prohibit-zoom
            :name="name"
            :disabled="disabled"
            class="py-10 w-full rounded-lg text-14 px-15"
            :class="{ 'border-red-500' : hasError, 'focus:ring-0 pr-0 bg-transparent outline-none': isSearchType, 'pl-35' : !largeScreen, 'pl-25' : largeScreen }"
            :type="type"
            :value="inputValue"
            :placeholder="placeholder"
            v-on="validationListeners"
            @focus="isFocused = true"
            @blur="isFocused = false">

        <InputClear v-if="inputClear" :active="showInputClear" @clear="handleClear"/>
        <InputErrorMessage
            v-if="showErrors"
            class="mt-5"
            :name="name"/>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref, toRef, watch } from 'vue';
import { useField, useFieldError } from 'vee-validate';
import InputClear from '@/project/forms/InputClear.vue';
import InputErrorMessage from '@/project/forms/InputErrorMessage.vue';
import { searchInputElement } from '@/project/search/search-input.service';

export default defineComponent({
    name: 'InputSearch',
    components: {
        InputClear,
        InputErrorMessage,
    },
    inheritAttrs: false,
    props: {
        type: {
            type: String,
            default: 'text',
        },
        value: {
            type: [String, Number],
            default: '',
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: null,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        id: {
            type: String,
            required: false,
            default: null,
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false,
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: true,
        },
        inputClear: {
            type: Boolean,
            required: false,
            default: false,
        },
        fieldDisplayName: {
            type: String,
            required: false,
            default: '',
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        validationBehaviour: {
            type: String,
            default: 'eager',
            validate: (value: string): boolean => ['aggressive', 'eager'].includes(value),
        },
        searchActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        largeScreen: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: {
        clear: () => true,
        input: (value) => true,
        'update:modelValue': (value) => true,
    },
    setup(props, {
        attrs,
        emit,
    }) {
        const isFocused: Ref<boolean> = ref(false);

        const isModelBound = computed(() => {
            return 'modelValue' in props;
        });

        const {
            value: inputValue,
            errorMessage,
            handleChange,
            handleBlur,
            validate,
            resetField,
        } = useField(props.name, undefined, {
            initialValue: isModelBound.value ? props.modelValue : props.value,
            validateOnValueUpdate: false,
            label: props.fieldDisplayName || props.label,
        });
        const fieldId = computed(() => {
            return props.id || props.name + Math.random();
        });

        const isSearchType = computed(() => {
            return props.type === 'search';
        });

        const fieldAttrs = computed(() => {
            const fieldAttrs = {
                ...attrs,
                class: '',
            };
            return fieldAttrs;
        });

        const showInputClear = computed(() => {
            return isFocused.value && !!inputValue.value;
        });

        const handleClear = () => {
            resetField();
            isModelBound.value && emit('update:modelValue', inputValue.value);
            emit('clear');
        };

        const handleInputEvent = (evt) => {
            isModelBound.value && emit('update:modelValue', evt.target.value);
            emit('input', evt);
            handleChange(evt);
        };

        const handleChangeEvent = (evt) => {
            if (evt) {
                isModelBound.value && emit('update:modelValue', evt.target.value);
                emit('input', evt);
                handleChange(evt);
            }
        };

        const validationListeners = computed(() => {
            // if no current errors use lazy validation handlers
            const listeners = {
                blur: handleBlur,
                change: handleChangeEvent,
                input: handleInputEvent,
            };

            // if aggressive mode or current errors, replace with eager handlers to validate changes immediately
            if (props.validationBehaviour === 'aggressive' || errorMessage.value) {
                listeners.blur = handleChangeEvent;
                listeners.input = handleChangeEvent;
            }

            return listeners;
        });

        if (isModelBound.value) {
            const modelValue = toRef(props, 'modelValue');
            watch(modelValue, newModelValue => {
                if (newModelValue !== inputValue.value) {
                    inputValue.value = newModelValue;
                    validate();
                }
            });
        }

        const hasError = useFieldError(props.name);

        return {
            validationListeners,
            errorMessage,
            inputValue,
            fieldId,
            isSearchType,
            handleClear,
            showInputClear,
            isFocused,
            fieldAttrs,
            hasError,
            searchInputElement,
        };
    },
});
</script>
