import { accessToken } from '@/project/authentication/authentication';
import globalAxios from 'axios';
import { handleError } from './errorHandler';

globalAxios.interceptors.request.use((config) => {
    if (!config.headers?.Authorization && accessToken.value) {
        config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken.value}`,
        };
    }
    return config;
});

globalAxios.interceptors.response.use((response) => {
    return response;
}, (error) => {
    handleError(error);
    return Promise.reject(error);
});
