import { OrderApiFactory, OrderDetailsViewModel, OrderLineItemViewModel, OrderListViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { ref, Ref } from 'vue';
import tempOrder from '@/project/pages/order-page/dummy-confirmation.json';
import { CategoryAndLineItems } from '@/project/basket/basket.types';

const orderApi = OrderApiFactory({ isJsonMime: () => true }, commerceApiHost);

export async function getOrders(page = 1, pageSize = 10): Promise<OrderListViewModel> {
    const response = await orderApi.apiAccountOrderGet(page, pageSize || 10);

    return response.data.model;
}

export async function getOrder(id: string): Promise<OrderDetailsViewModel> {
    const res = await orderApi.apiAccountOrderIdGet(id);
    return res.data.model;
}

export function useOrder(id: string): {
    quantity: Ref<number>,
    categories: Ref<CategoryAndLineItems[]>,
    items: Ref<OrderLineItemViewModel[]>,
    order: Ref<OrderDetailsViewModel | undefined>,
} {
    const quantity = ref<number>(0);
    const items = ref<OrderLineItemViewModel[]>([]);
    const categories = ref<CategoryAndLineItems[]>([]);
    const order = ref<OrderDetailsViewModel>();

    getOrder(id)
        .then(orderResponse => {
            quantity.value = orderResponse.lineItems.reduce((prev, cur) => prev + cur.quantity, 0);
            items.value = orderResponse.lineItems || [];

            const category: CategoryAndLineItems[] = [];
            orderResponse.lineItems.forEach(item => {
                if (!category[item.firstLevelCategorySortOrder]) {
                    category[item.firstLevelCategorySortOrder] = {
                        categoryName: item.firstLevelCategoryName,
                        lineItems: [],
                    };
                }
                category[item.firstLevelCategorySortOrder].lineItems.push({ ...item, availableInStock: 0, alwaysInStock: true });
            });

            categories.value = category.filter(n => n).sort((a, b) => a.categoryName.localeCompare(b.categoryName));
            order.value = orderResponse;
        }).catch(_ => tempOrder.model);

    return {
        quantity,
        categories,
        items,
        order,
    };
}
