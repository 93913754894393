<template>
    <ServiceMenu v-if="showFullMenu" :links="serviceMenuItems"/>
    <div class="sticky top-0 bg-white shadow-md z-menu border border-white">
        <MainMenu
            v-if="showFullMenu"
            :main-menu-links="leftMainMenuItems"
            :links="rightMainMenuItems"/>
        <MobileMenu
            v-if="!showFullMenu"
            @open="openMobileMenu"/>
    </div>
</template>

<script setup lang="ts">
import ServiceMenu from '@/project/navigation/service-menu/ServiceMenu.vue';
import MainMenu from '@/project/navigation/main-menu/MainMenu.vue';
import MobileMenu from '@/project/navigation/mobile-menu/MobileMenu.vue';
import { useMenus } from '@/project/navigation/menus.composable';
import { useMediaQuery } from '@vueuse/core';

const showFullMenu = useMediaQuery('(min-width: 1025px)');

const { openMobileMenu, leftMainMenuItems, rightMainMenuItems, serviceMenuItems } = useMenus();
</script>

<style scoped>
</style>
