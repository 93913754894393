import { configure, asString, asNumber } from '@/core/browser/query/useQueryParams';

export enum QueryKeys {
    TERM = 'term',
    LAST_FACET = 'lastfacet',
    SORT_BY = 'sortBy',
    PAGE_SIZE = 'pageSize',
    CATEGORY = 'category',
    ORDER_ID = 'orderId',
    PAYMENT_ID = 'paymentId',
    BONUS = 'bonus',
}

configure([QueryKeys.TERM, QueryKeys.LAST_FACET, QueryKeys.SORT_BY, QueryKeys.PAGE_SIZE, QueryKeys.CATEGORY], {}, {
    [QueryKeys.TERM]: asString,
    [QueryKeys.LAST_FACET]: asString,
    [QueryKeys.SORT_BY]: asString,
    [QueryKeys.PAGE_SIZE]: asNumber,
    [QueryKeys.CATEGORY]: asString,
    [QueryKeys.ORDER_ID]: asString,
    [QueryKeys.BONUS]: asString,
}, QueryKeys.LAST_FACET);
