<template>
    <svg width="192px"
         height="169px"
         viewBox="0 0 192 169"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>0988B3ED-9BEB-41FD-9C6D-97DCCFD500EB</title>
        <g id="🎨-Styleguide"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
            <g id="Styleguide" transform="translate(-1702.000000, -1082.000000)" stroke="#006BB6">
                <g id="empty-search" transform="translate(1703.994670, 1083.873895)">
                    <circle id="Oval"
                            stroke-width="3"
                            fill="#F8FCFF"
                            cx="84.7942286"
                            cy="75.5"
                            r="50"/>
                    <path id="Path-3-Copy" d="M67.2942286,110 C79.3585699,116.666667 90.9190584,116.666667 101.975694,110" stroke-width="3" stroke-linecap="round"/>
                    <line id="Line"
                          x1="119.794229"
                          y1="111.5"
                          x2="154.794229"
                          y2="146.5"
                          stroke-width="3"
                          stroke-linecap="round"/>
                    <rect id="Rectangle"
                          stroke-width="3"
                          fill="#F8FCFF"
                          transform="translate(151.940675, 143.474874) rotate(-45.000000) translate(-151.940675, -143.474874) "
                          x="142.940675"
                          y="122.974874"
                          width="18"
                          height="41"
                          rx="2"/>
                    <polygon id="Star"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             points="97.7092921 152.71465 90.9497617 156.268346 92.2407172 148.741498 86.7721422 143.410955 94.3295269 142.312803 97.7092921 135.46465 101.089057 142.312803 108.646442 143.410955 103.177867 148.741498 104.468823 156.268346"/>
                    <polygon id="Star-Copy"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(122.709292, 15.964650) rotate(-34.000000) translate(-122.709292, -15.964650) "
                             points="122.709292 21.7146505 115.949762 25.2683459 117.240717 17.7414982 111.772142 12.410955 119.329527 11.3128028 122.709292 4.46465047 126.089057 11.3128028 133.646442 12.410955 128.177867 17.7414982 129.468823 25.2683459"/>
                    <polygon id="Star-Copy-2"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(15.709292, 38.964650) rotate(-60.000000) translate(-15.709292, -38.964650) "
                             points="15.7092921 44.7146505 8.94976174 48.2683459 10.2407172 40.7414982 4.77214221 35.410955 12.3295269 34.3128028 15.7092921 27.4646505 19.0890573 34.3128028 26.6464421 35.410955 21.1778671 40.7414982 22.4688225 48.2683459"/>
                    <polygon id="Star-Copy-3"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(159.294229, 91.879714) rotate(-160.000000) translate(-159.294229, -91.879714) "
                             points="159.294229 96.919714 154.004161 99.1608669 154.500904 93.4371596 150.73472 89.098561 156.331791 87.8022683 159.294229 82.879714 162.256666 87.8022683 167.853737 89.098561 164.087553 93.4371596 164.584296 99.1608669"/>
                    <polygon id="Star-Copy-5"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(157.089947, 48.373895) rotate(-130.000000) translate(-157.089947, -48.373895) "
                             points="157.089947 52.0138952 153.269343 53.6325056 153.628101 49.498717 150.908079 46.3652847 154.950408 45.4290733 157.089947 41.8738952 159.229485 45.4290733 163.271814 46.3652847 160.551792 49.498717 160.910551 53.6325056"/>
                    <polygon id="Star-Copy-6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(50.089947, 11.373895) rotate(-100.000000) translate(-50.089947, -11.373895) "
                             points="50.0899467 15.0138952 46.2693426 16.6325056 46.628101 12.498717 43.9080794 9.36528471 47.9504084 8.42907332 50.0899467 4.87389518 52.229485 8.42907332 56.2718141 9.36528471 53.5517924 12.498717 53.9105509 16.6325056"/>
                    <polygon id="Star-Copy-7"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(48.089947, 146.373895) rotate(-40.000000) translate(-48.089947, -146.373895) "
                             points="48.0899467 150.013895 44.2693426 151.632506 44.628101 147.498717 41.9080794 144.365285 45.9504084 143.429073 48.0899467 139.873895 50.229485 143.429073 54.2718141 144.365285 51.5517924 147.498717 51.9105509 151.632506"/>
                    <polygon id="Star-Copy-8"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(183.089947, 121.373895) rotate(-90.000000) translate(-183.089947, -121.373895) "
                             points="183.089947 125.013895 179.269343 126.632506 179.628101 122.498717 176.908079 119.365285 180.950408 118.429073 183.089947 114.873895 185.229485 118.429073 189.271814 119.365285 186.551792 122.498717 186.910551 126.632506"/>
                    <polygon id="Star-Copy-4"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(22.294229, 97.879714) rotate(90.000000) translate(-22.294229, -97.879714) "
                             points="22.2942286 102.919714 17.0041614 105.160867 17.5009038 99.4371596 13.73472 95.098561 19.331791 93.8022683 22.2942286 88.879714 25.2566663 93.8022683 30.8537373 95.098561 27.0875535 99.4371596 27.5842959 105.160867"/>
                </g>
            </g>
        </g>
    </svg>
</template>
