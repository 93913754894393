<template>
    <DrawerDialog
        v-slot="{close}"
        :title="deliveryState.type === TimeSlotTypeViewModel.PickUp ? $translate('Client.CheckoutPage.Delivery.PickUpInStoreOption') : $translate('Client.CheckoutPage.Delivery.HomeDeliveryOption')"
        use-slide-animation
        :drawer-classes="'bg-background'"
        :title-classes="'px-30'">
        <div class="pt-5 w-full p-30 sm:w-[644px]">
            <TimeSlotChooser :zip-code="deliveryState.zipCode!"
                             :time-slot-type="deliveryState.type!"
                             :store="deliveryState.store"
                             :small-screen-number="3"
                             @change-zip="changeZip(close)">
                <template #default="{disabled, selectedTimeslot}">
                    <DeliveryNextButton :label="$translate('Client.CheckoutPage.Delivery.SubmitTimeSlot')"
                                        :disabled="disabled"
                                        no-icon
                                        :show-cancel="true"
                                        @cancel="close"
                                        @click="reserveTimeSlot(selectedTimeslot)"/>
                </template>
            </TimeSlotChooser>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import { DeliveryPageStates, RefetchTimeSlots, useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { TimeSlotTypeViewModel, TimeSlotViewModel } from '@/api/commerce';
import TimeSlotChooser from './TimeSlotChooser.vue';
import { reservePickUpTimeSlot, reserveHomeDeliveryTimeSlot } from '@/project/apis/commerce/checkoutApi';
import bus from '@/core/bus';
import { closeDialog } from '@/project/dialog/dialog';
import DeliveryNextButton from '@/project/pages/checkout-page/delivery/DeliveryNextButton.vue';

const { deliveryState } = useDeliveryState();

function reserveTimeSlot(selectedTimeSlot: TimeSlotViewModel | null) {
    if (selectedTimeSlot) {
        if (selectedTimeSlot.timeSlotType === TimeSlotTypeViewModel.PickUp) {
            reservePickUpTimeSlot(selectedTimeSlot.id, Number(deliveryState.zipCode), deliveryState.store!.id)
                .then(() => {
                    if (selectedTimeSlot) {
                        deliveryState.timeSlotId = selectedTimeSlot.id;

                        deliveryState.pageState = DeliveryPageStates.PickupSelected;
                        closeDialog();
                    }
                }).catch(() => {
                    // If error, try to refetch time slots - some may have been reserved
                    bus.emit(RefetchTimeSlots);
                });
        }
        if (selectedTimeSlot.timeSlotType === TimeSlotTypeViewModel.HomeDelivery) {
            reserveHomeDeliveryTimeSlot(selectedTimeSlot.id, Number(deliveryState.zipCode))
                .then(() => {
                    if (selectedTimeSlot) {
                        deliveryState.timeSlotId = selectedTimeSlot.id;

                        deliveryState.pageState = DeliveryPageStates.HomeDeliverySelected;
                        closeDialog();
                    }
                }).catch(() => {
                    // If error, try to refetch time slots - some may have been reserved
                    bus.emit(RefetchTimeSlots);
                });
        }
    }
}

function changeZip(close: () => void) {
    close();
    deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
}
</script>
