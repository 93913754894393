<template>
    <button :aria-label="$translate('Client.A11y.ScrollToTop')" class="flex justify-center items-center bg-white rounded-full cursor-pointer w-50 h-50" @click="scrollToTop">
        <CIcon name="arrow-up"
               width="20"
               height="20"
               class="text-primary"/>
    </button>
</template>

<script setup lang="ts">
const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};
</script>
