/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BasketBonusViewModelClientResponse } from '../models';
// @ts-ignore
import { BasketViewModelClientResponse } from '../models';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { LineItemDeltaRequest } from '../models';
// @ts-ignore
import { LineItemTotalQuantityRequest } from '../models';
/**
 * BasketApi - axios parameter creator
 * @export
 */
export const BasketApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets the estimated bonus for the basket of the current user.  The bonus is estimated as weighted products and product replacements may affect the final bonus calculation.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketBonusGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/bonus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Clears the basket.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketDelete: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs a delta change of product quantity on the line items in the request
         * @param {string} [apiVersion] 
         * @param {Array<LineItemDeltaRequest>} [lineItemDeltaRequest] A list of line item updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketDeltaAddOrUpdateLineItemsAsyncPost: async (apiVersion?: string, lineItemDeltaRequest?: Array<LineItemDeltaRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/DeltaAddOrUpdateLineItemsAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineItemDeltaRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets the basket for the current user.                If the user is authenticated, the basket is linked to the account.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGet: async (apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Performs a total change of product quantity on the line items in the request
         * @param {string} [apiVersion] 
         * @param {Array<LineItemTotalQuantityRequest>} [lineItemTotalQuantityRequest] A list of line item updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketTotalAddOrUpdateLineItemsAsyncPost: async (apiVersion?: string, lineItemTotalQuantityRequest?: Array<LineItemTotalQuantityRequest>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/basket/TotalAddOrUpdateLineItemsAsync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(lineItemTotalQuantityRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasketApi - functional programming interface
 * @export
 */
export const BasketApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BasketApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets the estimated bonus for the basket of the current user.  The bonus is estimated as weighted products and product replacements may affect the final bonus calculation.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketBonusGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketBonusViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketBonusGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Clears the basket.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketDelete(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketDelete(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Performs a delta change of product quantity on the line items in the request
         * @param {string} [apiVersion] 
         * @param {Array<LineItemDeltaRequest>} [lineItemDeltaRequest] A list of line item updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemDeltaRequest?: Array<LineItemDeltaRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion, lineItemDeltaRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets the basket for the current user.                If the user is authenticated, the basket is linked to the account.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketGet(apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketGet(apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Performs a total change of product quantity on the line items in the request
         * @param {string} [apiVersion] 
         * @param {Array<LineItemTotalQuantityRequest>} [lineItemTotalQuantityRequest] A list of line item updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemTotalQuantityRequest?: Array<LineItemTotalQuantityRequest>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion, lineItemTotalQuantityRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BasketApi - factory interface
 * @export
 */
export const BasketApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BasketApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets the estimated bonus for the basket of the current user.  The bonus is estimated as weighted products and product replacements may affect the final bonus calculation.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketBonusGet(apiVersion?: string, options?: any): AxiosPromise<BasketBonusViewModelClientResponse> {
            return localVarFp.apiBasketBonusGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Clears the basket.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketDelete(apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketDelete(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs a delta change of product quantity on the line items in the request
         * @param {string} [apiVersion] 
         * @param {Array<LineItemDeltaRequest>} [lineItemDeltaRequest] A list of line item updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemDeltaRequest?: Array<LineItemDeltaRequest>, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion, lineItemDeltaRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets the basket for the current user.                If the user is authenticated, the basket is linked to the account.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketGet(apiVersion?: string, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketGet(apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Performs a total change of product quantity on the line items in the request
         * @param {string} [apiVersion] 
         * @param {Array<LineItemTotalQuantityRequest>} [lineItemTotalQuantityRequest] A list of line item updates.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemTotalQuantityRequest?: Array<LineItemTotalQuantityRequest>, options?: any): AxiosPromise<BasketViewModelClientResponse> {
            return localVarFp.apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion, lineItemTotalQuantityRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BasketApi - interface
 * @export
 * @interface BasketApi
 */
export interface BasketApiInterface {
    /**
     * 
     * @summary Gets the estimated bonus for the basket of the current user.  The bonus is estimated as weighted products and product replacements may affect the final bonus calculation.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApiInterface
     */
    apiBasketBonusGet(apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketBonusViewModelClientResponse>;

    /**
     * 
     * @summary Clears the basket.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApiInterface
     */
    apiBasketDelete(apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Performs a delta change of product quantity on the line items in the request
     * @param {string} [apiVersion] 
     * @param {Array<LineItemDeltaRequest>} [lineItemDeltaRequest] A list of line item updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApiInterface
     */
    apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemDeltaRequest?: Array<LineItemDeltaRequest>, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Gets the basket for the current user.                If the user is authenticated, the basket is linked to the account.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApiInterface
     */
    apiBasketGet(apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

    /**
     * 
     * @summary Performs a total change of product quantity on the line items in the request
     * @param {string} [apiVersion] 
     * @param {Array<LineItemTotalQuantityRequest>} [lineItemTotalQuantityRequest] A list of line item updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApiInterface
     */
    apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemTotalQuantityRequest?: Array<LineItemTotalQuantityRequest>, options?: AxiosRequestConfig): AxiosPromise<BasketViewModelClientResponse>;

}

/**
 * BasketApi - object-oriented interface
 * @export
 * @class BasketApi
 * @extends {BaseAPI}
 */
export class BasketApi extends BaseAPI implements BasketApiInterface {
    /**
     * 
     * @summary Gets the estimated bonus for the basket of the current user.  The bonus is estimated as weighted products and product replacements may affect the final bonus calculation.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketBonusGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketBonusGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Clears the basket.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketDelete(apiVersion?: string, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketDelete(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs a delta change of product quantity on the line items in the request
     * @param {string} [apiVersion] 
     * @param {Array<LineItemDeltaRequest>} [lineItemDeltaRequest] A list of line item updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemDeltaRequest?: Array<LineItemDeltaRequest>, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketDeltaAddOrUpdateLineItemsAsyncPost(apiVersion, lineItemDeltaRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets the basket for the current user.                If the user is authenticated, the basket is linked to the account.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketGet(apiVersion?: string, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketGet(apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Performs a total change of product quantity on the line items in the request
     * @param {string} [apiVersion] 
     * @param {Array<LineItemTotalQuantityRequest>} [lineItemTotalQuantityRequest] A list of line item updates.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasketApi
     */
    public apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion?: string, lineItemTotalQuantityRequest?: Array<LineItemTotalQuantityRequest>, options?: AxiosRequestConfig) {
        return BasketApiFp(this.configuration).apiBasketTotalAddOrUpdateLineItemsAsyncPost(apiVersion, lineItemTotalQuantityRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
