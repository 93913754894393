export function parseQuantity(value: string): number {
    try {
        let newValue = parseInt(value);
        if (isNaN(newValue) || newValue < 0) {
            newValue = 0;
        }
        return newValue;
    } catch {
        throw Error('Could not parse quantity value');
    }
}
