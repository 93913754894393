<template>
    <div class="mb-30 py-30 rounded-lg bg-white px-20 lg:pl-40">
        <p class="mb-20 w-full">
            {{ $translate('Client.CheckoutPage.Confirmation.Email', model.invoiceAddress?.email ? model.invoiceAddress?.email : '' ) }}
        </p>
        <div class="grid grid-cols-4 lg:grid-cols-12 lg:space-x-20">
            <div class="col-span-4 md:col-span-2 lg:col-span-4">
                <div class="mb-40">
                    <h4 class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.OrderNumber') }}
                    </h4>
                    <span>{{ model.orderId }}</span>
                </div>
                <div class="mb-40">
                    <h4 class="text-18 mb-10 font-bold">
                        {{ $translate('Client.CheckoutPage.CustomerInformation.InvoiceAddress') }}
                    </h4>
                    <span>
                        {{ model.invoiceAddress?.firstName }} {{ model.invoiceAddress?.lastName }}
                    </span>
                    <p class="mb-15">
                        <span class="block">{{ model.invoiceAddress?.addressLine1 }}</span>
                        <span class="block">{{ model.invoiceAddress?.addressLine2 }}</span>
                        <span class="block">{{ model.invoiceAddress?.zipCode }} {{ model.invoiceAddress?.city }} </span>
                    </p>
                    <p class="mb-15">
                        <span class="block">{{ model.invoiceAddress?.companyName }}</span>
                        <span class="block">{{ model.invoiceAddress?.companySocialSecurityNumber }}</span>
                    </p>
                    <p>
                        <span class="block">{{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}: {{ model.invoiceAddress?.mobile }}</span>
                        <span class="block">{{ $translate('Client.CheckoutPage.CustomerInformation.Email') }}: {{ model.invoiceAddress?.email }}</span>
                    </p>
                </div>
            </div>
            <div class="col-span-4 md:col-span-2 lg:col-span-4">
                <div v-if="model.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery" class="mb-40">
                    <h4 class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.DeliveryAddress') }}
                    </h4>
                    <span v-if="deliverySameAsInvoice">{{ $translate('Client.CheckoutPage.Confirmation.SameAsInvoice') }}</span>
                    <div v-else-if="model.deliveryAddress">
                        <p>
                            {{ model.deliveryAddress?.firstName }} {{ model.deliveryAddress?.lastName }}
                        </p>
                        <p class="mb-15">
                            <span class="block">{{ model.deliveryAddress?.addressLine1 }}</span>
                            <span class="block">{{ model.deliveryAddress?.addressLine2 }}</span>
                            <span class="block">{{ model.deliveryAddress?.zipCode }} {{ model.deliveryAddress?.city }} </span>
                        </p>
                        <p>
                            <span class="block">{{ $translate('Client.CheckoutPage.CustomerInformation.Phone') }}: {{ model.deliveryAddress?.mobile }}</span>
                        </p>
                    </div>
                </div>
                <div v-else class="mb-40">
                    <h4 class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.PickUpPoint') }}
                    </h4>
                    <h5 class="text-15">
                        {{ model.store?.name }}
                    </h5>
                    <div>
                        <p class="mb-15">
                            <span class="block">{{ model.store?.address.street }} {{ model.store?.address.houseNumber }}</span>
                            <span class="block">{{ model.store?.address?.zipCode }} {{ model.store?.address.city }} </span>
                        </p>
                    </div>
                </div>
                <div class="mb-40">
                    <h4 v-if="model.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery" class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.Delivery') }}
                    </h4>
                    <h4 v-else class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.PickUpTime') }}
                    </h4>
                    <span class="block">{{ deliveryText }}, {{ deliveryDay }}</span>
                    <span>{{ deliveryTime }}</span>
                </div>
                <div v-if="model.noteToDriver" class="mb-30">
                    <h4 class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.Comment') }}
                    </h4>
                    <span>{{ model.noteToDriver }}</span>
                </div>
            </div>
            <div class="col-span-4 md:col-span-4 lg:col-span-4">
                <div v-if="model.earnedBonus" class="pb-20 lg:px-20 ">
                    <h4 class="text-18 font-bold">
                        {{ $translate('Client.CheckoutPage.Confirmation.EarnedBonus') }}
                    </h4>
                    <span>{{ $translate('Client.CheckoutPage.Confirmation.EstimatedEarnedText') }}</span>
                    <div class="mt-10 flex items-center">
                        <span class="text-bonus mr-5 font-bold">
                            {{ $translate('Client.CheckoutPage.Confirmation.EarnedBonus') }}
                        </span>
                        <PopoverIcon class="inline" icon-name="info" :text="$translate('Client.BasketPage.BonusInfo')"/>
                        <span class="text-bonus ml-auto font-bold">
                            {{ formatPrice(model.earnedBonus) }}
                        </span>
                    </div>
                </div>
                <div class="bg-background rounded-lg">
                    <div class="py-15 border-b-2 border-white px-20">
                        <span class="text-18 font-bold">{{ $translate('Client.CheckoutPage.Confirmation.Payment') }}</span>
                    </div>
                    <div class="py-15 border-b-2 border-white px-20">
                        <div class="flex">
                            <span>{{ $translate('Client.CheckoutPage.Confirmation.PaidWithBonus') }}</span><span class="ml-auto">{{ formatPrice(model.paidBonus) }}</span>
                        </div>
                        <div class="flex">
                            <span>{{ $translate('Client.CheckoutPage.Confirmation.PaidWithCard') }}</span><span class="ml-auto">{{ formatPrice(model.paidCard) }}</span>
                        </div>
                    </div>
                    <div class="py-15 flex border-b-2 border-white px-20">
                        <span class="text-15 font-bold">{{ $translate('Client.BasketPage.Total') }}</span><span class="text-15 ml-auto font-bold">{{ formatPrice(model.total) }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { OrderDetailsViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import { formatPrice } from '@/project/products/priceFormatter';
import dictionary from '@/core/dictionary/dictionary';
import { getWeekday, getDate, getTime } from '@/project/utils/datetime';
import { ref, computed } from 'vue';
import PopoverIcon from '@/project/components/popover-icon/PopoverIcon.vue';

const props = defineProps<{
    model: OrderDetailsViewModel
}>();

const deliveryText = props.model.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery ? dictionary.get('Client.CheckoutPage.Delivery.HomeDeliveryOption') : dictionary.get('Client.CheckoutPage.Delivery.PickUpInStoreOption');
const deliveryTime = ref('');
const deliveryDay = ref('');
const deliverySameAsInvoice = computed(() => {
    const d = props.model.deliveryAddress;
    const i = props.model.invoiceAddress;

    return d?.addressLine1 === i?.addressLine1 && d?.addressLine2 === i?.addressLine2 && d?.zipCode && i?.zipCode;
});

if (props.model.timeSlot) {
    const d = props.model.timeSlot.from;
    const fDate = getDate(d);

    const day = getWeekday(d);

    const fromTime = getTime(d);
    const toTime = getTime(props.model.timeSlot.to);

    deliveryDay.value = day;
    deliveryTime.value = `${fDate} ${dictionary.get('Client.CheckoutPage.Confirmation.Between')} ${fromTime}-${toTime}`;
}

</script>
