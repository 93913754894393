import { Ref, shallowRef } from 'vue';
import { cloneDeep } from 'lodash-es';

export type AnnotatedResult<T> = {
    data: T,
    dataReady: boolean,
    error?: any
};

export function useStaleWhileRevalidate<T>(defaultResponse: () => T, maxCacheEntries = 25): {
    makeReactiveResult: () => Ref<AnnotatedResult<T>>,
    fetchResult(result: Ref<AnnotatedResult<T>>, resultProvider: () => Promise<T>, cacheKey: string): Ref<AnnotatedResult<T>>
} {
    const cache = new Map<string, T>();
    const filo: string[] = [];

    function makeReactiveResult(): Ref<AnnotatedResult<T>> {
        return shallowRef<AnnotatedResult<T>>({
            dataReady: false,
            data: defaultResponse(),
        });
    }

    function fetchResult(result: Ref<AnnotatedResult<T>>, resultProvider: () => Promise<T>, cacheKey: string): Ref<AnnotatedResult<T>> {
        const cachedResult = cacheKey && cache.get(cacheKey);

        if (cachedResult) {
            result.value = {
                dataReady: true,
                data: cachedResult,
            };
        } else {
            result.value = {
                dataReady: false,
                data: defaultResponse(),
            };
        }

        resultProvider()
            .then(callResult => {
                result.value = {
                    data: callResult,
                    dataReady: true,
                };

                if (cacheKey) {
                    cache.set(cacheKey, cloneDeep(callResult));
                    maintainCacheSize(cacheKey);
                }
            })
            .catch((e) => {
                // Keep old result, but set error
                result.value = {
                    ...result.value,
                    error: e,
                    dataReady: true,
                };
            });

        return result;
    }

    function maintainCacheSize(cacheKey: string) {
        filo.push(cacheKey);
        if (filo.length > maxCacheEntries) {
            const removeKey = filo.shift();
            cache.delete(removeKey!);
        }
    }

    return {
        makeReactiveResult,
        fetchResult,
    };
}
