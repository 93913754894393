<template>
    <div class="bg-white">
        <div class="py-18 o-container relative flex items-center justify-between">
            <nav class="flex items-center space-x-40">
                <RouterLink to="/">
                    <CIcon name="netto-logo" width="100" height="31" color="#006BB6"/>
                </RouterLink>
                <div class="mb-8 space-x-40">
                    <RouterLink v-for="item in mainMenuLinks" :key="item.url" :to="item.url" class="text-14">
                        {{ item.name }}
                    </RouterLink>
                    <RouterLink
                        v-for="(item, index) in filteredLinks"
                        :key="item.link?.name || index"
                        :to="item.link?.url || ''"
                        :target="item.link?.target"
                        class="text-14">
                        {{ item.link?.name }}
                    </RouterLink>
                </div>
            </nav>
            <SearchField/>
            <div class="flex items-center space-x-40">
                <MascotWithBonus/>
                <BasketButton label="17.234" :badge-count="27"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouterLink } from 'vue-router';
import BasketButton from '@/project/basket/basket-button/BasketButton.vue';
import { HeaderLinkSectionModel, NameAndUrl } from '@/api/cms';
import SearchField from '@/project/search/SearchField.vue';
import MascotWithBonus from '@/project/navigation/main-menu/MascotWithBonus.vue';

const props = defineProps<{
    mainMenuLinks: NameAndUrl[],
    links: HeaderLinkSectionModel[]
}>();

const filteredLinks = computed(() => props.links.filter(link => !link.needsAuthorization));
</script>
