<template>
    <RouterLinkConditional v-if="to"
                           :url="to"
                           :disabled="disabled"
                           :class="classes"
                           :target="target"
                           @click="onClick">
        {{ label }}
    </RouterLinkConditional>
    <button v-else :class="classes" :disabled="disabled" @click="onClick">
        <slot/>
        <AnimateValue>
            <span v-if="label" :key="label" class="px-15" :class="{'whitespace-nowrap' : nowrap, 'mt-1': search }">{{ label }}</span>
        </AnimateValue>
        <slot name="right"/>
    </button>
</template>

<script setup lang="ts">
import { computed, useSlots } from 'vue';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';

export type ButtonSizes = 'small' | 'medium' | 'large' | 'basket';

interface Props {
    label?: string
    to?: string
    target?: string
    primary?: boolean
    pill?: boolean
    strong?: boolean
    size?: ButtonSizes
    transparent?: boolean
    fullWidth?: boolean
    disabled?: boolean
    nowrap?: boolean
    basket?: boolean
    list?: boolean
    search?: boolean
    whiteBg?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    primary: true,
    size: 'medium',
    label: '',
    to: '',
    target: '_self',
});

const emit = defineEmits(['click']);

const slots = useSlots();

const classes = computed(() => ({
    'text-14 flex justify-center items-center transition-colors duration-200 whitespace-nowrap': true,
    'rounded-lg': !props.pill,
    'h-33 px-20 rounded-full': props.pill,
    'h-40 px-20': props.list,
    'bg-background text-black hover:bg-secondaryButtonHover': props.whiteBg,
    'bg-primary text-white hover:bg-primaryButtonHover': props.primary && !props.transparent && !props.disabled,
    'bg-secondary text-black hover:bg-secondaryButtonHover': !props.primary && !props.disabled && !props.whiteBg,
    'font-bold': props.strong || props.primary,
    'px-5 h-30': props.size === 'small' && !props.pill && !props.list,
    'px-10 h-40': props.size === 'medium' && !props.pill && !props.list,
    'px-10 h-50': props.size === 'large' && !props.pill && !props.list,
    'px-30 h-50 mx-auto w-4/5 lg:w-full': props.size === 'basket' && !props.pill && !props.list,
    'px-20 h-40 md:h-50': !props.size && !props.pill && !props.list && !props.search,
    'pl-15': !!slots.default && props.label,
    'bg-transparent hover:bg-transparent text-primary font-normal': props.transparent,
    'w-full': props.fullWidth,
    'cursor-not-allowed opacity-50 bg-disabledBackground text-disabledText hover:bg-disabledBackground hover:text-disabledText': props.disabled && !props.transparent,
    'cursor-not-allowed opacity-50 bg-transparent text-disabledText hover:bg-transparent hover:text-disabledText': props.disabled && props.transparent,
    'px-[16px]': !!slots.right,
    'h-full': props.search,
}));

const onClick = () => {
    if (!props.disabled) {
        emit('click');
    }
};
</script>
