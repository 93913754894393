<template>
    <div v-if="address">
        <Form v-slot="{errors, isSubmitting}"
              key="customerInfoForm"
              :validation-schema="validationSchema"
              :initial-values="address"
              @submit="onSubmit">
            <div>
                <div class="grid grid-cols-8 gap-20">
                    <div class="col-span-8 sm:col-span-4">
                        <InputText v-model.trim="address.firstName"
                                   type="text"
                                   name="firstName"
                                   validation-behaviour="lazy"
                                   :label="$translate('Client.CheckoutPage.CustomerInformation.FirstName')"/>
                    </div>
                    <div class="col-span-8 sm:col-span-4">
                        <InputText v-model.trim="address.lastName"
                                   type="text"
                                   name="lastName"
                                   validation-behaviour="lazy"
                                   :label="$translate('Client.CheckoutPage.CustomerInformation.LastName')"/>
                    </div>
                    <div class="col-span-8 sm:col-span-4">
                        <InputText v-model="address.mobile"
                                   type="tel"
                                   name="mobile"
                                   validation-behaviour="lazy"
                                   :label="$translate('Client.CheckoutPage.CustomerInformation.Phone')"/>
                    </div>
                </div>
            </div>
            <div class="mt-20">
                <div class="space-y-20">
                    <div class="col-span-8">
                        <InputText v-model.trim="address.addressLine1"
                                   type="text"
                                   name="addressLine1"
                                   :aria-required="true"
                                   validation-behaviour="lazy"
                                   :placeholder="$translate('Client.CheckoutPage.CustomerInformation.AddressLine1Placeholder')"
                                   :label="$translate('Client.CheckoutPage.CustomerInformation.AddressLine1')"/>
                    </div>
                    <div class="col-span-8">
                        <InputText v-model.trim="address.addressLine2"
                                   type="text"
                                   name="addressLine2"
                                   :aria-required="true"
                                   validation-behaviour="lazy"
                                   :placeholder="$translate('Client.CheckoutPage.CustomerInformation.AddressLine2Placeholder')"
                                   :label="$translate('Client.CheckoutPage.CustomerInformation.AddressLine2')"/>
                    </div>
                    <div class="grid grid-cols-8 gap-20">
                        <div class="col-span-2">
                            <InputText v-model="address.zipCode"
                                       type="number"
                                       name="zipCode"
                                       validation-behaviour="lazy"
                                       :label="$translate('Client.CheckoutPage.CustomerInformation.ZipCode')"
                                       disabled/>
                        </div>
                        <div class="col-span-6">
                            <InputText v-model.trim="address.city"
                                       type="text"
                                       name="city"
                                       validation-behaviour="lazy"
                                       :label="$translate('Client.CheckoutPage.CustomerInformation.City')"
                                       disabled/>
                        </div>
                    </div>
                </div>
                <div class="flex justify-end w-full">
                    <Button :label="$translate('Client.CheckoutPage.Delivery.ChangeZip')"
                            class="mt-10 -mr-20"
                            type="button"
                            transparent
                            @click="changeZipCode"/>
                </div>
            </div>
            <div class="mt-10">
                <div class="flex justify-end">
                    <Button type="button"
                            size="medium"
                            :label="$translate('Client.Common.Cancel')"
                            transparent
                            :disabled="!validDeliveryAddress"
                            class="mr-10"
                            @click="emit('cancel')"/>
                    <Button type="submit"
                            size="medium"
                            :label="$translate('Client.CheckoutPage.Delivery.UpdateAddress')"
                            :disabled="Object.keys(errors).length > 0 || isSubmitting"/>
                </div>
                <div v-if="Object.keys(errors).length > 0" class="mt-20 text-right rounded-lg text-errorText">
                    {{ $translate('Client.CheckoutPage.CustomerInformation.ErrorInForm') }}
                </div>
            </div>
        </Form>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { getCheckoutBasket, updateDeliveryAddress } from '@/project/apis/commerce/checkoutApi';
import Button from '@/project/components/button/Button.vue';
import InputText from '@/project/forms/InputText.vue';
import { Form } from 'vee-validate';
import { DeliveryPageStates, useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import { BasketViewModel } from '@/api/commerce';

defineProps<{
    validDeliveryAddress: boolean,
}>();

const emit = defineEmits(['cancel']);

const { basket } = getCheckoutBasket();

const { deliveryState } = useDeliveryState();

const address = ref({ ...basket.value?.deliveryAddress } || { ...basket.value?.invoiceAddress });

// Clear out addressLine1 and addressLine2 if selected zip code does not match stored delivery address
watch([basket], ([b]) => {
    if (b?.selectedZipCode?.zipCode !== address.value.zipCode) {
        address.value.addressLine1 = '';
        address.value.addressLine2 = '';
        address.value.zipCode = b?.selectedZipCode?.zipCode;
        address.value.city = b?.selectedZipCode?.areaName;
    }
}, { immediate: true });

const onSubmit = (model: any) => {
    updateDeliveryAddress(model).then((res: BasketViewModel) => {
        if (res.deliveryAddress) {
            address.value = { ...res.deliveryAddress };
            emit('cancel');
        }
    });
};

const validationSchema: Record<string, string> = {
    firstName: 'required|max:50',
    lastName: 'required|max:49',
    addressLine1: 'required|max:100',
    addressLine2: 'max:50',
    mobile: 'required|number|min:7|max:25',
};

function changeZipCode() {
    deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
}
</script>
