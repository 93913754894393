/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Line item invalid reason
 * @export
 * @enum {string}
 */

export enum LineItemInvalidReasonViewModel {
    ProductNotFound = 'ProductNotFound',
    NotEnoughInStock = 'NotEnoughInStock'
}


