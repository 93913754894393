import { DataLayerEntry } from './tracking.config';
import { ProductTileViewModel, ProductDetailsViewModel, LineItemViewModel, ProductMarking, FacetViewModel, FacetValueViewModel, OrderLineItemViewModel } from '@/api/commerce';
import router, { getPageName, PRODUCT_INFO_SPLITTER } from '@/router';
import { isAuthenticated } from '@/project/authentication/authentication';
import { DeepReadonly } from 'vue';

enum GTMEventType {
    ProductDetail = 'productDetail',
    AddToBasket = 'addToBasket',
    RemoveFromBasket = 'removeFromBasket',
    Checkout = 'checkout',
    CheckoutOption = 'checkout_option',
    TransactionComplete = 'transactionComplete',
    ErrorPage = 'errorPage',
    Variables = 'variables',
    ProductClick = 'productClick',
    ProductImpressions = 'productImpressions',
    OverlayPage = 'overlayPage',
    PromotionView = 'promoView',
    PromotionClick = 'promotionClick',
    GAEvent = 'GAEvent',
    SearchResultPage = 'searchResultPage'
}

const NonCmsPageNames = {
    ProductDetailsPage: 'ProductDetailsPage',
    UnknownPage: 'UnknownPage',
};

export interface TrackingObject {
    productTileMode: boolean,
    productIndex: number,
    contentBlockIndex: number,
}

export function trackPage(pageName?: string): void {
    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.Variables,
            loggedInState: isAuthenticated.value ? 'LoggedIn' : 'LoggedOut',
            pageType: pageName || getPageName(),
        };

    pushToDataLayer(trackingObject);
}

export function trackOverlay(title: string): void {
    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.OverlayPage,
            eventCategory: title,
        };

    pushToDataLayer(trackingObject);
}

export interface ImpressionList {
    pageTypeOverride?: string,
    listType: 'PLP' | 'Product band' | 'Recommended products' | 'MyTop' | 'Search product' | 'Basket' | 'Mini basket list' | string // Add as needed
    choosingMechanism: 'ElasticSearch' | 'Editor' | 'PLP' | 'User history' | 'User' // Add as needed
}

export interface TrackingContext {
    impressionList: ImpressionList,
    index: number,
}

export function trackProductImpressions(products: ProductTileViewModel[], list: ImpressionList, offset = 0): void {
    const trackingObject: DataLayerEntry =
    {
        event: GTMEventType.ProductImpressions,
        ecommerce: {
            currencyCode: 'ISK',
            impressions: setProductsData(products, null, list, offset),
        },
    };

    pushToDataLayer(trackingObject);
}

export function trackGoToPdp(model: ProductTileViewModel, index: number, list: ImpressionList): void {
    const actionField: any = {
        actionField: {
            list: getListType(list),
        },
    };

    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.ProductClick,
            ecommerce: {
                currencyCode: 'ISK',
                click: {
                    ...actionField,
                    products: [{
                        ...setProductDetailsData(model, index),
                    },
                    ],
                },
            },
        };

    pushToDataLayer(trackingObject);
}

interface AddToBasketOptions {
    product: LineItemViewModel | ProductDetailsViewModel | ProductTileViewModel,
    quantity: number,
    increasedQuantity: boolean,
    lineItemMode: boolean,
    list?: ImpressionList,
    index?: number
}

export function trackAddToBasket(options: AddToBasketOptions): void {
    const { increasedQuantity, lineItemMode, product, quantity, index, list } = options;
    const actionField: any = list
        ? {
            actionField: {
                list: getListType(list),
            },
        }
        : {};

    const propName = increasedQuantity ? 'add' : 'remove';
    const trackingObject: DataLayerEntry =
        {
            event: increasedQuantity ? GTMEventType.AddToBasket : GTMEventType.RemoveFromBasket,
            ecommerce: {
                currencyCode: 'ISK',
                [propName]: {
                    ...actionField,
                    products: [
                        lineItemMode
                            ? { ...setLineItemData(product as LineItemViewModel, index), quantity }
                            : { ...setProductDetailsData(product as ProductDetailsViewModel, index), quantity },
                    ],
                },
            },
        };

    pushToDataLayer(trackingObject);
}

export function trackCheckoutStep(step: number, lineItems: DeepReadonly<LineItemViewModel[] | undefined>, option?: string): void {
    let actionField: any = {
        step: step,
    };

    if (option) {
        actionField = {
            ...actionField,
            option: option,
        };
    }

    pushToDataLayer(getCheckoutTrackingObject(GTMEventType.Checkout, actionField, lineItems));
}

export function trackCheckoutOption(step: number, lineItems: DeepReadonly<LineItemViewModel[] | undefined>, option: string): void {
    const actionField = {
        step: step,
        option: option,
    };

    pushToDataLayer(getCheckoutTrackingObject(GTMEventType.CheckoutOption, actionField, lineItems));
}

export function trackProductDetailsPage(model: ProductDetailsViewModel): void {
    const products = setProductDetailsData(model);

    const trackingObject: DataLayerEntry =
        {
            event: GTMEventType.ProductDetail,
            ecommerce: {
                currencyCode: 'ISK',
                detail: {
                    products: [
                        products,
                    ],
                },
            },
        };

    pushToDataLayer(trackingObject);
}

export function trackReceiptPage(paymentId: string, total: number, vat: number, deliveryPrice: number, packagingPrice: number, lineItems: OrderLineItemViewModel[]): void {
    const products = lineItems.map((lineItem) => {
        return {
            ...setLineItemData(lineItem as LineItemViewModel),
        };
    });

    const trackingObject: DataLayerEntry = {
        event: GTMEventType.TransactionComplete,
        ecommerce: {
            currencyCode: 'ISK',
            purchase: {
                actionField: {
                    id: paymentId,
                    revenue: total,
                    tax: vat,
                    metric1: packagingPrice,
                    shipping: deliveryPrice,
                },
                products: products,
            },
        },
    };

    // trackPaymentMethod();
    pushToDataLayer(trackingObject);
}

export function trackMiniBasket(minimumAchieved: boolean, quantity: number): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.GAEvent,
        eventCategory: 'MiniBasket',
        eventAction: minimumAchieved ? 'Minimum achieved' : 'Minimum not achieved',
        eventValue: quantity,
    };

    pushToDataLayer(trackingObject);
}

export function trackDeliveryCheck(minimumAchieved: boolean, quantity: number): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.GAEvent,
        eventCategory: 'DeliveryCheck',
        eventAction: minimumAchieved ? 'Minimum achieved' : 'Minimum not achieved',
        eventValue: quantity,
    };

    pushToDataLayer(trackingObject);
}

export function trackStoreFinding(zipCode: number, storeName: string, useDefaultZipCode = true, viewOpeningHours = false, viewOnMap = false): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.GAEvent,
        eventCategory: 'Stores',
        eventAction: `${useDefaultZipCode ? 'Default look-up' : 'Manual look-up'}: ${zipCode}`,
        eventLabel: viewOpeningHours || viewOnMap ? `${viewOpeningHours ? 'View opening hours' : ''}${viewOnMap ? 'View on map' : ''}: ${storeName}` : undefined,
        eventValue: undefined,
    };

    pushToDataLayer(trackingObject);
}

export function trackSearchIntent(): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.GAEvent,
        eventCategory: 'searchIntent',
        eventAction: undefined,
        eventLabel: undefined,
        eventValue: undefined,
    };

    pushToDataLayer(trackingObject);
}

export function trackSearchResult(searchTerm: string, searchProductCount: number): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.SearchResultPage,
        searchTerm,
        searchProductCount,
    };

    pushToDataLayer(trackingObject);
}

export function trackFacetUse(action: 'Add' | 'Remove', facets: Record<string, string | string[]>, lastFacet: FacetViewModel, lastFacetValue: FacetValueViewModel): void {
    if (Array.isArray(facets[lastFacet.key])) {
        if (action === 'Remove') {
            facets = { ...facets, [lastFacet.key]: (facets[lastFacet.key] as string[]).filter(s => s !== lastFacetValue.value) };
        }
    }

    const trackingObject: DataLayerEntry = {
        event: GTMEventType.GAEvent,
        eventCategory: 'Facet',
        eventAction: `${action}=${lastFacet.key}:${lastFacetValue.value}`,
        eventLabel: Object.keys(facets).map(k => (facets[k] as string[]).map(v => `${k}:${v}`).join(', ')).join(', ').trim(),
        eventValue: undefined,
    };

    pushToDataLayer(trackingObject);
}

export function trackSorting(newSorting: string, oldSorting?: string): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.GAEvent,
        eventCategory: 'Facet',
        eventAction: `Sorting: ${newSorting}`,
        eventLabel: oldSorting ? `Sorting: ${oldSorting}` : undefined,
        eventValue: undefined,
    };

    pushToDataLayer(trackingObject);
}

export interface GTMPromotion {
    nameOfModule: string,
    headlineOrImageName: string,
    creative: string,
    index: number,
}

export function trackPromotionImpressions(promotions: GTMPromotion[]): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.PromotionView,
        ecommerce: {
            promoView: {
                promotions: [
                    ...preparePromotions(promotions),
                ],
            },
        },
    };

    pushToDataLayer(trackingObject);
}

export function trackPromotionClick(promotions: GTMPromotion[]): void {
    const trackingObject: DataLayerEntry = {
        event: GTMEventType.PromotionClick,
        ecommerce: {
            promoClick: {
                promotions: [
                    ...preparePromotions(promotions),
                ],
            },
        },
    };

    pushToDataLayer(trackingObject);
}

function preparePromotions(promotions: GTMPromotion[]) {
    return promotions.map(p => ({
        id: `${p.nameOfModule}_${p.headlineOrImageName}_${getPageName()}_${p.index}`,
        name: `${p.nameOfModule}_${p.headlineOrImageName}`,
        creative: p.creative,
        position: p.index,
    }));
}

function setProductDetailsData(model: ProductTileViewModel | ProductDetailsViewModel, index: number | null = null) {
    const productData: any = {
        name: model.displayName,
        id: model.sku,
        price: model.salesPrice,
        brand: model.brand,
        category: model.url?.substring(1, model.url?.lastIndexOf('/')), // Using url because breadcrumbs aren't available at all times
        dimension4: certificationsToString(model.icons),
        dimension5: model.offer?.description,
        position: index !== null ? index : '',
    };

    return productData;
}

function setLineItemData(model: LineItemViewModel, index: number | null = null) {
    const productData: any = {
        name: model.displayName,
        id: model.sku,
        price: model.salesPrice,
        brand: model.brand,
        category: model.url?.substring(1, model.url?.lastIndexOf('/')), // Using url because breadcrumbs aren't available at all times
        dimension4: certificationsToString(model.icons),
        dimension5: model.discount,
        position: index != null ? index + 1 : '',
        quantity: model.quantity,
    };

    return productData;
}

function setProductsData(products: ProductTileViewModel[], index: number | null = null, list: ImpressionList, offset = 0) {
    const productsData = products.map((item) => {
        return {
            ...setProductDetailsData(item, index),
            list: getListType(list),
            position: products.indexOf(item) + 1 + offset,
        };
    });

    return productsData;
}

function getListType(list: ImpressionList) {
    if (list.pageTypeOverride) {
        return `${list.pageTypeOverride}/${list.listType}/${list.choosingMechanism}`;
    }

    let pageName = NonCmsPageNames.UnknownPage;
    const _pageName = getPageName();
    if (_pageName) {
        pageName = `${_pageName.substring(0, 1).toLocaleUpperCase()}${_pageName.substring(1)}`;
    } else if (router.currentRoute.value.path.includes(PRODUCT_INFO_SPLITTER)) {
        pageName = NonCmsPageNames.ProductDetailsPage;
    }
    return `${pageName}/${list.listType}/${list.choosingMechanism}`;
}

// function setStockStatus(stockResult?) {
//     const { shouldShowStockStatus, allReserved, fewAvailable, soldOut } = useStockStatus(stockResult);
//     if (allReserved.value) {
//         return StockStatus.AllReserved;
//     }
//     else if (soldOut.value) {
//         return StockStatus.SoldOut;
//     }
//     else if (fewAvailable.value) {
//         return StockStatus.FewAvailable;
//     }
//     else if (!shouldShowStockStatus.value) {
//         return StockStatus.Available;
//     }
// }

function certificationsToString(items: ProductMarking[]) {
    return items.map(i => i.substring(0, 1).toLocaleUpperCase() + i.substring(1)).join('/');
}

function getCheckoutTrackingObject(checkoutKey, actionField, lineItems: DeepReadonly<LineItemViewModel[] | undefined>) {
    const products = lineItems?.map((lineItem) => {
        return {
            ...setLineItemData(lineItem as LineItemViewModel),
        };
    });
    const trackingObject: DataLayerEntry = {
        event: checkoutKey,
        ecommerce: {
            [checkoutKey]: {
                actionField: actionField,
                products: products,
            },
        },
    };

    return trackingObject;
}

function pushToDataLayer(trackingObject: DataLayerEntry): void {
    // console.log(trackingObject);
    window.dataLayer && window.dataLayer.push(trackingObject);
}
