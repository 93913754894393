<template>
    <div :class="isMobile ? 'w-full' : 'sm:w-[400px]'">
        <div class="pb-20 h-full bg-white rounded-lg">
            <div class="flex items-center pb-10 border-b pt-15 px-30 text-18 border-lightBorder" :class="{'justify-center block border-b-0': isMobile}">
                <CIcon v-if="!isMobile" width="24" height="24" name="myaccount"/>
                <span class="ml-10 font-bold text-18">{{ $translate('Client.OrderPage.Greeting', user || '') }}</span>
            </div>
            <div v-if="isMobile">
                <div class="mt-10 space-x-10 text-center">
                    <RouterLink :to="menu.url">
                        <div class="inline-block px-10 mb-10 rounded-full border-2 border-white leading-28 text-14 bg-background"
                             :class="menu.selected && menu.children.filter(i => i.selected).length === 0 ? 'text-link font-bold' : 'text-link cursor-pointer'">
                            {{ menu.name }}
                        </div>
                    </RouterLink>
                    <RouterLink v-for="item in menu.children" :key="item.name" :to="item.url">
                        <div class="inline-block px-10 mb-10 rounded-full border-2 border-white leading-28 text-14 bg-background"
                             :class="item.selected ? 'text-link font-bold' : 'text-link cursor-pointer'">
                            {{ item.name }}
                        </div>
                    </RouterLink>
                    <span class="inline-block px-10 rounded-full border-2 border-white cursor-pointer leading-28 bg-background"
                          @click="logout">
                        <CIcon name="logout" width="16" height="16" class="text-link"/> <span class="ml-5 text-link">{{ $translate('Client.Common.Logout') }}</span>
                    </span>
                </div>
            </div>
            <div v-else>
                <div class="py-20 px-30">
                    <RouterLink v-if="menu.children.length > 0" :to="menu.url">
                        <span class="cursor-pointer text-link" :class="{'font-bold': menu.selected}">
                            {{ menu.name }}
                        </span>
                    </RouterLink>
                    <RouterLink v-else :to="menu.url">
                        <span class="cursor-pointer text-link" :class="{'font-bold': menu.selected}">
                            {{ menu.name }}
                        </span>
                    </RouterLink>
                    <div v-for="item in menu.children" :key="item.name" class="ml-20 mt-15">
                        <RouterLink :to="item.url">
                            <span class="cursor-pointer text-link" :class="{'font-bold !text-black': item.selected}">
                                {{ item.name }}
                            </span>
                        </RouterLink>
                    </div>
                </div>
                <div v-if="!isFromLobycoApp" class="pt-10 space-y-20 border-t border-t-background px-30">
                    <span class="flex items-center cursor-pointer" @click="logout">
                        <CIcon name="logout" width="16" height="16"/> <span class="ml-10 ">{{ $translate('Client.Common.Logout') }}</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { SiteMenuItem } from '@/api/cms';
import { logout } from '@/project/authentication/authentication';
import { useLobyco } from '@/project/authentication/useLobyco';

defineProps<{
    user?: string,
    isMobile?: boolean,
    menu: SiteMenuItem,
}>();

const { isFromLobycoApp } =  useLobyco();
</script>
