import { App } from 'vue';
import { config as dictionaryConfig } from './dictionary/dictionary';
import responsiveConfig from './responsive';
import renderingConfig from './rendering';
import layoutConfig from './layout';
import formsConfig from './forms';
import browserConfig from './browser';
import navigationConfig from './navigation';

export default async function config(app: App): Promise<void> {
    dictionaryConfig(app);
    responsiveConfig(app);
    formsConfig(app);
    browserConfig(app);
    layoutConfig(app);
    renderingConfig(app);
    navigationConfig(app);
}
