<template>
    <div class="flex flex-col justify-center items-center w-full bg-white rounded-lg duration-500 ease-in-out transition-maxHeight"
         :class="showAllCategories ? 'max-h-2000' : 'max-h-200'">
        <h1 class="font-bold text-center text-28 text-primary">
            {{ metadata.navigationTitle }}
        </h1>
        <div class="p-2 mt-10 space-x-10 text-center"
             :class="{'overflow-hidden h-35' : !showAllCategories, 'h-[68px]' : (!showAllCategories && model.curatedCategories.length > 0)}">
            <RouterLink v-for="category in cmsCategories"
                        :key="category.url"
                        :ref="mobileItemRef"
                        :to="category.url"
                        class="inline-block px-20 rounded-full border-2 border-white leading-28 text-14 bg-background"
                        :class="[category.name === metadata.navigationTitle ? 'text-black font-bold cursor-default' : 'text-link', showAllCategories ? 'mb-10' : '']">
                {{ category.name }}
            </RouterLink>

            <div v-if="model.curatedCategories.length > 0" class="mt-10 mb-10 font-bold text-18">
                {{ $translate('Client.CategoryPage.CuratedTitle') }}
            </div>
            <RouterLink v-for="curated in model.curatedCategories"
                        :key="curated.url"
                        :ref="mobileItemRef"
                        :to="curated.url"
                        class="inline-block px-20 mb-10 rounded-full border-2 border-white leading-28 text-14 text-link bg-background">
                {{ curated.name }}
            </RouterLink>
        </div>
        <Button v-if="hiddenItems"
                :label="showAllCategories ? $translate('Client.CategoryPage.ShowLessCategories') : $translate('Client.CategoryPage.ShowAllCategories')"
                :primary="false"
                pill
                class="text-black mt-15 bg-background hover:bg-background focus:bg-background focus-within:bg-background"
                @click="showAllCategories = !showAllCategories">
            <template #right>
                <CIcon name="arrow-down"
                       width="10"
                       height="10"
                       class="transition-transform duration-200 ease-in-out transform"
                       :class="showAllCategories ? 'rotate-180': ' rotate-0'"/>
            </template>
        </Button>
    </div>
</template>

<script setup lang="ts">
import { CategoryPageModel, IMetadata } from '@/api/cms';
import { onBeforeUpdate, onMounted, onUnmounted, ref } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useCmsCategories } from './cms-category-composable';

defineProps<{
    model: CategoryPageModel,
    metadata: IMetadata,
}>();

const showAllCategories = ref(false);
let itemRefs:any[] = [];
const hiddenItems = ref(false);

const { cmsCategories } = useCmsCategories();

const mobileItemRef = (el) => {
    if (el) {
        itemRefs.push(el);
    }
};

const checkIndexes = () => {
    hiddenItems.value = false;
    for (const i in itemRefs) {
        const el = itemRefs[i].$el;
        const p = el.parentNode.clientHeight + el.parentNode.offsetTop - 12; // take margin into account otherwise p is higher than child element offset when overflowing
        if (el.offsetTop > p && !showAllCategories.value) {
            el.tabIndex = -1;
            hiddenItems.value = true;
        } else {
            if (el.classList.contains('text-black')) {
                el.tabIndex = -1;
            } else {
                el.tabIndex = 0;
            }
        }
    }
};

onBeforeUpdate(() => {
    itemRefs = [];
});

onMounted(() => {
    checkIndexes();
    window.addEventListener('resize', checkIndexes, true);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkIndexes, true);
});

</script>
