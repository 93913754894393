import { SearchApiFactory, SearchRequest, ProductSearchResultViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { AnnotatedResult, useStaleWhileRevalidate } from '@/core/cache/useStaleWhileRevalidate';
import { ref, Ref } from 'vue';
import axios, { CancelTokenSource } from 'axios';

const searchApi = SearchApiFactory({ isJsonMime: () => true }, commerceApiHost);

const {
    makeReactiveResult,
    fetchResult,
} = useStaleWhileRevalidate<ProductSearchResultViewModel>(() => ({
    results: [],
    facets: [],
    sortBy: [],
    totalHits: 0,
    categories: [],
}));

const cancelTokenSrc: Record<string, CancelTokenSource> = {};

export function useSearch(searchRequest?: SearchRequest): {
    searchResult: Ref<AnnotatedResult<ProductSearchResultViewModel>>,
    search: typeof search
} {
    const searchResult = makeReactiveResult();

    function search(searchRequest: SearchRequest, cancelKey?: string) {
        // Fetch all if no specific
        searchRequest.size ||= 30;
        cancelKey ||= 'search';

        if (cancelKey) {
            // Cancel previous and create new for this cancel-key
            if (cancelTokenSrc[cancelKey]) {
                cancelTokenSrc[cancelKey].cancel();
            }
            cancelTokenSrc[cancelKey] = axios.CancelToken.source();
        }

        const searchResultProvider = () => searchApi.apiCatalogProductsSearchPost(undefined, searchRequest, {
            cancelToken: cancelKey && cancelTokenSrc[cancelKey]?.token,
        }).then(searchResponse => searchResponse.data.model);

        fetchResult(searchResult, searchResultProvider, JSON.stringify(searchRequest));
    }

    if (searchRequest) {
        search(searchRequest);
    }

    return {
        search,
        searchResult,
    };
}

const isSearchActive = ref(false);
const isSearching = ref(false);

export function useSearchToggle(): { isSearchActive: Ref<boolean>, setSearch: (value: boolean) => void, isSearching: Ref<boolean>, searchDone: (value: boolean) => void, } {
    function setSearch(value: boolean) {
        isSearchActive.value = value;
    }
    function searchDone(value: boolean) {
        isSearching.value = !value;
    }
    return { isSearchActive, setSearch, isSearching, searchDone };
}
