import { OrderDetailsViewModel, StoreViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import { reactive } from 'vue';

export const RefetchTimeSlots = 'RefetchTimeSlots';

export enum DeliveryPageStates {
    NotYetDecided,
    ChooseDeliveryTypeAndTimeSlot,
    PickupSelected,
    HomeDeliverySelected,
    ChooseExistingOrder,
    ExistingPickupSelected,
    ExistingHomeDeliverySelected,
}

export type DeliveryState = {
    zipCode: number | null,
    type: TimeSlotTypeViewModel | null,
    store: StoreViewModel | null,
    timeSlotId: number | null,
    pageState: DeliveryPageStates,
    existingOrder: OrderDetailsViewModel | null,
}

const deliveryState = reactive<DeliveryState>({
    zipCode: null,
    type: null,
    store: null,
    timeSlotId: null,
    pageState: DeliveryPageStates.NotYetDecided,
    existingOrder: null,
});

function reset() {
    deliveryState.zipCode = null;
    deliveryState.type = null;
    deliveryState.store = null;
    deliveryState.timeSlotId = null;
    deliveryState.pageState = DeliveryPageStates.NotYetDecided;
    deliveryState.existingOrder = null;
}

export function useDeliveryState(): { deliveryState: typeof deliveryState, reset: typeof reset} {
    return { deliveryState, reset };
}
