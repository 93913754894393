<template>
    <div class="flex">
        <div class="flex items-start">
            <CIcon name="checkcircle" width="20" height="20" class="text-bonus"/>
        </div>
        <div class="ml-10">
            <div class="font-bold">
                {{ $translate('Client.CheckoutPage.Delivery.DeliveryDate') }}
            </div>
        </div>
    </div>
    <div class="flex mt-10 mb-20">
        <Button v-if="showArrows"
                class="flex justify-center items-center mr-1 rounded-r-none cursor-pointer shrink-0 w-50 h-50"
                :class="{'bg-backgroundContrastBlue hover:bg-backgroundContrastBlue/60': !disableLeftButton}"
                transparent
                :disabled="disableLeftButton"
                @click="left">
            <CIcon name="arrow-left"
                   width="14"
                   height="14"
                   class="text-black"/>
        </Button>
        <div ref="datesContainer"
             class="flex overflow-hidden snap-x dates-container"
             :style="{'width': `${numberOfDatesShown * DATE_WIDTH}px`}">
            <button v-for="(date, index) in dates"
                    :key="date.date"
                    class="flex flex-col items-center p-7 ml-1 cursor-pointer snap-start shrink-0 w-70 sm:w-[6.8rem] h-50"
                    :class="{'bg-blue text-white/95': index === selectedDateIndex,
                             'bg-disabledBackground': date.isSoldOut && index !== selectedDateIndex,
                             'hover:bg-blue/20 transition-colors duration-200 ease-in-out': index !== selectedDateIndex,
                             'rounded-l-lg': !showArrows && index === 0,
                             'rounded-r-lg': !showArrows && index === dates.length - 1,
                             'bg-backgroundContrastBlue': index !== selectedDateIndex && !date.isSoldOut}"
                    :tabindex="index < totalTabIndexedDates ? 0 : -1"
                    @click="emit('onSelect', date.date), selectedDateIndex = index">
                <div class="-mb-4 font-bold">
                    {{ getWeekday(date.date, true) }}
                </div>
                <div class="whitespace-nowrap">
                    {{ new Date(date.date).getDate() }}. {{ getMonth(date.date, true) }}
                </div>
            </button>
        </div>
        <Button v-if="showArrows"
                class="flex justify-center items-center ml-1 rounded-l-none cursor-pointer shrink-0 w-50 h-50"
                :class="{'bg-backgroundContrastBlue hover:bg-backgroundContrastBlue/60': !disableRightButton}"
                transparent
                :disabled="disableRightButton"
                @click="right">
            <CIcon name="arrow-right"
                   width="14"
                   height="14"
                   class="text-black"/>
        </Button>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { getWeekday, getMonth } from '@/project/utils/datetime';
import Button from '@/project/components/button/Button.vue';
import { TimeSlotDate } from '@/api/commerce';

const props = defineProps<{
    dates: TimeSlotDate[]
    smallScreenNumber: number
}>();

const emit = defineEmits<{(e: 'onSelect', date: string): void}>();

const { smaller } = useBreakpoints();
const isMobile = smaller('sm');

const datesContainer = ref();
const totalTabIndexedDates = computed(() => isMobile.value ? props.smallScreenNumber : 7);
const numberOfDatesShown = computed(() => {
    if (isMobile.value) {
        if (props.dates.length > props.smallScreenNumber) {
            return props.smallScreenNumber;
        } else {
            return props.dates.length;
        }
    } else {
        if (props.dates.length > 7) {
            return 7;
        } else {
            return props.dates.length;
        }
    }
});
const showArrows = computed(() => props.dates.length > numberOfDatesShown.value);
const disableLeftButton = ref(true);
const disableRightButton = ref(false);
const DATE_WIDTH = 70;

const selectedDateIndex = ref(0);

watch(() => props.dates, (dates) => {
    if (dates.length > 0) {
        const dateWithSelectedTimeslot = dates.findIndex(d => d.hasSelectedTimeSlot);

        selectedDateIndex.value = dateWithSelectedTimeslot > -1 ? dateWithSelectedTimeslot : dates.findIndex(d => !d.isSoldOut);
    }
});

onMounted(() => {
    document.addEventListener('keyup', arrowKeysNavigation);
});

onUnmounted(() => {
    document.removeEventListener('keyup', arrowKeysNavigation);
});

function arrowKeysNavigation(e: KeyboardEvent) {
    if (e.code === 'ArrowLeft' && selectedDateIndex.value !== 0) {
        left();
    }

    if (e.code === 'ArrowRight' && selectedDateIndex.value !== props.dates.length - 1) {
        right();
    }
}

function left() {
    datesContainer.value.scrollBy({
        top: 0,
        left: -DATE_WIDTH,
        behavior: 'smooth',
    });

    disableRightButton.value = false;

    // Times 1.1 because the value of scrollLeft can differ between 69 - 71.
    if (datesContainer.value.scrollLeft < DATE_WIDTH * 1.1) {
        disableLeftButton.value = true;
    }
}

function right() {
    datesContainer.value.scrollBy({
        top: 0,
        left: DATE_WIDTH,
        behavior: 'smooth',
    });

    disableLeftButton.value = false;
    // Times 1.1 because the value of scrollLeft can differ between 69 - 71.
    if (datesContainer.value.scrollLeftMax - datesContainer.value.scrollLeft < DATE_WIDTH * 1.1) {
        disableRightButton.value = true;
    }
}
</script>

<style scoped>

</style>
