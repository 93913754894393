import { AccountApiFactory, CustomerBonusBalanceViewModel, CustomerInformationViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { isAuthenticated } from '@/project/authentication/authentication';
import { Ref, ref, watch } from 'vue';

const accountApi = AccountApiFactory({ isJsonMime: () => true }, commerceApiHost);

export async function getCustomerInfo(): Promise<CustomerInformationViewModel> {
    const response = await accountApi.apiAccountCustomerInformationGet();

    return response.data.model;
}

export async function getCustomerBonusBalance(): Promise<CustomerBonusBalanceViewModel> {
    const response = await accountApi.apiAccountBonusBalanceGet();

    return response.data.model;
}

const customerInfo: Ref<CustomerInformationViewModel | null> = ref(null);

export function useCustomerInfo(): Ref<CustomerInformationViewModel | null> {
    watch(isAuthenticated, async(authenticated) => {
        if (authenticated) {
            if (!customerInfo.value) {
                customerInfo.value = await getCustomerInfo();
            }
        } else {
            customerInfo.value = null;
        }
    }, { immediate: true });

    return customerInfo;
}
