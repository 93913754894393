/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Basket validation status codes
 * @export
 * @enum {string}
 */

export enum BasketValidityViewModel {
    Valid = 'Valid',
    HasInvalidLineItem = 'HasInvalidLineItem',
    IsEmpty = 'IsEmpty',
    MinimumSubTotalNotReached = 'MinimumSubTotalNotReached',
    MinimumSubTotalNotReachedButCustomerHasOpenOrder = 'MinimumSubTotalNotReachedButCustomerHasOpenOrder',
    PaymentAuthorizationFailed = 'PaymentAuthorizationFailed',
    PaymentIdInvalid = 'PaymentIdInvalid',
    CustomerIdMissing = 'CustomerIdMissing',
    TimeSlotMissing = 'TimeSlotMissing',
    TimeSlotLeadTimePassed = 'TimeSlotLeadTimePassed',
    DeliveryAddressMissing = 'DeliveryAddressMissing',
    DeliveryAddressFirstNameMissing = 'DeliveryAddressFirstNameMissing',
    DeliveryAddressLastNameMissing = 'DeliveryAddressLastNameMissing',
    DeliveryAddressStreetMissing = 'DeliveryAddressStreetMissing',
    DeliveryAddressHouseNumberMissing = 'DeliveryAddressHouseNumberMissing',
    DeliveryAddressZipCodeMissing = 'DeliveryAddressZipCodeMissing',
    DeliveryAddressCityMissing = 'DeliveryAddressCityMissing',
    DeliveryAddressDoesNotMatchSelectedTimeSlot = 'DeliveryAddressDoesNotMatchSelectedTimeSlot',
    StoreMissing = 'StoreMissing',
    StoreAddressDoesNotMatchSelectedTimeSlot = 'StoreAddressDoesNotMatchSelectedTimeSlot',
    InvoiceAddressMissing = 'InvoiceAddressMissing',
    InvoiceAddressFirstNameMissing = 'InvoiceAddressFirstNameMissing',
    InvoiceAddressLastNameMissing = 'InvoiceAddressLastNameMissing',
    InvoiceAddressStreetMissing = 'InvoiceAddressStreetMissing',
    InvoiceAddressHouseNumberMissing = 'InvoiceAddressHouseNumberMissing',
    InvoiceAddressZipCodeMissing = 'InvoiceAddressZipCodeMissing',
    InvoiceAddressCityMissing = 'InvoiceAddressCityMissing',
    InvoiceAddressEmailMissing = 'InvoiceAddressEmailMissing',
    InvoiceAddressMobileMissing = 'InvoiceAddressMobileMissing',
    SelectedProductReplacementOptionNotSet = 'SelectedProductReplacementOptionNotSet',
    TermsAndConditionsNotAccepted = 'TermsAndConditionsNotAccepted'
}


