<template>
    <DrawerDialog
        :title="$translate('Client.MyAccount.EditCustomerInfoTitle')"
        use-slide-animation
        :drawer-classes="'bg-background'">
        <iframe class="w-full h-full sm:w-[470px]" :src="lobycoMemberProfileUrl"></iframe>
    </DrawerDialog>
</template>

<script setup lang="ts">
import { lobycoMemberProfileUrl } from '@/core/infrastructure/environment';
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import { trackOverlay } from '@/project/tracking/tracking.service';

trackOverlay('Edit customer information');
</script>
