<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="navigation.breadcrumb.slice(1)" :current-page-name="metadata.navigationTitle"/>
        <div v-if="isLargeScreen" class="xl:flex xl:justify-between xl:items-center mb-20 bg-white rounded-lg p-15">
            <div class="flex flex-col xl:flex-row justify-center xl:items-baseline xl:justify-left">
                <div>
                    <h1 class="font-bold text-center xl:text-left text-28 lg:text-40 text-primary">
                        {{ metadata.navigationTitle }}
                    </h1>
                </div>
            </div>
        </div>
        <SearchMobileNavigation v-if="!isLargeScreen"
                                :term="''"
                                :title="metadata.navigationTitle"
                                :title-color="'text-blue'"
                                :model="initialCategories"
                                :current="categoryId"
                                :allow-deselect="true"
                                @set-category="setCategory"/>
        <EmptyResults v-if="isAuthenticated && results?.length === 0" :title="$translate('Client.Top100.EmptyList')">
            <EmptyTop100Icon/>
        </EmptyResults>

        <div v-if="isAuthenticated" class="flex space-x-20">
            <!-- Navigation and filters shown on large screens -->
            <div v-if="isLargeScreen" class="space-y-20 w-284">
                <div v-if="totalHits > 0" class="bg-white rounded-lg pb-25">
                    <div class="pb-10 font-bold border-b pt-15 px-30 text-18 border-lightBorder">
                        {{ $translate('Client.CategoryPage.NavigationTitle') }}
                    </div>
                    <div v-if="initialCategories.length > 0" class="pt-20 space-y-20 px-30">
                        <div v-for="category in initialCategories" :key="category.displayName">
                            <span class="cursor-pointer"
                                  :class="category.categoryId === categoryId ? 'text-black font-bold' : 'text-blue'"
                                  @click="setCategory(category.categoryId)">
                                {{ category.displayName }}
                            </span>
                        </div>
                    </div>
                </div>

                <div class="bg-white rounded-lg min-w-[284px]" :class="{'min-h-[300px]': loading}">
                    <ProductFilters v-if="facets.length > 0" :model="facets" :bonus="bonusFacet" @toggle-bonus="onlyBonusRequest = onlyBonusRequest === 'true' ? 'false' : 'true'"/>
                </div>
            </div>

            <div class="w-full">
                <!-- Filter and sorting shown on small screens -->
                <div v-if="!isLargeScreen" class="flex relative flex-col sm:justify-center items-center mb-10 sm:mb-20 space-y-10">
                    <div class="flex justify-center space-x-10">
                        <Button :label="`${$translate('Client.ProductFilters.Title')} ${numberOfAppliedFacets > 0 ? `(${numberOfAppliedFacets})`: ''}`"
                                :primary="false"
                                :list="true"
                                @click="openFilters">
                            <CIcon name="filter" width="16" height="16"/>
                        </Button>
                    </div>

                    <!-- Number of products found -->
                    <span class="sm:absolute sm:left-0 text-18">
                        {{ totalHits > 100 ? 100 : totalHits }} {{ totalHits === 1 ? $translate('Client.CategoryPage.SingleProductFound') : $translate('Client.CategoryPage.ProductsFound') }}
                    </span>
                </div>

                <!-- Grid of products -->
                <div v-if="!loading && results?.length === 0" class="flex justify-center w-full">
                    {{ $translate('Client.CategoryPage.NoProductsFound') }}
                </div>
                <div v-else-if="loading" class="grid grid-cols-8 md:grid-cols-12 grid-flow-row auto-rows-max gap-10 px-10 sm:px-0 rounded-lg lg:grid-cols-25">
                    <div v-for="(_, index) in Array.from({length: 15})"
                         :key="index"
                         class="col-span-4 md:col-span-3 lg:col-span-5 bg-white rounded-lg animate-pulse h-[380px]"></div>
                </div>
                <div v-else-if="!loading && results?.length! > 0" class="grid grid-cols-8 md:grid-cols-12 grid-flow-row auto-rows-max gap-10 px-10 sm:px-0 rounded-lg lg:grid-cols-25">
                    <RemovableTile v-for="(product, index) in results"
                                   :key="product.sku"
                                   :sku="product.sku"
                                   class="col-span-4 md:col-span-3 lg:col-span-5"
                                   @on-removed="refetchResults">
                        <ProductTile
                            :model="product"
                            class="h-full"
                            :tracking-context="{index, impressionList: {listType: 'MyTop', choosingMechanism: 'User history'}}"/>
                    </RemovableTile>
                </div>
            </div>
        </div>
        <div v-else class="flex justify-center items-center py-20 md:py-80 w-full bg-white rounded-lg">
            <div class="flex flex-col items-center p-20">
                <EmptyResults :title="$translate('Client.Top100.NotLoggedIn')">
                    <EmptyTop100Icon/>
                </EmptyResults>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { IMetadata, SiteNavigation } from '@/api/cms';
import { watch, ref, onUnmounted, computed } from 'vue';
import ProductFilters from '@/project/products/product-filters/ProductFilters.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import Button from '@/project/components/button/Button.vue';
import openDialog from '@/project/dialog/dialog';
import ProductFiltersDialog from '@/project/products/product-filters/ProductFiltersDialog.vue';
import { useTopSearch } from '@/project/apis/commerce/top100Api';
import { FacetOption, FacetValueViewModel, FacetViewModel, ProductTileViewModel } from '@/api/commerce';
import ProductTile from '@/project/products/product-tile/ProductTile.vue';
import { useFacets, useRouteQuery } from '@/core/browser/query/useQueryParams';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import { QueryKeys } from '@/project/browser/query';
import CIcon from '@/core/layout/svgicon/CIcon.vue';
import { CategoryTreeViewModel } from '@/api/commerce/models/category-tree-view-model';
import { useCategories } from '@/project/pages/category-page/search-category-composable';
import SearchMobileNavigation from '@/project/search/SearchMobileNavigation.vue';
import { isAuthenticated } from '@/project/authentication/authentication';
import EmptyResults from '@/project/components/empty-results/EmptyResults.vue';
import EmptyTop100Icon from './EmptyTop100Icon.vue';
import RemovableTile from '@/project/products/product-tile/RemovableTile.vue';
import { trackPage } from '@/project/tracking/tracking.service';

defineProps<{
    metadata: IMetadata,
    navigation: SiteNavigation,
}>();

trackPage();

const { setCategories, setOfferMode } = useCategories();
setOfferMode(false);

const breakpoints = useBreakpoints();

const isLargeScreen = breakpoints.greater('xl');

const loading = ref(false);
const results = ref<ProductTileViewModel[] | null>(null);
const facets = ref<FacetViewModel[]>([]);
const totalHits = ref(0);
const categoryId = useRouteQuery(QueryKeys.CATEGORY);
const categories = ref<CategoryTreeViewModel[]>([]);
const initialCategories = ref<CategoryTreeViewModel[]>([]);
const bonusFacet = ref<FacetValueViewModel>();
const onlyBonusRequest = useRouteQuery(QueryKeys.BONUS);

const {
    facets: facetsFromUrl,
} = useFacets();

const numberOfAppliedFacets = computed(() => Object.values(facetsFromUrl.value).flat().length);
const numberOfResults = computed(() => results.value?.length ?? 0);
const { search, searchResult } = useTopSearch();

const setCategory = (id:string) => {
    if (categoryId.value === id) {
        categoryId.value = '';
    } else {
        categoryId.value = id;
    }
};

const openFilters = () => {
    openDialog(ProductFiltersDialog, { model: facets, numberOfResults });
};

watch(searchResult, (res) => {
    loading.value = !res.dataReady;
    if (res.dataReady && isAuthenticated.value) {
        results.value = res.data.results;
        facets.value = res.data.facets;
        totalHits.value = res.data.totalHits;
        categories.value = res.data.categories;
        bonusFacet.value = res.data.bonus;

        if (initialCategories.value?.length === 0 && res.data.categories?.length !== 0) {
            initialCategories.value = res.data.categories;
        }
        setCategories(res.data.categories);
    }
});

function requestifyFacets(facetsFromUrl: Record<string, string | string[]>): Array<FacetOption> {
    const rawArrays = Object.entries(facetsFromUrl).reduce((previousVal: Array<FacetOption>, [key, value]) => {
        const arrayedValue = Array.isArray(value) ? value : [value];
        return previousVal.concat({
            key,
            values: arrayedValue,
        });
    }, []);
    return rawArrays;
}

watch([facetsFromUrl, categoryId, onlyBonusRequest], ([facetValue, categoryIdValue, onlyBonusValue]) => {
    search({
        facetOptions: requestifyFacets(facetValue),
        size: 100,
        categories: categoryIdValue ? [categoryIdValue] : null,
        includeFacets: true,
        includeCategories: true,
        onlyBonus: onlyBonusValue === 'true',
    });
}, { immediate: true, deep: true });

onUnmounted(() => {
    setCategories([]);
    setOfferMode(false);
});

function refetchResults() {
    search({
        facetOptions: requestifyFacets(facetsFromUrl.value),
        size: 100,
        categories: categoryId.value ? [categoryId.value] : null,
        includeFacets: true,
        includeCategories: true,
        onlyBonus: onlyBonusRequest.value === 'true',
    });
}
</script>
