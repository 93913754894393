<template>
    <Transition
        name="fade"
        appear>
        <div class="relative w-full min-h-half page-spacing bg-background z-1">
            <div class="flex space-x-20">
                <!-- Navigation and filters shown on large screens -->
                <Transition
                    name="fade"
                    mode="out-in">
                    <div v-if="isLargeScreen" class="space-y-20 w-284">
                        <div v-if="totalHits > 0" class="bg-white rounded-lg pb-25">
                            <!-- Desktop navigation -->
                            <div class="pb-10 font-bold border-b pt-15 px-30 text-18 border-lightBorder">
                                {{ $translate('Client.CategoryPage.NavigationTitle') }}
                            </div>
                            <div v-if="initialCategories.length > 0" class="pt-20 space-y-20 px-30">
                                <div v-for="category in initialCategories" :key="category.displayName">
                                    <a class="cursor-pointer"
                                       tabindex="0"
                                       :class="category.categoryId === categoryId ? 'text-black font-bold' : 'text-blue'"
                                       @click="setCategory(category.categoryId, category.displayName)">
                                        {{ category.displayName }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="bg-white rounded-lg">
                            <ProductFilters v-if="facets.length > 0" :model="facets" :bonus="bonusFacet" @toggle-bonus="onlyBonusRequest = onlyBonusRequest === 'true' ? 'false' : 'true'"/>
                        </div>
                    </div>
                </Transition>
                <Transition
                    name="fade"
                    mode="out-in">
                    <div v-if="totalHits > 0" class="relative w-full">
                        <div v-if="isLargeScreen" class="sm:flex relative flex-wrap sm:items-center mb-20 bg-white rounded-lg p-15">
                            <h2 class="mr-0 font-bold md:pl-15 text-30">
                                {{ $translate('Client.Search.Results') }} <span class="text-blue">{{ termQuery }}</span>
                            </h2>
                            <span v-if="!isSearching" class="mt-10 md:ml-20">

                                <Transition name="fade">
                                    <span v-if="!isSearching">{{ totalHits }}</span>
                                    <span v-else class="opacity-0">0</span>
                                </Transition>
                                {{ totalHits === 1 ? $translate('Client.CategoryPage.SingleProductFound') : $translate('Client.CategoryPage.ProductsFound') }} </span>
                            <div v-if="isLargeScreen" class="ml-auto">
                                <!-- Sorting dropdown shown on large screens -->
                                <Button
                                    :label="`${sortByQuery || sortBy[0] ? $translate(`Client.CategoryPage.Sorting.${sortByQuery || sortBy[0]}`) : ''}`"
                                    :primary="false"
                                    @click="openSorting">
                                    <template #right>
                                        <CIcon name="arrow-down" width="12" height="12"/>
                                    </template>
                                </Button>
                            </div>
                        </div>

                        <SearchMobileNavigation v-if="!isLargeScreen"
                                                :term="termQuery"
                                                :model="initialCategories"
                                                :current="categoryId"
                                                @set-category="setCategory"/>
                        <!-- Filter and sorting shown on small screens -->
                        <div v-if="!isLargeScreen" class="flex relative flex-col sm:justify-center items-center md:mb-20 space-y-10">
                            <span v-if="isMediumScreen" class="absolute left-0 mt-10 md:ml-20 text-18">{{ totalHits }}
                                {{ totalHits === 1 ? $translate('Client.CategoryPage.SingleProductFound') : $translate('Client.CategoryPage.ProductsFound') }} </span>
                            <div class="flex justify-center space-x-10">
                                <Button :label="`${$translate('Client.ProductFilters.Title')} ${numberOfAppliedFacets > 0 ? `(${numberOfAppliedFacets})`: ''}`"
                                        :primary="false"
                                        :list="true"
                                        @click="openFilter">
                                    <CIcon name="filter" width="16" height="16"/>
                                </Button>
                                <Button
                                    :label="`${sortByQuery || sortBy[0] ? $translate(`Client.CategoryPage.Sorting.${sortByQuery || sortBy[0]}`) : ''}`"
                                    :primary="false"
                                    :list="true"
                                    @click="openSorting">
                                    <template #right>
                                        <CIcon name="arrow-down" width="12" height="12"/>
                                    </template>
                                </Button>
                            </div>
                        </div>

                        <div v-if="!isLargeScreen && !isMediumScreen" class="flex justify-center mb-20">
                            <span class="mt-10 md:ml-20 text-18">{{ totalHits }}
                                {{ totalHits === 1 ? $translate('Client.CategoryPage.SingleProductFound') : $translate('Client.CategoryPage.ProductsFound') }}</span>
                        </div>
                        <!-- Grid of products -->
                        <Transition name="fade" mode="out-in">
                            <div id="search-results"
                                 key="products"
                                 ref="tileWrapper"
                                 class="grid grid-cols-8 md:grid-cols-12 grid-flow-row auto-rows-max gap-10 px-10 sm:px-0 rounded-lg lg:grid-cols-25">
                                <ProductTile v-for="(product, index) in results"
                                             :key="`${product.sku}-${product.availableInStock}`"
                                             :model="product"
                                             :tracking-context="{index, impressionList: {listType: 'Search product', choosingMechanism: 'ElasticSearch'}}"
                                             class="col-span-4 md:col-span-3 lg:col-span-5"/>
                            </div>
                        </Transition>
                        <Transition name="fade">
                            <ShowMoreButton v-if="!isSearching"
                                            :page-size="DEFAULT_PAGE_SIZE"
                                            :max-page-size="MAX_PAGE_SIZE"
                                            :number-of-results="results.length"
                                            :total="totalHits"
                                            @click="rememberPositionBottom"/>
                        </Transition>
                    </div>
                </Transition>
            </div>
            <Transition
                name="fade"
                mode="out-in">
                <div v-if="totalHits === 0" class="p-20 w-full bg-white rounded-lg">
                    <div class="flex flex-col items-center">
                        <h2 class="font-bold text-center md:text-left text-28 md:text-40">
                            {{ $translate('Client.Search.NoResults') }} <span class="text-blue">{{ termQuery }}</span>
                        </h2>
                        <NoSearchResultsIcon class="my-40"/>
                        <div class="mt-20 text-16 customer-info" v-html="customerServiceModel?.customerService.searchText"></div>
                    </div>
                </div>
            </Transition>
        </div>
    </Transition>
</template>

<script lang="ts" setup>

import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import ShowMoreButton from './show-more-button/ShowMoreButton.vue';
import ProductTile from '@/project/products/product-tile/ProductTile.vue';
import { computed, onBeforeUnmount, ref, watch } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useSearch, useSearchToggle } from '../apis/commerce/searchApi';
import { ProductTileViewModel, FacetViewModel, FacetOption, CategoryTreeViewModel, FacetValueViewModel } from '@/api/commerce/models';
import { useFacets, useRouteQuery } from '@/core/browser/query/useQueryParams';
import { QueryKeys } from '@/project/browser/query';
import ProductFilters from '@/project/products/product-filters/ProductFilters.vue';
import openDialog from '@/project/dialog/dialog';
import ProductFiltersDialog from '@/project/products/product-filters/ProductFiltersDialog.vue';
import SortingDrawer from '@/project/pages/category-page/SortingDrawer.vue';
import SearchMobileNavigation from './SearchMobileNavigation.vue';
import { getFooter, getLeftMainMenu } from '@/project/apis/cms/contentApi';
import { NameAndUrl, SiteSettingFooterModel } from '@/api/cms/models';
import bus from '@/core/bus';
import { BlurSearch } from './search-input.service';
import NoSearchResultsIcon from './NoSearchResultsIcon.vue';
import { trackPage, trackProductImpressions, trackSearchResult } from '@/project/tracking/tracking.service';
import debounce from 'lodash-es/debounce';
import { useScrollAfterLoad } from '@/project/utils/scroll-after-load.composable';
import { isAuthenticated } from '@/project/authentication/authentication';

trackPage('SearchPage');

window.scrollTo(0, 0);

const { element: tileWrapper, rememberPositionBottom, scrollToPreviousBottom } = useScrollAfterLoad();

const breakpoints = useBreakpoints();

const isMediumScreen = breakpoints.greater('md');
const isLargeScreen = breakpoints.greater('xl');

const { search, searchResult } = useSearch();
const { isSearching, searchDone } = useSearchToggle();
const results = ref<ProductTileViewModel[]>([]);
const facets = ref<FacetViewModel[]>([]);
const sortBy = ref<string[]>([]);
const totalHits = ref(1);

const mainMenuLinks = ref<NameAndUrl[]>([]);

getLeftMainMenu().then(_leftMainMenuItems => {
    mainMenuLinks.value = _leftMainMenuItems;
});

const {
    facets: facetsFromUrl,
} = useFacets();

const DEFAULT_PAGE_SIZE = 30;
const MAX_PAGE_SIZE = 300;
const numberOfAppliedFacets = computed(() => Object.values(facetsFromUrl.value).flat().length);
const numberOfResults = computed(() => results.value.length);

const sortByQuery = useRouteQuery(QueryKeys.SORT_BY);
const termQuery = useRouteQuery(QueryKeys.TERM);
const pageSize = useRouteQuery(QueryKeys.PAGE_SIZE);
const categoryId = useRouteQuery(QueryKeys.CATEGORY);
const categories = ref<CategoryTreeViewModel[]>([]);
const initialCategories = ref<CategoryTreeViewModel[]>([]);
const bonusFacet = ref<FacetValueViewModel>();
const onlyBonusRequest = useRouteQuery(QueryKeys.BONUS);

const customerServiceModel = ref<SiteSettingFooterModel>();
getFooter().then(_model => { customerServiceModel.value = _model; });

let prevQuery = '';
const setCategory = (id: string, displayName: string) => {
    trackPage(`SearchPage/${displayName}`);
    if (categoryId.value === id) {
        categoryId.value = '';
    } else {
        categoryId.value = id;
    }
};

let previousLength = 0;
let prevNumberOfAppliedFacets = 0;
let prevSort = '';
let initialSearch = true;
watch(searchResult, (res) => {
    if (res.dataReady) {
        results.value = res.data.results;
        facets.value = res.data.facets;
        sortBy.value = res.data.sortBy;
        totalHits.value = res.data.totalHits;
        categories.value = res.data.categories;
        bonusFacet.value = res.data.bonus;

        if (!initialSearch && prevQuery !== termQuery.value) {
            initialCategories.value = res.data.categories;
            // When making a new query only return default result size
            pageSize.value = DEFAULT_PAGE_SIZE.toString();
            trackSearchResult(termQuery.value, res.data.totalHits);
            window.scrollTo({ top: 0 });
        }
        initialSearch = false;

        const facetsHaveChanged = prevNumberOfAppliedFacets !== numberOfAppliedFacets.value;
        const sortHasChanged = prevSort !== sortByQuery.value;

        // watch is triggered more than once on the same results, so only track if the length has changed
        if (res.data.totalHits !== previousLength || prevQuery !== termQuery.value || facetsHaveChanged || sortHasChanged) {
            const pageSizeNumber = Number(pageSize.value);
            // If facets changed the page size should go back to 30.
            const _pageSize = pageSizeNumber > 0 && !facetsHaveChanged ? pageSizeNumber : DEFAULT_PAGE_SIZE;

            trackProductImpressions(res.data.results.slice(_pageSize - DEFAULT_PAGE_SIZE, _pageSize), { listType: 'Search product', choosingMechanism: 'ElasticSearch' }, _pageSize - DEFAULT_PAGE_SIZE);
            previousLength = res.data.totalHits;
            prevNumberOfAppliedFacets = numberOfAppliedFacets.value;
            prevSort = sortByQuery.value;
        }

        prevQuery = termQuery.value;
        searchDone(true);

        scrollToPreviousBottom();
    }
});
const openFilter = () => {
    openDialog(ProductFiltersDialog, { model: facets, bonus: bonusFacet, numberOfResults });
};
const openSorting = () => {
    openDialog(SortingDrawer, { items: sortBy.value, label: 'Client.CategoryPage.Sorting', onChange: onSelectSortBy, selected: sortByQuery.value || sortBy.value[0] });
};

function requestifyFacets(facetsFromUrl: Record<string, string | string[]>): Array<FacetOption> {
    const rawArrays = Object.entries(facetsFromUrl).reduce((previousVal: Array<FacetOption>, [key, value]) => {
        const arrayedValue = Array.isArray(value) ? value : [value];
        return previousVal.concat({
            key,
            values: arrayedValue,
        });
    }, []);

    return rawArrays;
}

watch([facetsFromUrl, sortByQuery, pageSize, termQuery, categoryId, onlyBonusRequest, isAuthenticated], debounce(([facetValue, sortByValue, pageSizeValue, termValue, categoryIdValue, onlyBonusValue]) => {
    searchDone(false);

    let _pageSize = DEFAULT_PAGE_SIZE;
    // If a facet is changed, go back to page size 30 - no need to fetch more
    if (prevNumberOfAppliedFacets === numberOfAppliedFacets.value) {
        _pageSize = pageSizeValue;
    } else {
        pageSize.value = DEFAULT_PAGE_SIZE.toString();
    }

    if (_pageSize > MAX_PAGE_SIZE) {
        pageSize.value = MAX_PAGE_SIZE.toString();
    }

    search({
        facetOptions: requestifyFacets(facetValue),
        offset: 0, // Viewing more results than the first 30 seems so unlikely that we haven't implemented offset, but it's a potential improvement.
        size: _pageSize <= MAX_PAGE_SIZE ? _pageSize : MAX_PAGE_SIZE,
        onlyOffers: false,
        onlyBonus: onlyBonusValue === 'true',
        categories: categoryIdValue ? [categoryIdValue] : null,
        sortBy: sortByValue,
        term: termValue,
        includeFacets: true,
        includeCategories: true,
        hideOutOfStock: false,
    });
}, 200), { immediate: true });

const onSelectSortBy = (sortBy: string) => {
    sortByQuery.value = sortBy;
};

onBeforeUnmount(() => {
    bus.emit(BlurSearch);
    searchDone(true);
});
</script>

<style scoped>
.fade-enter-active{
    transition: all .5s;
}
.fade-leave-active{
    transition: all .2s;
}
.fade-enter, .fade-leave-active, .fade-enter-from{
    opacity: 0;
}

.customer-info:deep() a{
    @apply text-blue;
}
</style>
