<template>
    <div class="flex justify-center items-center py-40 w-full bg-white rounded-lg">
        <div class="flex flex-col items-center">
            <slot class="mb-40"></slot>
            <div v-if="title" class="p-20 mb-10 text-30">
                <span>{{ title }} </span>
            </div>
            <div class="flex space-x-40">
                <RouterLink v-for="item in mainMenuLinks"
                            :key="item.url"
                            :to="item.url"
                            class="text-14 text-link">
                    {{ item.name }}
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { NameAndUrl } from '@/api/cms';
import { getLeftMainMenu } from '@/project/apis/cms/contentApi';
import { ref } from 'vue';

defineProps<{
    title?: string
}>();

const mainMenuLinks = ref<NameAndUrl[]>([]);

getLeftMainMenu().then(_leftMainMenuItems => {
    mainMenuLinks.value = _leftMainMenuItems;
});

</script>
