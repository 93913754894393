<template>
    <div class="relative w-62 h-74">
        <CIcon name="spinner-apple"
               width="62"
               height="74"
               original/>
        <CIcon name="spinner-bubble"
               width="30"
               height="25"
               class="absolute bottom-3 left-7 animate-long-bounce"
               original/>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Spinner',
});
</script>

<style scoped>
</style>
