<template>
    <RouterLink :to="previousStep.url">
        <div class="flex items-center text-primary">
            <CIcon name="arrow-left" width="10" height="10" class="mr-10 text-primary"/>
            <span v-if="isMobile">{{ $translate('Client.Common.Back') }}</span>
            <span v-else>{{ $translate('Client.Common.Back') }} {{ $translate('Client.Common.To') }} {{ previousStep.name }}</span>
        </div>
    </RouterLink>
</template>

<script setup lang="ts">
import { NameAndUrl } from '@/api/cms';
import dictionary from '@/core/dictionary/dictionary';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { getCheckoutSteps, getPageUrls } from '@/project/apis/cms/contentApi';
import { computed, ref, Ref } from 'vue';
import { useRoute } from 'vue-router';

const { smaller } = useBreakpoints();

const isMobile = smaller('md');

const route = useRoute();

const { basketUrl } = getPageUrls();

const steps: Ref<NameAndUrl[]> = ref([]);

getCheckoutSteps().then(res => {
    steps.value = res;
});

const previousStep: Ref<NameAndUrl> = computed(() => {
    const currentIndex = steps.value.findIndex(i => i.url === route.path);
    if (currentIndex === 0) {
        return {
            name: dictionary.get('Client.CheckoutPage.BasketStep'),
            url: basketUrl.value,
        };
    } else {
        const previous = steps.value[currentIndex - 1];
        if (previous) {
            return previous;
        }
    }
    return { name: '', url: '' };
});
</script>
