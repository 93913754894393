<template>
    <Field v-slot="{ field }"
           v-model="internalValue"
           :name="name"
           type="radio"
           :value="value">
        <div class="flex items-start sm:items-center">
            <input
                :id="fieldId"
                v-bind="{ ...$attrs, ...field }"
                type="radio"
                class="p-9"
                :class="{ '!border-red-500' : hasError }"
                :name="name"
                :value="value"
                :checked="checked">
            <label :for="fieldId" class="ml-7" :class="{'cursor-pointer': !$attrs.disabled}">
                <span v-if="label">{{ label }}</span>
                <span v-else-if="htmlLabel" v-html="htmlLabel"/>
            </label>
        </div>
        <InputErrorMessage
            v-if="showErrors"
            :name="name"/>
    </Field>
</template>

<script lang="ts">
import { computed, defineComponent, ref, Ref, toRef, watch } from 'vue';
import { Field, useFieldError } from 'vee-validate';
import { CustomInputFieldInternalValue } from '@/core/forms/vee-validate.types';
import InputErrorMessage from '@/project/forms/InputErrorMessage.vue';

export default defineComponent({
    name: 'InputRadio',
    components: { Field, InputErrorMessage },
    props: {
        id: {
            type: String,
            required: false,
            default: null,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Object, Boolean, Number],
            required: true,
        },
        // eslint-disable-next-line vue/require-default-prop
        modelValue: {
            type: null,
        },
        checked: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        htmlLabel: {
            type: String,
            default: '',
        },
        showErrors: {
            type: Boolean,
            default: false,
        },
    },
    emits: {
        'update:modelValue': (value) => true,
    },
    setup(props, { emit }) {
        const internalValue: Ref<CustomInputFieldInternalValue> = ref(null);

        const hasError = useFieldError('shippingMethod');

        const fieldId = computed(() => {
            return props.id || props.name + Math.random();
        });

        const isModelBound = computed(() => {
            return 'modelValue' in props;
        });

        if (isModelBound.value) {
            const modelValue = toRef(props, 'modelValue');
            watch(modelValue, newModelValue => {
                if (newModelValue !== internalValue.value) {
                    internalValue.value = newModelValue;
                }
            }, { immediate: true });
            watch(internalValue, newInternalValue => {
                emit('update:modelValue', newInternalValue);
            });
        }

        return {
            hasError,
            fieldId,
            internalValue,
        };
    },
});
</script>
