import { NameAndUrl, HeaderLinkSectionModel, SiteSettingServiceMenuItemModel } from '@/api/cms';
import { getLeftMainMenu, getRightMainMenu, getServiceMenu } from '@/project/apis/cms/contentApi';
import { computed, ref } from 'vue';
import MobileMenuDrawer from '@/project/navigation/mobile-menu/MobileMenuDrawer.vue';
import { isAuthenticated } from '@/project/authentication/authentication';
import openDialog from '@/project/dialog/dialog';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';

export function useMenus(): {
    openMobileMenu: typeof openMobileMenu,
    leftMainMenuItems: typeof leftMainMenuItems,
    rightMainMenuItems: typeof rightMainMenuItems,
    serviceMenuItems: typeof serviceMenuItems,
    renderInMobileMenu: typeof renderInMobileMenu,
    drawerWidth: typeof drawerWidth,
    } {
    const leftMainMenuItems = ref<NameAndUrl[]>([]);
    const rightMainMenuItems = ref<HeaderLinkSectionModel[]>([]);

    const serviceMenuItems = ref<SiteSettingServiceMenuItemModel[]>([]);

    getRightMainMenu().then(_rightMainMenuItems => {
        rightMainMenuItems.value = _rightMainMenuItems;
    });
    getLeftMainMenu().then(_leftMainMenuItems => {
        leftMainMenuItems.value = _leftMainMenuItems;
    });
    getServiceMenu().then(_serviceMenuItems => {
        serviceMenuItems.value = _serviceMenuItems;
    });

    const filteredMenuLinks = computed(() => rightMainMenuItems.value.filter(link => !link.needsAuthorization));

    const filteredServiceMenuLinks = computed(() => serviceMenuItems.value.filter(link => (!isAuthenticated.value && link.showWhenNotAuthenticated) || (isAuthenticated.value && link.showWhenAuthenticated)));

    const openMobileMenu = () => {
        openDialog(MobileMenuDrawer, {
            leftMenuLinks: leftMainMenuItems,
            rightMenuLinks: filteredMenuLinks,
            serviceMenuLinks: filteredServiceMenuLinks,
        });
    };

    const { smaller } = useBreakpoints();
    const renderInMobileMenu = smaller('lg');
    const drawerWidth = renderInMobileMenu.value ? 'w-[320px]' : 'w-[644px]';

    return { leftMainMenuItems, rightMainMenuItems, serviceMenuItems, openMobileMenu, renderInMobileMenu, drawerWidth };
}
