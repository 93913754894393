/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Timeslot status view model
 * @export
 * @enum {string}
 */

export enum TimeSlotStatusViewModel {
    Available = 'Available',
    SoldOut = 'SoldOut',
    Selected = 'Selected'
}


