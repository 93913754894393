import { configureBreakpoints } from '@/core/responsive/breakpoints/breakpointsConfig';

export const XS = 'xs';
export const SM = 'sm';
export const MD = 'md';
export const LG = 'lg';
export const XL = 'xl';
export const XXL = 'xxl';

/* REMEMBER TO KEEP IN SYNC WITH TAILWINDS screens */
export const breakpoints = {
    [XS]: 0, // Pure mobile
    [SM]: 640, // Mobile and tablet vertical
    [MD]: 768, // Up to and including old tablet horizontal
    [LG]: 1024, // Desktop,
    [XL]: 1440, // Desktop large
    [XXL]: 1920, // Desktop extra large
};

configureBreakpoints(breakpoints);
