<template>
    <div class="flex flex-col justify-center items-center w-full bg-white rounded-lg duration-500 ease-in-out transition-maxHeight"
         :class="showAllCategories ? 'max-h-2000' : 'max-h-200'">
        <h1 class="font-bold text-center text-28 text-primary">
            {{ metadata.navigationTitle }}
        </h1>
        <div class="p-2 mt-10 space-x-10 text-center" :class="{'overflow-hidden h-35' : !showAllCategories}">
            <div v-for="category in items"
                 :key="category.displayName"
                 :ref="mobileItemRef"
                 class="inline-block px-20 rounded-full border-2 border-white leading-28 text-14 bg-background"
                 :class="[category.displayName === metadata.navigationTitle ? 'text-black font-bold cursor-default' : 'text-link', showAllCategories ? 'mb-10' : '']">
                <!-- If no category id, link to content page via url instead -->
                <RouterLink
                    v-if="isOffersMode && !category.categoryId"
                    :to="getUrl(category, isOffersMode)">
                    {{ $translate("Client.CategoryPage.AllOffers") }}
                </RouterLink>
                <RouterLink
                    v-else
                    :to="getUrl(category, isOffersMode)">
                    {{ category.displayName }}
                </RouterLink>
            </div>
        </div>
        <Button v-if="hiddenItems"
                :label="showAllCategories ? $translate('Client.CategoryPage.ShowLessCategories') : $translate('Client.CategoryPage.ShowAllCategories')"
                :primary="false"
                pill
                class="text-black mt-15 bg-background hover:bg-background focus:bg-background focus-within:bg-background"
                @click="showAllCategories = !showAllCategories">
            <template #right>
                <CIcon name="arrow-down"
                       width="10"
                       height="10"
                       class="transition-transform duration-200 ease-in-out transform"
                       :class="showAllCategories ? 'rotate-180': ' rotate-0'"/>
            </template>
        </Button>
    </div>
</template>

<script setup lang="ts">
import { CategoryPageModel, IMetadata } from '@/api/cms';
import { computed, onBeforeUpdate, onMounted, onUnmounted, ref } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useCategories } from './search-category-composable';
import { useTimeoutFn } from '@vueuse/core';

defineProps<{
    model: CategoryPageModel,
    metadata: IMetadata,
    isOffersMode: boolean,
}>();

const showAllCategories = ref(false);
let itemRefs:any[] = [];
const hiddenItems = ref(false);

const { children, siblings, getCategoryUrl: getUrl } = useCategories();

const items = computed(() => children.value.length > 0 ? children.value : siblings.value);

const mobileItemRef = (el) => {
    if (el) {
        itemRefs.push(el);
    }
};

const checkIndexes = () => {
    hiddenItems.value = false;
    for (const i in itemRefs) {
        const el = itemRefs[i].$el;
        const p = el.parentNode.clientHeight + el.parentNode.offsetTop - 12; // take margin into account otherwise p is higher than child element offset when overflowing
        if (el.offsetTop > p && !showAllCategories.value) {
            el.tabIndex = -1;
            hiddenItems.value = true;
        } else {
            if (el.classList.contains('text-black')) {
                el.tabIndex = -1;
            } else {
                el.tabIndex = 0;
            }
        }
    }
};

onBeforeUpdate(() => {
    itemRefs = [];
});

onMounted(() => {
    useTimeoutFn(() => {
        checkIndexes();
    }, 50);
    window.addEventListener('resize', checkIndexes, true);
});

onUnmounted(() => {
    window.removeEventListener('resize', checkIndexes, true);
});
</script>
