<template>
    <div class="px-20 mb-1 w-full bg-white min-h-38">
        <div class="flex justify-end o-container">
            <div class="flex py-10 space-x-30">
                <button class="flex items-center cursor-pointer text-12" @click="openDeliveryChecker">
                    <CIcon name="delivery-simple" width="16" height="16"/>
                    <span class="ml-7">{{ $translate('Client.BasketPage.DeliveryCheck') }}</span>
                </button>
                <button class="flex items-center cursor-pointer text-12" @click="openStoreLocater">
                    <CIcon name="location" width="16" height="16"/>
                    <span class="ml-7">{{ $translate('Client.Navigation.Stores') }}</span>
                </button>
                <RouterLink v-for="item in filteredLinks"
                            :key="item.link?.name"
                            :to="item.link?.url"
                            :target="item.link?.target"
                            class="flex items-center text-12">
                    <CIcon :name="item.iconName" width="16" height="16"/>
                    <span class="ml-7" :aria-label="item.link.url.replaceAll('/', '')">
                        {{ item.link?.name }}
                    </span>
                </RouterLink>
                <button class="flex items-center cursor-pointer text-12" @click="openLoginOrMyAccount">
                    <CIcon name="user"
                           width="16"
                           height="16"/>
                    <span v-if="isAuthenticated && customerInfo" class="ml-7">{{ $translate('Client.OrderPage.Greeting', customerInfo?.firstName) }}</span>
                    <span v-else-if="isAuthenticated && !customerInfo" class="ml-7 w-60"></span>
                    <span v-else class="ml-7">{{ $translate('Client.LoginDialog.LoginShort') }}</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { SiteSettingServiceMenuItemModel } from '@/api/cms';
import { computed } from 'vue';
import { isAuthenticated, refreshUserState, login } from '@/project/authentication/authentication';
import { useDeliveryChecker } from '@/project/components/delivery-checker/delivery-checker.composable';
import { useCustomerInfo } from '@/project/apis/commerce/accountApi';
import { useStoreLocater } from '@/project/components/store-locater/store-locater.composable';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import router from '@/router';

const props = defineProps<{
    links: SiteSettingServiceMenuItemModel[]
}>();

const { openDeliveryChecker } = useDeliveryChecker();
const { openStoreLocater } = useStoreLocater();

const filteredLinks = computed(() => props.links.filter(link => (!isAuthenticated.value && link.showWhenNotAuthenticated) || (isAuthenticated.value && link.showWhenAuthenticated)));

const customerInfo = useCustomerInfo();

const { myAccountUrl } = getPageUrls();

function openLoginOrMyAccount() {
    if (isAuthenticated.value) {
        router.push(myAccountUrl.value);
    } else {
        login().then(async() => {
            await refreshUserState();
        });
    }
}

</script>
