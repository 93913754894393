import dictionary from '@/core/dictionary/dictionary';
let formatter: Intl.NumberFormat | undefined;

type PriceFormatterOptions = {
    hideCurrency?: boolean;
    currencyFirst?: boolean;
}

export function formatPrice(price : number | undefined | null, options?: PriceFormatterOptions): string {
    if (!price) return `0 ${options?.hideCurrency ? '' : dictionary.get('Client.Common.Kroner')}`;
    if (!formatter) {
        // Using da-DK because is-IS is not supported in Chrome
        formatter = new Intl.NumberFormat(['is-IS', 'da-DK'], {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });
    }

    return `${options?.currencyFirst && !options?.hideCurrency ? `${dictionary.get('Client.Common.Kroner')} ` : ''}${formatter.format(price)}${!options?.hideCurrency && !options?.currencyFirst ? ` ${dictionary.get('Client.Common.Kroner')}` : ''}`;
}
