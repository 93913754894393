import { Ref, ref } from 'vue';

const funnelView = ref(false);

function setFunnelView(_funnelView: boolean): void {
    funnelView.value = _funnelView;
}

export function useFunnelView(): { setFunnelView: (_funnelView: boolean) => void, funnelView: Ref<boolean>} {
    return { setFunnelView, funnelView };
}
