<template>
    <div class="px-10 sm:px-0 block-spacing">
        <div class="grid grid-cols-12 gap-10 lg:grid-cols-20 xl:grid-cols-24" :class="[{'toggle-category': !showAllCategories}, getCategoryCount ]">
            <div v-if="model.showDiscountsInFirstPosition" class="col-span-4 lg:col-span-2 xl:col-span-3">
                <RouterLink :to="offersUrl !== undefined ? offersUrl : ''" @click="onClickOffers">
                    <div class="overflow-hidden relative p-10 h-full rounded-lg bg-splash">
                        <div class="absolute inset-0 w-full">
                            <TextBackground :text="$translate('Client.Common.Offers')"/>
                        </div>

                        <div class="relative pt-[60%]">
                            <CIcon
                                class="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] "
                                name="circle-offer"
                                width="48"
                                height="48"
                                original="true"/>
                        </div>
                        <span class="block relative py-2 text-center">{{ $translate('Client.Common.Offers') }}</span>
                    </div>
                </RouterLink>
            </div>
            <div v-for="(category, index) in model.categories" :key="category.name" class="col-span-4 lg:col-span-2 xl:col-span-3">
                <RouterLink :to="category.url" @click="onClick(category, index)">
                    <div class="overflow-hidden relative p-10 h-full bg-white rounded-lg">
                        <ResponsiveImage
                            :aspect-ratio="5/3"
                            mode="pad"
                            :width-on-screen="{
                                xs: 20,
                                lg: 16,
                                xl: 12,
                            }"
                            :image-url="category.squareImage.url"/>
                        <span class="block relative py-2 text-center">{{ category.name }}</span>
                    </div>
                </RouterLink>
            </div>
            <div>
            </div>
        </div>
        <div class="flex justify-center items-center mt-20">
            <span class="inline-block mr-20 w-full h-0 border-category"></span>
            <Button :label="showAllCategories ? $translate('Client.CategoryPage.HideCategories') : $translate('Client.CategoryPage.ShowAllCategories')"
                    :pill="true"
                    :primary="false"
                    @click="showAllCategories = !showAllCategories">
                <template #right>
                    <CIcon v-if="showAllCategories" name="arrow-up" width="12" height="12"/>
                    <CIcon v-else name="arrow-down" width="12" height="12"/>
                </template>
            </Button>

            <span class="inline-block ml-20 w-full h-0 border-category"></span>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import { ref, computed } from 'vue';
import { RouterLink } from 'vue-router';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { CategoryBandBlockModel, CategoryPageLink } from '@/api/cms';
import TextBackground from '@/project/components/text-background/TextBackground.vue';
import { trackPromotionClick, trackPromotionImpressions } from '@/project/tracking/tracking.service';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { watchOnce } from '@vueuse/core';
import dictionary from '@/core/dictionary/dictionary';

const props = defineProps<{
    model: CategoryBandBlockModel
}>();

const { offersUrl } = getPageUrls();

const showAllCategories = ref(false);

const getCategoryCount = computed(() => {
    const count = props.model.categories.length;
    const style:string[] = [];
    if (count > 4) {
        style.push('count-md');
    }
    if (count > 6) {
        style.push('count-sm');
        style.push('count-lg');
    }
    if (count > 8) {
        style.push('count-xl');
    }
    return style.join(' ');
});

// TRACKING RELATED START
const offerImpression = {
    nameOfModule: 'CategoryBand',
    headlineOrImageName: dictionary.get('Client.Common.Offers'),
    creative: dictionary.get('Client.Common.Offers'),
    index: 0,
};

function getImpression(category: CategoryPageLink, index: number) {
    return {
        nameOfModule: 'CategoryBand',
        headlineOrImageName: category.name,
        creative: category.name,
        index: index + 1,
    };
}

function getImpressions(categories: CategoryPageLink[]) {
    const impressions = categories.map((c, index) => getImpression(c, index));

    impressions.unshift(offerImpression);
    return impressions;
}

const { between } = useBreakpoints();
const md = between('md', 'lg');
const lg = between('lg', 'xl');

let categoriesToTrack: CategoryPageLink[] = [];

if (lg.value) {
    categoriesToTrack = [...props.model.categories].splice(0, 5);
} else if (md.value) {
    categoriesToTrack = [...props.model.categories].splice(0, 3);
} else {
    categoriesToTrack = [...props.model.categories].splice(0, 7);
}

trackPromotionImpressions(getImpressions(categoriesToTrack));

watchOnce(showAllCategories, () => {
    if (showAllCategories.value) {
        trackPromotionImpressions(getImpressions(props.model.categories));
    }
});

function onClickOffers() {
    trackPromotionClick([offerImpression]);
}

function onClick(category: CategoryPageLink, index: number) {
    trackPromotionClick([getImpression(category, index)]);
}
// TRACKING RELATED END

</script>

<style scoped>

.border-category{
    border-top: 1px solid #e3e3e3;
}

.toggle-category + div{
    display: none;
}

@media(max-width: 768px){
    .toggle-category > :nth-child(1n+7){
        display: none;
    }
    .toggle-category.count-sm + div{
        display: flex;
    }
}

@media(min-width: 768px) and (max-width: 1024px){
    .toggle-category > :nth-child(1n+7){
        display: none;
    }
     .toggle-category.count-md + div{
        display: flex;
    }
}

@media(min-width: 1024px) and (max-width: 1439px){
    .toggle-category > :nth-child(1n+7){
        display: none;
    }
    .toggle-category.count-lg + div{
        display: flex;
    }
}
@media(min-width: 1440px){
    .toggle-category > :nth-child(1n+9){
        display: none;
    }
    .toggle-category.count-xl + div{
        display: flex;
    }
}

</style>
