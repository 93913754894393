<template>
    <DrawerDialog
        v-slot="{close}"
        use-slide-animation
        :drawer-classes="'bg-background'"
        show-backdrop
        :title="$translate('Client.LoginDialog.Title')">
        <div class="overflow-x-hidden overflow-y-auto h-full w-[375px] sm:w-[510px] bg-background">
            <div class="px-20 my-20 sm:text-16">
                {{ $translate('Client.LoginDialog.Heading') }}
                <ul class="px-20 mt-20 space-y-10">
                    <li v-if="$translate('Client.LoginDialog.Point1') && !$translate('Client.LoginDialog.Point1').includes('##')">
                        <CIcon name="checkmark" width="16" height="16"/>
                        <span class="ml-10">{{ $translate('Client.LoginDialog.Point1') }}</span>
                    </li>
                    <li v-if="$translate('Client.LoginDialog.Point2') && !$translate('Client.LoginDialog.Point2').includes('##')">
                        <CIcon name="checkmark" width="16" height="16"/>
                        <span class="ml-10">{{ $translate('Client.LoginDialog.Point2') }}</span>
                    </li>
                    <li v-if="$translate('Client.LoginDialog.Point3') && !$translate('Client.LoginDialog.Point3').includes('##')">
                        <CIcon name="checkmark" width="16" height="16"/>
                        <span class="ml-10">{{ $translate('Client.LoginDialog.Point3') }}</span>
                    </li>
                </ul>
            </div>
            <div class="flex flex-col items-center mt-40 space-y-10">
                <Button :label="$translate('Client.LoginDialog.Login')"
                        size="large"
                        class="mx-auto"
                        @click="goToLogin(close)"/>
                <span>{{ $translate('Client.Common.Or') }}</span>
                <Button :label="$translate('Client.LoginDialog.SignUp')"
                        size="small"
                        transparent
                        @click="goToLogin(close)"/>
            </div>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import { refreshUserState, login } from '@/project/authentication/authentication';
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import Button from '@/project/components/button/Button.vue';

function goToLogin(close: any) {
    close();
    login().then(async() => {
        await refreshUserState();
    });
}
</script>

<style scoped>
</style>
