<template>
    <div class="mt-20 space-y-10 mb-30">
        <div class="w-full text-center">
            <span v-if="numberOfResults === 1 && total === 1">{{ $translate('Client.CategoryPage.Showing1of1Results') }}</span>
            <span v-else>{{ $translate('Client.CategoryPage.ShowingXofYResults', numberOfResults, total) }}</span>
        </div>
        <div v-if="showShowMoreButton" class="flex justify-center items-center w-full">
            <div class="flex-grow mr-20 border-b border-lightBorder"></div>
            <Button :label="$translate('Client.CategoryPage.ShowMoreResults')"
                    pill
                    @click="showMore">
                <template #right>
                    <CIcon name="arrow-down" width="12" height="12"/>
                </template>
            </Button>
            <div class="flex-grow ml-20 border-b border-lightBorder"></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { useRouteQuery } from '@/core/browser/query/useQueryParams';
import { QueryKeys } from '@/project/browser/query';
import CIcon from '@/core/layout/svgicon/CIcon.vue';
import { computed } from 'vue';

const props = defineProps<{
    pageSize: number,
    maxPageSize: number,
    numberOfResults: number,
    total: number,
}>();

const emit = defineEmits(['click']);

const currentPageSize = useRouteQuery<number>(QueryKeys.PAGE_SIZE);

const showShowMoreButton = computed(() => currentPageSize.value < props.total && props.numberOfResults !== props.total && currentPageSize.value < props.maxPageSize);

// If no pageSize from previous visit use default from props
if (!currentPageSize.value) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    currentPageSize.value = props.pageSize;
}

const showMore = () => {
    emit('click');
    if (currentPageSize.value < props.pageSize) {
        currentPageSize.value = props.pageSize * 2; // to remove extra click required in case of current page is 0 on initial load with query params (e.g. on search results page)
    } else {
        currentPageSize.value = currentPageSize.value + props.pageSize;
    }
};
</script>

<style scoped>

</style>
