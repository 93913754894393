<template>
    <div v-if="!isRemoving">
        <div v-if="model.invalidReason" class="py-8 px-20 font-bold bg-warning">
            {{ $translate(`Client.BasketStatus.${model.invalidReason}`) }}
        </div>
        <div class="flex items-center pl-20 w-full bg-white border-b border-lightBorder"
             :class="{'border border-warning': model.invalidReason}">
            <RouterLinkConditional :url="model.url" class="flex items-center grow min-h-[60px]" :disabled="!!model.invalidReason">
                <div class="relative mr-10" :class="{'grayscale opacity-50': disableItem}">
                    <div v-if="model.discount"
                         class="flex absolute top-0 -right-5 z-1">
                        <Splash :label="model.discount" size="xs"/>
                    </div>
                    <ResponsiveImage :image-url="model.imageUrl"
                                     :aspect-ratio="1/1"
                                     class="w-50 h-50"
                                     :fixed-width-on-screen="50"
                                     mode="pad"/>
                </div>
                <div class="flex flex-col">
                    <span class="block" :class="{'text-fadedText': disableItem}">
                        {{ model.displayName }}
                    </span>
                    <span class="text-fadedText text-12">
                        {{ productTeasers }}
                    </span>
                </div>
            </RouterLinkConditional>
            <div class="flex flex-col justify-center items-center ml-auto min-w-[116px] background-white" @click.prevent>
                <div class="flex justify-center items-center">
                    <Button v-if="!disableItem" transparent class="px-5" @click="onDecrement({delayRemoval: true})">
                        <CIcon name="minus"
                               class="text-blue"
                               width="12"
                               height="12"/>
                    </Button>
                    <div class="relative">
                        <input ref="inputField"
                               class="mx-5 w-40 font-bold text-center rounded border-0 h-35 bg-background z-1 text-14"
                               type="number"
                               :disabled="disableItem"
                               :value="focussingField ? model.quantity : ''"
                               @focus="focussingField = true"
                               @blur="focussingField = false"
                               @change="onChange">
                        <div v-if="!focussingField"
                             class="flex absolute inset-0 justify-center items-center cursor-text"
                             @click="inputField.focus()">
                            <AnimateValue animation="scroll">
                                <div :key="model.quantity"
                                     class="font-bold"
                                     :class="{'text-fadedText': disableItem}">
                                    {{ model.quantity }}
                                </div>
                            </AnimateValue>
                        </div>
                    </div>
                    <Button v-if="!disableItem"
                            :disabled="stockLimitHit || !!model.invalidReason"
                            transparent
                            class="px-5"
                            @click="onIncrement">
                        <CIcon name="plus"
                               class="text-blue"
                               width="12"
                               height="12"/>
                    </Button>
                </div>
                <div v-if="fewLeftInStock && !model.invalidReason">
                    <span class="flex justify-center items-center whitespace-nowrap text-12 text-errorText">{{ $translate('Client.ProductDetailsPage.FewLeft') }}</span>
                </div>
            </div>

            <!-- Price details -->
            <div class="flex flex-col justify-center items-end w-80 min-w-90">
                <span v-if="model.discount"
                      class="font-bold text-right text-save text-12"
                      :class="{'text-fadedText': disableItem}">
                    {{ $translate("Client.ProductDetailsPage.Save") }} {{ formatPrice(model.totalDiscountAmount) }}
                </span>
                <span v-else class="text-12">&nbsp;</span>
                <span class="font-bold text-right text-14 leading-[1.5rem]"
                      :class="{'text-fadedText': disableItem}">{{ formatPrice(model.totalDiscountedPrice || model.totalSalesPrice) }}</span>
                <span v-if="model.pricePerUnit" class="text-right text-fadedText text-12">
                    {{ formatPrice(model.pricePerUnit) }} / {{ model.unitOfMeasure }}
                </span>
                <span v-else class="text-12">&nbsp;</span>
                <div v-if="model.bonus" class="my-5">
                    <span class="py-2 px-5 font-bold text-white whitespace-nowrap rounded-lg bg-bonus-background text-11">
                        {{ $translate('Client.Common.BonusShort', model.bonus.bonusPercentage) }}%
                    </span>
                </div>
            </div>

            <!-- Delete button -->
            <div class="mx-10">
                <div :class="{'bg-warning rounded-full': !!model.invalidReason}">
                    <Button class="cursor-pointer" transparent @click="deleteItem">
                        <CIcon name="delete"
                               width="20"
                               height="20"
                               class="opacity-80 text-removeLineItem"/>
                    </Button>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <LineItemRemoveState :display-name="model.displayName" :image-url="model.imageUrl" @undo="stopRemoval"/>
    </div>
</template>

<script setup lang="ts">

import { computed, DeepReadonly, ref } from 'vue';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import Button from '@/project/components/button/Button.vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Splash from '@/project/products/Splash/Splash.vue';
import { LineItemInvalidReasonViewModel, LineItemViewModel } from '@/api/commerce';
import { formatPrice } from '@/project/products/priceFormatter';
import { useLineItem } from '../../apis/commerce/basketApi';
import { parseQuantity } from '@/project/products/quantityParser';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';
import LineItemRemoveState from '@/project/basket/basket-line-item/LineItemRemoveState.vue';
import { trackAddToBasket } from '@/project/tracking/tracking.service';
import { useStock } from '@/project/products/useStock';

const props = defineProps<{
    model: DeepReadonly<LineItemViewModel>;
    position?: number;
}>();

const inputField = ref();
const focussingField = ref(false);

const productTeasers = [
    props.model.brand,
    props.model.weight,
    props.model.productTeaser,
].filter(elem => elem).join(', ');

const { decrement, increment, manualChange, isRemoving, stopRemoval, lineItem } = useLineItem(props.model.sku);

const { fewLeftInStock, stockLimitHit } = useStock(props.model as LineItemViewModel, lineItem);

const disableItem = computed(() => props.model.invalidReason === LineItemInvalidReasonViewModel.ProductNotFound || (!props.model.alwaysInStock && props.model.availableInStock <= 0));

let prevQuantity = lineItem.value?.quantity ?? 0;
function onIncrement() {
    increment();
    trackAddToBasket({
        increasedQuantity: true,
        quantity: 1,
        product: lineItem.value!,
        lineItemMode: true,
        list: { pageTypeOverride: 'Mini basket drawer', listType: 'Mini basket list', choosingMechanism: 'User' },
        index: props.position,
    });
    prevQuantity = prevQuantity + 1;
}

function onDecrement(options: {delayRemoval: boolean}) {
    decrement(options);
    trackAddToBasket({
        product: lineItem.value!,
        increasedQuantity: false,
        lineItemMode: true,
        quantity: 1,
        list: { pageTypeOverride: 'Mini basket drawer', listType: 'Mini basket list', choosingMechanism: 'User' },
        index: props.position,
    });
    prevQuantity = prevQuantity - 1;
}

function onChange(e: Event) {
    const newValue = parseQuantity((e.target as HTMLInputElement).value);
    if (newValue >= 0) {
        manualChange(newValue);
    }

    const isAdding = prevQuantity < newValue;
    trackAddToBasket({
        product: lineItem.value!,
        increasedQuantity: isAdding,
        lineItemMode: true,
        quantity: isAdding ? newValue - prevQuantity : prevQuantity - newValue,
        list: { pageTypeOverride: 'Mini basket drawer', listType: 'Mini basket list', choosingMechanism: 'User' },
        index: props.position,
    });
    prevQuantity = newValue;
}

function deleteItem() {
    manualChange(0);

    trackAddToBasket({
        product: lineItem.value!,
        increasedQuantity: false,
        lineItemMode: true,
        quantity: prevQuantity,
        list: { pageTypeOverride: 'Mini basket drawer', listType: 'Mini basket list', choosingMechanism: 'User' },
        index: props.position,
    });
}
</script>
