import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import './styling/index.css';
import coreConfig from './core';
import projectConfig from './project';
import loadDictionary from '@/core/dictionary/dictionaryLoader';

const app = createApp(App);

coreConfig(app).then(() => {
    const boot = projectConfig(app).then(() => {
        app.use(router);
    });

    Promise.all([loadDictionary(), boot])
        .then(() => app.mount('#app'));
});
