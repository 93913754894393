<template>
    <CIcon :name="`${chain.iconName}-logo`"
           :width="iconWidth"
           :height="iconHeight"
           :style="{color: chain.color}"
           original/>
</template>

<script setup lang="ts">
import { ChainViewModel } from '@/api/commerce';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';

const props = defineProps<{
    chain: ChainViewModel
}>();

const { smaller } = useBreakpoints();

const isMobile = smaller('md');

let iconWidth = isMobile.value ? '48' : '58';
let iconHeight = isMobile.value ? '15' : '18';

if (isMobile.value) {
    switch (props.chain.iconName) {
    case 'netto':
        break;
    case 'krambudin':
        iconWidth = '20';
        iconHeight = '20';
        break;
    case 'iceland':
        iconWidth = '48';
        iconHeight = '10';
        break;
    case 'kjorbudin':
        iconWidth = '48';
        iconHeight = '8';
        break;
    }
} else {
    switch (props.chain.iconName) {
    case 'netto':
        break;
    case 'krambudin':
        iconWidth = '35';
        iconHeight = '35';
        break;
    case 'iceland':
        iconWidth = '58';
        iconHeight = '12';
        break;
    case 'kjorbudin':
        iconWidth = '58';
        iconHeight = '10';
        break;
    }
}
</script>

<style scoped>

</style>
