/* eslint-disable indent */
import { BasketApiFactory, BasketValidityViewModel, BasketViewModel, BasketViewModelClientResponse, LineItemTotalQuantityRequest, LineItemViewModel } from '@/api/commerce';
import dictionary from '@/core/dictionary/dictionary';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { debounce } from 'lodash-es';
import { computed, DeepReadonly, onBeforeUnmount, readonly, Ref, ref, shallowReadonly, watch } from 'vue';
import { addMessage, removeAllOfType } from '@/project/components/system-message/system-message.service';
import { CategoryAndLineItems } from '../../basket/basket.types';
import { AxiosResponse } from 'axios';
import { useTimeoutFn } from '@vueuse/core';
import { isAuthenticated } from '@/project/authentication/authentication';
import { openAsyncDialog } from '@/project/dialog/dialog';
import LoginDialog from '@/project/components/login-dialog/LoginDialog.vue';

const basketApi = BasketApiFactory({ isJsonMime: () => true }, commerceApiHost);

let queue: Array<LineItemTotalQuantityRequest> = [];
const debouncedUpdateServer = debounce(updateServer, 300);
let updatingServer = false;

const basket = ref<BasketViewModel | undefined>();
const bonus = ref(0);
const readonlyBasket: DeepReadonly<Ref<BasketViewModel | undefined>> = readonly(basket);
const readonlyBonus = shallowReadonly(bonus);

const basketValidated = ref(false);

export function initBasket(): void {
    if (isAuthenticated.value) {
        fetchBasket();
    }
}

export function getBasket(): { basket: DeepReadonly<Ref<BasketViewModel | undefined>>, basketValidated: Ref<boolean>, bonus: Readonly<Ref<number>> } {
    return {
        basket: readonlyBasket,
        basketValidated,
        bonus: readonlyBonus,
    };
}

export function useBasket():{
    minimumPurchase: Ref<number>,
    shipmentAsBonusThreshold: Ref<number | null | undefined>,
    subTotal: Ref<number>,
    quantity: Ref<number>,
    categories: Ref<CategoryAndLineItems[]>,
    items : Ref<LineItemViewModel[]>,
    bonus: Readonly<Ref<number>>,
 } {
    const minimumPurchase: Ref<number> = computed(() => basket.value?.minimumPurchase || 0);
    const shipmentAsBonusThreshold: Ref<number | null | undefined> = computed(() => basket.value?.shipmentAsBonusThreshold);
    const subTotal: Ref<number> = computed(() => basket.value?.subTotal || 0);
    const quantity: Ref<number> = computed(() => basket.value?.lineItems.reduce((prev, cur) => prev + cur.quantity, 0) || 0);

    const items:Ref<LineItemViewModel[]> = computed(() => basket.value?.lineItems || []);

    const categories: Ref<CategoryAndLineItems[]> = computed(() => {
        const category:CategoryAndLineItems[] = [];

        // A bit funky to first put them in CMS order, when they are sorted alphabetically later, but this is also just a way to distribute items in the right categories.
        items.value.forEach(item => {
            if (!category[item.firstLevelCategorySortOrder]) {
                category[item.firstLevelCategorySortOrder] = {
                    categoryName: item.firstLevelCategoryName,
                    lineItems: [],
                };
            }
            category[item.firstLevelCategorySortOrder].lineItems.push(item);
        });

        return category.filter(n => n).sort((a, b) => a.categoryName.localeCompare(b.categoryName));
    });

    return {
        minimumPurchase,
        shipmentAsBonusThreshold,
        subTotal,
        quantity,
        categories,
        items,
        bonus: readonlyBonus,
    };
}

export function fetchBasket(): Promise<BasketViewModelClientResponse> {
    return basketApi.apiBasketGet()
        .then(_basket => {
            basket.value = _basket.data.model;
            validateBasket(_basket);
            fetchBonus();
            return _basket;
        }).catch(error => {
            return error;
        });
}

export async function fetchBonus(): Promise<number> {
    if (!isAuthenticated.value) {
        return bonus.value = 0;
    }
    const bonusResponse = await basketApi.apiBasketBonusGet();
    return bonus.value = bonusResponse.data.model.bonus;
}

export async function addOrUpdateLineitem(sku: string, quantity = 1): Promise<void> {
    if (!isAuthenticated.value) {
        // If not logged in await login before continuing to add to basket.
        await openAsyncDialog(LoginDialog, {});

        // If user didn't log in, return
        if (!isAuthenticated.value) return;
    }

    if (!basket.value) {
        await fetchBasket();
        if (!basket.value) {
            throw new Error('Could not fetch basket');
        }
    }

    // If exists already, its really an update
    const existingLineItem = getLineItem(sku);
    if (existingLineItem) {
        return updateLineItem(sku, quantity);
    }

    // Update basket beforehand - insert as first
    basket.value.lineItems.unshift({
        sku,
        quantity,
    } as LineItemViewModel);

    updateQueue(sku, quantity);
}

function updateLineItem(sku: string, quantity: number): void {
    if (!basket.value) {
        throw new Error('Basket not fetched yet');
    }

    // Update basket beforehand - maybe delete
    const existingLineItem = getLineItem(sku);
    if (!existingLineItem) {
        throw new Error('Lineitem not in basket: ' + sku);
    }
    if (quantity === 0) {
        basket.value.lineItems = basket.value.lineItems.filter(l => l.sku !== sku);
    } else {
        existingLineItem.quantity = quantity;
        existingLineItem.totalDiscountedPrice = 0; // Unknown price until server response
    }

    updateQueue(sku, quantity);
}

function updateQueue(sku: string, totalQuantity: number): void {
    const existingLine = queue.find(l => l.sku === sku);
    if (existingLine) {
        existingLine.totalQuantity = totalQuantity;
    } else {
        queue.push({ sku, totalQuantity });
    }
    debouncedUpdateServer();
}

async function updateServer() {
    if (updatingServer) return;

    updatingServer = true;
    const lineItems = [...queue];
    queue = [];
    basketApi.apiBasketTotalAddOrUpdateLineItemsAsyncPost(undefined, lineItems)
        .then(_basket => {
            // Only use result if nothing more in queue
            if (!queue.length) {
                basket.value = _basket.data.model;
                validateBasket(_basket);
                fetchBonus();
            } else {
                debouncedUpdateServer();
            }
        })
        .finally(() => {
            updatingServer = false;
        });
}

export async function removeListOfLineItems(skus: string[]): Promise<void> {
    const lineItems: LineItemTotalQuantityRequest[] = skus.map((sku: string) => ({ sku, totalQuantity: 0 }));
    basketApi.apiBasketTotalAddOrUpdateLineItemsAsyncPost(undefined, lineItems);
}

export function getLineItem(sku: string): LineItemViewModel | undefined {
    return basket.value?.lineItems.find(l => l.sku === sku);
}

export function useLineItem(sku: string, options?: { onRemove?: (sku: string) => void }): {
    lineItem: Ref<LineItemViewModel | undefined>,
    increment: () => void,
    decrement: (options?: {delayRemoval?: boolean}) => void,
    manualChange: typeof manualChange,
    isRemoving: Ref<boolean>,
    stopRemoval: () => void,
} {
    const lineItem = ref<LineItemViewModel | undefined>(getLineItem(sku));
    watch(basket, () => {
        lineItem.value = getLineItem(sku);
    }, { deep: true });

    const { start: startRemoval, stop: stopRemoval, isPending: isRemoving } = useTimeoutFn(() => {
        addOrUpdateLineitem(sku, 0);
        options?.onRemove?.(sku);
    }, 3000, { immediate: false });

    const manualChange = (amount: number, options?: {immediate?: boolean}) => {
        if (amount === 0 && !options?.immediate) {
            startRemoval();
        } else {
            addOrUpdateLineitem(sku, amount);
        }
    };

    const increment = () => {
        if (lineItem.value) {
            addOrUpdateLineitem(sku, lineItem.value?.quantity + 1);
        } else {
            addOrUpdateLineitem(sku, 1);
        }
    };

    const decrement = (options?: {delayRemoval?: boolean}) => {
        if (lineItem.value) {
            if (lineItem.value.quantity > 1) {
                addOrUpdateLineitem(sku, lineItem.value.quantity - 1);
            } else {
                if (options?.delayRemoval) {
                startRemoval();
                } else {
                    addOrUpdateLineitem(sku, 0);
                }
            }
        }
    };

    onBeforeUnmount(() => {
        if (isRemoving.value) {
            stopRemoval();
            addOrUpdateLineitem(sku, 0);
        }
    });

    return { lineItem, increment, decrement, manualChange, isRemoving, stopRemoval };
}

function validateBasket(basket: AxiosResponse<BasketViewModelClientResponse>) {
    basketValidated.value = basket.data.model.status === BasketValidityViewModel.Valid ||
    basket.data.model.status === BasketValidityViewModel.MinimumSubTotalNotReachedButCustomerHasOpenOrder;

    const skipErrorMessage = basket.data.model.status === BasketValidityViewModel.IsEmpty || basket.data.model.status === BasketValidityViewModel.MinimumSubTotalNotReached;

    if (!basketValidated.value && !skipErrorMessage) {
        addMessage({
            title: dictionary.get(`Client.BasketStatus.${basket.data.model.status}`),
            description: dictionary.get(`Client.BasketStatus.${basket.data.model.status}Description`),
            type: 'basket',
            severity: 'warning',
        });
    } else {
        removeAllOfType('basket');
    }
}

export function emptyBasket(): Promise<BasketViewModel> {
    return basketApi.apiBasketDelete().then((res) => {
        fetchBasket();
        return res.data.model;
    }).catch((err) => {
        console.error('Could not empty basket', err);
        return err;
    });
}

export function clearBasket(): void {
    basket.value = undefined;
}
