<template>
    <ProductBlock :model="selectedProducts" :link="model.link" :hide-products="model.hideProducts"/>
</template>

<script lang="ts" setup>
import { ProductBandBlockModel } from '@/api/cms';
import { ProductTileViewModel } from '@/api/commerce/models/product-tile-view-model';
import ProductBlock from '@/project/products/product-band/ProductBlock.vue';
import { ref, watch, Ref } from 'vue';
import { useSearch } from '@/project/apis/commerce/searchApi';
import { trackProductImpressions } from '@/project/tracking/tracking.service';

const props = defineProps<{
    model: ProductBandBlockModel
}>();

const { search, searchResult } = useSearch();

const selectedProducts:Ref<ProductTileViewModel[]> = ref([]);

search({
    facetOptions: [],
    offset: 0,
    size: Number(props.model.limit),
    onlyOffers: props.model.onlyOffers,
    onlyBonus: props.model.onlyBonus,
    categories: props.model.categoryIds,
    includeFacets: false,
    sortBy: '',
    term: props.model.searchTerm,
    includeCategories: false,
    hideOutOfStock: true,
}, Math.random().toString());

watch(searchResult, (res) => {
    if (res.dataReady) {
        selectedProducts.value = res.data.results;
        trackProductImpressions(res.data.results, { listType: 'Product band', choosingMechanism: 'Editor' });
    }
});

</script>
