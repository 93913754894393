/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { StoreSearchViewModelClientResponse } from '../models';
/**
 * StoresApi - axios parameter creator
 * @export
 */
export const StoresApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Nettó stores near a zip code.
         * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, stores near Reykjavík are returned.
         * @param {number} [limit] Optional limit. If null, all Nettó stores will be returned.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresNettoGet: async (zipCode?: number, limit?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/netto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get other Samkaup stores near a zip code.
         * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, one of each kind of store nearest are returned.
         * @param {number} [limit] Optional limit. If null, all other Samkaup stores will be returned.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresOtherGet: async (zipCode?: number, limit?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/stores/other`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoresApi - functional programming interface
 * @export
 */
export const StoresApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoresApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Nettó stores near a zip code.
         * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, stores near Reykjavík are returned.
         * @param {number} [limit] Optional limit. If null, all Nettó stores will be returned.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStoresNettoGet(zipCode?: number, limit?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreSearchViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStoresNettoGet(zipCode, limit, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get other Samkaup stores near a zip code.
         * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, one of each kind of store nearest are returned.
         * @param {number} [limit] Optional limit. If null, all other Samkaup stores will be returned.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiStoresOtherGet(zipCode?: number, limit?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreSearchViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiStoresOtherGet(zipCode, limit, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoresApi - factory interface
 * @export
 */
export const StoresApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoresApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Nettó stores near a zip code.
         * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, stores near Reykjavík are returned.
         * @param {number} [limit] Optional limit. If null, all Nettó stores will be returned.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresNettoGet(zipCode?: number, limit?: number, apiVersion?: string, options?: any): AxiosPromise<StoreSearchViewModelClientResponse> {
            return localVarFp.apiStoresNettoGet(zipCode, limit, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get other Samkaup stores near a zip code.
         * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, one of each kind of store nearest are returned.
         * @param {number} [limit] Optional limit. If null, all other Samkaup stores will be returned.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiStoresOtherGet(zipCode?: number, limit?: number, apiVersion?: string, options?: any): AxiosPromise<StoreSearchViewModelClientResponse> {
            return localVarFp.apiStoresOtherGet(zipCode, limit, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoresApi - interface
 * @export
 * @interface StoresApi
 */
export interface StoresApiInterface {
    /**
     * 
     * @summary Get Nettó stores near a zip code.
     * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, stores near Reykjavík are returned.
     * @param {number} [limit] Optional limit. If null, all Nettó stores will be returned.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    apiStoresNettoGet(zipCode?: number, limit?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<StoreSearchViewModelClientResponse>;

    /**
     * 
     * @summary Get other Samkaup stores near a zip code.
     * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, one of each kind of store nearest are returned.
     * @param {number} [limit] Optional limit. If null, all other Samkaup stores will be returned.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApiInterface
     */
    apiStoresOtherGet(zipCode?: number, limit?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<StoreSearchViewModelClientResponse>;

}

/**
 * StoresApi - object-oriented interface
 * @export
 * @class StoresApi
 * @extends {BaseAPI}
 */
export class StoresApi extends BaseAPI implements StoresApiInterface {
    /**
     * 
     * @summary Get Nettó stores near a zip code.
     * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, stores near Reykjavík are returned.
     * @param {number} [limit] Optional limit. If null, all Nettó stores will be returned.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public apiStoresNettoGet(zipCode?: number, limit?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).apiStoresNettoGet(zipCode, limit, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get other Samkaup stores near a zip code.
     * @param {number} [zipCode] The zip code to get nearby stores from. If unspecified, one of each kind of store nearest are returned.
     * @param {number} [limit] Optional limit. If null, all other Samkaup stores will be returned.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoresApi
     */
    public apiStoresOtherGet(zipCode?: number, limit?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return StoresApiFp(this.configuration).apiStoresOtherGet(zipCode, limit, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
