<template>
    <div class="grid py-8 pl-20 w-full pr-30 grid-cols-24 bg-backgroundContrastBlue">
        <div class="col-span-12 font-bold">
            {{ props.name }}
        </div>
        <template v-if="notMobile">
            <div class="col-span-4 text-center">
                {{ $translate('Client.BasketPage.Quantity') }}
            </div>
            <div class="col-span-3 text-right">
                {{ $translate('Client.BasketPage.UnitPrice') }}
            </div>
            <div class="col-span-5 text-right">
                {{ $translate('Client.BasketPage.Total') }}
            </div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';

const props = defineProps<{
    name: string;
}>();

const { greater } = useBreakpoints();
const notMobile = greater('sm');
</script>
