import { useElementBounding } from '@vueuse/core';
import { nextTick, Ref, ref } from 'vue';

export function useScrollAfterLoad(): {
    element: Ref
    rememberPositionBottom: typeof rememberPositionBottom
    scrollToPreviousBottom: typeof scrollToPreviousBottom
    } {
    const element = ref(null);
    const { bottom } = useElementBounding(element);
    const prevScrollPos = ref(bottom.value);

    function rememberPositionBottom() {
        prevScrollPos.value = bottom.value;
    }

    function scrollToPreviousBottom() {
        nextTick(() => {
            if (prevScrollPos.value) {
                window.scrollTo({ top: prevScrollPos.value });
                prevScrollPos.value = 0;
            }
        });
    }

    return { element, rememberPositionBottom, scrollToPreviousBottom };
}
