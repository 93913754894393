<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="model.ancestors" :current-page-name="metadata.navigationTitle"/>
        <div>
            <div class="flex items-start lg:space-x-10 xl:space-x-20">
                <div class="flex xl:space-x-20">
                    <div v-if="isLargeScreen" class="w-284 space-y-20">
                        <div class="pb-25 rounded-lg bg-white">
                            <CategoryNavigation :model="model" :metadata="metadata"/>
                        </div>
                        <div v-if="model.curatedCategories.length > 0" class="pb-25 rounded-lg bg-white">
                            <CuratedNavigation :model="model.curatedCategories"/>
                        </div>
                    </div>
                </div>
                <div class="w-full">
                    <div
                        v-if="hero"
                        class="mb-20 overflow-hidden rounded-lg bg-white">
                        <ResponsiveImage
                            v-if="isMediumOrGreaterScreen"
                            :image-url="hero.image.url"
                            :aspect-ratio="6 / 1"/>
                        <ResponsiveImage
                            v-else-if="hero.mobileImage && hero.mobileImage.url"
                            :image-url="hero.mobileImage.url"
                            :aspect-ratio="4 / 3"/>
                    </div>

                    <div class="p-15 relative mb-10 w-full rounded-lg bg-white xl:flex xl:items-center xl:justify-between">
                        <div class="xl:justify-left flex flex-col justify-center xl:flex-row xl:items-baseline">
                            <h1 v-if="isLargeScreen" class="text-28 lg:text-40 text-primary text-center font-bold xl:text-left">
                                {{ metadata.navigationTitle }}
                            </h1>
                            <CategoryListMobileNavigation v-if="!isLargeScreen && model.children.length > 0" :model="model" :metadata="metadata"/>
                        </div>
                    </div>

                    <div class="xl:grid-cols-25 grid grid-flow-row auto-rows-max grid-cols-8 gap-10 rounded-lg px-10 pb-10 sm:px-0 md:grid-cols-12">
                        <CategoryTile
                            v-for="(child,index) in model.childCategories"
                            :key="'ct' + child.name"
                            :class="index % 7 === 0 ? 'col-span-8 md:col-span-6 xl:col-span-10' : 'col-span-4 md:col-span-3 xl:col-span-5'"
                            :model="child"
                            :wide="index % 7 === 0 ? true : false"/>
                    </div>
                    <h2 v-if="model.curatedCategories.length > 0" class="text-18 lg:text-30 text-primary mb-20 mt-28 text-center xl:text-left">
                        {{ $translate("Client.CategoryPage.Curated") }}
                    </h2>
                    <div v-if="model.curatedCategories.length > 0" class="xl:grid-cols-25 grid grid-flow-row auto-rows-max grid-cols-8 gap-10 rounded-lg px-10 pb-10 sm:px-0 md:grid-cols-12">
                        <CuratedTile
                            v-for="(curated, index) in model.curatedCategories"
                            :key="'ct' + curated.name"
                            :class="index % 7 === 0 ? 'col-span-8 md:col-span-6 xl:col-span-10' : 'col-span-4 md:col-span-3 xl:col-span-5'"
                            :model="curated"
                            :wide="index % 7 === 0 ? true : false"/>
                    </div>
                    <div v-if="seoTextSections.length > 0" class="mt-10 rounded-lg bg-white p-20 lg:p-40" :class="readmore ? 'readmore active': 'readmore'">
                        <h4 class="text-18 lg:text-30 mb-20 font-bold">
                            {{ model.seoHeading || metadata.navigationTitle }}
                        </h4>
                        <p v-for="(section, i) in seoTextSections" :key="i" class="text-16 mb-10">
                            {{ section }}
                        </p>
                        <button class="text-16 underline" @click="readmore = !readmore">
                            <span v-if="readmore">
                                {{ $translate("Client.CategoryPage.ReadLess") }}
                                <CIcon
                                    name="arrow-up"
                                    class="ml-10"
                                    width="12"
                                    height="12"/>
                            </span>
                            <span v-else>
                                {{ $translate("Client.CategoryPage.ReadMore") }}
                                <CIcon
                                    name="arrow-down"
                                    class="ml-10"
                                    width="12"
                                    height="12"/>
                            </span>
                        </button>
                    </div>
                    <RenderBlocks v-if="model.bodyContent" :model="model.bodyContent"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { CategoryPageModel, IMetadata } from '@/api/cms';
import { computed, ref } from 'vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import CategoryNavigation from './CategoryNavigation.vue';
import CuratedNavigation from './CuratedNavigation.vue';
import CategoryListMobileNavigation from './CategoryListMobileNavigation.vue';
import CategoryTile from '@/project/pages/category-page/category-tile/CategoryTile.vue';
import CuratedTile from './CuratedTile.vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import CIcon from '@/core/layout/svgicon/CIcon.vue';
import { useCmsCategories } from './cms-category-composable';
import RenderBlocks from '@/project/pages/content-page/RenderBlocks.vue';
import { trackPage } from '@/project/tracking/tracking.service';

const props = defineProps<{
    model: CategoryPageModel,
    metadata: IMetadata,
}>();

trackPage();

const { setCmsCategories } = useCmsCategories();
setCmsCategories(props.model, props.metadata);

const hero = props.model.heroBanner && props.model.heroBanner.length > 0 ? props.model.heroBanner[0].content : null;

const readmore = ref(false);
const breakpoints = useBreakpoints();

const isLargeScreen = breakpoints.greater('lg');
const isMediumOrGreaterScreen = breakpoints.greater('md');

const seoTextSections = computed<string[]>(() => props.model.seoText?.split('\n\n').filter(x => x));
</script>

<style scoped>

    .readmore > p{
        display: none;
    }
    .readmore > h4 + p{
        display: block;
    }

    .readmore.active p{
        display: block;
    }

</style>
