import { ProductDetailsViewModel, ProductsApiFactory, ProductTileViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { AnnotatedResult, useStaleWhileRevalidate } from '@/core/cache/useStaleWhileRevalidate';
import { Ref } from 'vue';

const productsApi = ProductsApiFactory({ isJsonMime: () => true }, commerceApiHost);

const {
    makeReactiveResult: makeReactiveForProduct,
    fetchResult: fetchProductResult,
} = useStaleWhileRevalidate<ProductDetailsViewModel>(() => ({
    sku: '',
    displayName: '',
    categories: [],
    brand: '',
    weight: '',
    country: '',
    productTeaser: '',
    salesPrice: 0,
    pricePerUnit: 0,
    unitOfMeasure: '',
    icons: [],
    hazardLabels: [],
    imageUrls: [],
    ingredients: '',
    nutrition: {
        calories: '',
        carbohydrates: '',
        energy: '',
        fat: '',
        fibers: '',
        ingredientsText: '',
        protein: '',
        salt: '',
        saturatedFat: '',
        sodium: '',
        sugar: '',
        unit: '',
    },
    productInformation: '',
    offer: undefined,
    breadcrumbs: [],
    url: '',
    relatedProducts: [],
    alwaysInStock: false,
    availableInStock: 0,
    stockEnabled: false,
    notInAssortment: false,
}));

const {
    makeReactiveResult: makeReactiveForRelatedProducts,
    fetchResult: fetchRelatedProductsResult,
} = useStaleWhileRevalidate<ProductTileViewModel[]>(() => ([{
    sku: '',
    displayName: '',
    brand: '',
    weight: '',
    country: '',
    productTeaser: '',
    salesPrice: 0,
    pricePerUnit: 0,
    unitOfMeasure: '',
    imageUrl: '',
    icons: [],
    hazardLabels: [],
    offer: undefined,
    breadcrumbs: [],
    url: '',
    alwaysInStock: false,
    availableInStock: 0,
    stockEnabled: false,
}]));

const {
    makeReactiveResult: makeReactiveForSelectedProducts,
    fetchResult: fetchSelectedProductResult,
} = useStaleWhileRevalidate<ProductTileViewModel[]>(() => ([{
    sku: '',
    displayName: '',
    brand: '',
    weight: '',
    country: '',
    productTeaser: '',
    salesPrice: 0,
    pricePerUnit: 0,
    unitOfMeasure: '',
    imageUrl: '',
    icons: [],
    hazardLabels: [],
    offer: undefined,
    breadcrumbs: [],
    url: '',
    alwaysInStock: false,
    availableInStock: 0,
    stockEnabled: false,
}]));

export function useProductDetail(productId: string): {
    productResult: Ref<AnnotatedResult<ProductDetailsViewModel>>,
    getProduct: typeof getProduct,
} {
    const productResult = makeReactiveForProduct();

    function getProduct(productId: string) {
        const productResultProvider = () => productsApi.apiCatalogProductsSkuGet(productId)
            .then(productResponse => {
                if (!productResponse.data.isSuccess) {
                    // e.g. handle not found and bad request
                }
                return productResponse.data.model;
            });

        fetchProductResult(productResult, productResultProvider, productId);
    }

    getProduct(productId);

    return {
        productResult,
        getProduct,
    };
}

export function useRelatedProducts(productIds?: string[]): {
    getRelatedProducts: typeof getRelatedProducts,
    relatedProductsResult: Ref<AnnotatedResult<ProductTileViewModel[]>>
    } {
    const relatedProductsResult = makeReactiveForRelatedProducts();

    function getRelatedProducts(productIds: string[]) {
        const relatedProductsResultProvider = () => productsApi.apiCatalogProductsTilesPost(undefined, productIds)
            .then((relatedProductsResponse) => {
                if (!relatedProductsResponse.data.isSuccess) {
                    // handle not found or bad request
                }
                return relatedProductsResponse.data.model;
            });

        fetchRelatedProductsResult(relatedProductsResult, relatedProductsResultProvider, productIds.join(''));
    }

    if (productIds) {
        getRelatedProducts(productIds);
    }
    return { getRelatedProducts, relatedProductsResult };
}

export function useSelectedProducts(productIds?: string[]): {
    getSelectedProducts: typeof getSelectedProducts,
    selectedProductsResult: Ref<AnnotatedResult<ProductTileViewModel[]>>
    } {
    const selectedProductsResult = makeReactiveForSelectedProducts();

    function getSelectedProducts(productIds: string[]) {
        const selectedProductsResultProvider = () => productsApi.apiCatalogProductsTilesPost(undefined, productIds)
            .then((selectedProductsResponse) => {
                if (!selectedProductsResponse.data.isSuccess) {
                    // handle not found or bad request
                }
                return selectedProductsResponse.data.model;
            });
        fetchSelectedProductResult(selectedProductsResult, selectedProductsResultProvider, productIds.join(''));
    }

    if (productIds) {
        getSelectedProducts(productIds);
    }
    return { getSelectedProducts, selectedProductsResult };
}
