<template>
    <DrawerDialog v-slot="{close}"
                  use-slide-animation>
        <div class="p-20 w-full md:w-[375px]">
            <div class="flex justify-between items-center mb-20">
                <h2 class="font-bold text-18">
                    {{ $translate('Client.CategoryPage.SortBy') }}
                </h2>
                <Button transparent @click="close">
                    <CIcon name="close" width="12" height="12" class="text-black"/>
                </Button>
            </div>
            <div class="flex flex-col space-y-8">
                <div v-for="item in items"
                     :key="item"
                     class="flex items-center"
                     @click="onChange(item), trackSorting(item, selected), close()">
                    <InputRadio
                        name="sorting"
                        :label="$translate(`${label}.${item}`)"
                        :value="$translate(`${label}.${item}`)"
                        :checked="selected === item"/>
                </div>
            </div>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import InputRadio from '@/project/forms/InputRadio.vue';
import Button from '@/project/components/button/Button.vue';
import { trackOverlay, trackSorting } from '@/project/tracking/tracking.service';

defineProps<{
    items: string[],
    selected?: string,
    label?: string,
    onChange:(selected: string) => void,
}>();

trackOverlay('Product sorting');

</script>
