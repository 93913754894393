import bus from '@/core/bus';
import { ref } from 'vue';
export const BlurSearch = 'BlurSearch';

export const searchInputElement = ref();

bus.on(BlurSearch, () => {
    const element = searchInputElement.value;
    if (element && element !== document.activeElement) {
        element.value = '';
        element.dispatchEvent(new Event('blur'));
    }
});
