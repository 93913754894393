<template>
    <div class="flex">
        <Button class="rounded-r-none" :size="'large'" @click="emit('decrement')">
            <CIcon name="minus" width="24" height="24" color="#FFFFFF"/>
        </Button>
        <div class="relative">
            <input ref="inputField"
                   class="w-full font-bold text-center h-50 bg-background text-14"
                   type="number"
                   :value="focusingField ? quantity || 0 : ''"
                   @focusin="onFocusField"
                   @focusout="onUnfocusField"
                   @change="onChange">
            <div v-if="!focusingField" class="flex absolute inset-0 justify-center items-center" @click="inputField.focus()">
                <AnimateValue animation="scroll">
                    <div :key="quantity" class="font-bold">
                        {{ quantity }}
                    </div>
                </AnimateValue>
            </div>
        </div>
        <Button class="rounded-l-none" :size="buttonSize" :disabled="disableIncrement" @click="emit('increment')">
            <CIcon name="plus" width="24" height="24" color="#FFFFFF"/>
        </Button>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import Button, { ButtonSizes } from '@/project/components/button/Button.vue';
import { parseQuantity } from '@/project/products/quantityParser';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import { ref } from 'vue';

defineProps<{
    quantity: number,
    disableIncrement: boolean,
}>();

const focusingField = ref(false);
const inputField = ref();

const { greater } = useBreakpoints();
const buttonSize: ButtonSizes = greater('md') ? 'large' : 'medium';

const emit = defineEmits(['increment', 'decrement', 'onChange']);
const onChange = (e: any) => {
    const newValue = parseQuantity(e.target.value);

    if (newValue >= 0) {
        emit('onChange', newValue);
    }
};

const onFocusField = () => {
    focusingField.value = true;
};

const onUnfocusField = () => {
    focusingField.value = false;
};
</script>
