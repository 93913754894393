<template>
    <svg width="222px"
         height="197px"
         viewBox="0 0 222 197"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>7A65E897-EC96-4439-825E-59E2C3796BBC</title>
        <g id="🎨-Styleguide"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
            <g id="Styleguide" transform="translate(-1160.000000, -1050.000000)">
                <g id="empty-top-100" transform="translate(1160.294229, 1051.500000)">
                    <g id="Group" transform="translate(33.705771, 19.000000)">
                        <g id="Group-8" transform="translate(64.222275, 61.488264) rotate(-16.000000) translate(-64.222275, -61.488264) translate(27.722275, 7.988264)" stroke="#006BB6">
                            <g id="Group-12" stroke-width="3">
                                <path id="Combined-Shape" d="M35.9384615,0 C41.2700097,0 45.8784637,3.11818201 48.0641404,7.64264862 C49.5324328,7.08960004 51.1234249,6.78688525 52.7846154,6.78688525 C60.2277145,6.78688525 66.2615385,12.8640693 66.2615385,20.3606557 C66.2615385,21.3158522 66.163578,22.2480043 65.9772236,23.1474768 C70.1616689,25.4500542 73,29.9239681 73,35.0655738 C73,40.2071794 70.1616689,44.6810934 65.9770279,46.9845725 C66.163578,47.8831432 66.2615385,48.8152954 66.2615385,49.7704918 C66.2615385,57.2670782 60.2277145,63.3442623 52.7846154,63.3442623 C50.9353269,63.3442623 49.173034,62.969113 47.5686655,62.2902528 C45.2275423,66.3044502 40.8955456,69 35.9384615,69 C31.1259971,69 26.902679,66.4594376 24.518509,62.6372972 C23.167903,63.0957723 21.7204557,63.3442623 20.2153846,63.3442623 C12.7722855,63.3442623 6.73846154,57.2670782 6.73846154,49.7704918 C6.73846154,48.8152954 6.83642202,47.8831432 7.02277641,46.9836707 C2.8383311,44.6810934 0,40.2071794 0,35.0655738 C0,29.9239681 2.8383311,25.4500542 7.02297207,23.146575 C6.83642202,22.2480043 6.73846154,21.3158522 6.73846154,20.3606557 C6.73846154,12.8640693 12.7722855,6.78688525 20.2153846,6.78688525 C21.5196014,6.78688525 22.780548,6.97347781 23.9733442,7.32160375 C26.2183257,2.97146072 30.7338586,0 35.9384615,0 Z" fill="#FFFFFF"/>
                                <path id="Path-Copy" d="M51,62 L47.334991,99.2412208 C46.9020575,103.640384 43.0841164,107 38.5177454,107 L32.4822546,107 C27.9158836,107 24.0979425,103.640384 23.665009,99.2412208 L20,62"/>
                            </g>
                            <path id="Path-3"
                                  d="M29.561098,13.5 C31.9302194,12.3240836 34.075494,11.7361254 35.9969215,11.7361254 C37.9183491,11.7361254 40.0373687,12.3240836 42.3539803,13.5"
                                  stroke-width="2"
                                  opacity="0.400000006"
                                  stroke-linecap="round"/>
                            <path id="Path-3-Copy-2"
                                  d="M54.561098,35.5 C56.9302194,34.3240836 59.075494,33.7361254 60.9969215,33.7361254 C62.9183491,33.7361254 65.0373687,34.3240836 67.3539803,35.5"
                                  stroke-width="2"
                                  opacity="0.400000006"
                                  stroke-linecap="round"
                                  transform="translate(60.957539, 34.618063) rotate(-270.000000) translate(-60.957539, -34.618063) "/>
                            <path id="Path-3-Copy-4"
                                  d="M24.1791607,29.1180627 C25.8075197,28.451396 27.282023,28.1180627 28.6026704,28.1180627 C29.9233178,28.1180627 31.3797753,28.451396 32.972043,29.1180627"
                                  stroke-width="2"
                                  opacity="0.400000006"
                                  stroke-linecap="round"
                                  transform="translate(28.575602, 28.618063) scale(-1, 1) rotate(-315.000000) translate(-28.575602, -28.618063) "/>
                            <path id="Path-3-Copy"
                                  d="M29.561098,58.5 C31.9302194,57.3240836 34.075494,56.7361254 35.9969215,56.7361254 C37.9183491,56.7361254 40.0373687,57.3240836 42.3539803,58.5"
                                  stroke-width="2"
                                  opacity="0.400000006"
                                  stroke-linecap="round"
                                  transform="translate(35.957539, 57.618063) scale(1, -1) translate(-35.957539, -57.618063) "/>
                        </g>
                        <g id="Group-10" transform="translate(89.722275, 65.488264) rotate(-10.000000) translate(-89.722275, -65.488264) translate(70.722275, 32.988264)">
                            <rect id="Rectangle"
                                  stroke="#006BB6"
                                  stroke-width="3"
                                  fill="#FFFFFF"
                                  stroke-linecap="round"
                                  x="0"
                                  y="9"
                                  width="38"
                                  height="56"
                                  rx="4"/>
                            <circle id="Oval"
                                    fill="#006BB6"
                                    opacity="0.200000003"
                                    cx="19"
                                    cy="28"
                                    r="10"/>
                            <path id="Rectangle-Copy"
                                  d="M7.33030077,0 L30.6696992,0 C31.6338154,1.9795983e-15 32.460708,0.687823398 32.6362632,1.63582148 L34,9 L34,9 L4,9 L5.36373676,1.63582148 C5.53929196,0.687823398 6.3661846,1.5093729e-15 7.33030077,0 Z"
                                  stroke="#006BB6"
                                  stroke-width="3"
                                  fill="#FFFFFF"
                                  stroke-linecap="round"/>
                        </g>
                        <path id="Path"
                              d="M28.6167806,50.0468233 C28.5707229,49.3855666 28.5075581,48.6973332 28.4207065,48.0341026 C31.0492842,48.2426781 37.2604915,48.601928 40.4838713,47.6932757 C45.6660184,46.2325893 46.23187,37.9882642 46.23187,37.9882642 C46.23187,37.9882642 37.3256302,38.787694 32.3803512,41.0511001 C29.8221759,42.2203072 28.529271,44.0185307 27.8713041,45.483165 C26.7836849,42.4051959 23.1971074,38.4139688 23.1971074,38.4139688 L21.2396559,41.1076852 C21.2396559,41.1076852 23.9175811,43.0421078 26.0211012,46.3747101 C26.5073388,47.1438734 26.8139513,48.5960063 27.0067356,50.0027395 C26.7876327,50.0001076 26.5718195,49.9955019 26.3494267,49.9955019 C12.754515,49.9955019 6.72227462,57.6548943 6.72227462,64.6326331 C6.72227462,71.6110299 10.2259483,85.9882642 26.3494267,85.9882642 C42.4801428,85.9882642 46.7858781,74.2343438 46.7858781,64.6326331 C46.7858781,55.5776929 40.7135017,50.587672 28.6167806,50.0468233 L27.6086241,49.9955019"
                              stroke="#006BB6"
                              stroke-width="3"
                              fill="#FFFFFF"
                              transform="translate(26.754076, 61.988264) rotate(-19.000000) translate(-26.754076, -61.988264) "/>
                        <g id="Group-7" transform="translate(122.414626, 73.023405) rotate(16.000000) translate(-122.414626, -73.023405) translate(106.414626, 17.523405)" stroke="#006BB6" stroke-linecap="round">
                            <path id="Rectangle" d="M16,0 C22.0253699,-1.10684249e-15 27.2518976,4.16222522 28.6006343,10.0347027 C30.8668781,19.9020966 32,34.776135 32,54.6568176 C32,73.5929245 31.4125683,88.2344948 30.2377049,98.5815285 C29.4331758,105.655873 23.4473374,110.999846 16.3273926,111 L15.901123,111 C8.80316051,110.999962 2.82840024,105.688097 1.99715687,98.6389757 C0.665718958,87.3257521 0,72.6650327 0,54.6568176 C0,35.4867588 1.32018418,20.4281179 3.96055254,9.48089488 C5.3020014,3.9190543 10.2786754,1.05099029e-15 16,0 Z" stroke-width="3" fill="#FFFFFF"/>
                            <path id="Line-4" d="M10.5,13.5 C12.5532747,12.5395022 14.4782747,12.0592533 16.275,12.0592533 C18.0717253,12.0592533 19.8133919,12.5395022 21.5,13.5" stroke-width="2" opacity="0.400000006"/>
                            <path id="Line-4-Copy" d="M8.5,24.5 C11.2999201,23.5395022 13.9249201,23.0592533 16.375,23.0592533 C18.8250799,23.0592533 21.2000799,23.5395022 23.5,24.5" stroke-width="2" opacity="0.400000006"/>
                            <path id="Line-4-Copy-2" d="M6.5,35.5 C10.0465655,34.5395022 13.3715655,34.0592533 16.475,34.0592533 C19.5784345,34.0592533 22.5867679,34.5395022 25.5,35.5" stroke-width="2" opacity="0.400000006"/>
                            <path id="Line-4-Copy-3" d="M5.5,46.5 C9.41988813,45.5395022 13.0948881,45.0592533 16.525,45.0592533 C19.9551119,45.0592533 23.2801119,45.5395022 26.5,46.5" stroke-width="2" opacity="0.400000006"/>
                            <path id="Line-4-Copy-4" d="M6.5,57.5 C10.0465655,56.5395022 13.3715655,56.0592533 16.475,56.0592533 C19.5784345,56.0592533 22.5867679,56.5395022 25.5,57.5" stroke-width="2" opacity="0.400000006"/>
                            <path id="Line-4-Copy-5" d="M6.5,68.5 C10.0465655,67.5395022 13.3715655,67.0592533 16.475,67.0592533 C19.5784345,67.0592533 22.5867679,67.5395022 25.5,68.5" stroke-width="2" opacity="0.400000006"/>
                        </g>
                        <g id="Group-11" transform="translate(2.722275, 71.988264)" stroke="#006BB6" stroke-width="3">
                            <path id="Rectangle" d="M7.87479304,0 L135.125207,0 C137.27353,-3.94640564e-16 139.038198,1.69700359 139.122152,3.84368575 L142.674906,94.6873715 C142.847566,99.1022745 139.408549,102.821229 134.993645,102.993889 C134.889486,102.997963 134.785256,103 134.681017,103 L8.31898304,103 C3.90070504,103 0.318983038,99.418278 0.318983038,95 C0.318983038,94.8957606 0.321020384,94.7915312 0.325093909,94.6873715 L3.87784847,3.84368575 C3.96180186,1.69700359 5.72646987,2.1709974e-15 7.87479304,0 Z" fill="#F8FCFF"/>
                            <circle id="Oval" cx="42" cy="28.5" r="5.5"/>
                            <path id="Path" d="M101,29 L101,52.5 C101,68.7924001 87.7924001,82 71.5,82 C55.2075999,82 42,68.7924001 42,52.5 L42,29" stroke-linecap="round"/>
                            <circle id="Oval-Copy" cx="101" cy="28.5" r="5.5"/>
                        </g>
                    </g>
                    <polygon id="Star"
                             stroke="#006BB6"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             points="207.415064 172.71465 200.655533 176.268346 201.946489 168.741498 196.477914 163.410955 204.035298 162.312803 207.415064 155.46465 210.794829 162.312803 218.352213 163.410955 212.883638 168.741498 214.174594 176.268346"/>
                    <polygon id="Star-Copy"
                             stroke="#006BB6"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(138.415064, 15.964650) rotate(-34.000000) translate(-138.415064, -15.964650) "
                             points="138.415064 21.7146505 131.655533 25.2683459 132.946489 17.7414982 127.477914 12.410955 135.035298 11.3128028 138.415064 4.46465047 141.794829 11.3128028 149.352213 12.410955 143.883638 17.7414982 145.174594 25.2683459"/>
                    <polygon id="Star-Copy-2"
                             stroke="#006BB6"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(18.415064, 69.964650) rotate(-60.000000) translate(-18.415064, -69.964650) "
                             points="18.4150635 75.7146505 11.6555331 79.2683459 12.9464885 71.7414982 7.47791357 66.410955 15.0352983 65.3128028 18.4150635 58.4646505 21.7948287 65.3128028 29.3522134 66.410955 23.8836385 71.7414982 25.1745939 79.2683459"/>
                    <polygon id="Star-Copy-3"
                             stroke="#006BB6"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(209.000000, 85.879714) rotate(-160.000000) translate(-209.000000, -85.879714) "
                             points="209 90.919714 203.709933 93.1608669 204.206675 87.4371596 200.440491 83.098561 206.037562 81.8022683 209 76.879714 211.962438 81.8022683 217.559509 83.098561 213.793325 87.4371596 214.290067 93.1608669"/>
                    <polygon id="Star-Copy-5"
                             stroke="#006BB6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(197.795718, 20.373895) rotate(-130.000000) translate(-197.795718, -20.373895) "
                             points="197.795718 24.0138952 193.975114 25.6325056 194.333872 21.498717 191.613851 18.3652847 195.65618 17.4290733 197.795718 13.8738952 199.935256 17.4290733 203.977585 18.3652847 201.257564 21.498717 201.616322 25.6325056"/>
                    <polygon id="Star-Copy-6"
                             stroke="#006BB6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(23.795718, 23.373895) rotate(-100.000000) translate(-23.795718, -23.373895) "
                             points="23.7957181 27.0138952 19.9751139 28.6325056 20.3338724 24.498717 17.6138507 21.3652847 21.6561798 20.4290733 23.7957181 16.8738952 25.9352564 20.4290733 29.9775854 21.3652847 27.2575638 24.498717 27.6163222 28.6325056"/>
                    <polygon id="Star-Copy-7"
                             stroke="#006BB6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(19.795718, 167.373895) rotate(-40.000000) translate(-19.795718, -167.373895) "
                             points="19.7957181 171.013895 15.9751139 172.632506 16.3338724 168.498717 13.6138507 165.365285 17.6561798 164.429073 19.7957181 160.873895 21.9352564 164.429073 25.9775854 165.365285 23.2575638 168.498717 23.6163222 172.632506"/>
                    <polygon id="Star-Copy-8"
                             stroke="#006BB6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(202.795718, 124.373895) rotate(-90.000000) translate(-202.795718, -124.373895) "
                             points="202.795718 128.013895 198.975114 129.632506 199.333872 125.498717 196.613851 122.365285 200.65618 121.429073 202.795718 117.873895 204.935256 121.429073 208.977585 122.365285 206.257564 125.498717 206.616322 129.632506"/>
                    <polygon id="Star-Copy-4"
                             stroke="#006BB6"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(9.000000, 123.879714) rotate(90.000000) translate(-9.000000, -123.879714) "
                             points="9 128.919714 3.70993273 131.160867 4.20667516 125.43716 0.440491353 121.098561 6.03756233 119.802268 9 114.879714 11.9624377 119.802268 17.5595086 121.098561 13.7933248 125.43716 14.2900673 131.160867"/>
                </g>
            </g>
        </g>
    </svg>
</template>
