<template>
    <div v-if="model">
        <section class="py-40 md:py-80 bg-white px-25">
            <h2 class="font-bold text-center text-16">
                {{ model.customerService?.title }}
            </h2>
            <span
                class="block font-bold leading-snug text-center my-25 text-28 md:text-30 xl:text-40">
                {{ model.customerService?.description }}
            </span>
            <div class="flex justify-center space-x-38 md:space-x-90">
                <div v-for="item in model.customerService?.items" :key="item.title" class="flex flex-col lg:flex-row flex-shrink-0 items-center">
                    <CIcon :name="item.iconName"
                           width="40"
                           height="40"
                           class="text-primary"/>
                    <div class="flex flex-col lg:justify-center text-center lg:text-left text-16 md:text-18 xl:text-20 lg:ml-15">
                        <span class="mt-10 lg:mt-0 text-primary">{{ item.title }}</span>
                        <RouterLinkConditional class="mt-2 font-bold text-primary" :url="item.link?.url || ''">
                            {{ item.link?.name }}
                        </RouterLinkConditional>
                    </div>
                </div>
            </div>
            <div v-if="model.customerService" class="flex justify-center mt-30 text-primary">
                <div v-if="model.customerService.todayIsClosed">
                    {{ $translate('Client.Common.CustomerServiceIsClosedToday') }}
                </div>
                <div v-else-if="model.customerService.todaysOpeningTime && model.customerService.todaysClosingTime">
                    {{ $translate("Client.Common.TodaysOpeningHours") }} {{ model.customerService.todaysOpeningTime }} - {{ model.customerService.todaysClosingTime }}
                </div>
            </div>
        </section>
        <section class="relative p-40 text-white text-13 xl:text-15 bg-primary">
            <div class="md:flex col-span-full md:justify-center mb-40 md:mb-30">
                <CIcon name="netto-logo"
                       width="119"
                       height="37"
                       color="white"/>
            </div>
            <div class="md:grid lg:flex md:grid-cols-12 lg:justify-between lg:px-80 xl:px-120 xxl:px-180">
                <div v-for="(section, index) in model.linkSection " :key="index" class="md:col-span-5 space-y-10 mb-25" :class="{'md:col-start-3': index % 2 === 0}">
                    <h2 class="font-bold">
                        <RouterLinkConditional
                            v-if="section.titleLink.url"
                            :url="section.titleLink.url"
                            :target="section.titleLink.target">
                            {{ section.titleLink.name }}
                        </RouterLinkConditional>
                        <template v-else>
                            {{ section.titleLink.name }}
                        </template>
                    </h2>
                    <div v-for="(link, linkIndex) in section.links" :key="linkIndex" class="opacity-90">
                        <RouterLinkConditional :url="link.url" :target="link.target">
                            {{ link.name }}
                        </RouterLinkConditional>
                    </div>
                </div>
                <div class="md:col-span-5 space-y-10">
                    <h2 class="font-bold">
                        {{ $translate('Client.Footer.PaymentMethods') }}
                    </h2>
                    <div class="flex flex-wrap items-baseline space-x-4">
                        <img v-for="card in model.creditcards"
                             :key="card.name || ''"
                             :src="card.url || ''"
                             :width="card.width"
                             :height="card.height"
                             :alt="card.name || ''">
                    </div>
                </div>
            </div>
            <div class="absolute right-10 bottom-40 md:bottom-20 md:right-30">
                <ScrollToTop/>
            </div>
        </section>
        <section class="grid grid-cols-12 py-20 px-20 bg-addressline">
            <address class="col-span-full lg:col-span-8 lg:col-start-3 not-italic leading-8 text-center text-white text-12 md:text-13">
                <div class="inline-block">
                    © {{ model.companyname }}
                </div>&nbsp;
                <div class="inline-block">
                    {{ model.streetAndNumber }} {{ model.cityAndZipCode }}
                </div>&nbsp;
                <div class="inline-block">
                    {{ $translate('Client.Common.Id') }} {{ model.idNumber }}
                </div>&nbsp;
                <div class="inline-block">
                    {{ $translate('Client.Common.Vat') }} {{ model.vatNumber }}
                </div>&nbsp;
                <div class="inline-block">
                    {{ $translate('Client.Common.Phone') }} <a :href="`tel:${model.phoneNumber}`">{{ model.phoneNumber }}</a>
                </div>&nbsp;
                <div class="inline-block">
                    {{ $translate('Client.Common.Email') }} <a :href="`mailto:${model.email}`">{{ model.email }}</a>
                </div>
            </address>
        </section>
    </div>
</template>

<script setup lang="ts">
import { SiteSettingFooterModel } from '@/api/cms';
import { getFooter } from '@/project/apis/cms/contentApi';
import { ref } from 'vue';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';
import ScrollToTop from './ScrollToTop.vue';

const model = ref<SiteSettingFooterModel>();

getFooter().then(_model => {
    model.value = _model;
});
</script>

<style scoped>
</style>
