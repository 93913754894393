import { computed, ComputedRef } from 'vue';
import { useCustomerInfo } from '@/project/apis/commerce/accountApi';

export function useAgeLimit(ageLimit: number | null | undefined): ComputedRef<boolean> {
    const customerInfo = useCustomerInfo();
    return computed(() => {
        if (ageLimit) {
            if (customerInfo.value?.age && customerInfo.value.age >= ageLimit) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    });
}
