<template>
    <div>
        <div class="pb-10 font-bold border-b pt-15 px-30 text-18 border-lightBorder">
            {{ $translate('Client.CategoryPage.NavigationTitle') }}
        </div>
        <div v-if="ancestors.length > 0">
            <div v-for="category in ancestors" :key="category.displayName" class="flex items-baseline px-10 space-x-8 border-b py-15 border-lightBorder">
                <CIcon name="arrow-left" width="10" height="10"/>
                <!-- If no category id, link to content page via url instead -->
                <RouterLink v-if="isOffersMode && !category.categoryId" :to="getUrl(category, isOffersMode)" class="text-14 text-primary">
                    {{ $translate("Client.CategoryPage.AllOffers") }}
                </RouterLink>
                <RouterLink v-else :to="getUrl(category, isOffersMode)" class="text-14 text-primary">
                    {{ category.displayName }}
                </RouterLink>
            </div>
        </div>
        <div v-if="ancestors.length > 0 && children.length > 0 && current?.displayName"
             class="-ml-2 space-y-20 font-bold border-b px-30 py-15 text-14 border-lightBorder">
            {{ current?.displayName }}
        </div>
        <div class="space-y-20 pt-15 px-30">
            <div v-for="category in children" :key="category.displayName">
                <RouterLink :to="getUrl(category, isOffersMode)" class="text-14 text-link">
                    {{ category.displayName }}
                </RouterLink>
            </div>
        </div>
        <div v-if="children.length === 0">
            <div v-for="item in siblings"
                 :key="item.url"
                 class="py-10 space-y-20 px-30 text-14">
                <span v-if="item.selected" class="font-bold text-14">{{ item.displayName }}</span>
                <RouterLink v-if="!item.selected" :to="getUrl(item, isOffersMode)" class="text-14 text-link">
                    {{ item.displayName }}
                </RouterLink>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { QueryKeys } from '@/project/browser/query';
import { useCategories } from './search-category-composable';

export default defineComponent({
    name: 'SearchCategoryNavigation',
    components: {
    },
    props: {
        isOffersMode: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['updateCategory'],
    setup(_, { emit }) {
        const { ancestors, children, current, siblings, getCategoryUrl: getUrl } = useCategories();

        watch(current, (value) => {
            if (value) {
                emit('updateCategory', value);
            }
        });

        return { ancestors, siblings, children, current, param: QueryKeys.CATEGORY, getUrl };
    },
});
</script>
