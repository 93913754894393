<template>
    <div class="page-spacing">
        <Breadcrumb :breadcrumbs="renderData.breadcrumbs" :current-page-name="renderData.displayName"/>
        <div class="grid grid-cols-48">
            <div class="col-span-full xl:mb-10 xl:mr-30 xl:col-span-33">
                <ProductDetails :key="key" :model="renderData"/>
            </div>
            <div class="col-span-full xl:col-span-15">
                <RelatedProducts v-if="relatedProducts.length > 0" :related-products="relatedProducts" :category-name="currentCategory"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useProductDetail, useRelatedProducts } from '@/project/apis/commerce/productsApi';
import { onUnmounted, Ref, ref, watch } from 'vue';
import { createRenderData } from './product-details-render-data';
import ProductDetails from './ProductDetails.vue';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import { ProductTileViewModel } from '@/api/commerce';
import RelatedProducts from './RelatedProducts.vue';
import router from '@/router';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { trackPage, trackProductDetailsPage, trackProductImpressions } from '@/project/tracking/tracking.service';
import { useCategories } from '@/project/pages/category-page/search-category-composable';
import dictionary from '@/core/dictionary/dictionary';
import { isAuthenticated } from '@/project/authentication/authentication';

const props = defineProps<{
    productId: string,
}>();

const renderData = createRenderData();
const key = ref(Symbol(''));
const { productResult, getProduct } = useProductDetail(props.productId);
const relatedProducts: Ref<ProductTileViewModel[]> = ref([]);
const { getRelatedProducts, relatedProductsResult } = useRelatedProducts();
const currentCategory = ref('');

trackPage('productDetailsPage');

watch(isAuthenticated, () => {
    getProduct(props.productId);
});

watch(productResult, (p) => {
    if (!p.data.sku) {
        const { notFoundUrl } = getPageUrls();
        router.replace(notFoundUrl.value);
        return;
    }

    if (p.data.notInAssortment) {
        router.replace(p.data.breadcrumbs[p.data.breadcrumbs.length - 1].url);
    }

    renderData.value = p.data;
    trackProductDetailsPage(p.data);
    currentCategory.value = p.data.breadcrumbs[p.data.breadcrumbs.length - 1].name;

    getRelatedProducts(p.data.relatedProducts.map(p => p.sku));

    key.value = Symbol(p.data.sku as string);
});

watch(relatedProductsResult, (res) => {
    if (res.dataReady) {
        relatedProducts.value = res.data;
        if (res.data.length > 0) {
            trackProductImpressions(relatedProducts.value, { listType: dictionary.get('Client.ProductDetailsPage.RelatedProducts'), choosingMechanism: 'Editor' });
        }
    }
});

const { setCategories, setOfferMode } = useCategories();

onUnmounted(() => {
    setCategories([]);
    setOfferMode(false);
});
</script>

<style scoped>
</style>
