/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { StoreViewModelIEnumerableClientResponse } from '../models';
// @ts-ignore
import { TimeSlotDateIEnumerableClientResponse } from '../models';
// @ts-ignore
import { TimeSlotTypeViewModel } from '../models';
// @ts-ignore
import { TimeSlotTypeViewModelIEnumerableClientResponse } from '../models';
// @ts-ignore
import { TimeSlotViewModelIEnumerableClientResponse } from '../models';
// @ts-ignore
import { ZipCodeViewModelClientResponse } from '../models';
/**
 * TimeSlotsApi - axios parameter creator
 * @export
 */
export const TimeSlotsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get available dates
         * @param {number} [zipCode] A valid zip code.
         * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
         * @param {string} [from] The start date
         * @param {number} [daysAhead] The number of days ahead of from to retrieve dates from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsDatesGet: async (zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, from?: string, daysAhead?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/timeslots/dates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (timeSlotType !== undefined) {
                localVarQueryParameter['timeSlotType'] = timeSlotType;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString() :
                    from;
            }

            if (daysAhead !== undefined) {
                localVarQueryParameter['daysAhead'] = daysAhead;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available stores for store delivery.
         * @param {number} [zipCode] A valid zip code.
         * @param {number} [daysAhead] The number of days ahead of today to retrireve Deliverytypes from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsDeliverytypesGet: async (zipCode?: number, daysAhead?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/timeslots/deliverytypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (daysAhead !== undefined) {
                localVarQueryParameter['daysAhead'] = daysAhead;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available stores Pickup
         * @param {number} [zipCode] A valid zip code.
         * @param {number} [daysAhead] The number of days ahead of today to retrieve stores from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsStoresGet: async (zipCode?: number, daysAhead?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/timeslots/stores`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (daysAhead !== undefined) {
                localVarQueryParameter['daysAhead'] = daysAhead;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available timeslots
         * @param {number} [zipCode] A valid zip code.
         * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
         * @param {string} [deliveryDate] The date of delivery
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsTimeslotsGet: async (zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, deliveryDate?: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/timeslots/timeslots`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (timeSlotType !== undefined) {
                localVarQueryParameter['timeSlotType'] = timeSlotType;
            }

            if (deliveryDate !== undefined) {
                localVarQueryParameter['deliveryDate'] = (deliveryDate as any instanceof Date) ?
                    (deliveryDate as any).toISOString() :
                    deliveryDate;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get area name of zip code.
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsZipCodeInfoGet: async (zipCode?: number, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/timeslots/zipCodeInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (zipCode !== undefined) {
                localVarQueryParameter['zipCode'] = zipCode;
            }

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeSlotsApi - functional programming interface
 * @export
 */
export const TimeSlotsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimeSlotsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get available dates
         * @param {number} [zipCode] A valid zip code.
         * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
         * @param {string} [from] The start date
         * @param {number} [daysAhead] The number of days ahead of from to retrieve dates from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeslotsDatesGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, from?: string, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotDateIEnumerableClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeslotsDatesGet(zipCode, timeSlotType, from, daysAhead, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available stores for store delivery.
         * @param {number} [zipCode] A valid zip code.
         * @param {number} [daysAhead] The number of days ahead of today to retrireve Deliverytypes from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeslotsDeliverytypesGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotTypeViewModelIEnumerableClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeslotsDeliverytypesGet(zipCode, daysAhead, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available stores Pickup
         * @param {number} [zipCode] A valid zip code.
         * @param {number} [daysAhead] The number of days ahead of today to retrieve stores from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeslotsStoresGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreViewModelIEnumerableClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeslotsStoresGet(zipCode, daysAhead, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get available timeslots
         * @param {number} [zipCode] A valid zip code.
         * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
         * @param {string} [deliveryDate] The date of delivery
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeslotsTimeslotsGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, deliveryDate?: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeSlotViewModelIEnumerableClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeslotsTimeslotsGet(zipCode, timeSlotType, deliveryDate, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get area name of zip code.
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTimeslotsZipCodeInfoGet(zipCode?: number, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZipCodeViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTimeslotsZipCodeInfoGet(zipCode, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TimeSlotsApi - factory interface
 * @export
 */
export const TimeSlotsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimeSlotsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get available dates
         * @param {number} [zipCode] A valid zip code.
         * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
         * @param {string} [from] The start date
         * @param {number} [daysAhead] The number of days ahead of from to retrieve dates from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsDatesGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, from?: string, daysAhead?: number, apiVersion?: string, options?: any): AxiosPromise<TimeSlotDateIEnumerableClientResponse> {
            return localVarFp.apiTimeslotsDatesGet(zipCode, timeSlotType, from, daysAhead, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available stores for store delivery.
         * @param {number} [zipCode] A valid zip code.
         * @param {number} [daysAhead] The number of days ahead of today to retrireve Deliverytypes from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsDeliverytypesGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: any): AxiosPromise<TimeSlotTypeViewModelIEnumerableClientResponse> {
            return localVarFp.apiTimeslotsDeliverytypesGet(zipCode, daysAhead, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available stores Pickup
         * @param {number} [zipCode] A valid zip code.
         * @param {number} [daysAhead] The number of days ahead of today to retrieve stores from
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsStoresGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: any): AxiosPromise<StoreViewModelIEnumerableClientResponse> {
            return localVarFp.apiTimeslotsStoresGet(zipCode, daysAhead, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available timeslots
         * @param {number} [zipCode] A valid zip code.
         * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
         * @param {string} [deliveryDate] The date of delivery
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsTimeslotsGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, deliveryDate?: string, apiVersion?: string, options?: any): AxiosPromise<TimeSlotViewModelIEnumerableClientResponse> {
            return localVarFp.apiTimeslotsTimeslotsGet(zipCode, timeSlotType, deliveryDate, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get area name of zip code.
         * @param {number} [zipCode] A valid zip code.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeslotsZipCodeInfoGet(zipCode?: number, apiVersion?: string, options?: any): AxiosPromise<ZipCodeViewModelClientResponse> {
            return localVarFp.apiTimeslotsZipCodeInfoGet(zipCode, apiVersion, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimeSlotsApi - interface
 * @export
 * @interface TimeSlotsApi
 */
export interface TimeSlotsApiInterface {
    /**
     * 
     * @summary Get available dates
     * @param {number} [zipCode] A valid zip code.
     * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
     * @param {string} [from] The start date
     * @param {number} [daysAhead] The number of days ahead of from to retrieve dates from
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApiInterface
     */
    apiTimeslotsDatesGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, from?: string, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<TimeSlotDateIEnumerableClientResponse>;

    /**
     * 
     * @summary Get available stores for store delivery.
     * @param {number} [zipCode] A valid zip code.
     * @param {number} [daysAhead] The number of days ahead of today to retrireve Deliverytypes from
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApiInterface
     */
    apiTimeslotsDeliverytypesGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<TimeSlotTypeViewModelIEnumerableClientResponse>;

    /**
     * 
     * @summary Get available stores Pickup
     * @param {number} [zipCode] A valid zip code.
     * @param {number} [daysAhead] The number of days ahead of today to retrieve stores from
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApiInterface
     */
    apiTimeslotsStoresGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<StoreViewModelIEnumerableClientResponse>;

    /**
     * 
     * @summary Get available timeslots
     * @param {number} [zipCode] A valid zip code.
     * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
     * @param {string} [deliveryDate] The date of delivery
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApiInterface
     */
    apiTimeslotsTimeslotsGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, deliveryDate?: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<TimeSlotViewModelIEnumerableClientResponse>;

    /**
     * 
     * @summary Get area name of zip code.
     * @param {number} [zipCode] A valid zip code.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApiInterface
     */
    apiTimeslotsZipCodeInfoGet(zipCode?: number, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<ZipCodeViewModelClientResponse>;

}

/**
 * TimeSlotsApi - object-oriented interface
 * @export
 * @class TimeSlotsApi
 * @extends {BaseAPI}
 */
export class TimeSlotsApi extends BaseAPI implements TimeSlotsApiInterface {
    /**
     * 
     * @summary Get available dates
     * @param {number} [zipCode] A valid zip code.
     * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
     * @param {string} [from] The start date
     * @param {number} [daysAhead] The number of days ahead of from to retrieve dates from
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApi
     */
    public apiTimeslotsDatesGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, from?: string, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return TimeSlotsApiFp(this.configuration).apiTimeslotsDatesGet(zipCode, timeSlotType, from, daysAhead, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available stores for store delivery.
     * @param {number} [zipCode] A valid zip code.
     * @param {number} [daysAhead] The number of days ahead of today to retrireve Deliverytypes from
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApi
     */
    public apiTimeslotsDeliverytypesGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return TimeSlotsApiFp(this.configuration).apiTimeslotsDeliverytypesGet(zipCode, daysAhead, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available stores Pickup
     * @param {number} [zipCode] A valid zip code.
     * @param {number} [daysAhead] The number of days ahead of today to retrieve stores from
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApi
     */
    public apiTimeslotsStoresGet(zipCode?: number, daysAhead?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return TimeSlotsApiFp(this.configuration).apiTimeslotsStoresGet(zipCode, daysAhead, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available timeslots
     * @param {number} [zipCode] A valid zip code.
     * @param {TimeSlotTypeViewModel} [timeSlotType] Type of delivery
     * @param {string} [deliveryDate] The date of delivery
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApi
     */
    public apiTimeslotsTimeslotsGet(zipCode?: number, timeSlotType?: TimeSlotTypeViewModel, deliveryDate?: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return TimeSlotsApiFp(this.configuration).apiTimeslotsTimeslotsGet(zipCode, timeSlotType, deliveryDate, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get area name of zip code.
     * @param {number} [zipCode] A valid zip code.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeSlotsApi
     */
    public apiTimeslotsZipCodeInfoGet(zipCode?: number, apiVersion?: string, options?: AxiosRequestConfig) {
        return TimeSlotsApiFp(this.configuration).apiTimeslotsZipCodeInfoGet(zipCode, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }
}
