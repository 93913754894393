<template>
    <div v-if="showFullBreadcrumb" class="flex items-baseline pb-20 sm:pl-0 space-x-10 pl-18 text-14">
        <RouterLink to="/">
            {{ $translate('Client.Common.Home') }}
        </RouterLink>
        <CIcon name="arrow-right" width="8" height="8"/>
        <template v-for="breadcrumb in breadcrumbs" :key="breadcrumb.url">
            <RouterLink :to="breadcrumb.url">
                {{ breadcrumb.name }}
            </RouterLink>
            <CIcon name="arrow-right" width="8" height="8"/>
        </template>
        <span class="text-fadedText">{{ currentPageName }}</span>
    </div>
    <div v-else class="flex items-baseline pb-10 pl-20 sm:pl-0 space-x-10 text-14">
        <CIcon name="arrow-left" width="8" height="8"/>
        <RouterLink v-if="breadcrumbs.length === 0" to="/">
            {{ $translate('Client.Common.Home') }}
        </RouterLink>
        <RouterLink v-else :to="breadcrumbs[breadcrumbs.length - 1].url">
            {{ breadcrumbs[breadcrumbs.length - 1].name }}
        </RouterLink>
    </div>
</template>

<script lang="ts">
import { NameAndUrl } from '@/api/cms';
import { ProductBreadcrumbViewModel } from '@/api/commerce';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
    name: 'Breadcrumb',
    props: {
        breadcrumbs: {
            type: Array as PropType<Array<ProductBreadcrumbViewModel | NameAndUrl>>,
            required: true,
        },
        currentPageName: {
            type: String,
            required: true,
        },
    },
    setup() {
        const breakpoints = useBreakpoints();
        const showFullBreadcrumb = breakpoints.greater('sm');

        return { showFullBreadcrumb };
    },
});
</script>

<style scoped>
</style>
