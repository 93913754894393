/* tslint:disable */
/* eslint-disable */
/**
 * Samkaup Commerce API
 * Omnichannel (App and Web) API for Samkaup Commerce
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientResponse } from '../models';
// @ts-ignore
import { ProductDetailsViewModelClientResponse } from '../models';
// @ts-ignore
import { ProductTileViewModelIEnumerableClientResponse } from '../models';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * The product details information will include similar/related products.
         * @summary Endpoint for retrieving details for a single product.
         * @param {string} sku The product SKU for the product to retrieve.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsSkuGet: async (sku: string, apiVersion?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sku' is not null or undefined
            assertParamExists('apiCatalogProductsSkuGet', 'sku', sku)
            const localVarPath = `/api/catalog/products/{sku}`
                .replace(`{${"sku"}}`, encodeURIComponent(String(sku)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Endpoint for retrieving product tile for a list of products.
         * @param {string} [apiVersion] 
         * @param {Array<string>} [requestBody] The product SKUs for the products to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsTilesPost: async (apiVersion?: string, requestBody?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/catalog/products/tiles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required

            if (apiVersion !== undefined) {
                localVarQueryParameter['api-version'] = apiVersion;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * The product details information will include similar/related products.
         * @summary Endpoint for retrieving details for a single product.
         * @param {string} sku The product SKU for the product to retrieve.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCatalogProductsSkuGet(sku: string, apiVersion?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetailsViewModelClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCatalogProductsSkuGet(sku, apiVersion, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Endpoint for retrieving product tile for a list of products.
         * @param {string} [apiVersion] 
         * @param {Array<string>} [requestBody] The product SKUs for the products to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCatalogProductsTilesPost(apiVersion?: string, requestBody?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductTileViewModelIEnumerableClientResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCatalogProductsTilesPost(apiVersion, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * The product details information will include similar/related products.
         * @summary Endpoint for retrieving details for a single product.
         * @param {string} sku The product SKU for the product to retrieve.
         * @param {string} [apiVersion] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsSkuGet(sku: string, apiVersion?: string, options?: any): AxiosPromise<ProductDetailsViewModelClientResponse> {
            return localVarFp.apiCatalogProductsSkuGet(sku, apiVersion, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Endpoint for retrieving product tile for a list of products.
         * @param {string} [apiVersion] 
         * @param {Array<string>} [requestBody] The product SKUs for the products to retrieve.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCatalogProductsTilesPost(apiVersion?: string, requestBody?: Array<string>, options?: any): AxiosPromise<ProductTileViewModelIEnumerableClientResponse> {
            return localVarFp.apiCatalogProductsTilesPost(apiVersion, requestBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProductsApi - interface
 * @export
 * @interface ProductsApi
 */
export interface ProductsApiInterface {
    /**
     * The product details information will include similar/related products.
     * @summary Endpoint for retrieving details for a single product.
     * @param {string} sku The product SKU for the product to retrieve.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiCatalogProductsSkuGet(sku: string, apiVersion?: string, options?: AxiosRequestConfig): AxiosPromise<ProductDetailsViewModelClientResponse>;

    /**
     * 
     * @summary Endpoint for retrieving product tile for a list of products.
     * @param {string} [apiVersion] 
     * @param {Array<string>} [requestBody] The product SKUs for the products to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApiInterface
     */
    apiCatalogProductsTilesPost(apiVersion?: string, requestBody?: Array<string>, options?: AxiosRequestConfig): AxiosPromise<ProductTileViewModelIEnumerableClientResponse>;

}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI implements ProductsApiInterface {
    /**
     * The product details information will include similar/related products.
     * @summary Endpoint for retrieving details for a single product.
     * @param {string} sku The product SKU for the product to retrieve.
     * @param {string} [apiVersion] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiCatalogProductsSkuGet(sku: string, apiVersion?: string, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiCatalogProductsSkuGet(sku, apiVersion, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Endpoint for retrieving product tile for a list of products.
     * @param {string} [apiVersion] 
     * @param {Array<string>} [requestBody] The product SKUs for the products to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public apiCatalogProductsTilesPost(apiVersion?: string, requestBody?: Array<string>, options?: AxiosRequestConfig) {
        return ProductsApiFp(this.configuration).apiCatalogProductsTilesPost(apiVersion, requestBody, options).then((request) => request(this.axios, this.basePath));
    }
}
