import { App } from 'vue';
import './config';
import './browser/query';
import contentConfig from './content';
import { initBasket } from './apis/commerce/basketApi';
import { initAuth } from './authentication/authentication';
import GTMConfig from './tracking/tracking.config';
import './apis/commerce/commerceAxios';

export default async function config(app: App<Element>): Promise<void> {
    contentConfig(app);
    await initAuth();
    initBasket();
    GTMConfig();
}
