import { IMetadata, LanguageAndUrl, Media } from '@/api/cms';
import { createHead, HeadObjectPlain } from '@vueuse/head';
import { App, Ref, ref } from 'vue';

export interface IMetaData {
    index: boolean,
    navigationTitle: string,
    url: string,
    seoTitle?: string,
    seoDescription?: string | null,
    seoImage?: Media,
    languages?: Array<LanguageAndUrl>,
}

const fallbackMetaData = {
    title: 'Netto.is',
};

const head = createHead();

export default function configMeta(app: App): void {
    app.use(head);
}

const meta: Ref<HeadObjectPlain> = ref({});

export function setMetaData(metaData: IMetaData): void {
    meta.value = {
        title: metaData.navigationTitle || fallbackMetaData.title,
        meta: [
            {
                name: 'og:title',
                content: metaData.seoTitle,
            },
            {
                name: 'og:url',
                content: metaData.url,
            },
            {
                name: 'og:description',
                content: metaData.seoDescription,
            },
            {
                name: 'og:image',
                content: metaData.seoImage?.url,
            },
            {
                name: 'description',
                content: metaData.seoDescription,
            },
            {
                name: 'theme-color',
                content: '#006BB6',
            },
        ],
        link: [
            { rel: 'canonical', href: metaData.url },
            ...(metaData.languages || []).map((language) => {
                return {
                    rel: 'alternate',
                    hreflang: language.language,
                    href: language.url,
                };
            }),
        ],
    };

    if (!metaData.index && meta.value.meta) {
        meta.value.meta.push({
            name: 'robots',
            content: 'noindex, follow',
        });
    }
}

export function getMetaData(): Ref<HeadObjectPlain> {
    return meta;
}
