<template>
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 633.02 895.76">
  <g id="Layer_1-2" data-name="Layer 1">
    <g>
      <path stroke-width="0" d="M222.3,675.98c-13.67,56.77-13.32,147.49.82,211.05.39,1.77-.19,3.6-1.5,4.83-1.32,1.22-3.21,1.66-4.92,1.13-8.78-2.65-33.23-4.25-46.74,2.27-.7.34-1.46.5-2.2.5-1.89,0-3.7-1.05-4.56-2.86-1.22-2.52-.16-5.55,2.35-6.77,13.06-6.3,33.14-6.39,46.07-4.52-12.82-64.34-12.62-152.18.83-208.01"/>
      <path stroke-width="0" d="M393.23,673.6c13.44,55.83,13.65,143.67.82,208.01,12.93-1.87,33.01-1.78,46.07,4.52,2.52,1.21,3.58,4.25,2.36,6.76-.87,1.81-2.68,2.87-4.57,2.87-.74,0-1.49-.16-2.2-.5-13.52-6.52-37.97-4.92-46.76-2.27-1.72.53-3.59.09-4.91-1.13-1.32-1.23-1.89-3.06-1.5-4.82,14.14-63.56,14.49-154.28.81-211.05"/>
      <path stroke-width="0" fill="#ffde00" d="M338.92,122.87c-15.27-.79-19.02-.89-19.02-.89-.46-6.56-1.03-13.36-1.82-19.91,24.81,2.12,83.59,5.8,114.16-3.48C481.28,83.76,486.62,0,486.62,0c0,0-84.26,8.14-131.06,31.14-24.34,11.93-36.51,30.27-42.68,45.22-10.21-31.29-44.22-72-44.22-72l-18.51,27.37s25.34,19.65,45.23,53.5c4.6,7.77,7.48,22.51,9.32,36.76,0,0,0,0-.1,0-203.05.18-293.15,123.1-293.15,235.04s52.39,342.92,293.59,342.92,305.65-188.75,305.65-342.92c0-145.34-90.89-225.43-271.76-234.14"/>
      <path stroke-width="0" fill="#f0bf00" d="M178.44,673.47c79.95,37.86,297.93,59.6,389.65-119.52,20.91-42.12,28.94-78.87,28.94-78.87,0,0-88.47,229.38-418.59,198.39"/>
      <path stroke-width="0" fill="#f0bf00" d="M318.08,102.07c15.95-11.83,158.23-30.52,168.51-102.07-1.72,21.79-11.6,78.84-45.78,95.33-15.22,8.69-57.27,12.82-122.73,6.74"/>
      <polygon stroke-width="0" fill="#f0bf00" points="304.62 121.98 303.44 113.49 319.08 111.28 319.9 121.98 304.62 121.98"/>
      <path stroke-width="0" fill="#fff299" d="M202.93,608.52c-17.09-6.87-33.28-12.72-47.96-24.26-13.79-10.84-24.13-24.83-37.17-36.28-10.84-9.53-23.47,3.3-20.51,14.88,10.01,39.1,58.1,71.29,95.65,78.83,19.19,3.86,28.35-25.8,9.98-33.18"/>
      <path stroke-width="0" fill="#fff299" d="M102.09,206.17c12.63-9.36,26.89-17.22,41.83-22.24,14.02-4.71,29.15-12.33,44.05-12.72,7.85-.21,12.85,10.55,6.65,16-9.05,7.96-20.42,11.06-30.64,17.15-10.42,6.2-21.07,13.89-30.24,21.82-14.6,12.64-35.19,34.81-33.15,55.3,1.04,10.41-8.74,19.88-18.4,21.04-11.2,1.36-19.46-5.17-23.37-15.33-5.43-14.14,3.15-32.27,10.1-44.49,8.31-14.59,19.74-26.59,33.15-36.54"/>
      <path stroke-width="0" fill="#fff299" d="M212.06,154.61c.3-.21.61-.43.91-.64,7.62-5.31,18.96.28,19.39,9.56.02.37.03.74.05,1.11.22,4.73-1.87,9.26-5.87,11.9-3.86,2.55-8.9,2.87-13.06.82-4.15-2.05-6.97-6.24-7.3-10.86-.34-4.78,1.98-9.19,5.87-11.9"/>
      <path stroke-width="0" d="M238.33,336.43c.06,18.31-14.74,33.21-33.05,33.27-18.32.06-33.21-14.74-33.27-33.05-.06-18.31,14.74-33.21,33.05-33.27,18.31-.06,33.21,14.74,33.27,33.05"/>
      <path stroke-width="0" fill="#fff" d="M205.89,325.99c.02,6.24-5.03,11.32-11.27,11.34-6.24.02-11.32-5.02-11.34-11.27-.02-6.24,5.02-11.32,11.26-11.34,6.24-.02,11.32,5.02,11.34,11.27"/>
      <path stroke-width="0" d="M450.11,335.73c.06,18.31-14.74,33.21-33.05,33.27-18.32.06-33.21-14.74-33.27-33.05-.06-18.31,14.74-33.21,33.05-33.27,18.31-.06,33.21,14.74,33.27,33.05"/>
      <path stroke-width="0" fill="#fff" d="M417.66,325.29c.02,6.24-5.02,11.32-11.26,11.34-6.24.02-11.32-5.02-11.34-11.27-.02-6.24,5.02-11.32,11.26-11.34,6.24-.02,11.32,5.02,11.34,11.27"/>
      <path stroke-width="0" d="M311.26,428.01c-26.95.09-48.96-26.16-49.07-58.5-.01-2.85,2.3-5.17,5.15-5.18,2.85-.01,5.17,2.29,5.18,5.15.09,26.65,17.45,48.28,38.71,48.21,21.25-.07,38.47-21.81,38.38-48.47,0-2.85,2.29-5.17,5.15-5.18,2.85-.01,5.17,2.29,5.18,5.15.11,32.35-21.73,58.74-48.68,58.83"/>
      <path stroke-width="0" fill="#f55671" d="M397.13,386.74c-.01,5.31,10.84,9.64,24.24,9.68,13.4.04,24.28-4.23,24.29-9.54.02-5.3-10.83-9.64-24.24-9.68-13.41-.04-24.28,4.23-24.3,9.54"/>
      <path stroke-width="0" fill="#f55671" d="M176.46,386.1c-.01,5.31,10.84,9.64,24.24,9.68,13.4.04,24.28-4.23,24.29-9.54.02-5.3-10.83-9.64-24.24-9.68-13.41-.04-24.28,4.23-24.3,9.54"/>
      <g>
        <path stroke-width="0" fill="#e30613" d="M372.96,460.92c-5.5-2.77-6.86-3.42-6.86-3.42.9-2.49,1.79-5.09,2.56-7.63,8.79,4.81,29.85,15.71,42.62,17.25,20.47,2.5,36.04-27.48,36.04-27.48,0,0-32.36-10.68-53.33-9.81-10.9.44-18.36,5.22-23.06,9.72,1.32-13.18-4.6-33.7-4.6-33.7l-11.26,7.07s6.14,11.35,7.97,27.05c.43,3.61-.9,9.5-2.54,15.05l.08.04c-74.88-32.99-128.07-2.33-146.26,38.93-18.19,41.27-36.38,134.82,52.46,173.98,88.85,39.16,143.23-19.9,168.26-76.69,23.6-53.53,3.12-97.79-62.08-130.37Z"/>
        <path stroke-width="0" fill="#fff" d="M374.58,513.71l18.37,8.1-131.58,79.39-18.37-8.1,131.58-79.39ZM322.44,497.8c3.63,8.37,3.42,17.13-.62,26.28-3.99,9.04-10.29,15.05-18.91,18.01-8.62,2.97-17.34,2.51-26.15-1.38-8.81-3.89-15.09-10.04-18.83-18.45-3.74-8.42-3.62-17.15.37-26.19,4.04-9.16,10.42-15.19,19.16-18.11,8.74-2.92,17.51-2.43,26.32,1.45,8.81,3.89,15.03,10.01,18.66,18.38ZM286.33,499.01c-2.92,1.04-5.11,3.22-6.57,6.54-1.36,3.09-1.43,6.1-.19,9.04s3.23,5.01,5.97,6.22c2.63,1.16,5.42,1.19,8.36.1s5.1-3.19,6.46-6.28c1.46-3.32,1.59-6.41.39-9.26-1.2-2.85-3.12-4.86-5.75-6.02-2.86-1.26-5.75-1.37-8.67-.34ZM378.55,592.87c3.63,8.37,3.42,17.13-.62,26.28-3.99,9.04-10.29,15.05-18.91,18.01-8.62,2.97-17.34,2.51-26.15-1.38-8.81-3.89-15.09-10.04-18.83-18.45s-3.62-17.15.37-26.19c4.04-9.16,10.42-15.19,19.16-18.11,8.74-2.92,17.51-2.43,26.32,1.45,8.81,3.88,15.03,10.01,18.66,18.38ZM342.44,594.08c-2.92,1.04-5.11,3.22-6.57,6.54-1.36,3.09-1.43,6.1-.19,9.04,1.23,2.94,3.23,5.01,5.97,6.22,2.63,1.16,5.42,1.19,8.36.1,2.94-1.1,5.1-3.19,6.46-6.28,1.46-3.32,1.59-6.41.39-9.26-1.2-2.85-3.12-4.86-5.75-6.02-2.86-1.26-5.75-1.37-8.67-.34Z"/>
      </g>
      <path stroke-width="0" d="M191.42,594.4c1.6,0,3.15-.88,3.9-2.41l19.4-39.61c1.05-2.14.17-4.72-1.96-5.77-2.13-1.04-4.71-.16-5.75,1.98l-19.41,39.61c-1.04,2.14-.15,4.72,1.97,5.76.61.29,1.23.43,1.86.44"/>
      <path stroke-width="0" d="M191.42,594.38c.61,0,1.22-.11,1.81-.38l40.17-18.23c2.17-.98,3.12-3.53,2.13-5.69-.98-2.17-3.52-3.14-5.69-2.14l-40.17,18.24c-2.16.97-3.12,3.53-2.13,5.69.71,1.58,2.25,2.5,3.87,2.51"/>
      <path stroke-width="0" d="M233.54,607.54c1.84.01,3.55-1.16,4.13-3.02.7-2.27-.56-4.67-2.82-5.39l-42.11-13.15c-2.28-.71-4.68.55-5.39,2.83-.71,2.26.55,4.67,2.82,5.38l42.11,13.15c.41.13.84.2,1.25.19"/>
      <path stroke-width="0" d="M191.32,584.9c-.98.42-98.16,41.02-145.08,17.27-12.05-6.09-29.77-30.16-33.03-43.87-7.29-30.65-6.03-60.86,13.62-85.55-.82-4.17-3.48-13.49-3.48-13.49-22.43,25.63-28.48,61.77-19.12,101.17,3.87,16.28,23.43,42.67,37.84,49.96,10.55,5.35,20.17,7.06,33.55,7.58,50.89,2,116-23.19,119.28-24.58l-3.59-8.49Z"/>
      <path stroke-width="0" d="M452.88,563.98c-1.6,0-3.15-.88-3.9-2.41l-19.4-39.61c-1.05-2.14-.17-4.72,1.96-5.77,2.13-1.04,4.71-.16,5.75,1.98l19.41,39.61c1.04,2.14.15,4.72-1.97,5.76-.61.29-1.23.43-1.86.44"/>
      <path stroke-width="0" d="M452.87,563.96c-.61,0-1.22-.11-1.81-.38l-40.17-18.23c-2.17-.98-3.12-3.53-2.13-5.69.98-2.17,3.52-3.14,5.69-2.14l40.17,18.24c2.16.97,3.12,3.53,2.13,5.69-.72,1.58-2.25,2.5-3.87,2.51"/>
      <path stroke-width="0" d="M410.76,577.12c-1.84.01-3.55-1.16-4.13-3.02-.7-2.27.56-4.67,2.82-5.39l42.11-13.14c2.28-.72,4.68.55,5.39,2.82.71,2.26-.55,4.67-2.82,5.38l-42.11,13.15c-.41.13-.84.2-1.25.19"/>
      <path stroke-width="0" d="M452.98,554.49c.98.42,98.16,41.02,145.08,17.27,12.05-6.09,19.48-15.72,22.74-29.43,7.29-30.65,1.59-69.32-18.07-94.01.82-4.17,2.34-14.12,2.34-14.12,22.43,25.63,34.05,70.86,24.69,110.26-3.87,16.28-13.14,28.23-27.55,35.52-10.55,5.35-23.14,7.81-36.52,8.33-50.89,2-113.03-23.94-116.31-25.33l3.59-8.49Z"/>
    </g>
  </g>
</svg>
</template>