<template>
    <div class="p-20 lg:p-40 mx-10 sm:mx-0 bg-white rounded-lg text-block block-spacing">
        <div class="mx-auto " :class="{'readmore': model.readMoreText, 'active': readmore, 'max-w-[80rem]': !model.useFullWidth}">
            <div v-html="model.text"/>

            <div v-if="model.primaryCta || model.secondaryCta || model.textLinkButtonLink" class="flex flex-wrap gap-20 mt-20 mb-20 cta-buttons" :class="{'flex-col' : isMobile}">
                <Button v-if="model.primaryCta"
                        :to="model.primaryCta?.url"
                        :label="model.primaryCta.name"
                        class="mr-20"
                        size="medium"/>
                <Button v-if="model.secondaryCta"
                        :to="model.secondaryCta?.url"
                        :label="model.secondaryCta.name"
                        class="mr-20"
                        :primary="false"
                        :white-bg="true"
                        size="medium"/>
                <Button v-if="model.textLinkButtonLink"
                        :to="model.textLinkButtonLink?.url"
                        :label="model.textLinkButtonLink.name"
                        class="px-0 text-cta"
                        :transparent="true"
                        size="medium"/>
            </div>
        </div>
        <button v-if="model.readMoreText" class="mt-10 underline text-16 readmore-button" @click="readmore = !readmore">
            <span v-if="readmore">
                {{ $translate("Client.CategoryPage.ReadLess") }}
                <CIcon
                    name="arrow-up"
                    class="ml-10"
                    width="12"
                    height="12"/>
            </span>
            <span v-else>
                {{ model.readMoreText }}
                <CIcon
                    name="arrow-down"
                    class="ml-10"
                    width="12"
                    height="12"/>
            </span>
        </button>
    </div>
</template>

<script lang="ts" setup>import { TextBlockModel } from '@/api/cms';
import { ref } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';

defineProps<{
    model: TextBlockModel;
}>();

const readmore = ref(false);
const breakspoints = useBreakpoints();
const isMobile = breakspoints.smaller('md');
</script>

<style scoped>
    .readmore{
        transition: max-height .5s ease-in-out;
        @apply overflow-hidden max-h-[30rem] relative;
    }

    .readmore:after{
        content: "";
        @apply bg-gradient-to-b from-transparent to-white bottom-0 absolute left-0 w-full h-[4rem];
    }

    .readmore.active{
        max-height: 9999px;
    }
    .readmore.active:after{
        opacity: 0;
    }

    .text-cta:deep() span{
        @apply px-0;
    }

</style>
