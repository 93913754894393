<template>
    <svg width="238px"
         height="203px"
         viewBox="0 0 238 203"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>10D8D608-8E40-40FA-861B-BC0220B9EB90</title>
        <g id="🎨-Styleguide"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
            <g id="Styleguide" transform="translate(-770.000000, -1046.000000)" stroke="#006BB6">
                <g id="empty-basket" transform="translate(771.790708, 1048.027561)">
                    <polygon id="Star"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             points="223.660191 177.71465 216.871799 181.268346 218.168267 173.741498 212.676343 168.410955 220.265995 167.312803 223.660191 160.46465 227.054386 167.312803 234.644039 168.410955 229.152115 173.741498 230.448582 181.268346"/>
                    <polygon id="Star-Copy"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(112.573536, 15.062505) rotate(-34.000000) translate(-112.573536, -15.062505) "
                             points="112.591952 21.9131106 105.819619 25.4581708 107.099464 17.9238292 101.60429 12.5753447 109.181679 11.4906853 112.557807 4.6400798 115.961046 11.504065 123.542782 12.6186425 118.06871 17.9454781 119.378352 25.4849304"/>
                    <polygon id="Star-Copy-2"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(14.824419, 73.422630) rotate(-60.000000) translate(-14.824419, -73.422630) "
                             points="14.84162 80.2864636 8.08144446 83.8390417 9.35986204 76.2904777 3.87559454 70.9327543 11.4390159 69.8450578 14.8097277 62.9812245 18.2057616 69.857555 25.7732435 70.9731961 20.3086865 76.3106986 21.6149358 83.8640361"/>
                    <polygon id="Star-Copy-3"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(215.917035, 91.682995) rotate(-160.000000) translate(-215.917035, -91.682995) "
                             points="215.906303 97.5817356 210.593215 99.8312672 211.099685 94.1040195 207.325255 89.7684221 212.945208 88.4638014 215.925569 83.5347232 218.892421 88.455671 224.508815 89.7449305 220.722479 94.0908642 221.213239 99.8167486"/>
                    <polygon id="Star-Copy-5"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(194.442341, 45.768053) rotate(-130.000000) translate(-194.442341, -45.768053) "
                             points="194.430465 50.0354071 190.599718 51.6661056 190.9678 47.5212053 188.249568 44.3856406 192.300996 43.4385848 194.451784 39.8700008 196.587622 43.4295885 200.635114 44.359647 197.903706 47.5066489 198.254406 51.6500406"/>
                    <polygon id="Star-Copy-6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(54.712486, 31.480437) rotate(-100.000000) translate(-54.712486, -31.480437) "
                             points="54.7083618 35.7573807 50.8860839 37.3854834 51.2479069 33.2343151 48.5298229 30.0898934 52.573356 29.1468537 54.7157656 25.5753916 56.8529836 29.1437293 60.8951494 30.0808659 58.1724897 33.2292597 58.528276 37.3799041"/>
                    <polygon id="Star-Copy-7"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(24.796741, 180.903368) rotate(-40.000000) translate(-24.796741, -180.903368) "
                             points="24.8086165 185.167563 20.9818426 186.780996 21.332809 182.640669 18.5993844 179.49599 22.6498734 178.566626 24.7872981 175.009674 26.9396715 178.575622 30.9940972 179.521984 28.2738481 182.655226 28.6421963 186.797061"/>
                    <polygon id="Star-Copy-8"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(209.359350, 129.373895) rotate(-90.000000) translate(-209.359350, -129.373895) "
                             points="209.35935 133.652782 205.538746 135.278303 205.897504 131.126865 203.177483 127.980054 207.219812 127.039845 209.35935 123.469487 211.498888 127.039845 215.541217 127.980054 212.821196 131.126865 213.179954 135.278303"/>
                    <polygon id="Star-Copy-4"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(15.218353, 137.879714) rotate(90.000000) translate(-15.218353, -137.879714) "
                             points="15.2183535 143.804326 9.92828622 146.055048 10.4250286 140.306902 6.65884484 135.949779 12.2559158 134.647952 15.2183535 129.70438 18.1807912 134.647952 23.7778621 135.949779 20.0116783 140.306902 20.5084208 146.055048"/>
                    <g id="Group-6" transform="translate(42.389513, 55.464650)" stroke-width="3">
                        <path d="M11.0469665,20.4 L11.0469665,4 C11.0469665,1.790861 12.6575702,0 14.6443505,0 L128.966215,0 C130.952995,0 132.563598,1.790861 132.563598,4 L132.563598,20.4" id="Path" fill="#DBE7EF"/>
                        <path d="M7.91597416,21 L135.694591,21 C137.852545,21 139.621494,22.7117261 139.692429,24.8685144 L143.338803,135.737029 C143.484038,140.152919 140.021988,143.850442 135.606097,143.995677 C135.51847,143.998559 135.430801,144 135.343126,144 L8.26743899,144 C3.84916099,144 0.267438989,140.418278 0.267438989,136 C0.267438989,135.912325 0.268880278,135.824656 0.271762272,135.737029 L3.9181358,24.8685144 C3.98907078,22.7117261 5.75801962,21 7.91597416,21 Z" id="Rectangle" fill="#F8FCFF"/>
                        <ellipse id="Oval"
                                 cx="42.1793268"
                                 cy="57.5"
                                 rx="5.52348327"
                                 ry="5.5"/>
                        <path d="M101.431238,58 L101.431238,81.5 C101.431238,97.7924001 88.1672461,111 71.8052825,111 C55.443319,111 42.1793268,97.7924001 42.1793268,81.5 L42.1793268,58" id="Path" stroke-linecap="round"/>
                        <ellipse id="Oval-Copy"
                                 cx="101.431238"
                                 cy="57.5"
                                 rx="5.52348327"
                                 ry="5.5"/>
                        <polyline id="Path-2" stroke-linejoin="round" points="12.754225 20.4 22.5960679 8.54545455 12.754225 0.4"/>
                        <line x1="22.5960679"
                              y1="8.59090909"
                              x2="22.5960679"
                              y2="20.4090909"
                              id="Line-2"/>
                        <line x1="121.014497"
                              y1="8.59090909"
                              x2="121.014497"
                              y2="20.4090909"
                              id="Line-2-Copy"/>
                        <polyline id="Path-2-Copy" stroke-linejoin="round" transform="translate(125.946050, 10.400000) scale(-1, 1) translate(-125.946050, -10.400000) " points="121.014497 20.4 130.877602 8.54545455 121.014497 0.4"/>
                    </g>
                    <g id="Group-9"
                       opacity="0.800000012"
                       transform="translate(96.135137, 40.464650)"
                       stroke-linecap="round"
                       stroke-width="2">
                        <line x1="18.0596578"
                              y1="8.5"
                              x2="18.0596578"
                              y2="0.5"
                              id="Line-3"/>
                        <line x1="27.0961378"
                              y1="8.66700999"
                              x2="27.100032"
                              y2="3.33299001"
                              id="Line-3"
                              transform="translate(27.098085, 6.000000) rotate(10.000000) translate(-27.098085, -6.000000) "/>
                        <line x1="35.1304127"
                              y1="8.83399928"
                              x2="35.1340721"
                              y2="6.16600072"
                              id="Line-3-Copy-2"
                              transform="translate(35.132242, 7.500000) rotate(20.000000) translate(-35.132242, -7.500000) "/>
                        <line x1="9.02317767"
                              y1="8.66700999"
                              x2="9.01928349"
                              y2="3.33299001"
                              id="Line-3-Copy"
                              transform="translate(9.021231, 6.000000) rotate(-10.000000) translate(-9.021231, -6.000000) "/>
                        <line x1="0.988902766"
                              y1="8.83399928"
                              x2="0.985243432"
                              y2="6.16600072"
                              id="Line-3-Copy-3"
                              transform="translate(0.987073, 7.500000) rotate(-20.000000) translate(-0.987073, -7.500000) "/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
