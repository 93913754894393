import { addSize, setMaxWidth } from '@/core/responsive/image/responsive-image.service';

addSize(125);
addSize(250);
addSize(500);
addSize(750);
addSize(1000);
addSize(1250);
addSize(1500);
addSize(1750);
addSize(2000);
addSize(3000);
setMaxWidth(1440);
