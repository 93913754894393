<template>
    <div v-if="model.invalidReason" class="py-8 px-20 font-bold bg-warning">
        {{ $translate(`Client.BasketStatus.${model.invalidReason}`) }}
    </div>
    <div class="grid py-10 bg-white pr-30 pl-30 grid-cols-24" :class="{'border border-warning': model.invalidReason}">
        <RouterLinkConditional :url="model.url" class="flex col-span-12 items-center">
            <div class="relative mr-25" :class="{'grayscale opacity-50': model.invalidReason}">
                <div v-if="model.discount"
                     class="flex absolute top-5 -right-15 z-2">
                    <Splash :label="model.discount" :size="isMobile ? 'xs' : 'sm'"/>
                </div>
                <ResponsiveImage :image-url="model.imageUrl"
                                 :aspect-ratio="1/1"
                                 class="w-50 h-50"
                                 :fixed-width-on-screen="60"
                                 mode="pad"/>
            </div>
            <div class="flex flex-col">
                <div :class="{'text-fadedText': model.invalidReason}">
                    {{ model.displayName }}
                </div>
                <div class="text-fadedText">
                    {{ productTeasers }}
                </div>
                <div class="flex space-x-10">
                    <CIcon
                        v-for="icon in model.icons"
                        :key="icon"
                        :name="icon"
                        width="20"
                        height="20"
                        original
                        translate/>
                    <CIcon
                        v-for="icon in model.hazardLabels"
                        :key="icon"
                        :name="icon"
                        width="20"
                        height="20"
                        original
                        translate/>
                </div>
            </div>
        </RouterLinkConditional>
        <div class="flex col-span-4 justify-center items-center space-x-4 text-right z-1">
            <div class="relative">
                <div :key="model.quantity" class="font-bold" :class="{'text-fadedText': model.invalidReason}">
                    {{ model.quantity }}
                </div>
            </div>
        </div>
        <div v-if="!isMobile"
             class="flex flex-col col-span-3 justify-end text-right"
             :class="{'justify-center': !model.pricePerUnit && !model.unitOfMeasure}">
            <div :class="{'text-fadedText': props.model.invalidReason}">
                {{ formatPrice(model.discountedPrice || model.salesPrice) }}
            </div>
            <div v-if="model.pricePerUnit && model.unitOfMeasure"
                 class="mb-10 text-fadedText text-12 lg:text-11 xl:text-14">
                {{ formatPrice(model.pricePerUnit) }} / {{ model.unitOfMeasure }}
            </div>
        </div>
        <div class="flex flex-col col-span-8 sm:col-span-5 justify-end text-right"
             :class="{'justify-center': !model.discountedPrice, 'text-fadedText': model.invalidReason}">
            <div v-if="model.totalDiscountAmount"
                 class="font-bold text-save text-13"
                 :class="{'text-fadedText': model.invalidReason}">
                {{ $translate('Client.ProductDetailsPage.Save') }} {{ formatPrice(model.totalDiscountAmount) }}
            </div>
            <div class="mb-10 font-bold">
                {{ formatPrice(model.totalDiscountedPrice || model.totalSalesPrice) }}
            </div>
            <div v-if="model.pricePerUnit && model.unitOfMeasure && isMobile" class="mb-10 text-fadedText text-12 lg:text-11 xl:text-14">
                {{ formatPrice(model.pricePerUnit) }} / {{ model.unitOfMeasure }}
            </div>
            <!-- Bonus -->
            <div v-if="model.bonus" class="mt-5">
                <span class="py-2 px-6 sm:px-4 xl:px-6 font-bold text-white whitespace-nowrap rounded bg-bonus-background text-11 xl:text-12">
                    {{ $translate('Client.Common.BonusShort', model.bonus.bonusPercentage) }}%
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { OrderLineItemViewModel } from '@/api/commerce';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Splash from '@/project/products/Splash/Splash.vue';
import { DeepReadonly } from 'vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';

const props = defineProps<{
    model: DeepReadonly<OrderLineItemViewModel>;
}>();

const { smaller } = useBreakpoints();
const isMobile = smaller('sm');

const productTeasers = [
    props.model.brand,
    props.model.weight,
    props.model.productTeaser,
].filter(elem => elem).join(', ');

</script>
