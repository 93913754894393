<template>
    <div class="flex relative flex-col justify-center h-full">
        <div :style="aspectRatioStyling">
            <div ref="videoElement" class="absolute inset-0 video-wrapper" :style="positionStyling"/>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, PropType, computed, watch } from 'vue';
import Player, { Options } from '@vimeo/player';
import { useBreakpointsLegacy, normalizeBreakpointsStructure } from '@/core/responsive/breakpoints/breakpoints-legacy.composable';
const videoFormat = 16 / 9;

export default defineComponent({
    name: 'VimeoPlayer',
    props: {
        videoUrl: {
            type: String,
            required: true,
        },
        aspectRatio: {
            type: [Object, Number] as PropType<Record<string, number> | number>,
            default: 16 / 9,
        },
        autoPlay: {
            type: Boolean,
            default: false,
        },
        loop: {
            type: Boolean,
            default: false,
        },
        background: {
            type: Boolean,
            default: true, /* note that background true disables ui controls on some videos depending on their embed setting */
        },
        doNotTrack: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const options: Options = {
            url: props.videoUrl,
            background: props.background,
            autoplay: props.autoPlay,
            dnt: props.loop,
            loop: props.doNotTrack,
        };
        const videoElement = ref(undefined);
        const { isBreakpointActive, activeBreakpoint } = useBreakpointsLegacy();

        const activeAspectRatio = ref(0);
        watch(activeBreakpoint, () => {
            activeAspectRatio.value = getActiveAspectRatio();
        }, { immediate: true });

        function getActiveAspectRatio() {
            if (typeof props.aspectRatio === 'number') {
                return props.aspectRatio;
            }

            // Else - object with aspect-ratio pr. breakpoint-def. Normalize once.
            const aspectRatiosByNormalizedBreakpoints = normalizeBreakpointsStructure(props.aspectRatio);

            // Find first active breakpoint-def
            const activeBpDef = Object.keys(aspectRatiosByNormalizedBreakpoints).find((bpDef) => isBreakpointActive(bpDef));
            // Use active or default
            const result = activeBpDef ? aspectRatiosByNormalizedBreakpoints[activeBpDef] : (aspectRatiosByNormalizedBreakpoints as any).default;
            if (!result) {
                throw new Error('Provide a breakpoint-definition for all cases or a default');
            }
            return result;
        }

        const aspectRatioStyling = computed(() => ({
            paddingTop: `${100 / activeAspectRatio.value}%`,
        }));

        const positionStyling = computed(() => {
            const width = videoFormat * 100 / activeAspectRatio.value;
            const left = (width - 100) / -2;
            return {
                width: `${width}%`,
                left: `${left}%`,
            };
        });

        onMounted(() => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const player = new Player(videoElement.value!, options);
        });

        return {
            videoElement,
            positionStyling,
            aspectRatioStyling,
        };
    },
});
</script>

<style scoped>
    .video-wrapper:deep() iframe{
            @apply w-full h-full;
    }
</style>
