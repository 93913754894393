<template>
    <div class="px-40 card py-30">
        <h2 class="font-bold text-40 text-blue">
            {{ isAuthenticated ? model.headlineLoggedIn.replace('{CustomerName}', customerInfo?.firstName || '') : model.headlineNotLoggedIn }}
        </h2>
        <Form v-slot="{errors, isSubmitting, resetForm}"
              key="customerInfoForm"
              :validation-schema="validationSchema"
              @submit="onSubmit">
            <vue-recaptcha v-if="!isPrerender"
                           ref="recaptcha"
                           size="invisible"
                           hl="is"
                           :sitekey="recaptchaSiteKey"
                           @verify="responseToken => onRecaptchaSubmit(responseToken, resetForm)"
                           @expired="onRecaptchaExpired"
                           @fail="onRecaptchaError"/>
            <div class="grid grid-cols-8 gap-20">
                <div class="col-span-8 sm:col-span-4" :class="{'hidden': isAuthenticated}">
                    <InputText v-model="name"
                               :light-background="true"
                               name="name"
                               :hidden="isAuthenticated"
                               type="text"
                               validation-behaviour="lazy"
                               label="Name"/>
                </div>
                <div class="col-span-8 sm:col-span-4">
                    <InputText v-model="email"
                               name="email"
                               :light-background="true"
                               type="email"
                               validation-behaviour="lazy"
                               label="Email"/>
                </div>
                <div class="relative col-span-8">
                    <Textarea v-model="message"
                              :light-background="true"
                              name="message"
                              validation-behaviour="lazy"
                              :rows="8"
                              label="Message"/>
                    <div class="mt-4 text-right h-[13px] text-13" :class="{'text-errorText absolute right-0 bottom-5': message.length > 500}">
                        {{ message.length > 100 ? `${500 - message.length}` : '' }}
                    </div>
                </div>
                <div class="flex flex-col col-span-full items-end">
                    <Button :label="$translate('Client.Common.Submit')"
                            :disabled="Object.keys(errors).length > 0 || isSubmitting || !message || !email"/>
                    <div v-if="Object.keys(errors).length > 0" class="mt-20 text-right rounded-lg text-errorText">
                        {{ $translate('Client.CheckoutPage.CustomerInformation.ErrorInForm') }}
                    </div>
                    <div v-if="statusMessage" class="mt-20 text-right rounded-lg">
                        {{ statusMessage }}
                    </div>
                </div>
            </div>
        </Form>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import vueRecaptcha from 'vue3-recaptcha2';
import { ContactFormApiFactory, WriteUsBlockModel } from '@/api/cms/';
import { cmsHost, recaptchaSiteKey } from '@/core/infrastructure/environment';
import InputText from '@/project/forms/InputText.vue';
import Textarea from '@/project/forms/Textarea.vue';
import Button from '@/project/components/button/Button.vue';
import { isAuthenticated } from '@/project/authentication/authentication';
import { useCustomerInfo } from '@/project/apis/commerce/accountApi';
import { Form } from 'vee-validate';
import { isUserAgentPrerender } from '@/core/browser/bot.service';
import dictionary from '@/core/dictionary/dictionary';

defineProps<{
    model: WriteUsBlockModel,
}>();

const isPrerender = isUserAgentPrerender();

const customerInfo = useCustomerInfo();

const recaptcha = ref();

const name = ref('');
const email = ref('');
const message = ref('');
const statusMessage = ref('');

const validationSchema: Record<string, string> = {
    email: 'email|required|max:255',
    name: 'max:255',
    message: 'required|max:500',
};

watch([customerInfo], ([ci]) => {
    if (ci) {
        name.value = `${ci.firstName || ''} ${ci.lastName || ''}`.trim();
        email.value = ci.email || '';
    }
}, { immediate: true });

function onSubmit() {
    recaptcha.value?.reset();
    recaptcha.value?.execute();
}

async function onRecaptchaSubmit(responseToken: string, resetForm: () => void): Promise<void> {
    const contactFormApi = ContactFormApiFactory({
        basePath: cmsHost,
        isJsonMime: () => false,
    });

    try {
        await contactFormApi.apiContactformPost(email.value, message.value, responseToken, name.value);
        statusMessage.value = dictionary.get('Client.ContactForm.SuccessMessage');
        resetForm();
    } catch {
        statusMessage.value = dictionary.get('Client.ContactForm.SubmitErrorMessage');
    }
}

function onRecaptchaExpired() {
    recaptcha.value?.reset();
}

function onRecaptchaError(): void {
    recaptcha.value?.reset();
}
</script>
