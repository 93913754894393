<template>
    <component :is="pageComponent"
               v-if="pageComponent"
               :model="pageModel"
               :alias="alias"
               :navigation="navigation"
               :metadata="metadata"/>
</template>

<script lang="ts">
import { defineComponent, Ref, shallowRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import contentApi, { getPageUrls } from '@/project/apis/cms/contentApi';
import { pageResolver } from '@/core/content/componentResolver';
import HttpStatus from 'http-status-codes';
import { HttpServerError } from '@/core/http/HttpServerError';
import Error500Page from '@/project/pages/Error500Page.vue';
import { HttpNoServerResponse } from '@/core/http/HttpNoServerResponse';
import logging from '@/core/infrastructure/logging';
import { SitePageData, IMetadata, SiteNavigation, ErrorPageModel } from '@/api/cms';
import bus from '@/core/bus';
import { NEW_PAGE_SET } from '@/core/content/constants';
import { setMetaData } from '@/core/browser/metadata/metaData';
import { useFunnelView } from '@/core/layout/funnel-view-composable';

export const pageCache = new Map<string, SitePageData>();

export default defineComponent({
    name: 'CmsPageProxy',
    setup() {
        const pageData: {
            alias: Ref<string | undefined>,
            pageComponent: Ref,
            pageModel: Ref<any | undefined>,
            navigation: Ref<SiteNavigation | undefined>,
            metadata: Ref<IMetadata | undefined>,
        } = {
            alias: shallowRef(undefined),
            pageComponent: shallowRef(undefined),
            pageModel: shallowRef(undefined),
            navigation: shallowRef(undefined),
            metadata: shallowRef(undefined),
        };

        const { path } = useRoute();
        const router = useRouter();

        const { setFunnelView } = useFunnelView();

        (async() => {
            try {
                const page = pageCache.get(path);
                if (page) {
                    // Stale while revalidate pattern
                    resolveAndSetPage(path, page.jsonContent.alias, page);
                }

                const contentPageModel = await contentApi.getPage(path);

                const url = new URL(contentPageModel.metadata.url);

                if (url.pathname !== path) {
                    router.replace(url.pathname);
                }

                resolveAndSetPage(path, contentPageModel.jsonContent.alias, contentPageModel);
            } catch (error) {
                if (error instanceof HttpServerError) {
                    switch (error.status) {
                    case HttpStatus.NOT_FOUND: {
                        const { notFoundUrl } = getPageUrls();
                        if (!notFoundUrl) {
                            logging.error('404 url not found in rootpage');
                            return;
                        }
                        const error404Page = await contentApi.getPage(notFoundUrl.value);

                        resolveAndSetPage(notFoundUrl.value, error404Page.jsonContent.alias, error404Page);
                        setFunnelView(true);
                        break;
                    }
                    case HttpStatus.INTERNAL_SERVER_ERROR: {
                        logging.exception(`http-status 500 on path: ${path}`, error);
                        await goToErrorPage();
                        break;
                    }
                    }
                } else if (error instanceof HttpNoServerResponse) {
                    logging.error(`No server-response on path: ${path}`);
                    await goToErrorPage();
                }
            }
        })();

        // Will try to resolve the system error page from the CMS data, but if that is unavailable
        // it will fall back to the built-in Error500Page.
        async function goToErrorPage() {
            const { errorUrl } = getPageUrls();
            if (!errorUrl.value) {
                logging.error('Error page url not found in rootpage');
                pageData.pageComponent.value = Error500Page;
                pageData.pageModel.value = {
                    headline: 'Ooops',
                    description: 'An unexpected error ocurred on the server.',
                } as ErrorPageModel;
                return;
            }
            const errorPage = await contentApi.getPage(errorUrl.value);

            resolveAndSetPage(errorUrl.value, errorPage.jsonContent.alias, errorPage);
        }

        function resolveAndSetPage(path: string, pageType: string, model: SitePageData) {
            pageData.pageComponent.value = pageResolver.resolve(pageType);
            pageData.alias.value = pageType;
            pageData.metadata.value = model.metadata;
            pageData.navigation.value = model.navigation;
            pageData.pageModel.value = model.jsonContent.content;
            setMetaData(model.metadata);
            pageCache.set(path, model);
            bus.emit(NEW_PAGE_SET);
        }

        return pageData;
    },
});
</script>
