import vue from 'vue';
import { useBreakpoints as useVueUseBreakpoints } from '@vueuse/core';
import { breakpoints as bp } from '@/project/config/breakpoints.config';

type K = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export function useBreakpoints(): {
    greater(k: K): vue.Ref<boolean>;
    smaller(k: K): vue.Ref<boolean>;
    between(a: K, b: K): vue.Ref<boolean>;
    isGreater(k: K): boolean;
    isSmaller(k: K): boolean;
    isInBetween(a: K, b: K): boolean;
    } {
    const breakpoint = useVueUseBreakpoints(bp);
    return breakpoint;
}
