<template>
    <svg width="229px"
         height="204px"
         viewBox="0 0 229 204"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg"
         xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>39D6381D-4CFF-4DAB-B603-C070D94175C9</title>
        <g id="🎨-Styleguide"
           stroke="none"
           stroke-width="1"
           fill="none"
           fill-rule="evenodd">
            <g id="Styleguide" transform="translate(-1442.000000, -1041.000000)" stroke="#006BB6">
                <g id="empty-orders" transform="translate(1443.616923, 1042.891189)">
                    <polygon id="Star"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             points="208.709292 180.71465 201.949762 184.268346 203.240717 176.741498 197.772142 171.410955 205.329527 170.312803 208.709292 163.46465 212.089057 170.312803 219.646442 171.410955 214.177867 176.741498 215.468823 184.268346"/>
                    <polygon id="Star-Copy"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(112.709292, 15.964650) rotate(-34.000000) translate(-112.709292, -15.964650) "
                             points="112.709292 21.7146505 105.949762 25.2683459 107.240717 17.7414982 101.772142 12.410955 109.329527 11.3128028 112.709292 4.46465047 116.089057 11.3128028 123.646442 12.410955 118.177867 17.7414982 119.468823 25.2683459"/>
                    <polygon id="Star-Copy-2"
                             stroke-width="2"
                             opacity="0.800000012"
                             stroke-linejoin="round"
                             transform="translate(15.709292, 73.964650) rotate(-60.000000) translate(-15.709292, -73.964650) "
                             points="15.7092921 79.7146505 8.94976174 83.2683459 10.2407172 75.7414982 4.77214221 70.410955 12.3295269 69.3128028 15.7092921 62.4646505 19.0890573 69.3128028 26.6464421 70.410955 21.1778671 75.7414982 22.4688225 83.2683459"/>
                    <polygon id="Star-Copy-3"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(215.294229, 90.879714) rotate(-160.000000) translate(-215.294229, -90.879714) "
                             points="215.294229 95.919714 210.004161 98.1608669 210.500904 92.4371596 206.73472 88.098561 212.331791 86.8022683 215.294229 81.879714 218.256666 86.8022683 223.853737 88.098561 220.087553 92.4371596 220.584296 98.1608669"/>
                    <polygon id="Star-Copy-5"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(194.089947, 45.373895) rotate(-130.000000) translate(-194.089947, -45.373895) "
                             points="194.089947 49.0138952 190.269343 50.6325056 190.628101 46.498717 187.908079 43.3652847 191.950408 42.4290733 194.089947 38.8738952 196.229485 42.4290733 200.271814 43.3652847 197.551792 46.498717 197.910551 50.6325056"/>
                    <polygon id="Star-Copy-6"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(55.089947, 31.373895) rotate(-100.000000) translate(-55.089947, -31.373895) "
                             points="55.0899467 35.0138952 51.2693426 36.6325056 51.628101 32.498717 48.9080794 29.3652847 52.9504084 28.4290733 55.0899467 24.8738952 57.229485 28.4290733 61.2718141 29.3652847 58.5517924 32.498717 58.9105509 36.6325056"/>
                    <polygon id="Star-Copy-7"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(15.089947, 186.373895) rotate(-40.000000) translate(-15.089947, -186.373895) "
                             points="15.0899467 190.013895 11.2693426 191.632506 11.628101 187.498717 8.90807937 184.365285 12.9504084 183.429073 15.0899467 179.873895 17.229485 183.429073 21.2718141 184.365285 18.5517924 187.498717 18.9105509 191.632506"/>
                    <polygon id="Star-Copy-8"
                             stroke-width="1.5"
                             opacity="0.300000012"
                             stroke-linejoin="round"
                             transform="translate(209.089947, 129.373895) rotate(-90.000000) translate(-209.089947, -129.373895) "
                             points="209.089947 133.013895 205.269343 134.632506 205.628101 130.498717 202.908079 127.365285 206.950408 126.429073 209.089947 122.873895 211.229485 126.429073 215.271814 127.365285 212.551792 130.498717 212.910551 134.632506"/>
                    <polygon id="Star-Copy-4"
                             stroke-width="2"
                             opacity="0.600000024"
                             stroke-linejoin="round"
                             transform="translate(22.294229, 130.879714) rotate(90.000000) translate(-22.294229, -130.879714) "
                             points="22.2942286 135.919714 17.0041614 138.160867 17.5009038 132.43716 13.73472 128.098561 19.331791 126.802268 22.2942286 121.879714 25.2566663 126.802268 30.8537373 128.098561 27.0875535 132.43716 27.5842959 138.160867"/>
                    <g id="Group-9"
                       opacity="0.800000012"
                       transform="translate(99.000000, 44.000000)"
                       stroke-linecap="round"
                       stroke-width="2">
                        <line id="Line-3"
                              x1="17.9828765"
                              y1="8.5"
                              x2="17.9828765"
                              y2="0.5"/>
                        <line id="Line-3"
                              x1="26.9828765"
                              y1="8.66666667"
                              x2="26.9828765"
                              y2="3.33333333"
                              transform="translate(26.982877, 6.000000) rotate(10.000000) translate(-26.982877, -6.000000) "/>
                        <line id="Line-3-Copy-2"
                              x1="34.9828765"
                              y1="8.83333333"
                              x2="34.9828765"
                              y2="6.16666667"
                              transform="translate(34.982877, 7.500000) rotate(20.000000) translate(-34.982877, -7.500000) "/>
                        <line id="Line-3-Copy"
                              x1="8.98287653"
                              y1="8.66666667"
                              x2="8.98287653"
                              y2="3.33333333"
                              transform="translate(8.982877, 6.000000) rotate(-10.000000) translate(-8.982877, -6.000000) "/>
                        <line id="Line-3-Copy-3"
                              x1="0.982876525"
                              y1="8.83333333"
                              x2="0.982876525"
                              y2="6.16666667"
                              transform="translate(0.982877, 7.500000) rotate(-20.000000) translate(-0.982877, -7.500000) "/>
                    </g>
                    <path id="Path" d="M57,185 L57,67 C57,62.581722 60.581722,59 65,59 L169,59 C173.418278,59 177,62.581722 177,67 L177,185" stroke-width="3" fill="#F8FCFF"/>
                    <line id="Line-3"
                          x1="117.467742"
                          y1="93.5"
                          x2="145.532258"
                          y2="93.5"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"/>
                    <path id="Path-3-Copy" d="M80.8538928,129.380251 C105.959676,146.713584 130.016946,146.713584 153.025703,129.380251" stroke-width="3" stroke-linecap="round"/>
                    <g id="Group-12" transform="translate(37.150000, 171.000000)" stroke-width="3">
                        <path id="Path" d="M122.395739,29.5 L17.5,29.5 C7.83501688,29.5 0,21.6649831 0,12 L0,7 C0,3.581722 3.581722,0 7,0 L101,0 C104.418278,0 108,3.581722 108,7 L108,14.4373259" fill="#DBE7EF"/>
                        <path id="Path" d="M108,13.5 C108,22.336556 114.6994,29.5 123.535956,29.5 C132.372512,29.5 139.863391,22.336556 139.863391,13.5" fill="#F8FCFF"/>
                    </g>
                    <polyline id="Path-4-Copy"
                              stroke-width="3"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              points="88.7412077 91.0570666 95.7067414 99.1882765 104.452403 87.1882765"/>
                </g>
            </g>
        </g>
    </svg>
</template>
