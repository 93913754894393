import axios from 'axios';
import { cmsHost } from '@/core/infrastructure/environment';
import { HttpServerError } from '@/core/http/HttpServerError';
import { HttpNoServerResponse } from '@/core/http/HttpNoServerResponse';
import { DefaultApiFactory } from '@/project/content/umbraco-api-fixed';
import { SitePageData, HeaderLinkSectionModel, NameAndUrl, SiteRootModel, SiteSettingFooterModel, SiteSettingServiceMenuItemModel } from '@/api/cms';
import HttpStatus from 'http-status-codes';

import { Ref, ref } from 'vue';
import { addMessage, SystemMessageSeverity } from '../../components/system-message/system-message.service';
import { Severity } from '@/api/cms/models/severity';

const contentApi = DefaultApiFactory({
    basePath: cmsHost,
    isJsonMime: () => true,
});

export const rootPage = getRootPage();
setPageUrls();

export async function getPage(path: string): Promise<SitePageData> {
    try {
        return await getLowLevelPage(path);
    } catch (error) {
        if (axios.isAxiosError(error)) {
            if (error.response) {
                if (error.response.status === HttpStatus.NOT_FOUND) {
                    const trackingObject = {
                        event: 'GAEvent',
                        eventCategory: 'Error Page',
                        eventAction: '404',
                        eventLabel: window.location.href,
                        eventValue: undefined,
                    };

                    window.dataLayer && window.dataLayer.push(trackingObject);

                    return error.response.data as SitePageData;
                }
                throw new HttpServerError(error.response.status);
            } else {
                throw new HttpNoServerResponse();
            }
        } else {
            throw error;
        }
    }
}

async function getLowLevelPage<T extends SitePageData = SitePageData>(path: string): Promise<T> {
    const response = await contentApi.verticaUmbracoSpaContentApi(path);
    return response.data as T;
}

async function getRootPage(): Promise<SiteRootModel> {
    // Fetch frontpage containing 404 link, footer etc.
    const page = await getLowLevelPage<SitePageData>('/');

    if (page.jsonContent?.alias !== 'siteRoot') {
        throw Error('Expected JsonContent to be of type Siteroot for the root page. Another type has probably been set in Umbraco');
    }

    const rootPageModel = page.jsonContent?.content as SiteRootModel;

    const mapSeverity = (severity: Severity): SystemMessageSeverity => {
        if (severity === Severity.Info) {
            return 'info';
        }
        if (severity === Severity.Success) {
            return 'success';
        }
        if (severity === Severity.Warning) {
            return 'warning';
        }
        if (severity === Severity.Error) {
            return 'error';
        }
        return 'error';
    };

    if (rootPageModel.notifications) {
        for (const notification of rootPageModel.notifications) {
            addMessage({
                severity: mapSeverity(notification.severity),
                title: notification.headline,
                description: notification.description,
                type: 'system',
            });
        }
    }

    return rootPageModel;
}

const frontPageUrl = ref('');
const notFoundUrl = ref('');
const basketUrl = ref('');
const checkoutUrl = ref('');
const offersUrl = ref('');
const confirmationUrl = ref('');
const errorUrl = ref('');
const myAccountUrl = ref('');

async function setPageUrls() {
    const theRootPage: SiteRootModel = await rootPage;
    frontPageUrl.value = '/';
    notFoundUrl.value = theRootPage.notFoundPage?.url;
    basketUrl.value = theRootPage.basketPage?.url;
    checkoutUrl.value = theRootPage.checkout.basePath;
    offersUrl.value = theRootPage.offersPage?.url;
    confirmationUrl.value = theRootPage.confirmationPage?.url;
    errorUrl.value = theRootPage.errorPage?.url;
    myAccountUrl.value = theRootPage.myAccountPage?.url;
}

export function getPageUrls(): {
    frontPageUrl: Ref<string>,
    notFoundUrl: Ref<string>,
    basketUrl: Ref<string>,
    checkoutUrl: Ref<string>,
    offersUrl: Ref<string>,
    confirmationUrl: Ref<string>,
    errorUrl: Ref<string>,
    myAccountUrl: Ref<string>,
    } {
    return {
        frontPageUrl,
        notFoundUrl,
        basketUrl,
        checkoutUrl,
        offersUrl,
        confirmationUrl,
        errorUrl,
        myAccountUrl,
    };
}

export async function getCheckoutSteps(): Promise<NameAndUrl[]> {
    const root = await rootPage;
    return root.checkout.steps;
}

export async function getLeftMainMenu(): Promise<NameAndUrl[]> {
    const root = await rootPage;
    return root.mainMenu;
}

export async function getRightMainMenu(): Promise<HeaderLinkSectionModel[]> {
    const root = await rootPage;
    return root.headerModel?.menuLinks ?? [];
}

export async function getServiceMenu(): Promise<SiteSettingServiceMenuItemModel[]> {
    const root = await rootPage;
    return root.serviceMenuModel?.links ?? [];
}

export async function getFooter(): Promise<SiteSettingFooterModel | undefined> {
    const root = await rootPage;
    return root.footerModel;
}

export default {
    getPage,
};
