<template>
    <div class="page-spacing">
        <HiddenSkipTo :id="SKIP_TO_ID" :label="$translate('Client.A11y.SkipToProductList')"/>
        <Breadcrumb :breadcrumbs="model.ancestors" :current-page-name="metadata.navigationTitle"/>
        <div class="flex space-x-20">
            <!-- Navigation and filters shown on large screens -->
            <div v-if="isLargeScreen" class="space-y-20 w-284">
                <div class="bg-white rounded-lg min-w-[284px] pb-25" :class="{'min-h-[300px]': loading}">
                    <SearchCategoryNavigation :is-offers-mode="model.showOnlyOffers" @update-category="updateCategoryName"/>
                </div>
                <div class="bg-white rounded-lg min-w-[284px]" :class="{'min-h-[300px]': loading}">
                    <ProductFilters v-if="facets.length > 0" :model="facets" :bonus="bonusFacet" @toggle-bonus="onlyBonusRequest = onlyBonusRequest === 'true' ? 'false' : 'true'"/>
                </div>
            </div>

            <div class="w-full">
                <div v-if="hero" class="overflow-hidden mb-20 bg-white rounded-lg">
                    <ResponsiveImage v-if="isMediumOrGreaterScreen"
                                     :image-url="hero.image.url"
                                     :aspect-ratio="6 / 1"/>
                    <ResponsiveImage v-else-if="hero.mobileImage && hero.mobileImage.url"
                                     :image-url="hero.mobileImage.url"
                                     :aspect-ratio="4 / 3"/>
                </div>

                <div class="xl:flex xl:justify-between xl:items-center mb-20 bg-white rounded-lg p-15">
                    <div class="flex flex-col xl:flex-row justify-center xl:items-baseline xl:justify-left">
                        <div v-if="isLargeScreen">
                            <h1 v-if="!model.showOnlyOffers || !categoryId" class="font-bold text-center xl:text-left text-28 lg:text-40 text-primary">
                                {{ metadata.navigationTitle }}
                            </h1>
                            <h1 v-else class="font-bold text-center xl:text-left text-28 lg:text-40 text-primary">
                                {{ $translate("Client.CategoryPage.OffersFor") }} {{ categoryDisplayName }}
                            </h1>
                        </div>

                        <span v-if="isLargeScreen" class="ml-20 text-18">
                            {{ totalHits }} {{ totalHits === 1 ? $translate('Client.CategoryPage.SingleProductFound') : $translate('Client.CategoryPage.ProductsFound') }}
                        </span>

                        <!-- Category navigation shown on small screens -->
                        <MobileCategoryNavigation v-if="!isLargeScreen" :is-offers-mode="model.showOnlyOffers" :model="model" :metadata="metadata"/>
                    </div>
                    <div>
                        <!-- Sorting dropdown shown on large screens -->
                        <Button v-if="isLargeScreen"
                                :label="`${sortByQuery || sortBy[0] ? $translate(`Client.CategoryPage.Sorting.${sortByQuery || sortBy[0]}`) : ''}`"
                                :primary="false"
                                @click="openSorting">
                            <template #right>
                                <CIcon name="arrow-down" width="12" height="12"/>
                            </template>
                        </Button>
                    </div>
                </div>

                <!-- Filter and sorting shown on small screens -->
                <div v-if="!isLargeScreen" class="flex relative flex-col sm:justify-center items-center mb-10 sm:mb-20 space-y-10">
                    <div class="flex justify-center space-x-10">
                        <Button :label="`${$translate('Client.ProductFilters.Title')} ${numberOfAppliedFacets > 0 ? `(${numberOfAppliedFacets})`: ''}`"
                                :primary="false"
                                :list="true"
                                @click="openFilters">
                            <CIcon name="filter" width="16" height="16"/>
                        </Button>
                        <Button :label="`${sortByQuery || sortBy[0] ? $translate(`Client.CategoryPage.Sorting.${sortByQuery || sortBy[0]}`) : $translate(`Client.CategoryPage.Sorting.MostPopular`)}`"
                                :primary="false"
                                :list="true"
                                @click="openSorting">
                            <template #right>
                                <CIcon name="arrow-down" width="12" height="12"/>
                            </template>
                        </Button>
                    </div>

                    <!-- Number of products found -->
                    <span class="sm:absolute sm:left-0 text-18">
                        {{ totalHits }} {{ totalHits === 1 ? $translate('Client.CategoryPage.SingleProductFound') : $translate('Client.CategoryPage.ProductsFound') }}
                    </span>
                </div>

                <!-- Grid of products -->
                <div v-if="loading" class="grid grid-cols-8 md:grid-cols-12 grid-flow-row auto-rows-max gap-10 px-10 sm:px-0 rounded-lg lg:grid-cols-25">
                    <div v-for="(_, index) in Array.from({length: 15})"
                         :key="index"
                         class="col-span-4 md:col-span-3 lg:col-span-5 bg-white rounded-lg animate-pulse h-[380px]"></div>
                </div>
                <div v-else-if="!loading && results.length > 0" :id="SKIP_TO_ID" ref="tileWrapper" class="grid grid-cols-8 md:grid-cols-12 grid-flow-row auto-rows-max gap-10 px-10 sm:px-0 rounded-lg lg:grid-cols-25">
                    <ProductTile v-for="(product, index) in results"
                                 :key="`${product.sku}-${product.availableInStock}`"
                                 :model="product"
                                 :tracking-context="{index: index + 1, impressionList: {listType: 'PLP', choosingMechanism: 'PLP'}}"
                                 class="col-span-4 md:col-span-3 lg:col-span-5"/>
                </div>
                <div v-else class="flex justify-center w-full">
                    {{ $translate('Client.CategoryPage.NoProductsFound') }}
                </div>

                <ShowMoreButton :max-page-size="MAX_PAGE_SIZE"
                                :page-size="DEFAULT_PAGE_SIZE"
                                :number-of-results="results.length"
                                :total="totalHits"
                                :class="{'hidden': results.length === 0}"
                                @click="rememberPositionBottom"/>
            </div>
        </div>
        <RenderBlocks v-if="model.bodyContent" :model="model.bodyContent"/>
    </div>
</template>

<script setup lang="ts">
import { CategoryPageModel, IMetadata } from '@/api/cms';
import { watch, ref, onUnmounted, computed } from 'vue';
import ProductFilters from '@/project/products/product-filters/ProductFilters.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import Button from '@/project/components/button/Button.vue';
import HiddenSkipTo from '@/project/components/hidden-skip-to/HiddenSkipTo.vue';
import openDialog from '@/project/dialog/dialog';
import ProductFiltersDialog from '@/project/products/product-filters/ProductFiltersDialog.vue';
import { useSearch } from '@/project/apis/commerce/searchApi';
import { FacetOption, FacetValueViewModel, FacetViewModel, ProductTileViewModel } from '@/api/commerce';
import ProductTile from '@/project/products/product-tile/ProductTile.vue';
import { useFacets, useRouteQuery } from '@/core/browser/query/useQueryParams';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import { QueryKeys } from '@/project/browser/query';
import CIcon from '@/core/layout/svgicon/CIcon.vue';
import SearchCategoryNavigation from './SearchCategoryNavigation.vue';
import MobileCategoryNavigation from './ProductListMobileNavigation.vue';
import ShowMoreButton from '@/project/search/show-more-button/ShowMoreButton.vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import { CategoryTreeViewModel } from '@/api/commerce/models/category-tree-view-model';
import { useCategories } from '@/project/pages/category-page/search-category-composable';
import SortingDrawer from '@/project/pages/category-page/SortingDrawer.vue';
import RenderBlocks from '../content-page/RenderBlocks.vue';
import { useScrollAfterLoad } from '@/project/utils/scroll-after-load.composable';
import { trackPage, trackProductImpressions } from '@/project/tracking/tracking.service';
import router, { PRODUCT_INFO_SPLITTER } from '@/router';
import axios from 'axios';
import { isAuthenticated } from '@/project/authentication/authentication';

const props = defineProps<{
    model: CategoryPageModel,
    metadata: IMetadata,
}>();

const SKIP_TO_ID = 'product-list';

const { setCategories, setOfferMode } = useCategories();
setOfferMode(props.model.showOnlyOffers);

trackPage(props.model.showOnlyOffers ? 'OffersPage' : 'ProductListPage');

const breakpoints = useBreakpoints();

const isLargeScreen = breakpoints.greater('xl');
const isMediumOrGreaterScreen = breakpoints.greater('md');

const loading = ref(false);
const results = ref<ProductTileViewModel[]>([]);
const facets = ref<FacetViewModel[]>([]);
const sortBy = ref<string[]>([]);
const totalHits = ref(0);
const hero = props.model.heroBanner && props.model.heroBanner.length > 0 ? props.model.heroBanner[0].content : null;
const {
    facets: facetsFromUrl,
} = useFacets();

const DEFAULT_PAGE_SIZE = 30;
const MAX_PAGE_SIZE = 300;
const numberOfAppliedFacets = computed(() => Object.values(facetsFromUrl.value).flat().length);
const numberOfResults = computed(() => totalHits.value);

const sortByQuery = useRouteQuery(QueryKeys.SORT_BY);
const termQuery = useRouteQuery(QueryKeys.TERM);
const pageSize = useRouteQuery(QueryKeys.PAGE_SIZE);
const categoryId = useRouteQuery(QueryKeys.CATEGORY);
const categoryDisplayName = ref('');
const bonusFacet = ref<FacetValueViewModel>();
const onlyBonusRequest = useRouteQuery(QueryKeys.BONUS);

const { element: tileWrapper, rememberPositionBottom, scrollToPreviousBottom } = useScrollAfterLoad();

const updateCategoryName = (category: CategoryTreeViewModel) => {
    categoryDisplayName.value = category.displayName;
};

const openFilters = () => {
    openDialog(ProductFiltersDialog, { model: facets, bonus: bonusFacet, numberOfResults });
};

const openSorting = () => {
    openDialog(SortingDrawer, { items: sortBy.value, label: 'Client.CategoryPage.Sorting', onChange: onSelectSortBy, selected: sortByQuery.value || sortBy.value[0] });
};

const { search, searchResult } = useSearch();

let previousLength = 0;
let prevNumberOfAppliedFacets = 0;
let prevSort = '';
watch(searchResult, (res) => {
    loading.value = !res.dataReady || axios.isCancel(res.error); // If a previous request was cancelled we assume a new one is incoming

    if (res.dataReady) {
        results.value = res.data.results;
        facets.value = res.data.facets;
        sortBy.value = res.data.sortBy;
        totalHits.value = res.data.totalHits;
        bonusFacet.value = res.data.bonus;
        setCategories(res.data.categories);

        const facetsHaveChanged = prevNumberOfAppliedFacets !== numberOfAppliedFacets.value;
        const sortHasChanged = prevSort !== sortByQuery.value;

        // watch is triggered more than once on the same results, so only track if the length has changed
        if (res.data.results.length !== previousLength || facetsHaveChanged || sortHasChanged) {
            const pageSizeNumber = Number(pageSize.value);
            // If facets changed the page size should go back to 30.
            const _pageSize = pageSizeNumber > 0 && !facetsHaveChanged ? pageSizeNumber : DEFAULT_PAGE_SIZE;

            trackProductImpressions(res.data.results.slice(_pageSize - DEFAULT_PAGE_SIZE, _pageSize), { listType: 'PLP', choosingMechanism: 'PLP' }, _pageSize - DEFAULT_PAGE_SIZE);
            previousLength = res.data.results.length;
            prevNumberOfAppliedFacets = numberOfAppliedFacets.value;
            prevSort = sortByQuery.value;
        }

        scrollToPreviousBottom();
    }
});

function requestifyFacets(facetsFromUrl: Record<string, string | string[]>): Array<FacetOption> {
    const rawArrays = Object.entries(facetsFromUrl).reduce((previousVal: Array<FacetOption>, [key, value]) => {
        const arrayedValue = Array.isArray(value) ? value : [value];
        return previousVal.concat({
            key,
            values: arrayedValue,
        });
    }, []);
    return rawArrays;
}

watch([facetsFromUrl, sortByQuery, pageSize, termQuery, categoryId, onlyBonusRequest, isAuthenticated], ([facetValue, sortByValue, pageSizeValue, termValue, categoryIdValue, onlyBonusValue]) => {
    let _pageSize = DEFAULT_PAGE_SIZE;
    // If a facet is changed, go back to page size 30 - no need to fetch more
    if (prevNumberOfAppliedFacets === numberOfAppliedFacets.value) {
        _pageSize = Number(pageSizeValue);
    } else {
        pageSize.value = DEFAULT_PAGE_SIZE.toString();
    }

    if (_pageSize > MAX_PAGE_SIZE) {
        pageSize.value = MAX_PAGE_SIZE.toString();
    }

    search({
        facetOptions: requestifyFacets(facetValue),
        offset: 0, // Viewing more results than the first 30 seems so unlikely that we haven't implemented offset, but it's a potential improvement.
        size: _pageSize <= MAX_PAGE_SIZE ? _pageSize : MAX_PAGE_SIZE,
        onlyOffers: props.model.showOnlyOffers,
        onlyBonus: onlyBonusValue === 'true',
        categories: categoryIdValue ? [categoryIdValue] : props.model.plytixCategoryId ? [props.model.plytixCategoryId] : null,
        sortBy: sortByValue,
        term: termValue,
        includeFacets: true,
        includeCategories: true,
        hideOutOfStock: false,
    });
}, { immediate: true });

const onSelectSortBy = (sortBy: string) => {
    sortByQuery.value = sortBy;
};

onUnmounted(() => {
    if (!router.currentRoute.value.path.includes(PRODUCT_INFO_SPLITTER)) {
        setCategories([]);
        setOfferMode(false);
    }
});
</script>
