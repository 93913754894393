import { OrderApiFactory, TopProductsRequest, TopProductsResultViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';
import { AnnotatedResult, useStaleWhileRevalidate } from '@/core/cache/useStaleWhileRevalidate';
import { Ref } from 'vue';
import axios, { CancelTokenSource } from 'axios';

const orderApi = OrderApiFactory({ isJsonMime: () => true }, commerceApiHost);

const {
    makeReactiveResult,
    fetchResult,
} = useStaleWhileRevalidate<TopProductsResultViewModel>(() => ({
    results: [],
    facets: [],
    totalHits: 0,
    categories: [],
}));

const cancelTokenSrc: Record<string, CancelTokenSource> = {};

export function useTopSearch(searchRequest?: TopProductsRequest): {
    searchResult: Ref<AnnotatedResult<TopProductsResultViewModel>>,
    search: typeof search
} {
    const searchResult = makeReactiveResult();

    function search(searchRequest: TopProductsRequest, cancelKey?: string) {
        // Fetch all if no specific
        if (cancelKey) {
            // Cancel previous and create new for this cancel-key
            if (cancelTokenSrc[cancelKey]) {
                cancelTokenSrc[cancelKey].cancel();
            }
            cancelTokenSrc[cancelKey] = axios.CancelToken.source();
        }

        const searchResultProvider = () => orderApi.apiAccountOrderTopPost(undefined, searchRequest, {
            cancelToken: cancelKey && cancelTokenSrc[cancelKey]?.token,
        })
            .then(searchResponse => searchResponse.data.model)
            .catch(error => {
                return error;
            });

        fetchResult(searchResult, searchResultProvider, JSON.stringify(searchRequest));
    }

    if (searchRequest) {
        search(searchRequest);
    }

    return {
        search,
        searchResult,
    };
}

export async function removeFromTop100(sku: string): Promise<void> {
    await orderApi.apiAccountOrderTopSkuDelete(sku);
}
