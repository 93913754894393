<template>
    <div class="overflow-hidden relative bg-white rounded-lg text-40" :class="{'square-image' : !wide}">
        <RouterLink :to="model.url">
            <ResponsiveImage
                v-if="!wide"
                :image-url="model.squareImage.url"
                :aspect-ratio="1/1"
                :width-on-screen="{
                    xs: 50,
                    sm: 25,
                    md: 20
                }"
                mode="crop"/>
            <ResponsiveImage
                v-if="wide"
                :image-url="model.rectangleImage.url"
                :aspect-ratio="16/8"
                :width-on-screen="{
                    xs: 100,
                    md: 40
                }"
                mode="crop"/>
            <div :class="`${textPosition}`" class="absolute p-30">
                <span class="font-bold text-14" :class="labelColor">{{ model.label }}</span>
                <h3 class="font-bold"
                    :class="[textStyle, textColor]">
                    {{ model.name }}
                </h3>
            </div>
        </RouterLink>
    </div>
</template>

<script lang="ts">
import { CuratedCategoryLink } from '@/api/cms';
import { computed, defineComponent, PropType } from 'vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
export default defineComponent({
    name: 'CuratedTile',
    components: {
        ResponsiveImage,
    },
    props: {
        model: {
            type: Object as PropType<CuratedCategoryLink>,
            required: true,
        },
        wide: {
            type: Boolean,
            required: false,
        },
    },

    setup(props) {
        const textStyle = props.wide ? 'text-28 leading-10' : 'text-20 leading-10';

        const textPosition = computed(() => {
            switch (props.model.textPosition) {
            case 'Top':
                return 'top-0';
            case 'Middle':
                return 'inset-y-0 flex justify-center flex-col';
            case 'Bottom':
                return 'bottom-0';
            default:
                return '';
            }
        });

        const textColor = computed(() => {
            switch (props.model.textColor) {
            case 'Black':
                return 'text-black';
            case 'White':
                return 'text-white';
            case 'Blue':
                return 'text-blue';
            default:
                return '';
            }
        });

        const labelColor = computed(() => {
            switch (props.model.labelColor) {
            case 'Black':
                return 'text-black';
            case 'White':
                return 'text-white';
            case 'Blue':
                return 'text-blue';
            default:
                return '';
            }
        });

        return { textPosition, textStyle, textColor, labelColor };
    },
});

</script>

<style scoped>

 @media(min-width: 768px)
 {
     .square-image:deep() .responsive-image{
        height: 100% !important;
     }
 }

</style>
