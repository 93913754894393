<template>
    <div class="flex justify-center items-center font-bold rounded-full bg-splash" :class="sizes">
        <div v-if="isXForY" :class="xForYWrapper">
            <div v-for="(v, index) in label.split(' ')" :key="v" class="text-center" :class="index === 1 ? xForYFyrirSize : xForYNumberSize">
                {{ v }}
            </div>
        </div>
        <span v-else class="text-center">{{ label }}</span>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const props = defineProps<{
    label: string
    size: 'xs' | 'sm' | 'md' | 'lg'
}>();

const isXForY = ref(props.label.includes('fyrir'));
const xForYFyrirSize = ref('');
const xForYNumberSize = ref('');
const xForYWrapper = ref('');

let sizes: Record<string, boolean>;
if (isXForY.value) {
    if (props.size === 'md') {
        sizes = {
            'w-48 xl:w-60 h-48 xl:h-60': true,
        };
        xForYFyrirSize.value = 'text-[8px] xl:text-[12px]';
        xForYNumberSize.value = 'text-[12px] xl:text-[18px]';
        xForYWrapper.value = 'flex items-center space-x-[0.2rem]';
    } else if (props.size === 'xs') {
        sizes = {
            'w-30 h-30': true,
        };
        xForYFyrirSize.value = 'text-[6px] -mt-[0.3rem] -mb-[0.25rem]';
        xForYNumberSize.value = 'text-[10px]';
    } else if (props.size === 'sm') {
        sizes = {
            'w-40 h-40': true,
        };
        xForYFyrirSize.value = 'text-[8px] -mt-[0.2rem] -mb-[0.3rem]';
        xForYNumberSize.value = 'text-[12px]';
    } else if (props.size === 'lg') {
        sizes = {
            'w-80 h-80 lg:w-100 lg:h-100': true,
        };
        xForYFyrirSize.value = 'text-[16px] xl:text-[18px]';
        xForYNumberSize.value = 'text-[24px] xl:text-[28px]';
        xForYWrapper.value = 'flex items-center space-x-[0.3rem] xl:space-x-[0.5rem]';
    }
} else {
    if (props.size === 'md') {
        sizes = {
            'w-48 xl:w-60 h-48 xl:h-60': true,
            'text-11 xl:text-13': props.label.length > 7,
            'text-12 xl:text-14': props.label.length > 3 && props.label.length <= 7,
            'text-16 xl:text-20': props.label.length <= 3,
        };
    } else if (props.size === 'xs') {
        sizes = {
            'w-30 h-30 text-[10px] leading-[13px]': true,
        };
    } else if (props.size === 'sm') {
        sizes = {
            'w-40 h-40 text-[14px] leading-[13px]': true,
        };
    } else if (props.size === 'lg') {
        sizes = {
            'w-80 h-80 lg:w-100 lg:h-100': true,
            'text-16 lg:text-20': props.label.length > 7,
            'text-20 lg:text-24': props.label.length > 3 && props.label.length <= 7,
            'text-24 lg:text-32': props.label.length <= 3,
        };
    }
}

</script>
