/* tslint:disable */
/* eslint-disable */
/**
 * Vertica.Samkaup.Web.CMS
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * ContactFormApi - axios parameter creator
 * @export
 */
export const ContactFormApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} message 
         * @param {string} gRecaptchaResponse 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactformPost: async (email: string, message: string, gRecaptchaResponse: string, name?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('apiContactformPost', 'email', email)
            // verify required parameter 'message' is not null or undefined
            assertParamExists('apiContactformPost', 'message', message)
            // verify required parameter 'gRecaptchaResponse' is not null or undefined
            assertParamExists('apiContactformPost', 'gRecaptchaResponse', gRecaptchaResponse)
            const localVarPath = `/api/contactform`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
            if (message !== undefined) { 
                localVarFormParams.append('message', message as any);
            }
    
            if (gRecaptchaResponse !== undefined) { 
                localVarFormParams.append('g-recaptcha-response', gRecaptchaResponse as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormApi - functional programming interface
 * @export
 */
export const ContactFormApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} message 
         * @param {string} gRecaptchaResponse 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiContactformPost(email: string, message: string, gRecaptchaResponse: string, name?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiContactformPost(email, message, gRecaptchaResponse, name, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormApi - factory interface
 * @export
 */
export const ContactFormApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {string} message 
         * @param {string} gRecaptchaResponse 
         * @param {string} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContactformPost(email: string, message: string, gRecaptchaResponse: string, name?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiContactformPost(email, message, gRecaptchaResponse, name, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormApi - interface
 * @export
 * @interface ContactFormApi
 */
export interface ContactFormApiInterface {
    /**
     * 
     * @param {string} email 
     * @param {string} message 
     * @param {string} gRecaptchaResponse 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormApiInterface
     */
    apiContactformPost(email: string, message: string, gRecaptchaResponse: string, name?: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * ContactFormApi - object-oriented interface
 * @export
 * @class ContactFormApi
 * @extends {BaseAPI}
 */
export class ContactFormApi extends BaseAPI implements ContactFormApiInterface {
    /**
     * 
     * @param {string} email 
     * @param {string} message 
     * @param {string} gRecaptchaResponse 
     * @param {string} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormApi
     */
    public apiContactformPost(email: string, message: string, gRecaptchaResponse: string, name?: string, options?: AxiosRequestConfig) {
        return ContactFormApiFp(this.configuration).apiContactformPost(email, message, gRecaptchaResponse, name, options).then((request) => request(this.axios, this.basePath));
    }
}
