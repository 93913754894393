<template>
    <div v-if="disabled">
        <slot></slot>
    </div>
    <slot v-else-if="!url"></slot>
    <a v-else-if="isExternal"
       :href="url"
       :title="title"
       :target="target">
        <slot></slot>
    </a>
    <RouterLink v-else
                :to="url"
                :title="title"
                :target="target">
        <slot></slot>
    </RouterLink>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
    url: string | null | undefined
    title?: string
    target?: string
    disabled?: boolean
}>();

const isExternal = computed(() => {
    try {
        return props.url && new URL(props.url).origin !== location.origin;
    } catch {
        return false;
    }
});
</script>
