import { StoresApiFactory, StoreSearchViewModel } from '@/api/commerce';
import { commerceApiHost } from '@/core/infrastructure/environment';

const storesApi = StoresApiFactory({ isJsonMime: () => true }, commerceApiHost);

export type SamkaupStores = Record<'netto' | 'other', StoreSearchViewModel>;

export async function getStores(zipCode: number | undefined, nettoLimit: number, otherLimit: number): Promise<SamkaupStores> {
    const responses = await Promise.all([storesApi.apiStoresNettoGet(zipCode, nettoLimit), storesApi.apiStoresOtherGet(zipCode, otherLimit)]);
    const response: SamkaupStores = {
        netto: responses[0].data.model,
        other: responses[1].data.model,
    };

    return response;
}

export async function getAllNettoStores(zipCode: number | undefined): Promise<Record<'netto', StoreSearchViewModel>> {
    const nettoResponse = await storesApi.apiStoresNettoGet(zipCode, undefined);
    const response = {
        netto: nettoResponse.data.model,
    };

    return response;
}

export async function getAllOtherStores(zipCode: number | undefined): Promise<Record<'other', StoreSearchViewModel>> {
    const otherResponse = await storesApi.apiStoresOtherGet(zipCode, undefined);
    const response = {
        other: otherResponse.data.model,
    };

    return response;
}
