import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { addMessage } from '@/project/components/system-message/system-message.service';
import { silentSignIn, logout } from '../../authentication/authentication';
import { ReserveTimeSlotErrorViewModelClientResponse } from '@/api/commerce';
import dictionary from '@/core/dictionary/dictionary';

let silentSignInAttempt: Promise<void> | null = null;

export function handleError(error: unknown): void {
    if (axios.isAxiosError(error)) {
        if (error.response?.status) {
            switch (error.response.status) {
            case HttpStatus.UNAUTHORIZED:
                // User was logged in, but access_token is no longer valid
                // Only keep one sign-in attempt at a time, otherwise the user might be logged out before the first attempt finishes
                if (silentSignInAttempt === null) {
                    silentSignInAttempt = silentSignIn();
                }

                silentSignInAttempt
                    .catch(() => {
                        logout();
                    }).finally(() => {
                        silentSignInAttempt = null;
                    });
                break;
            case HttpStatus.INTERNAL_SERVER_ERROR: {
                const data = error.response.data as ReserveTimeSlotErrorViewModelClientResponse;
                if (data.validationMessages[0].message !== 'No timeslot can be reserved') {
                    addMessage({
                        title: dictionary.get('Client.GeneralErrorMessages.500Title'),
                        description: dictionary.get('Client.GeneralErrorMessages.500Description'),
                        severity: 'error',
                        type: 'system',
                    });
                }
                break;
            }
            }
        }
    } else {
        throw error;
    }
}
