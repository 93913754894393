<template>
    <TransitionRoot appear
                    :show="isOpen"
                    as="template">
        <Dialog :open="isOpen"
                :initial-focus="initialFocusElement"
                class="overflow-y-auto fixed z-dialog"
                :class="{'right-0': _position === 'right',
                         'left-0': _position === 'left',
                         'bottom-0': _position === 'bottom',
                         'top-0': _position === 'top',
                         'inset-y-0': !isTopOrBottomPosition,
                         'inset-x-0': isTopOrBottomPosition}"
                @close="closeModal">
            <div class="flex overflow-x-hidden overflow-y-auto h-screen"
                 :class="{'flex-col h-[calc(100vh-3rem)]': isTopOrBottomPosition,}">
                <TransitionChild v-if="showBackdrop"
                                 as="template"
                                 enter="duration-200 ease-out"
                                 enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="duration-200 ease-in"
                                 leave-from="opacity-100"
                                 leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-y-0 left-0 w-screen bg-black bg-opacity-60"/>
                </TransitionChild>

                <TransitionChild as="template"
                                 enter="duration-200 ease-out transform"
                                 leave="duration-200 ease-in transform"
                                 v-bind="drawerAnimation"
                                 @after-enter="onDialogOpen">
                    <div class="flex flex-col"
                         :class="drawerPositionStyling">
                        <DialogTitle v-if="title"
                                     class="flex sticky top-0 justify-between items-center px-20 font-bold bg-white z-dialog py-15 text-18"
                                     :class="titleClasses"
                                     as="h4">
                            <div class="flex items-center">
                                <Button v-if="showBackButton" transparent class="mr-10" @click="emit('back')">
                                    <CIcon name="arrow-left" width="12" height="12" class="text-black"/>
                                </Button>
                                {{ title }}
                            </div>
                            <Button transparent @click="closeModal">
                                <CIcon name="close" width="12" height="12" class="text-black"/>
                            </Button>
                        </DialogTitle>
                        <DialogDescription v-if="description">
                            {{ description }}
                        </DialogDescription>
                        <div class="overflow-x-hidden overflow-y-auto flex-grow bg-white">
                            <slot :close="closeModal"/>
                        </div>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Dialog, DialogDescription, DialogOverlay, DialogTitle, TransitionRoot, TransitionChild } from '@headlessui/vue';
import bus from '@/core/bus';
import { DialogClosedKey } from '@/project/dialog/dialog';
import Button from '@/project/components/button/Button.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';

interface Props {
    title?: string,
    position?: 'top' | 'right' | 'bottom' | 'left',
    useSlideAnimation?: boolean,
    showBackdrop?: boolean,
    description?: string,
    initialFocusElement?: HTMLElement | undefined,
    titleClasses?: string,
    drawerClasses?: string | null,
    showBackButton?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
    title: '',
    position: undefined,
    description: '',
    initialFocusElement: undefined,
    titleClasses: '',
    drawerClasses: null,
    showBackdrop: true,
});

const emit = defineEmits(['visible', 'back']);

const isOpen = ref(true);

const { smaller } = useBreakpoints();
const isMobile = smaller('sm');

const _position = props.position ? props.position : isMobile.value ? 'bottom' : 'right';

function getDrawerStyling(): string {
    switch (_position) {
    case 'top':
        return `bg-white z-1 w-full ${props.drawerClasses}`;
    case 'right':
        return `mr-0 ml-auto z-1 ${props.drawerClasses}`;
    case 'bottom':
        return `mt-auto mb-0 bg-white z-1 w-full ${props.drawerClasses}`;
    case 'left':
        return `mr-auto ml-0 bg-white z-1 w-full ${props.drawerClasses}`;
    default:
        return '';
    }
}

const drawerPositionStyling: string = getDrawerStyling();
const isTopOrBottomPosition: boolean = _position === 'top' || _position === 'bottom';

function getDrawerAnimation(): Record<string, string> {
    if (props.useSlideAnimation) {
        switch (_position) {
        case 'top':
            return {
                enterFrom: '-translate-y-full',
                enterTo: 'translate-y-0',
                leaveFrom: 'translate-y-0',
                leaveTo: '-translate-y-full',
            };
        case 'right':
            return {
                enterFrom: 'translate-x-full absolute',
                enterTo: 'translate-x-0',
                leaveFrom: 'translate-x-0',
                leaveTo: 'translate-x-full',
            };
        case 'bottom':
            return {
                enterFrom: 'translate-y-full absolute',
                enterTo: 'translate-y-0',
                leaveFrom: 'translate-y-0',
                leaveTo: 'translate-y-full',
            };
        case 'left':
            return {
                enterFrom: '-translate-x-full',
                enterTo: 'translate-x-0',
                leaveFrom: 'translate-x-0',
                leaveTo: '-translate-x-full',
            };
        }
    }
    return {
        enterFrom: 'opacity-0',
        enterTo: 'opacity-100',
        leaveFrom: 'opacity-100',
        leaveTo: 'opacity-0',
    };
}

const drawerAnimation = getDrawerAnimation();

function closeModal() {
    isOpen.value = false;
    bus.emit(DialogClosedKey);
}

function onDialogOpen(): void {
    emit('visible');
}
</script>
