import { ProductDetailsViewModel } from '@/api/commerce';
import { Ref, ref } from 'vue';

export function createRenderData(): Ref<ProductDetailsViewModel> {
    return ref<ProductDetailsViewModel>({
        sku: '',
        displayName: '',
        categories: [],
        brand: '',
        weight: '',
        country: '',
        productTeaser: '',
        salesPrice: 0,
        pricePerUnit: 0,
        unitOfMeasure: '',
        icons: [],
        hazardLabels: [],
        imageUrls: [],
        ingredients: '',
        nutrition: {
            calories: '',
            carbohydrates: '',
            energy: '',
            fat: '',
            fibers: '',
            nutritionContentPer: '',
            protein: '',
            salt: '',
            saturatedFat: '',
            sodium: '',
            sugar: '',
        },
        productInformation: '',
        offer: undefined,
        breadcrumbs: [],
        url: '',
        relatedProducts: [],
        alwaysInStock: false,
        availableInStock: 0,
        notInAssortment: false,
    });
}
