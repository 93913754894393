<template>
    <div class="checkout-page-spacing">
        <!-- <pre>{{JSON.stringify(deliveryState, null, 2)}}</pre> -->
        <div class="lg:mb-35 mb-10" :class="{'lg:mt-15': !isShowingSystemMessages}">
            <Stepper :current="1"/>
        </div>
        <div v-if="isShowingSystemMessages">
            <SystemMessage type="delivery"/>
        </div>
        <div class="grid grid-cols-12 lg:space-x-20">
            <div class="lg:pt-15 col-span-12 rounded-lg bg-white px-0 pb-20 sm:px-40 lg:col-span-7 xl:col-span-8">
                <div class="py-15 bg-white">
                    <h1 class="text-primary text-28 sm:text-40 mx-20 font-bold sm:mx-0">
                        2. {{ model.headline }}
                    </h1>
                </div>
                <DeliveryTypeChooserUndoable v-if="deliveryState.pageState === DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot"/>
                <PickupDetails v-else-if="deliveryState.pageState === DeliveryPageStates.PickupSelected" :terms-and-conditions-text="model.termsAndConditions"/>
                <HomeDeliveryDetails v-else-if="deliveryState.pageState === DeliveryPageStates.HomeDeliverySelected" :terms-and-conditions-text="model.termsAndConditions"/>
                <AddToExistingOrder v-else-if="deliveryState.pageState === DeliveryPageStates.ChooseExistingOrder" :terms-and-conditions-text="model.termsAndConditions"/>
                <ExistingPickupDetails v-else-if="deliveryState.pageState === DeliveryPageStates.ExistingPickupSelected && deliveryState.existingOrder" :terms-and-conditions-text="model.termsAndConditions"/>
                <ExistingHomeDeliveryDetails v-else-if="deliveryState.pageState === DeliveryPageStates.ExistingHomeDeliverySelected && deliveryState.existingOrder" :terms-and-conditions-text="model.termsAndConditions"/>
            </div>
            <div class="col-span-12 mt-10 lg:col-span-5 lg:mt-0 xl:col-span-4">
                <BasketSummary
                    v-if="basket"
                    :basket="basket"
                    :bonus="bonus"
                    :checkout-mode="true"
                    :hide-fees="deliveryState.pageState === DeliveryPageStates.ExistingPickupSelected || deliveryState.pageState === DeliveryPageStates.ExistingHomeDeliverySelected"/>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { DeliveryPageModel } from '@/api/cms';
import { getCheckoutBasket, prepareBasket } from '@/project/apis/commerce/checkoutApi';
import Stepper from '@/project/pages/checkout-page/Stepper.vue';
import BasketSummary from '@/project/basket/basket-summary/BasketSummary.vue';
import { useDeliveryState, DeliveryPageStates } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';
import PickupDetails from '@/project/pages/checkout-page/delivery/PickupDetails.vue';
import HomeDeliveryDetails from '@/project/pages/checkout-page/delivery/HomeDeliveryDetails.vue';
import { isAuthenticated } from '@/project/authentication/authentication';
import { BasketValidityViewModel, StoreViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import AddToExistingOrder from '@/project/pages/checkout-page/delivery/AddToExistingOrder.vue';
import ExistingPickupDetails from '@/project/pages/checkout-page/delivery/ExistingPickupDetails.vue';
import ExistingHomeDeliveryDetails from '@/project/pages/checkout-page/delivery/ExistingHomeDeliveryDetails.vue';
import DeliveryTypeChooserUndoable from '@/project/pages/checkout-page/delivery/DeliveryTypeChooserUndoable.vue';
import { useRouter } from 'vue-router';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { getOrder } from '../../../apis/commerce/orderApi';
import { isShowingSystemMessages } from '@/project/components/system-message/system-message.service';
import SystemMessage from '@/project/components/system-message/SystemMessage.vue';
import { trackCheckoutStep, trackPage } from '@/project/tracking/tracking.service';

defineProps<{
    model: DeliveryPageModel
}>();

trackPage();

const { deliveryState } = useDeliveryState();

const { basket, bonus } = getCheckoutBasket();

if (isAuthenticated.value) {
    trackCheckoutStep(3, basket.value?.lineItems);
    // This case handles page refresh on /checkout/delivery
    if (!basket.value) {
        prepareBasket().then(basketRes => {
        // If there is already a time slot on the basket, when hitting the page, render corresponing details step.

            // Restore the rest of state when reloading the page
            deliveryState.store = basketRes?.store ?? null;
            deliveryState.timeSlotId = basketRes?.timeSlot?.id ?? null;
            deliveryState.zipCode = basketRes?.selectedZipCode?.zipCode ?? null;
            deliveryState.type = basketRes?.timeSlot?.timeSlotType ?? null;

            if (basketRes.timeSlot) {
                if (basketRes.status === BasketValidityViewModel.StoreAddressDoesNotMatchSelectedTimeSlot || basketRes.status === BasketValidityViewModel.DeliveryAddressDoesNotMatchSelectedTimeSlot) {
                    deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
                    deliveryState.timeSlotId = null;
                } else if (basketRes.timeSlot.timeSlotType === TimeSlotTypeViewModel.HomeDelivery) {
                    deliveryState.pageState = basketRes.timeSlot.existingOrderId ? DeliveryPageStates.ExistingHomeDeliverySelected : DeliveryPageStates.HomeDeliverySelected;
                } else if (basketRes.timeSlot.timeSlotType === TimeSlotTypeViewModel.PickUp) {
                    deliveryState.pageState = basketRes.timeSlot.existingOrderId ? DeliveryPageStates.ExistingPickupSelected : DeliveryPageStates.PickupSelected;
                }

                if (basketRes.timeSlot.existingOrderId && deliveryState.existingOrder?.orderId !== basketRes.timeSlot.existingOrderId) {
                    getOrder(basketRes.timeSlot.existingOrderId).then(res => {
                        deliveryState.existingOrder = res;
                    });
                }
            } else if (basketRes.openOrderIds.length > 0) {
                deliveryState.pageState = DeliveryPageStates.ChooseExistingOrder;
            } else {
                deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
            }
        });
        // This case handles page refresh on /checkout/customer-information and subsequent navigation to /checkout/delivery
    } else if (basket.value?.timeSlot) {
        // The state can become invalid even without refresh (perhaps only in development)
        // Re-setting state just to be sure
        deliveryState.store = basket.value.store as StoreViewModel ?? null;
        deliveryState.timeSlotId = basket.value.timeSlot?.id ?? null;
        deliveryState.zipCode = basket.value.selectedZipCode?.zipCode ?? null;
        deliveryState.type = basket.value.timeSlot?.timeSlotType ?? null;

        if (basket.value.status === BasketValidityViewModel.StoreAddressDoesNotMatchSelectedTimeSlot || basket.value.status === BasketValidityViewModel.DeliveryAddressDoesNotMatchSelectedTimeSlot) {
            deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
            deliveryState.timeSlotId = null;
        } else if (basket.value.timeSlot.timeSlotType === TimeSlotTypeViewModel.HomeDelivery) {
            deliveryState.pageState = basket.value.timeSlot.existingOrderId ? DeliveryPageStates.ExistingHomeDeliverySelected : DeliveryPageStates.HomeDeliverySelected;
        } else if (basket.value.timeSlot.timeSlotType === TimeSlotTypeViewModel.PickUp) {
            deliveryState.pageState = basket.value.timeSlot.existingOrderId ? DeliveryPageStates.ExistingPickupSelected : DeliveryPageStates.PickupSelected;
        }

        if (basket.value.timeSlot.existingOrderId && deliveryState.existingOrder?.orderId !== basket.value.timeSlot.existingOrderId) {
            getOrder(basket.value.timeSlot.existingOrderId).then(res => {
                deliveryState.existingOrder = res;
            });
        }
    } else if (basket.value?.openOrderIds.length > 0) {
        deliveryState.pageState = DeliveryPageStates.ChooseExistingOrder;
    } else {
        deliveryState.pageState = DeliveryPageStates.ChooseDeliveryTypeAndTimeSlot;
    }
} else {
    const { checkoutUrl } = getPageUrls();
    const router = useRouter();
    router.replace(checkoutUrl.value);
}
</script>
