<template>
    <a :href="_id" class="skip" :class="showAbsolute ? 'focus:left-0' : 'focus:static'">{{ label }}</a>
</template>

<script setup lang="ts">
const props = defineProps<{
    id: string,
    label: string,
    showAbsolute?: boolean,
}>();

const _id = props.id.includes('#') ? props.id : `#${props.id}`;
</script>

<style scoped>
.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip:focus {
  width: auto;
  height: auto;
}
</style>
