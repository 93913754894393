<template>
    <slot/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useHead } from '@vueuse/head';
import { getMetaData } from '@/core/browser/metadata/metaData';

export default defineComponent({
    name: 'MetaData',
    setup() {
        const meta = getMetaData();
        useHead(meta);
    },
});
</script>
