<template>
    <DrawerDialog
        v-slot="{close}"
        use-slide-animation
        :drawer-classes="'bg-background'"
        show-backdrop
        position="right">
        <div class="overflow-x-hidden overflow-y-auto h-full w-[510px] bg-background">
            <div class="sticky top-0 z-2">
                <div class="flex items-center py-10 px-20 w-full bg-white justity-center justity-between">
                    <div class="mr-10 text-blue">
                        <CIcon name="basket" width="24" height="24"/>
                    </div>
                    <div class="flex flex-col mr-auto">
                        <span class="block pb-0 mb-0 font-bold text-18 text-blue leading-[1.9rem]">{{ $translate('Client.BasketPage.Basket') }}</span>
                        <span class="text-14 leading-[1.5rem]">
                            {{ quantity }} {{ $translate('Client.BasketPage.Products') }}
                        </span>
                    </div>
                    <div class="items-center font-bold -ml-50">
                        <span class="text-30">{{ formatPrice(subTotal, { hideCurrency: true }) }}</span><span class="ml-4 text-18">{{ $translate('Client.Common.Kroner') }}</span>
                    </div>
                    <button class="ml-auto cursor-pointer" @click="close">
                        <CIcon name="closecircle" width="24" height="24"/>
                    </button>
                </div>

                <div class="flex items-center py-20 px-50 pl-50 bg-background basket-buttons">
                    <Button
                        :to="basketValidated ? checkoutUrl : ''"
                        :label="$translate('Client.BasketPage.ProceedToCheckout')"
                        size="medium"
                        :disabled="!basketValidated"
                        :primary="false"
                        @click="basketValidated && close()"/>
                    <span class="flex justify-center px-20 w-60">{{ $translate('Client.Common.Or') }}</span>
                    <Button :label="$translate('Client.BasketPage.GoToBasket')"
                            size="medium"
                            :to="basketUrl"
                            strong
                            @click="close"/>
                </div>
            </div>
            <div class="flex justify-center py-0 px-20 pb-20 w-full md:px-25">
                <span v-if="subTotal < minimumPurchase">
                    <span class="font-bold">{{ formatPrice(minimumPurchase - subTotal) }}</span>&nbsp;{{ $translate('Client.BasketPage.FromMinPurchase') }}
                </span>
            </div>
            <SystemMessage type="basket" rendered-in-drawer/>
            <TransitionGroup name="list-complete">
                <div v-for="(category, categoryIndex) in categories" :key="'category' + categoryIndex" class="list-complete-item">
                    <div class="py-5 px-20 font-bold bg-backgroundContrastBlue">
                        {{ category.categoryName }}
                    </div>
                    <TransitionGroup name="list-complete">
                        <BasketMiniLineItem v-for="(item, itemIndex) in category.lineItems"
                                            :key="item.sku"
                                            :position="itemIndex"
                                            class="list-complete-item"
                                            :model="item"/>
                    </TransitionGroup>
                </div>
            </TransitionGroup>
        </div>
    </DrawerDialog>
</template>

<script setup lang="ts">
import Button from '@/project/components/button/Button.vue';
import { fetchBasket, getBasket, useBasket } from '@/project/apis/commerce/basketApi';
import BasketMiniLineItem from './BasketMiniLineItem.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import DrawerDialog from '@/project/dialog/DrawerDialog.vue';
import SystemMessage from '@/project/components/system-message/SystemMessage.vue';
import { trackMiniBasket } from '@/project/tracking/tracking.service';

const { basketValidated } = getBasket();

const { subTotal, quantity, minimumPurchase, categories } = useBasket();

const { basketUrl, checkoutUrl } = getPageUrls();

fetchBasket();

trackMiniBasket(subTotal.value >= minimumPurchase.value, quantity.value);
</script>

<style scoped>
.list-complete-move {
  transition: all 0.8s ease;
}

.list-complete-item {
  transition: all 0.3s ease;
  transform-origin: top left;
  overflow: hidden;
  max-height: 3000px;
}

.list-complete-leave-from {
    max-height: 3000px;
    overflow-y: hidden;
    transform: scaleY(1);
}

.list-complete-leave-to {
    max-height: 0;
    overflow-y: hidden;
    transform: scaleY(0);
}

.basket-buttons > a{
    width: 100%;
}
.basket-buttons > a > button{
    width: inherit;
    padding-left: .3rem;
    padding-right: .3rem;
}
</style>
