<template>
    <nav v-if="isMobile" class="flex justify-center space-x-10">
        <RouterLinkConditional v-for="(step, index) in steps"
                               :key="step.name"
                               :url="step.url"
                               :disabled="route.path === step.url">
            <div class="flex items-center space-x-10">
                <span class="flex justify-center items-center font-bold rounded-full h-30 w-30"
                      :class="route.path === step.url ? 'bg-primary text-white' : 'bg-white'">
                    {{ index + 1 }}
                </span>
                <span v-if="route.path === step.url" class="font-bold">{{ step.name }}</span>
                <CIcon v-if="steps.length - 1 !== index"
                       name="arrow-right"
                       width="14"
                       height="14"/>
            </div>
        </RouterLinkConditional>
    </nav>
    <nav v-else class="flex justify-center space-x-40">
        <RouterLinkConditional v-for="(step, index) in steps"
                               :key="step.name"
                               :url="step.url"
                               :disabled="route.path === step.url">
            <div class="flex items-center space-x-40">
                <span class="text-18" :class="route.path === step.url ? 'font-bold' : 'text-fadedText'">
                    {{ index + 1 }}. {{ step.name }}
                </span>
                <CIcon v-if="steps.length - 1 !== index"
                       name="arrow-right"
                       width="14"
                       height="14"/>
            </div>
        </RouterLinkConditional>
    </nav>
</template>

<script setup lang="ts">
import { NameAndUrl } from '@/api/cms';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import { getCheckoutSteps } from '@/project/apis/cms/contentApi';
import { ref, Ref } from 'vue';
import { useRoute } from 'vue-router';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';

const { smaller } = useBreakpoints();
const isMobile = smaller('sm');
const steps: Ref<NameAndUrl[]> = ref([]);

getCheckoutSteps().then(res => {
    steps.value = res;
});

const route = useRoute();
</script>
