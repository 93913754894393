<template>
    <div class="bg-white">
        <slot></slot>
        <Declarations v-slot="{ consent, needsAgeDeclaration}" :terms-and-conditions-text="termsAndConditionsText">
            <div class="mt-20 flex items-center justify-between">
                <Button
                    v-if="isExistingOrder"
                    type="button"
                    size="medium"
                    :label="$translate('Client.Common.Cancel')"
                    transparent
                    @click="cancelAddToExisting"/>
                <CheckoutBackButton v-else class="ml-10"/>
                <DeliveryNextButton
                    class="mr-10 sm:mr-0"
                    :label="$translate('Client.CheckoutPage.Delivery.ContinueToPaymentButton')"
                    :disabled="(!canContinueToPayment || !consent || needsAgeDeclaration || isSubmitting)"
                    @click="continueToPayment()"/>
            </div>
            <div v-if="!canContinueToPayment" class="text-errorText mt-20 flex w-full justify-end">
                <span class="px-10 sm:px-0">{{ $translate(`Client.DeliveryStatus.${statusMessage}Description`) }}</span>
            </div>
        </Declarations>
    </div>
</template>

<script setup lang="ts">
import { getCheckoutBasket } from '@/project/apis/commerce/checkoutApi';
import { computed, ref } from 'vue';
import { BasketValidityViewModel } from '@/api/commerce';
import DeliveryNextButton from '@/project/pages/checkout-page/delivery/DeliveryNextButton.vue';
import CheckoutBackButton from '@/project/navigation/checkout-header/CheckoutBackButton.vue';
import Declarations from '@/project/pages/checkout-page/delivery/Declarations.vue';
import Button from '@/project/components/button/Button.vue';
import { DeliveryPageStates, useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';

defineProps<{
    isExistingOrder?: boolean
    termsAndConditionsText: string
    isSubmitting?: boolean
}>();

const emit = defineEmits(['continueToPayment']);

window.scrollTo(0, 0);

const { basket } = getCheckoutBasket();
const editDeliveryAddress = ref(false);
const statusMessage = computed(() => basket.value?.status);
const canContinueToPayment = computed(() => (statusMessage.value === BasketValidityViewModel.Valid || statusMessage.value === BasketValidityViewModel.TermsAndConditionsNotAccepted) && !editDeliveryAddress.value);
const { deliveryState } = useDeliveryState();

async function continueToPayment() {
    emit('continueToPayment');
}

function cancelAddToExisting() {
    deliveryState.pageState = DeliveryPageStates.ChooseExistingOrder;
}
</script>
