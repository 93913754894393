<template>
    <div v-if="!isRemoving">
        <div v-if="model.invalidReason" class="py-8 px-20 font-bold bg-warning">
            {{ $translate(`Client.BasketStatus.${model.invalidReason}`) }}
        </div>
        <div class="flex items-center pl-20 w-full bg-white border-b border-lightBorder"
             :class="{'border border-warning': model.invalidReason}">
            <RouterLinkConditional :url="model.url" class="flex items-center grow min-h-[60px]" :disabled="!!model.invalidReason">
                <div class="relative mr-10">
                    <div v-if="model.discount"
                         class="flex absolute top-0 -right-5 z-1">
                        <Splash :label="model.discount" size="xs"/>
                    </div>
                    <ResponsiveImage :image-url="model.imageUrl"
                                     :aspect-ratio="1/1"
                                     class="w-50 h-50"
                                     :fixed-width-on-screen="50"
                                     mode="pad"/>
                </div>
                <div class="flex flex-col">
                    <span class="block">
                        {{ model.displayName }}
                    </span>
                    <span class="text-fadedText text-12">
                        {{ productTeasers }}
                    </span>
                </div>
            </RouterLinkConditional>
            <div class="flex flex-col justify-center items-center ml-auto md:min-w-[116px] background-white" @click.prevent>
                <div class="flex justify-center items-center">
                    <div class="relative">
                        <input ref="inputField"
                               class="mx-5 w-40 font-bold text-center rounded border-0 h-35 bg-background z-1 text-14"
                               type="number"
                               :disabled="disableItem"
                               :value="focussingField ? model.quantity : ''"
                               @focus="focussingField = true"
                               @blur="focussingField = false">
                        <div v-if="!focussingField"
                             class="flex absolute inset-0 justify-center items-center cursor-text"
                             @click="inputField.focus()">
                            <AnimateValue animation="scroll">
                                <div :key="model.quantity"
                                     class="font-bold"
                                     :class="{'text-fadedText': disableItem}">
                                    {{ model.quantity }}
                                </div>
                            </AnimateValue>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Price details -->
            <div class="flex flex-col justify-center items-end w-80 md:min-w-90">
                <span v-if="model.discount"
                      class="font-bold text-right text-save text-12"
                      :class="{'text-fadedText': disableItem}">
                    {{ $translate("Client.ProductDetailsPage.Save") }} {{ formatPrice(model.totalDiscountAmount) }}
                </span>
                <span v-else class="text-12">&nbsp;</span>
                <span class="font-bold text-right text-14 leading-[1.5rem]">{{ formatPrice(model.totalDiscountedPrice || model.totalSalesPrice) }}</span>
                <span v-if="model.pricePerUnit" class="text-right text-fadedText text-12">
                    {{ formatPrice(model.pricePerUnit) }} / {{ model.unitOfMeasure }}
                </span>
                <span v-else class="text-12">&nbsp;</span>
                <div v-if="model.bonus" class="my-5">
                    <span class="py-2 px-5 font-bold text-white whitespace-nowrap rounded-lg bg-bonus-background text-11">
                        {{ $translate('Client.Common.BonusShort', model.bonus.bonusPercentage) }}%
                    </span>
                </div>
            </div>

            <!-- Delete button -->
            <div class="mx-10">
                <div :class="{'bg-warning rounded-full': !!model.invalidReason}">
                    <Button class="cursor-pointer" transparent @click="deleteItem">
                        <CIcon name="delete"
                               width="20"
                               height="20"
                               class="opacity-80 text-removeLineItem"/>
                    </Button>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
        <LineItemRemoveState :display-name="model.displayName" :image-url="model.imageUrl" @undo="stopRemoval"/>
    </div>
</template>

<script setup lang="ts">

import { DeepReadonly, ref } from 'vue';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import Button from '@/project/components/button/Button.vue';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Splash from '@/project/products/Splash/Splash.vue';
import { LineItemViewModel } from '@/api/commerce';
import { formatPrice } from '@/project/products/priceFormatter';
import { useLineItem } from '../../apis/commerce/basketApi';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';
import LineItemRemoveState from '@/project/basket/basket-line-item/LineItemRemoveState.vue';

const props = defineProps<{
    model: DeepReadonly<LineItemViewModel>;
    position?: number;
}>();

const emit = defineEmits(['remove']);

const inputField = ref();
const focussingField = ref(false);

const productTeasers = [
    props.model.brand,
    props.model.weight,
    props.model.productTeaser,
].filter(elem => elem).join(', ');

const { manualChange, isRemoving, stopRemoval } = useLineItem(props.model.sku, { onRemove: () => emit('remove') });

const disableItem = true;

function deleteItem() {
    manualChange(0);
}
</script>
