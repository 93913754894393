<template>
    <div v-for="order in openOrders.sort((a, b) => +getDate(a.timeSlot!.from) - +getDate(b.timeSlot!.from))" :key="order.orderId">
        <TextWrapper v-if="order.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery" class="flex flex-wrap justify-between">
            <div class="flex">
                <div>
                    <div class="p-8 w-40 h-40 rounded-full bg-blue">
                        <CIcon name="delivery" width="24" height="24" class="text-white"/>
                    </div>
                </div>
                <div class="ml-20">
                    <h2 class="mt-7 font-bold mb-15 text-18">
                        {{ $translate('Client.CheckoutPage.Delivery.HomeDeliveryOptionExisting') }}
                    </h2>
                    <div class="sm:flex mb-10 font-bold">
                        <div>
                            <span class="capitalize">{{ getWeekday(order.timeSlot.from) }}</span>, {{ new Date(order.timeSlot.from).getDate() }}. {{ getMonth(order.timeSlot.from) }}
                        </div>
                        <div>
                            <span class="hidden sm:inline">&nbsp;</span>{{ $translate('Client.Common.Between') }} {{ getTime(order.timeSlot.from) }} - {{ getTime(order.timeSlot.to) }}
                        </div>
                    </div>
                    <div v-if="order.deliveryAddress" class="mb-10">
                        <div class="space-y-4">
                            <div class="font-bold">
                                {{ order.deliveryAddress.firstName }} {{ order.deliveryAddress.lastName }}
                            </div>
                            <div>{{ order.deliveryAddress.addressLine1 }}</div>
                            <div>{{ order.deliveryAddress.addressLine2 }}</div>
                            <div>{{ order.deliveryAddress.zipCode }} {{ order.deliveryAddress.city }}</div>
                        </div>
                    </div>
                    <div class="mb-10">
                        {{ $translate('Client.CheckoutPage.Delivery.OrderNumber') }}: {{ order.orderId }}
                    </div>
                </div>
            </div>
            <div class="ml-60">
                <Button :label="$translate('Client.Common.Choose')" @click="choose(order)"/>
            </div>
        </TextWrapper>
        <TextWrapper v-else-if="order.timeSlot?.timeSlotType === TimeSlotTypeViewModel.PickUp" class="flex flex-wrap justify-between">
            <div class="flex">
                <div>
                    <div class="p-8 w-40 h-40 rounded-full bg-blue">
                        <CIcon name="store" width="24" height="24" class="text-white"/>
                    </div>
                </div>
                <div class="ml-20">
                    <h2 class="mt-7 font-bold mb-15 text-18">
                        {{ $translate('Client.CheckoutPage.Delivery.PickUpPointExisting') }}
                    </h2>
                    <div class="sm:flex mb-10 font-bold">
                        <div>
                            <span class="capitalize">{{ getWeekday(order.timeSlot.from) }}</span>, {{ new Date(order.timeSlot.from).getDate() }}. {{ getMonth(order.timeSlot.from) }}
                        </div>
                        <div>
                            <span class="hidden sm:inline">&nbsp;</span>{{ $translate('Client.Common.Between') }} {{ getTime(order.timeSlot.from) }} - {{ getTime(order.timeSlot.to) }}
                        </div>
                    </div>
                    <div v-if="order.store" class="mb-10">
                        <ChainLogo :chain="order.store.chain"/>
                        <div class="mt-10">
                            <div>{{ order.store.address.street }} {{ order.store.address.houseNumber }}</div>
                            <div>{{ order.store.address.zipCode }} {{ order.store.address.city }}</div>
                        </div>
                    </div>
                    <div class="mb-10">
                        {{ $translate('Client.CheckoutPage.Delivery.OrderNumber') }}: {{ order.orderId }}
                    </div>
                </div>
            </div>
            <div class="ml-60">
                <Button :label="$translate('Client.Common.Choose')" @click="choose(order)"/>
            </div>
        </TextWrapper>

        <!-- "Or" line -->
        <div class="flex items-center h-40">
            <div class="ml-20 sm:ml-0 w-full border-b border-[#E3E3E3] mr-15"></div>
            <div class="font-bold">
                {{ $translate('Client.Common.Or') }}
            </div>
            <div class="mr-20 sm:mr-0 w-full border-b border-[#E3E3E3] ml-15"></div>
        </div>
    </div>
    <div v-if="basket">
        <DeliveryTypeChooserUndoable v-if="basket.subTotal > basket.minimumPurchase"/>
        <TextWrapper v-else class="flex">
            <div>
                <div class="p-8 w-40 h-40 rounded-full bg-blue">
                    <CIcon name="location" width="24" height="24" class="text-white"/>
                </div>
            </div>
            <div class="ml-20">
                <h2 class="mt-7 font-bold mb-15 text-18">
                    {{ $translate('Client.CheckoutPage.Delivery.ChooseDeliveryOptions') }}
                </h2>
                <p class="mb-20">
                    {{ $translate('Client.CheckoutPage.Delivery.ReachMinimumExplainer') }}
                </p>

                <p class="mb-20">
                    {{ $translate('Client.CheckoutPage.Delivery.YouAre') }} <span class="font-bold">{{ formatPrice(basket.minimumPurchase - basket.subTotal) }}</span> {{ $translate('Client.CheckoutPage.Delivery.FromMinimum') }}
                </p>

                <RouterLink :to="basketUrl">
                    <div class="flex items-center text-primary">
                        <CIcon name="arrow-left" width="10" height="10" class="mr-10 text-primary"/>
                        <span>{{ $translate('Client.Common.Back') }} {{ $translate('Client.Common.To') }} {{ $translate('Client.CheckoutPage.BasketStep') }}</span>
                    </div>
                </RouterLink>
            </div>
        </TextWrapper>
    </div>
</template>

<script setup lang="ts">
import { getOrder } from '@/project/apis/commerce/orderApi';
import { addToExistingTimeSlot, getCheckoutBasket, setDeliveryZipCode } from '@/project/apis/commerce/checkoutApi';
import { BasketValidityViewModel, OrderDetailsViewModel, TimeSlotTypeViewModel } from '@/api/commerce';
import { Ref, ref } from 'vue';
import TextWrapper from '@/project/components/text-wrapper/TextWrapper.vue';
import { getWeekday, getMonth, getTime } from '@/project/utils/datetime';
import Button from '@/project/components/button/Button.vue';
import ChainLogo from '@/project/pages/checkout-page/delivery/ChainLogo.vue';
import DeliveryTypeChooserUndoable from '@/project/pages/checkout-page/delivery/DeliveryTypeChooserUndoable.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { getPageUrls } from '@/project/apis/cms/contentApi';
import { DeliveryPageStates, useDeliveryState } from '@/project/pages/checkout-page/delivery/delivery-state.composeable';

const { basket, status } = getCheckoutBasket();

const { basketUrl } = getPageUrls();

const { deliveryState } = useDeliveryState();

const openOrders: Ref<OrderDetailsViewModel[]> = ref([]);

basket.value?.openOrderIds.forEach((id) => {
    getOrder(id).then(res => {
        openOrders.value.push(res);
    });
});

async function choose(order: OrderDetailsViewModel) {
    const _basket = await setDeliveryZipCode(order.deliveryAddress?.zipCode ?? order.store!.address.zipCode);
    if (_basket.status === BasketValidityViewModel.HasInvalidLineItem) {
        return;
    }
    await addToExistingTimeSlot(order.orderId);
    deliveryState.existingOrder = order;
    window.scrollTo(0, 0);
    if (order.timeSlot?.timeSlotType === TimeSlotTypeViewModel.HomeDelivery) {
        deliveryState.pageState = DeliveryPageStates.ExistingHomeDeliverySelected;
    } else if (order.timeSlot?.timeSlotType === TimeSlotTypeViewModel.PickUp) {
        deliveryState.pageState = DeliveryPageStates.ExistingPickupSelected;
    }
}

function getDate(dateString: string) {
    return new Date(dateString);
}
</script>
