const allBots = /(headlesschrome|prerender|prerender.cloud|googlebot|googlebot-mobile|duckduckbot|bingbot|baiduspider|facebookexternalhit|twitterbot|yandex|rogerbot|linkedinbot|embedly|bufferbot|quora link preview|showyoubot|outbrain|bot|crawler|80legs|ia_archiver|voyager|curl|wget|yahoo! slurp|mediapartners-google|prtg|monitis|demandbase|shopit|spider)/i;
const prerenderBots = /(prerender|prerender.cloud)/i;

export function isUserAgentBot(): boolean {
    const userAgent = navigator.userAgent;
    const result = allBots.test(userAgent);
    return result;
}

export function isUserAgentPrerender(): boolean {
    const userAgent = navigator.userAgent;
    const result = prerenderBots.test(userAgent);
    return result;
}
