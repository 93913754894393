<template>
    <div v-if="wideLayout" class="grid py-8 w-full bg-white px-30 grid-cols-24">
        <RouterLinkConditional :url="model.url" class="flex col-span-6 items-center">
            <div class="flex flex-col">
                <div>
                    {{ model.displayName }}
                </div>
                <div class="text-fadedText">
                    {{ productTeasers }}
                </div>
            </div>
        </RouterLinkConditional>
        <div class="flex col-span-4 justify-end items-center text-right">
            {{ model.weight }}
        </div>
        <div class="flex col-span-4 justify-center sm:justify-end items-center">
            {{ model.quantity }}
        </div>
        <div class="flex col-span-3 justify-center sm:justify-end items-center text-right">
            {{ formatPrice(model.salesPrice) }}
        </div>
        <div class="flex col-span-4 justify-end items-center text-right">
            {{ formatPrice(model.totalDiscountAmount) }}
        </div>
        <div class="flex col-span-5 sm:col-span-3 justify-end items-center text-right">
            {{ formatPrice(model.totalDiscountedPrice || model.totalSalesPrice) }}
        </div>
    </div>
    <div v-else class="grid py-8 pr-10 pl-20 w-full bg-white grid-cols-24">
        <RouterLinkConditional :url="model.url" class="col-span-10 text-left cell">
            <div class="flex flex-col">
                <div class="break-all">
                    {{ model.displayName }}
                </div>
                <div class="text-fadedText">
                    {{ productTeasers }}
                </div>
                <div class="text-fadedText">
                    {{ model.weight }}
                </div>
            </div>
        </RouterLinkConditional>
        <div class="col-span-4 justify-center cell">
            {{ model.quantity }}
        </div>
        <div class="col-span-5 justify-end cell">
            {{ formatPrice(model.salesPrice) }}
        </div>
        <div class="flex flex-col col-span-5 justify-center text-right text-13" :class="{'pt-[34px]': model.totalDiscountAmount}">
            <span>{{ formatPrice(model.totalDiscountedPrice) }}</span>
            <div v-if="model.totalDiscountAmount" class="text-11">
                <div>
                    {{ $translate('Client.OrderPage.TotalDiscountOnItem') }}
                </div>
                <div>{{ formatPrice(model.totalDiscountAmount) }}</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { OrderLineItemViewModel } from '@/api/commerce';
import { DeepReadonly } from 'vue';
import { formatPrice } from '@/project/products/priceFormatter';
import RouterLinkConditional from '@/core/navigation/RouterLinkConditional.vue';

const props = defineProps<{
    model: DeepReadonly<OrderLineItemViewModel>;
    wideLayout: boolean;
}>();

const productTeasers = [
    props.model.brand,
    props.model.productTeaser,
].filter(elem => elem).join(', ');

</script>

<style scoped>
    .cell {
        @apply flex items-center px-2 text-13;
    }
</style>
