<template>
    <div>
        <p>{{$translate('Client.ProductDetailsPage.Nutrition.NutritionContentPer')}}: {{nutrition.nutritionContentPer}}</p>
        <div class="flex space-x-60">
            <div>
                <div v-for="nutrient in nutritionArray" :key="nutrient.label">
                    {{$translate(nutrient.label)}}
                </div>
            </div>
            <div>
                <div v-for="nutrient in nutritionArray" :key="nutrient.label">
                    {{nutrient.model}}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ProductNutritionViewModel } from '@/api/commerce';

const props = defineProps<{
    nutrition: ProductNutritionViewModel;
}>();

const nutritionArray = [
    {
        model: `${props.nutrition.energy} / ${props.nutrition.calories}`,
        label: 'Client.ProductDetailsPage.Nutrition.Energy',
    },
    {
        model: props.nutrition.fat,
        label: 'Client.ProductDetailsPage.Nutrition.Fat',
    },
    {
        model: props.nutrition.saturatedFat,
        label: 'Client.ProductDetailsPage.Nutrition.SaturatedFat',
    },
    {
        model: props.nutrition.carbohydrates,
        label: 'Client.ProductDetailsPage.Nutrition.Carbohydrates',
    },
    {
        model: props.nutrition.sugar,
        label: 'Client.ProductDetailsPage.Nutrition.Sugar',
    },
    {
        model: props.nutrition.fibers,
        label: 'Client.ProductDetailsPage.Nutrition.Fibers',
    },
    {
        model: props.nutrition.protein,
        label: 'Client.ProductDetailsPage.Nutrition.Protein',
    },
    {
        model: props.nutrition.salt,
        label: 'Client.ProductDetailsPage.Nutrition.Salt',
    },
    {
        model: props.nutrition.sodium,
        label: 'Client.ProductDetailsPage.Nutrition.Sodium',
    },
];
</script>

<style scoped>

</style>
