<template>
    <div class="watermark">
        <div class="page-spacing">
            <Breadcrumb v-if="navigation.breadcrumb.length" :breadcrumbs="navigation.breadcrumb.slice(1)" :current-page-name="metadata.navigationTitle"/>

            <div class="flex items-start" :class="{'flex-col': compactMenu}">
                <template v-if="model.showLeftNavigation">
                    <div v-if="compactMenu" class="flex justify-center py-5 px-20 mb-20 w-full bg-white sm:rounded-lg">
                        <Button :label="metadata.navigationTitle"
                                :primary="false"
                                strong
                                :list="true"
                                @click="openDrawer">
                            <template #right>
                                <CIcon name="arrow-down" width="12" height="12"/>
                            </template>
                        </Button>
                    </div>
                    <ContentNavigation v-else :menu="navigation.menu"/>
                </template>

                <div class="flex items-start lg:space-x-10 xl:space-x-20 w-full">
                    <div class="w-full">
                        <RenderBlocks :model="model.bodyContent"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ContentPageModel, IMetadata, SiteNavigation } from '@/api/cms';
import RenderBlocks from './RenderBlocks.vue';
import Breadcrumb from '@/project/navigation/breadcrumb/Breadcrumb.vue';
import ContentNavigation from '@/project/content/navigation/ContentNavigation.vue';
import ContentNavigationDrawer from '@/project/content/navigation/ContentNavigationDrawer.vue';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import Button from '@/project/components/button/Button.vue';
import openDialog from '@/project/dialog/dialog';
import { trackPage } from '@/project/tracking/tracking.service';

const props = defineProps<{
    metadata: IMetadata,
    model: ContentPageModel,
    navigation: SiteNavigation,
}>();

trackPage();

const { smaller } = useBreakpoints();
const compactMenu = smaller('lg');

function openDrawer() {
    openDialog(ContentNavigationDrawer, { menu: props.navigation.menu });
}

</script>
