import log from '../infrastructure/logging';
import { App } from 'vue';
import dummyDictionary from './dummy-dictionary';

class Dictionary {
    private dictionaryMap;

    constructor() {
        // This is to make calls to dictionary.get work in Storybook. Is overwritten by init.
        this.dictionaryMap = new Map<string, string>(Object.entries(dummyDictionary));
    }

    public get(key: string, ...args: (string | number)[]) {
        if (window.location.hash.indexOf('showLabelNames=1') !== -1) {
            return `##${key}`;
        }

        let translated: string | undefined;
        if (this.dictionaryMap) {
            translated = this.dictionaryMap.get(key);
        }
        if (translated === undefined) {
            log.error(`### Key '${key}' not found in dictionary ###`);
            return `##${key}`;
        }
        return this.format(translated, ...args);
    }

    private format(input: string, ...args: (string | number)[]): string {
        const stringArgs = args.map(arg => arg.toString());
        return stringArgs.reduce((result, arg, ix) => {
            return result.split(`{${ix}}`).join(arg);
        }, input);
    }

    init(labels: Record<string, string>) {
        this.dictionaryMap = new Map<string, string>(Object.entries(labels));
    }
}

const dictionary = new Dictionary();

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $translate: (key: string, ...params: (string | number)[]) => string;
    }
}

export function config(app: App): void {
    app.config.globalProperties.$translate = dictionary.get.bind(dictionary);
}

export default dictionary;
