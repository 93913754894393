<template>
    <RouterLink :to="model.url" class="pt-15 flex flex-col justify-between rounded-lg bg-white p-10" @click="onSelectProduct">
        <div class="relative" :class="{'opacity-60': soldOut}">
            <div
                v-if="model.offer?.description"
                class="z-2 absolute -top-4 right-0 flex">
                <Splash :label="model.offer.description" size="md"/>
            </div>
            <ResponsiveImage
                v-if="model.imageUrl"
                :image-url="model.imageUrl"
                :aspect-ratio="1/1"
                :width-on-screen="{
                    xs: 50,
                    md: 25,
                    lg: 20
                }"
                mode="pad"/>
            <img
                v-else
                class="aspect-square p-20 opacity-80"
                :src="fallbackImage"
                width="206">
            <span v-if="stockExceeded" class="text-12 bg-error absolute bottom-0 right-0 rounded-lg px-4">
                {{ $translate('Client.BasketStatus.NotEnoughInStock') }}
            </span>
            <span v-else-if="fewLeftInStock" class="text-12 bg-error absolute bottom-0 right-0 rounded-lg px-4">
                {{ $translate('Client.ProductDetailsPage.FewLeft') }}
            </span>
        </div>
        <div class="mt-15">
            <div class="max-h-80 overflow-hidden">
                <span :title="model.displayName" class="ellipsis text-13 xl:text-14 mb-4 leading-6">
                    {{
                        model.displayName
                    }}
                </span>
                <span
                    v-if="productTeasers"
                    :title="productTeasers"
                    class="ellipsis text-11 text-fadedText xl:text-12 leading-5">
                    {{ productTeasers }}
                </span>
            </div>
            <div class="relative mt-4">
                <div class="absolute right-0 top-0 flex space-x-4">
                    <CIcon
                        v-for="icon in model.hazardLabels"
                        :key="icon"
                        :name="icon"
                        width="20"
                        height="20"
                        original
                        translate/>
                    <CIcon
                        v-for="icon in model.icons"
                        :key="icon"
                        :name="icon"
                        width="20"
                        height="20"
                        original
                        translate/>
                </div>
                <div class="w-full">
                    <div>
                        <span
                            v-if="model.offer?.discountAmount"
                            class="text-save text-10 xl:text-11 font-bold">{{ $translate('Client.ProductDetailsPage.Save') }} {{ formatPrice(model.offer.discountAmount) }}</span>
                        <span v-else class="h-15">&nbsp;</span>
                    </div>
                    <div>
                        <span class="text-20 xl:text-22 font-bold">
                            {{ formatPrice((model.offer?.discountedSalesPrice) || model.salesPrice, {hideCurrency: true}) }}
                            <span
                                class="text-13 xl:text-14 font-bold">{{ $translate('Client.Common.Kroner') }}</span>
                        </span>
                        <span
                            v-if="model.offer?.discountedSalesPrice"
                            class="text-fadedText text-12 xl:text-13 ml-7 inline-block line-through">
                            {{ formatPrice(model.salesPrice) }}
                        </span>
                    </div>
                    <div class="relative flex items-end justify-end" @click.prevent.stop="">
                        <div v-if="!soldOut" class="absolute inset-0 flex flex-col overflow-visible" :class="{'w-full': !expanded && (model.availableInStock !== 0 || model.alwaysInStock) && canAddToBasket}">
                            <div
                                v-if="model.pricePerUnit && model.unitOfMeasure && !expanded"
                                class="text-fadedText text-10 xl:text-11 absolute top-0"
                                :class="{'absolute bottom-0': soldOut}">
                                {{ formatPrice(model.pricePerUnit) }} / {{
                                    model.unitOfMeasure
                                }}
                            </div>
                            <div v-if="model.loyalty" class="text-12 text-primary absolute bottom-1 w-full max-w-[80%] leading-[12px]">
                                {{ $translate('Client.ProductDetailsPage.EarnBonus') }} <span class="whitespace-nowrap">{{ formatPrice(model.loyalty?.amount) }}</span>
                            </div>
                        </div>
                        <div v-if="soldOut" class="flex h-40 w-full items-center justify-center">
                            {{ $translate('Client.ProductDetailsPage.OutOfStock') }}
                        </div>
                        <div v-else-if="!canAddToBasket" class="text-12 lg:text-13 flex h-40 w-full items-center justify-center text-center">
                            {{ $translate('Client.ProductDetailsPage.AgeLimitMessage', 18) }}
                        </div>
                        <div
                            v-else
                            class="flex w-full justify-end overflow-hidden"
                            @mouseenter="onMouseEnter"
                            @mouseleave="onMouseLeave">
                            <div
                                class="flex transition-transform duration-200 ease-in-out"
                                :class="{ 'translate-x-0': expanded, 'translate-x-full': !expanded }">
                                <Button
                                    :tabindex="expanded ? '0' : '-1'"
                                    :class="{'rounded-r-none': expanded, 'hidden': !expanded && hideAfterDelay}"
                                    role="button"
                                    size="medium"
                                    :aria-label="$translate('Client.A11y.DecrementQuantity')"
                                    :aria-hidden="!expanded"
                                    @click="onDecrement"
                                    @focusin="onFocusButton"
                                    @focusout="onUnfocusButton">
                                    <CIcon name="minus" width="20" height="20" color="#FFFFFF"/>
                                </Button>
                                <div class="relative" :aria-hidden="!expanded">
                                    <input
                                        ref="inputField"
                                        :tabindex="expanded ? 0 : -1"
                                        class="bg-background text-14 h-40 w-full border-0 text-center font-bold"
                                        type="number"
                                        :value="focusingField ? lineItem?.quantity || 0 : ''"
                                        @focusin="onFocusField"
                                        @focusout="onUnfocusField"
                                        @change="onChange">
                                    <div v-if="!focusingField" class="absolute inset-0 flex items-center justify-center" @click="inputField.focus()">
                                        <AnimateValue animation="scroll">
                                            <div :key="lineItem ? lineItem.quantity : 0" class="font-bold">
                                                {{ lineItem?.quantity || 0 }}
                                            </div>
                                        </AnimateValue>
                                    </div>
                                </div>
                            </div>
                            <Button
                                v-if="(expanded || !lineItem)"
                                role="button"
                                class="z-1 border-save border-1 group"
                                :class="{ 'rounded-l-none': expanded }"
                                size="medium"
                                :aria-label="expanded ? $translate('Client.A11y.IncrementQuantity') : $translate('Client.ProductDetailsPage.AddToBasket')"
                                :disabled="stockLimitHit || stockExceeded"
                                @click="onIncrement"
                                @focusin="onFocusButton"
                                @focusout="onUnfocusButton">
                                <AnimateValue animation="scroll">
                                    <div :key="Number(expanded)">
                                        <CIcon
                                            v-if="!expanded && !lineItem"
                                            name="basket"
                                            width="20"
                                            height="20"
                                            color="#FFFFFF"
                                            class="scale-100 transition-transform duration-200 ease-in group-hover:scale-110"/>
                                        <CIcon
                                            v-if="expanded"
                                            name="plus"
                                            width="20"
                                            height="20"
                                            color="#FFFFFF"/>
                                    </div>
                                </AnimateValue>
                            </Button>
                            <input
                                v-if="!expanded && lineItem && lineItem.quantity > 0"
                                class="z-1 bg-background text-14 h-40 w-40 rounded border-0 text-center font-bold"
                                type="number"
                                :value="lineItem?.quantity"
                                @focus="onEdit">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </RouterLink>
</template>

<script setup lang="ts">
import { ProductTileViewModel } from '@/api/commerce';
import { watch, ref } from 'vue';
import Button from '@/project/components/button/Button.vue';
import { useTimeout, useTimeoutFn } from '@vueuse/core';
import { useBreakpoints } from '@/core/responsive/breakpoints/breakpoints.composable';
import ResponsiveImage from '@/core/responsive/image/ResponsiveImage.vue';
import Splash from '@/project/products/Splash/Splash.vue';
import { formatPrice } from '@/project/products/priceFormatter';
import { useLineItem } from '@/project/apis/commerce/basketApi';
import { parseQuantity } from '@/project/products/quantityParser';
import AnimateValue from '@/project/animation/AnimateValue.vue';
import { fallbackImage } from '@/project/pages/product-details/fallback-image';
import { TrackingContext, trackGoToPdp, trackAddToBasket } from '@/project/tracking/tracking.service';
import { isAuthenticated } from '@/project/authentication/authentication';
import { useStock } from '@/project/products/useStock';
import { useAgeLimit } from '@/project/products/useAgeLimit';

const props = defineProps<{
    model: ProductTileViewModel,
    trackingContext?: TrackingContext,
}>();

const expanded = ref(false);
const focusingField = ref(false);
const inputField = ref();
const hovering = ref(false);
const breakpoints = useBreakpoints();
const isLikelyTouchScreen = breakpoints.isSmaller('lg');

const canAddToBasket = useAgeLimit(props.model.ageLimit);

const { start: starthideAfterDelay, ready: hideAfterDelay } = useTimeout(200, { controls: true });

// On mouse leave, start timeout to contract input field
// Stop the timeout if mouse enters again (see div in template above)
const { start, stop } = useTimeoutFn(() => {
    if (!focusingField.value) {
        expanded.value = false;
        starthideAfterDelay();
    }
}, isLikelyTouchScreen ? 2000 : 1000); // On touch (small) screens the slide effect should delay longer

const { decrement, increment, lineItem, manualChange } = useLineItem(props.model.sku);
const { fewLeftInStock, soldOut, stockLimitHit } = useStock(props.model, lineItem);

const stockExceeded = ref(false);

watch(lineItem, (li) => {
    if (!props.model.alwaysInStock && li?.availableInStock) {
        stockExceeded.value = li.availableInStock - li.quantity <= 0;
    } else {
        stockExceeded.value = false;
    }
}, { immediate: true });

const commonTrackingFields = {
    product: props.model,
    lineItemMode: false,
    list: props.trackingContext?.impressionList,
    index: props.trackingContext?.index,
};

const onIncrement = () => {
    if (isAuthenticated.value) {
        // When clicking basket button, expand the input
        expanded.value = true;
        trackAddToBasket({
            increasedQuantity: true,
            quantity: 1,
            ...commonTrackingFields,
        });
    }
    increment();
};

const onDecrement = () => {
    decrement();
    trackAddToBasket({
        increasedQuantity: false,
        quantity: 1,
        ...commonTrackingFields,
    });
};

let prevQuantity = lineItem.value?.quantity ?? 1;
const onChange = (e: any) => {
    let newValue = parseQuantity(e.target.value);
    if (newValue >= 0) {
        if (newValue > lineItem.value!.availableInStock) {
            newValue = lineItem.value!.availableInStock;
        }
        manualChange(newValue, { immediate: true });
        // Below collapses the input when hitting enter in the input field
        focusingField.value = false;
        inputField.value.blur();
        start();
    }

    const isAdding = prevQuantity < newValue;
    trackAddToBasket({
        increasedQuantity: isAdding,
        quantity: isAdding ? newValue - prevQuantity : prevQuantity - newValue,
        ...commonTrackingFields,
    });
    prevQuantity = newValue;
};

const onMouseEnter = () => {
    stop();
    hovering.value = true;
};

const onMouseLeave = () => {
    start();
    hovering.value = false;
};

const onFocusButton = () => {
    stop();
};

const onUnfocusButton = () => {
    start();
};

const onFocusField = () => {
    stop();
    focusingField.value = true;
};

const onUnfocusField = () => {
    focusingField.value = false;
    if (!hovering.value) {
        start();
    }
};

const onEdit = () => {
    expanded.value = true;
    useTimeoutFn(() => {
        inputField.value.focus();
    }, 300);
};

const productTeasers = [
    props.model.brand,
    props.model.weight,
    props.model.productTeaser,
].filter(elem => elem).join(', ');

const onSelectProduct = () => {
    if (props.trackingContext) {
        trackGoToPdp(props.model, props.trackingContext?.index, props.trackingContext?.impressionList);
    }
};
</script>

<style scoped>
    .ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
</style>
