<template>
    <div class="items-center p-20 mt-10 sm:rounded-lg bg-backgroundContrastBlue">
        <label class="flex">
            <InputCheckbox :value="consent" name="terms" @click="onClick"/>
            <span class="terms-and-conditions" v-html="termsAndConditionsText"></span>
        </label>
    </div>
</template>

<script setup lang="ts">
import { removeAllOfType } from '@/project/components/system-message/system-message.service';
import InputCheckbox from '@/project/forms/InputCheckbox.vue';

defineProps<{
    consent: boolean;
    termsAndConditionsText: string;
}>();

const emit = defineEmits(['click']);

function onClick() {
    emit('click');
    removeAllOfType('delivery');
}
</script>

<style scoped>
.terms-and-conditions:deep() a {
    @apply text-link;
}
</style>
