<template>
    <article class="fallback">
        <h2><b>{{ type }}</b> page not registered!</h2>
        <pre>{{ jsonContent }}</pre>
    </article>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { SitePageData } from '@/api/cms';

export default defineComponent({
    name: 'FallbackPage',
    props: {
        alias: {
            type: String,
            required: true,
        },
        model: {
            type: Object as PropType<SitePageData>,
            required: true,
        },
    },
    setup(props) {
        return {
            jsonContent: JSON.stringify(props.model, null, 2),
            type: props.alias,
        };
    },
});
</script>

<style scoped>
.fallback {
    border: 1px dashed black;
    background-color: rgba(0, 131, 255, 0.6);
    padding: 10px;
    overflow: hidden;
}
</style>
